/* eslint-disable operator-assignment, react/sort-comp */

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import { useIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { copyHandler, duplicateItemHelper, getPanelName, handleLayerPosition, isImageOnly, isVideoOnly, pasteHandler } from "../../timeline/timeline-helper";
import { STATIC_PATH } from "../../../constants/config";
import content from "../../../constants/content";
import { updateItem, updateTimelineTime } from "../../../redux/actions/timelineUtils";
import { ARRANGE_MENU_LEFT_CLASS, ArrangeMenuStyled, ContextMenuListStyled, ContextMenuOptionStyled, ContextMenuStyled, GAP_BETWEEN_MENU, OptionTextStyled, VolumeSliderContainer } from "./workspace-contextmenu-components";
import Slider from "../../zoom/zoom-slider";
import { setClipboardData, setExpandPanel, setPropertyPanel, setSwapInfo, setSwapItem } from "../../../redux/actions/appUtils";
import { audioDetachHelper } from "../../panels/library-helper";
import withNotify from "../../../helper/hoc/withNotify";
import { getAudioSource } from "../../../helper/URLHelper";
import { checkAudioBuffer } from "../../../helper/checkAudioBuffer";
import vmTheme from "../../../constants/theme";
import { addNotification } from "../../../redux/actions/notificationUtils";
import uuid from "../../../helper/uuid";

const ContextMenuOption = (props) => {
    const intl = useIntl();
    const msg = intl.formatMessage({ id: props.langId });
    const showSubMenu = Boolean(props.isSubMenuOpen && props.subMenu);
    let selectedClass = props.isSubMenuOpen ? " cmo--selected" : "";
    if (props.isDisabled) {
        selectedClass = " cmo--disabled";
    }

    let hoverImgSrc;
    let disabledImgSrc;
    if (!props.hoverImgSrc && props.imgSrc) {
        hoverImgSrc = `url(${props.imgSrc})`;
    } else if (props.hoverImgSrc) {
        hoverImgSrc = `url(${props.hoverImgSrc})`;
    }
    if (!props.disabledImgSrc && props.imgSrc) {
        disabledImgSrc = `url(${props.imgSrc})`;
    } else if (props.disabledImgSrc) {
        disabledImgSrc = `url(${props.disabledImgSrc})`;
    }

    return (
        <ContextMenuOptionStyled
            onClick={props.onClick}
            $disabled={props.isDisabled}
            className={props.className + selectedClass}
            style={{
                "--hoverSrc": hoverImgSrc,
                "--disabledSrc": disabledImgSrc,
            }}
        >
            {props.imgSrc && <img className="cmo--icon" src={props.imgSrc} alt={msg} />}
            <OptionTextStyled>{msg}</OptionTextStyled>
            {
                props.subMenu && (
                    <img className="cmo--submenu-trigger" src={`${STATIC_PATH}zoom-drop.svg`} alt={"more"} />
                )
            }
            {showSubMenu && props.subMenu}
        </ContextMenuOptionStyled>
    );
}
ContextMenuOption.propTypes = {
    langId: PropTypes.string,
    subMenu: PropTypes.object,
    isSubMenuOpen: PropTypes.bool,
    onClick: PropTypes.func,
    imgSrc: PropTypes.string,
    hoverImgSrc: PropTypes.string,
    disabledImgSrc: PropTypes.string,
    isDisabled: PropTypes.bool,
    className: PropTypes.string,
};
ContextMenuOption.defaultProps = {
    className: "",
};

const ArrangeMenu = (props) => {
    const { arrangeMenuRef, workspaceItems, selectedItemId, handleTrackChange } = props;
    const theme = useSelector((state) => state.app.get('theme'));

    const iconStatus = useMemo(() => {
        const currentItem = workspaceItems.get(selectedItemId);
        const lastTrack = workspaceItems.reduce((prevTrack, item) => {
            return item.get("track") > prevTrack ? item.get("track") : prevTrack;
        }, 0);
        return {
            back: currentItem.get("track") > 0,
            backward: currentItem.get("track") > 0,
            front: currentItem.get("track") < lastTrack,
            forward: currentItem.get("track") < lastTrack,
        };
    }, [workspaceItems, selectedItemId]);

    return (
        <ArrangeMenuStyled
            className="arrange-context-menu"
            onClick={e => e.stopPropagation()}
            ref={arrangeMenuRef}
        >
            <ContextMenuListStyled>
                <ContextMenuOption
                    onClick={() => handleTrackChange("forward")}
                    isDisabled={!iconStatus.forward}
                    langId={content.FORWARD}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.forwardIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.forwardIcon}`}
                    disabledImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.forwardDisabledIcon}`}
                />
                <ContextMenuOption
                    onClick={() => handleTrackChange("backward")}
                    isDisabled={!iconStatus.backward}
                    langId={content.BACKWARD}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backwardIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backwardIcon}`}
                    disabledImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backwardDisabledIcon}`}
                />
                <ContextMenuOption
                    onClick={() => handleTrackChange("front")}
                    isDisabled={!iconStatus.front}
                    langId={content.TO_FRONT}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.frontIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.frontIcon}`}
                    disabledImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.frontDisabledIcon}`}
                />
                <ContextMenuOption
                    onClick={() => handleTrackChange("back")}
                    isDisabled={!iconStatus.back}
                    langId={content.TO_BACK}
                    imgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backIcon}`}
                    hoverImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backIcon}`}
                    disabledImgSrc={`${STATIC_PATH}${vmTheme[theme].icons.backDisabledIcon}`}
                />
            </ContextMenuListStyled>
        </ArrangeMenuStyled>
    );
}
ArrangeMenu.propTypes = {
    workspaceItems: PropTypes.object,
    selectedItemId: PropTypes.string,
    handleTrackChange: PropTypes.func,
    arrangeMenuRef: PropTypes.object,
};

const Volume = (props) => {
    const { item, itemContainer, updateItem } = props;
    const isAudioDetached = item.get("isAudioDetached");
    const [volume, setVolume] = useState(item.get("volume"));
    const theme = useSelector((state) => state.app.get('theme'));
    let containerClass = "";
    let volumeIcon = "mute";
    if (volume > 0) {
        volumeIcon = "un-mute";
    }
    if (isAudioDetached) {
        containerClass = `${containerClass} is-disabled`;
    }

    const onVolumeChange = (e, inputVal) => {
        setVolume(inputVal / 100);
    };

    const updateVolume = () => {
        if (volume !== item.get("volume")) {
            updateItem({
                container: itemContainer,
                selectedItem: item.get("id"),
                toUpdate: { volume },
            });
        }
    };

    return (
        <VolumeSliderContainer className={containerClass}>
            <div>
                <img src={`${STATIC_PATH}${volumeIcon}.svg`} alt="volume" />
                <Slider
                    min={0}
                    max={100}
                    inputMin={0}
                    inputMax={100}
                    value={volume * 100}
                    step={1}
                    onChangeSliderValue={onVolumeChange}
                    onMouseUp={updateVolume}
                    border={"none"}
                    borderRadius={"3px"}
                    background={vmTheme[theme].veryLightGray}
                    margin={"0"}
                    thumb={{
                        background: `${vmTheme[theme].polarColor} 0% 0% no-repeat padding-box`,
                        border: `2px solid ${vmTheme[theme].secondaryBorderColor}`,
                        height: "16px",
                        width: "16px",
                        hoverBG: `${vmTheme[theme].secondaryBorderColor}`,
                        hoverBorder: `1.7px solid ${vmTheme[theme].secondaryBorderColor}`,
                    }}
                    progressBackground={vmTheme[theme].secondaryBorderColor}
                    isChangeBg={true}
                />
            </div>
        </VolumeSliderContainer>
    );
}
Volume.propTypes = {
    item: PropTypes.object,
    itemContainer: PropTypes.string,
    updateItem: PropTypes.func,
};

class ContextMenuComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isArrangeMenuOpen: false,
            showPasteOption: false,
        };

        this.assignRef = this.assignRef.bind(this);
        this.handleMenuPosition = this.handleMenuPosition.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.toggleArrangeMenu = this.toggleArrangeMenu.bind(this);

        this.canSwap = this.canSwap.bind(this);
        this.checkCanPaste = this.checkCanPaste.bind(this);

        this.handleItemDelete = this.handleItemDelete.bind(this);
        this.handleItemDuplicate = this.handleItemDuplicate.bind(this);
        this.handleItemCopy = this.handleItemCopy.bind(this);
        this.handleItemCut = this.handleItemCut.bind(this);
        this.handlePasteItem = this.handlePasteItem.bind(this);
        this.handleSwap = this.handleSwap.bind(this);
        this.handleTextStyleCopy = this.handleTextStyleCopy.bind(this);
        this.handleTextStylePaste = this.handleTextStylePaste.bind(this);
        this.handleTrackChange = this.handleTrackChange.bind(this);
        this.handleAudioDetach = this.handleAudioDetach.bind(this);

        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.menuRef = React.createRef(null);
        /** @type {React.MutableRefObject<HTMLElement | null>} */
        this.arrangeMenuRef = React.createRef(null);

        this.canUpdateState = true;
    }

    componentDidMount() {
        this.handleMenuPosition();
        window.addEventListener("pointerdown", this.handleOutsideClick);
        if (this.props.copiedItem) {
            this.checkCanPaste();
        }
    }

    componentDidUpdate(prevProps) {
        this.handleMenuPosition();

        if (this.props.copiedItem !== prevProps.copiedItem) {
            this.checkCanPaste();
        }
    }

    componentWillUnmount() {
        this.canUpdateState = false;
        window.removeEventListener("pointerdown", this.handleOutsideClick);
    }

    toggleArrangeMenu() {
        this.setState(p => ({ ...p, isArrangeMenuOpen: !p.isArrangeMenuOpen }));
    }

    canSwap() {
        const { workspaceItems, selectedItems } = this.props;
        const selectedItem = workspaceItems.get(selectedItems.get(0));

        if (!selectedItem) {
            return false;
        }

        return (
            selectedItem.get("type") === "PROP"
            || selectedItem.get("type") === "SHAPE"
            || selectedItem.get("type") === "FRAME"
            || isImageOnly(selectedItem.get("type"), selectedItem.get("subType"))
            || isVideoOnly(selectedItem.get("type"), selectedItem.get("subType"))
        );
    }

    handleMenuPosition() {
        const { menuClickPos, workspaceStage } = this.props;

        if (!(this.menuRef.current instanceof HTMLDivElement) || !menuClickPos) {
            return;
        }

        const menuPos = { x: menuClickPos.x, y: menuClickPos.y };
        const leftStageEnd = workspaceStage.getIn(["left", "menu"]) + workspaceStage.getIn(["left", "menuBorder"]) + workspaceStage.getIn(["left", "library"]);
        const rightStageStart = window.innerWidth;
        const topStageEnd = workspaceStage.getIn(["top", "menu"]) + workspaceStage.getIn(["top", "toolbar"]);
        const bottomStageStart = window.innerHeight - workspaceStage.getIn(["bottom", "timeline"]);

        if (menuPos.x + this.menuRef.current.offsetWidth > rightStageStart) { // show menu on left of the cursor
            menuPos.x = menuPos.x - this.menuRef.current.offsetWidth;
        }
        if (menuPos.x < leftStageEnd) { // dont let menu go under library
            menuPos.x = leftStageEnd;
        }

        if (menuPos.y + this.menuRef.current.offsetHeight > bottomStageStart) { // show menu on top of the cursor
            menuPos.y = bottomStageStart - this.menuRef.current.offsetHeight;
        }
        if (menuPos.y < topStageEnd) { // dont let menu go under menubar
            menuPos.y = topStageEnd;
        }

        this.menuRef.current.style.left = `${menuPos.x}px`;
        this.menuRef.current.style.top = `${menuPos.y}px`;

        if (this.arrangeMenuRef.current instanceof HTMLDivElement) {
            if (menuPos.x + this.menuRef.current.offsetWidth + GAP_BETWEEN_MENU + this.arrangeMenuRef.current.offsetWidth > rightStageStart) { // show submenu on the left
                this.arrangeMenuRef.current.classList.add(ARRANGE_MENU_LEFT_CLASS);
            } else { // show submenu on the right
                this.arrangeMenuRef.current.classList.remove(ARRANGE_MENU_LEFT_CLASS);
            }

            this.arrangeMenuRef.current.style.top = "0px";
            const arrangeBounds = this.arrangeMenuRef.current.getBoundingClientRect();
            let arrangeY = arrangeBounds.y;
            if (arrangeBounds.y + arrangeBounds.height > bottomStageStart) { // show menu on top of the cursor
                arrangeY = bottomStageStart - arrangeBounds.height;
            }
            if (arrangeY < topStageEnd) { // dont let menu go under menubar
                arrangeY = topStageEnd;
            }
            arrangeY = arrangeY - arrangeBounds.y;
            this.arrangeMenuRef.current.style.top = `${arrangeY}px`;
        }
    }

    async checkCanPaste() {
        if (this.canUpdateState) {
            try {
                let showPaste = false;
                try {
                    const clipboardItems = await navigator.clipboard.readText();
                    const pastedData = JSON.parse(
                        decodeURIComponent(escape(atob(clipboardItems)))
                    );
                    if (pastedData.key === "VMAKER_CLIPBOARD") {
                        showPaste = true;
                    }
                } catch (error) {
                    // reading copied item from file manager (outside browser) or permission denied
                    showPaste = false;
                }

                if (showPaste === false && this.props.copiedItem !== null) {
                    showPaste = true;
                }
                this.setState({ showPasteOption: showPaste });
            } catch (error) {
                // error
            }

        }
    }

    handleOutsideClick(e) {
        const preventClosing = this.menuRef.current && this.menuRef.current.contains(e.target);
        if (!preventClosing) {
            this.props.hideContextMenu();
        }
    }

    handleItemDelete() {
        this.props.hideContextMenu();
        const { selectedItems } = this.props;

        const toUpdate = [];
        selectedItems.valueSeq().forEach((itemId) => {
            const isBlob = this.props.projectDetails && this.props.projectDetails.getIn(["workspaceItems", itemId, "isBlob"])
            if (isBlob) {
                this.props.addNotifications({
                    id: uuid(),
                    toastType: "WARNING",
                    description: content.UPLOAD_UNABLE_TO_DELETE,
                })
            } else {
                toUpdate.push({
                    id: itemId,
                    container: "workspaceItems",
                    isDelete: true,
                });
            }
        });

        if (toUpdate.length) {
            this.props.updateTimelineTime({ toUpdate });
        }
    }

    handleItemDuplicate() {
        const { projectDetails, selectedItems, updateTimelineTime } =
            this.props;
        const selectedSliders = [];
        const itemsToDuplicate = [];

        selectedItems.toJS().forEach((ids) => {
            selectedSliders.push({ sliderId: ids });
        });

        ["workspaceItems", /* "workspaceBG", */ "audios"].forEach(
            (container) => {
                selectedSliders.forEach(({ sliderId }) => {
                    if (projectDetails.getIn([container, sliderId])) {
                        itemsToDuplicate.push({
                            container,
                            item: projectDetails.getIn([container, sliderId]).toJS(),
                        });
                    }
                });
            }
        );
        const toUpdate = duplicateItemHelper({
            itemsToDuplicate,
            projectDetails,
        });
        updateTimelineTime({ toUpdate });
        this.props.hideContextMenu();
    }

    handleItemCopy() {
        const { projectDetails, selectedItems, setClipboard } = this.props;
        const selectedSliders = [];
        selectedItems.toJS().forEach((ids => {
            selectedSliders.push({ sliderId: ids })
        }))
        const result = copyHandler({ projectDetails, selectedSliders });
        if (result) {
            setClipboard(result);
        }
        this.props.hideContextMenu();
    }

    handleItemCut() {
        this.props.hideContextMenu();
    }

    handlePasteItem() {
        const { updateTimelineTime, copiedItem, projectDetails } = this.props;
        const { canPaste } = this.state;
        pasteHandler(canPaste, copiedItem).then((pastedData) => {
            if (pastedData?.key === "VMAKER_CLIPBOARD") {
                // Duplicating the copied items to paste.
                const toUpdate = duplicateItemHelper({
                    itemsToDuplicate: pastedData.data,
                    projectDetails,
                });
                // To update contains the copy of the items in the clipboard.
                updateTimelineTime({ toUpdate });
            }
        });
        this.props.hideContextMenu();
    }

    handleSwap() {
        const { workspaceItems, selectedItems, selectedFrameClip } = this.props;
        const selectedItem = workspaceItems.get(selectedItems.get(0));
        const isFrame = selectedItem.get("isSingleClipFrame") && !selectedFrameClip.get("clipId");
        const { panelName, itemType } = getPanelName(selectedItem.get("type"), selectedItem.get("subType"), isFrame);
        this.props.setReplaceItem(selectedItem);
        this.props.setSwapInfo(fromJS({ panelName, itemType, container: "workspaceItems", selectedFrameClip }));
        this.props.setPanel(panelName);
        this.props.setExpand(true);
        this.props.hideContextMenu();
    }

    handleTextStyleCopy() {
        this.props.hideContextMenu();
    }

    handleTextStylePaste() {
        this.props.hideContextMenu();
    }

    handleTrackChange(type) {
        this.props.hideContextMenu();
        const toUpdate = handleLayerPosition({
            container: "workspaceItems",
            itemId: this.props.selectedItems.first(),
            items: this.props.workspaceItems,
            type,
        });
        if (toUpdate.length) {
            this.props.updateTimelineTime({ toUpdate });
        }
    }

    async handleAudioDetach() {
        const { workspaceItems, selectedItems, updateTimelineTime, notify } = this.props;
        const item = workspaceItems.get(selectedItems.first());
        const { src } = getAudioSource({
            item: {
                type: item.get("type"),
                subType: item.get("subType"),
                src: item.get("src"),
            },
        });
        const hasBuffer = await checkAudioBuffer(src);
        if (hasBuffer) {
            const toUpdate = audioDetachHelper({
                item,
                container: "workspaceItems",
            });
            if (toUpdate) {
                updateTimelineTime({ toUpdate });
                notify.success("Audio detached Successfully! 🎧");
            } else {
                notify.warn("Audio detach failed. Give it another go! 🎧");
            }
        } else {
            notify.warn("No audio to detach from the video! 🎧");
        }
        this.props.hideContextMenu();
    }

    assignRef(ref) {
        const { contextMenuRef } = this.props;
        this.menuRef.current = ref;
        if (typeof contextMenuRef === "function") {
            contextMenuRef(ref);
        }
    }

    render() {
        const { selectedItems, workspaceItems, textStatus, propertyWindow } = this.props;
        const { isArrangeMenuOpen, showPasteOption } = this.state;
        const firstWorkspaceItem = workspaceItems.get(selectedItems.first());

        const itemCopyOptions = [];
        let itemCopyOptionsContainer = null;
        if (!textStatus.get("isFocused")) {
            if (selectedItems.size > 0) {
                itemCopyOptions.push(
                    <ContextMenuOption
                        key={"duplicate"}
                        onClick={this.handleItemDuplicate}
                        langId={content.DUPLICATE}
                    />
                );
                itemCopyOptions.push(
                    <ContextMenuOption
                        key={"copy"}
                        onClick={this.handleItemCopy}
                        langId={content.COPY}
                    />
                );
            }
            if (showPasteOption) {
                itemCopyOptions.push(
                    <ContextMenuOption
                        key={"paste"}
                        onClick={this.handlePasteItem}
                        langId={content.PASTE}
                    />
                );
            }

            if (this.canSwap()) {
                itemCopyOptions.push(
                    <ContextMenuOption
                        key={"swap"}
                        onClick={this.handleSwap}
                        langId={content.REPLACE}
                    />
                );
            }

        }
        if (itemCopyOptions.length > 0) {
            itemCopyOptionsContainer = (
                <ContextMenuListStyled>
                    {itemCopyOptions}
                </ContextMenuListStyled>
            );
        }

        const textOptions = [];
        let textOptionsContainer = null;
        // disabling showTextStyleOptions for now.
        const showTextStyleOptions = false && !textStatus.get('isGrouped') && (
            (textStatus.get("isSelected") && propertyWindow.get("type") === "TEXT" && textStatus.get("id") === selectedItems.get(0))
            || textStatus.get("isFocused")
        );
        if (showTextStyleOptions) {
            textOptions.push(
                <ContextMenuOption
                    key={"copy-style"}
                    onClick={this.handleTextStyleCopy}
                    langId={content.COPY_STYLE}
                />
            );
        }
        if (showTextStyleOptions) {
            textOptions.push(
                <ContextMenuOption
                    key={"paste-style"}
                    onClick={this.handleTextStylePaste}
                    langId={content.PASTE_STYLE}
                />
            );
        }
        if (textOptions.length > 0) {
            textOptionsContainer = (
                <ContextMenuListStyled>
                    {textOptions}
                </ContextMenuListStyled>
            );
        }

        let arrangeSection = null;
        if (selectedItems.size === 1) {
            const subMenu = (
                <ArrangeMenu
                    workspaceItems={workspaceItems}
                    selectedItemId={selectedItems.first()}
                    handleTrackChange={this.handleTrackChange}
                    arrangeMenuRef={this.arrangeMenuRef}
                />
            );
            arrangeSection = (
                <ContextMenuListStyled>
                    <ContextMenuOption
                        key={"arrange"}
                        onClick={this.toggleArrangeMenu}
                        langId={content.POSITION}
                        subMenu={subMenu}
                        isSubMenuOpen={isArrangeMenuOpen}
                    />
                </ContextMenuListStyled>
            );
        }
        const audioDetachOption = [];
        let audioDetachOptionContainer = null;
        const showAudioDetach = selectedItems.size === 1 && firstWorkspaceItem && isVideoOnly(firstWorkspaceItem.get("type"), firstWorkspaceItem.get("subType")) && !firstWorkspaceItem.get("isAudioDetached")

        if (showAudioDetach) {
            audioDetachOption.push(<ContextMenuOption
                key={"detach-style"}
                onClick={this.handleAudioDetach}
                langId={content.DETACH_AUDIO}
            />)
            audioDetachOptionContainer = (
                <ContextMenuListStyled>
                    {audioDetachOption}
                </ContextMenuListStyled>
            );
        }
        let otherOptionsContainer = null;
        const otherOptions = [];
        if (selectedItems.size > 0) {
            otherOptions.push(
                <ContextMenuOption
                    key={"delete"}
                    onClick={this.handleItemDelete}
                    langId={content.DELETE}
                />
            );
        }
        if (otherOptions.length > 0) {
            otherOptionsContainer = (
                <ContextMenuListStyled>
                    {otherOptions}
                </ContextMenuListStyled>
            );
        }

        let videoVolume = null;
        if (firstWorkspaceItem && isVideoOnly(firstWorkspaceItem.get("type"), firstWorkspaceItem.get("subType"))) {
            videoVolume = (
                <Volume
                    item={firstWorkspaceItem}
                    itemContainer={"workspaceItems"}
                    updateItem={this.props.updateItem}
                />
            );
        }

        const isContextMenuHidden = (
            itemCopyOptionsContainer === null
            && itemCopyOptionsContainer === null
            && textOptionsContainer === null
            && arrangeSection === null
            && audioDetachOptionContainer === null
            && otherOptionsContainer === null
            && videoVolume === null
        );

        return !isContextMenuHidden && (
            <ContextMenuStyled
                data-html2canvas-ignore="true"
                ref={this.assignRef}
                className="context-menu unselectable context-tool-bar"
            >
                {itemCopyOptionsContainer}
                {textOptionsContainer}
                {arrangeSection}
                {audioDetachOptionContainer}
                {otherOptionsContainer}
                {videoVolume}
            </ContextMenuStyled>
        );
    }
}

ContextMenuComponent.propTypes = {
    workspaceItems: PropTypes.object,
    selectedItems: PropTypes.object,
    hideContextMenu: PropTypes.func,
    copiedItem: PropTypes.object,
    menuClickPos: PropTypes.object,
    workspaceStage: PropTypes.object,
    textStatus: PropTypes.object,
    propertyWindow: PropTypes.object,
    contextMenuRef: PropTypes.func,
    updateTimelineTime: PropTypes.func,
    updateItem: PropTypes.func,
    projectDetails: PropTypes.object,
    setClipboard: PropTypes.func,
    notify: PropTypes.object,
    setReplaceItem: PropTypes.func,
    setSwapInfo: PropTypes.func,
    setPanel: PropTypes.func,
    setExpand: PropTypes.func,
    addNotifications: PropTypes.func,
    selectedFrameClip: PropTypes.object,
};

const mapStateToProps = (state) => ({
    projectDetails: state.projectDetails,
    workspaceItems: state.projectDetails.get("workspaceItems"),
    selectedItems: state.app.get("selectedItems"),
    copiedItem: state.app.get("clipboardData"),
    workspaceStage: state.app.get("workspaceStage"),
    textStatus: state.app.get("textStatus"),
    propertyWindow: state.app.get("propertyWindow"),
    selectedFrameClip: state.app.get("selectedFrameClip"),
});

const mapDispatchToProps = (dispatch) => ({
    updateTimelineTime: (data) => dispatch(updateTimelineTime(data)),
    updateItem: (data) => dispatch(updateItem(data)),
    setClipboard: (data) => dispatch(setClipboardData(data)),
    setReplaceItem: (data) => dispatch(setSwapItem(data)),
    setSwapInfo: (data) => dispatch(setSwapInfo(data)),
    setPanel: (data) => dispatch(setPropertyPanel(data)),
    setExpand: (data) => dispatch(setExpandPanel(data)),
    addNotifications: (data) => dispatch(addNotification(data)),
});

const ContextMenu = connect(mapStateToProps, mapDispatchToProps)(withNotify(ContextMenuComponent));

export default ContextMenu;
