import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { WorkspaceBGColorContainer } from "./workspace-components";
import vmTheme from "../../constants/theme";

const WorkspaceBGColor = ({ bgColor }) => {
  const theme = useSelector((state) => state.app.get("theme"));

  const styles = useMemo(() => {
    const styles = {};
    const allColors = bgColor.get("colors");
    const isDefaultColor = bgColor.getIn(["colors", bgColor.get("selectedKey")]) === "default";
    let colors = allColors;
    if (isDefaultColor) {
      colors = colors.pop();
    }

    if (colors && colors.size > 0) {
      if (colors.size === 1) {
        // Single color
        styles.backgroundColor = colors.get(0);
      } else {
        // Gradient
        let gradient;
        const isRadial = bgColor.get("radius");
        const gradientType = isRadial ? "radial" : "linear";

        if (!isRadial) {
          gradient = bgColor.get("direction")
            ? `to ${bgColor.get("direction")}, `
            : "to right, ";
        } else {
          gradient = "";
          colors = colors.set(0, `${colors.get(0)} ${bgColor.get("radius")}%`);
        }

        gradient += colors.join(", ");
        styles.backgroundImage = `${gradientType}-gradient(${gradient})`;
      }
    } else {
      styles.backgroundColor = vmTheme[theme].blackColor;
    }

    return styles;
  });

  return <WorkspaceBGColorContainer id={"workspace-bgcolor"} style={styles} />;
};

WorkspaceBGColor.propTypes = {
  bgColor: PropTypes.shape({
    get: PropTypes.func.isRequired,
    getIn: PropTypes.func.isRequired,
  }).isRequired,
};

export default WorkspaceBGColor;
