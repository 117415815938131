import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppConfig, { MAX_AUDIO_FILE_DURATION, STATIC_PATH } from "../../../constants/config";
import { font } from "../../../constants/font";
import useFileUpload from "../../../helper/hooks/useFileUpload";
import content from "../../../constants/content";
import { UploadContainer, UploadSection } from "./upload-components";
import Action from "../../../common-components/Action";
import Typography from "../../../common-components/Typography";
import { resetUploadModal, setUploadModal } from "../../../redux/actions/appUtils";
import useNotify from "../../../helper/hooks/useNotify";
import vmTheme from "../../../constants/theme";
import { getAudioMetaData } from "../../../helper/getAudioMetaData";
import getAudioData from "../../../helper/getAudioData";
import useModal from "../../../helper/hooks/useModal";
import { getSupportedFilesContent, PLAN_CONFIG_AUDIO } from "../../../constants";
import ActionTypes from "../../../constants/action-types";
import useUpgrade from "../../../helper/hooks/useUpgrade";
// import { PLAN_CONFIG_AUDIO } from "../../../constants";

const DragUpload = () => {
    const editFileRef = useRef(null);
    const dispatch = useDispatch();
    const { success } = useNotify();
    const { prepareMedia, uploadResponse, isFailed } = useFileUpload();
    const theme = useSelector((state) => state.app.get("theme"));
    const [dragging, setDragging] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const userDetails = useSelector((store) => store.userDetails);
    const { userId } = userDetails;
    const appReducer = useSelector((state) => state.app);
    const uploadData = appReducer.get("upload").toJS();
    const { plan } = useSelector((store) => store.userDetails);
    const { showModal, hideModal } = useModal();
    const { showUpgrade } = useUpgrade();

    useEffect(() => {
        if (uploadResponse?.res?.id && !uploadData.isCancel) {
            dispatch(setUploadModal({
                show: false,
                assetId: uploadResponse?.res?.id,
                assetData: uploadResponse.res,
            }));
            success(content.FILE_UPLOADED);
        }
        if (isFailed) {
            dispatch(resetUploadModal());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadResponse, isFailed]);

    const onAction = useCallback(() => {
        editFileRef.current.click();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [editFileRef]);

    const onClose = useCallback(() => {
        hideModal();
        dispatch({
            type: ActionTypes.SHOW_UPLOAD_MODAL,
            payload: {
                show: false,
                mode: null,
            },
        });
    });

    const onRestrict = useCallback((file) => {
        return getAudioData(file).then((duration) => {
            const fileSizeValid = file.size <= PLAN_CONFIG_AUDIO.SIZE_OPTIONS[plan];
            const files = Array.isArray(file) ? file : [file];
            const isAllFilesNotValid = files.every((item) => PLAN_CONFIG_AUDIO.SIZE_OPTIONS[plan] < item.size);
            const maxFileDuration = MAX_AUDIO_FILE_DURATION * 60;
            const durationExceed = duration > maxFileDuration;

            // If duration exceeds and file size is invalid
            if (durationExceed && isAllFilesNotValid) {
                showModal({
                    mainText: `File Too Large!`,
                    subText: `Please upload a audio under ${getSupportedFilesContent(plan)} and shorter than 5 minutes.`,
                    confirmText: null,
                    cancelText: `Upload Another File`,
                    upgradeDesign: true,
                    onSave: onClose,
                    cancelBackground: "#00baff",
                    hoverCancelBgColor: "#00baff",
                    fontsColor: "#fff",
                    cancelFontColor: "#fff",
                });
                return false; // Invalid file
            }

            // Check if file size is valid
            if (!fileSizeValid) {
                showUpgrade({
                    headerText: 'Audio too large!',
                    subText: 'Upgrade your plan or upload a smaller file.',
                    type: 'uploadLimit',
                });
                hideModal();
                dispatch({
                    type: ActionTypes.SHOW_UPLOAD_MODAL,
                    payload: {
                        show: false,
                        mode: null,
                    },
                });
                return false;
            }

            if (durationExceed) {
                showModal({
                    mainText: `Audio too long!`,
                    subText: `Audio duration exceeds 5 minutes limit. Upload a shorter video.`,
                    confirmText: null,
                    cancelText: "Upload another file",
                    cancelBackground: "#00baff",
                    hoverCancelBgColor: "#00baff",
                    fontsColor: "#fff",
                    cancelFontColor: "#fff",
                    onSave: onClose,
                });
                return false;
            }

            return true;
        }).catch(error => {
            console.error("Error validating file:", error);
            return false;
        });
    }, [plan, showUpgrade, hideModal, dispatch]);


    const processFile = (file) => {
        const blobSrc = URL.createObjectURL(file);
        getAudioMetaData(blobSrc);

        const payload = {
            active: 1,
            created: new Date().toISOString(),
            duration: file.duration || 0,
            id: file.id,
            name: file.name,
            parent: "0",
            product: "vmaker",
            roottype: "file",
            source_type: "mp3",
            src: file.src,
            tags: "userupload",
            type: "AUDIO",
            user_id: userId,
            isBlob: true,
        };

        dispatch(setUploadModal({
            show: false,
            assetId: uploadResponse?.res?.id,
            assetData: payload,
        }));
    };


    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const isFileValid = await onRestrict(file);

            if (!isFileValid) {
                e.target.value = null;
                return;
            }
            prepareMedia(file);
            processFile(file);
            e.target.value = null;
        }
    };


    const onDrop = useCallback(async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);

        const file = e.dataTransfer.files[0];
        if (file) {
            const isFileValid = await onRestrict(file);
            if (!isFileValid) {
                return;
            }
            prepareMedia(file);
            processFile(file);
        }
    }, []);


    const onDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    }, []);

    const onDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }, []);

    return (
        <UploadContainer
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            dragging={dragging}
        >
            <input
                type="file"
                ref={editFileRef}
                style={{ display: "none" }}
                accept={AppConfig.UPLOADS.SUPPORTED_AUDIO_TYPES}
                onChange={handleFileUpload}
            />
            <UploadSection>
                <img
                    src={dragging ? `${STATIC_PATH}vm-upload-color.svg` : `${STATIC_PATH}vm-uploads.svg`}
                    alt="upload"
                />
                <Typography
                    content={content.DRAG_AND_DROP}
                    color={vmTheme[theme].panelPrimaryColor}
                    font={font.semi_bold_16}
                    cursor="pointer"
                    padding="3px 0px"
                />
                <Typography
                    innerContent={`Supported file formats: .mp3, .wav (Max File Upload Size: ${getSupportedFilesContent(plan)})`}
                    color={vmTheme[theme].sbSecondaryColor}
                    font={font.normal14_400}
                    padding="2px 0px"
                    width={"auto"}
                    align={"center"}
                    cursor="pointer"
                    enableTrim={false}
                />
                <Action
                    text={content.UPLOAD_AUDIO}
                    className="upload-btn-btn"
                    cursor={"pointer"}
                    width="376px"
                    height="48px"
                    borderRadius={"8px"}
                    enableTrim={true}
                    font={font.mediumMiniBase}
                    hoverColor={vmTheme[theme].liteHoverColor}
                    isLoading={isLoading}
                    background={vmTheme[theme].secondaryBorderColor}
                    onClick={onAction}
                />
            </UploadSection>
        </UploadContainer>
    );
};

export default DragUpload;
