import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  CloseIcon,
  Overlay,
  OverlayContainer,
} from "./modal-components";
import { STATIC_PATH } from "../../constants/config";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";
import { setModalProps } from "../../redux/actions/modalAction";
import ModalFooter from "../../common-components/ModalFooter";
import Backdrop from "../../common-components/BackDrop";

const Modal = ({
  isShowModal,
  isDisableDrag,
  setModal,
  onSave,
  confirmText,
  cancelText,
  mainText,
  subText,
  width,
  cancelBackground,
  backgroundColor,
  fontsColor,
  cancelFontColor,
  hoverCancelBgColor
}) => {

  const closeModal = () => {
    setModal({ isShowModal: false, isDisableDrag: true });
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OverlayContainer display={isShowModal ? "flex" : "none"}>
      <Backdrop onClick={closeModal} zIndex={1} />
      <Draggable disabled={isDisableDrag}>
        <Overlay padding="24px" height={"auto"} width={width}>
          <CloseIcon
            src={`${STATIC_PATH}close-icon.svg`}
            alt="close"
            onClick={closeModal}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
          />
          <Typography
            content={mainText}
            font={font.boldSmall}
            padding="0px 0px 12px"
            display="block"
            align={"left"}
          />
          <Typography
            content={subText}
            font={font.normal_16}
            color="#1F222E"
            padding="12px 0px 12px"
            align={"left"}
            enableTrim={false}
            display="block"
          />
          <ModalFooter
            onClose={closeModal}
            onSave={onSave}
            confirmText={confirmText}
            cancelText={cancelText}
            justifyContent={"center"}
            cancelBackground={cancelBackground}
            backgroundColor={backgroundColor}
            fontsColor={fontsColor}
            cancelFontColor={cancelFontColor}
            hoverCancelBgColor={hoverCancelBgColor}
          />
        </Overlay>
      </Draggable>
    </OverlayContainer>
  );
};

Modal.propTypes = {
  isShowModal: PropTypes.bool,
  isDisableDrag: PropTypes.bool,
  setModal: PropTypes.func,
  onSave: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  mainText: PropTypes.string,
  subText: PropTypes.string,
  width: PropTypes.string,
  cancelBackground: PropTypes.string,
  backgroundColor: PropTypes.string,
  fontsColor: PropTypes.string,
  cancelFontColor: PropTypes.string,
  hoverCancelBgColor: PropTypes.string,
};

const mapStateToProps = ({ modal }) => {
  return {
    ...modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModal: bindActionCreators(setModalProps, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
