import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import Action from "./Action";
import { font } from "../constants/font";
import vmTheme from "../constants/theme";

const Root = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "end"};
  gap: 16px;
  padding-top: ${(props) => props.padding || "24px"};
  div {
    &:hover {
      transform: scale(1.01);
    }
  }
  .cancel-btn {
    padding: 0px 10px; 
    transition: all .4s;
    :hover {
      transform: scale(1.02);
      background-color: ${(props) => props.hoverCancelBgColor || props.theme.groupButton};
    }
  }
  .apply-btn{
    padding: 0px 10px; 
    transition: all .4s;
    :hover {
      background-color: ${(props) => props.theme.secondaryBorderColor};
      transform: scale(1.02);
      label {
        color: ${(props) => props.theme.polarColor};
      }
    }
  }
`;

const ModalFooter = ({
  onClose,
  onSave,
  confirmText,
  backgroundColor,
  fontsColor,
  cancelBackground,
  cancelFontColor,
  cancelText,
  padding,
  hoverCancelBgColor
}) => {
  const theme = useSelector((state) => state.app.get("theme"));

  return (
    <Root padding={padding} hoverCancelBgColor={hoverCancelBgColor}>
      {cancelText ? <Action
        background={cancelBackground}
        showHoverColor
        borderRadius="8px"
        font={font.boldBase}
        fontColor={cancelFontColor || vmTheme[theme].panelPrimaryColor}
        width="fit-content"
        height="36px"
        margin="0px"
        onClick={onClose}
        text={cancelText}
        className="cancel-btn"
      /> : null}
      {confirmText ? <Action
        background={backgroundColor}
        showHoverColor
        hover
        borderRadius="8px"
        font={font.boldBase}
        fontColor={fontsColor}
        width="fit-content"
        height="36px"
        margin="0px"
        onClick={onSave}
        text={confirmText}
        className="apply-btn"
      /> : null}
    </Root>
  );
};

ModalFooter.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  confirmText: PropTypes.string,
  backgroundColor: PropTypes.string,
  fontsColor: PropTypes.string,
  cancelBackground: PropTypes.string,
  cancelText: PropTypes.string,
  padding: PropTypes.string,
  cancelFontColor: PropTypes.string,
  hoverCancelBgColor: PropTypes.string,
};

export default ModalFooter;
