/* eslint-disable */
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GradientBox, GradientContainer, GradientItem, GradientType, GradientWrapper } from "./color-components";
import Typography from "../../../../common-components/Typography";
import { font } from "../../../../constants/font";
import vmTheme from "../../../../constants/theme";
import content from "../../../../constants/content";
import { applyColor } from "../../../../redux/actions/timelineUtils";
import { gradientArray, radiusArray } from "../../../../constants";

const GradientColorProperty = () => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.app.get("theme"));
    const projectData = useSelector((state) => state.projectDetails);
    const colors = projectData.getIn(["bgColor", "colors"]).toJS();

    const handleLinearGradientClick = useCallback((directionType, type) => {
        const toUpdate = {
            container: "bgColor",
            replaceAllColor: false,
            radius: type === "radius" ? directionType : null,
            direction: type !== "radius" ? directionType : null,
        };

        dispatch(applyColor([toUpdate]));
    }, [dispatch]);

    // Generate linear gradient patterns
    const gradLinearPatterns = gradientArray.map((direction, index) => {
        const gradientLinearType = `linear-gradient(to ${direction}, ${colors.join(", ")})`;
        return (
            <GradientBox
                key={`linear-${index}`}
                onClick={() => handleLinearGradientClick(direction)}
            >
                <GradientItem backgroundImage={gradientLinearType} />
            </GradientBox>
        );
    });

    // Generate radial gradient patterns
    const gradRadialPatterns = radiusArray.map((radius, index) => {
        const reversedColors = [...colors].reverse();
        const radialcolorArray = reversedColors.map((color, idx) =>
            idx === reversedColors.length - 1 ? `${color} ${radius}%` : color
        );
        const gradientRadialval = radialcolorArray.reverse().join(", ");
        const gradientRadialType = `radial-gradient(${gradientRadialval})`;

        return (
            <GradientBox
                key={`radial-${index}`}
                onClick={() => handleLinearGradientClick(radius, "radius")}
            >
                <GradientItem backgroundImage={gradientRadialType} />
            </GradientBox>
        );
    });

    return (
        <GradientContainer style={{ padding: "17px" }}>
            <Typography
                content={content.DIRECTION}
                color={vmTheme[theme].panelPrimaryColor}
                font={font.normal_18}
                height="19px"
            />
            <GradientType>
                <Typography
                    content={"Linear Gradients"}
                    color={vmTheme[theme].panelPrimaryColor}
                    font={font.normal_15}
                    padding="16px 0px 8px"
                    className="effectTitle"
                />
                <GradientWrapper>
                    {gradLinearPatterns}
                </GradientWrapper>
            </GradientType>
            <GradientType>
                <Typography
                    content={"Radial Gradients"}
                    color={vmTheme[theme].panelPrimaryColor}
                    font={font.normal_15}
                    padding="16px 0px 8px"
                    className="effectTitle"
                />
                <GradientWrapper>
                    {gradRadialPatterns}
                </GradientWrapper>
            </GradientType>
        </GradientContainer>
    );
};

export default GradientColorProperty;