const SWConfig = require("./sw-versioning");

const NODE_ENV = process.env.REACT_APP_NODE_ENV;
const ANIMO_RENDER = process.env.REACT_APP_ANIMO_RENDER || false;
const { PUBLIC_URL } = process.env;

const CLOUD_FRONT_URL = 'https://d1lvb9ymhbcwgp.cloudfront.net/api/static';
const EAB_URL = "https://dtqld9w0vfx0u.cloudfront.net/";
const USER_ASSETS = 'https://dash.animaker.com/a/u/';
let API_URL = 'https://dev.vmaker.org/';
let SITE_URL = 'https://dev.vmaker.org/';
let SOCKET_URL = 'https://sockets-dev.vmaker.asia/';
let ASSET_URL = "https://dev-static.animaker.com/";
let ENVIRONMENT = 'DEVELOPMENT';
let STATIC_PATH = "";
let CNAME = "";
let VERSION_HISTORY_CONFIG = {
    ACTION_COUNT: 500,
    TIMER_COUNT: 15
}
let MAX_FILE_DURATION = 3 * 60;  // 3 hours
let MAX_AUDIO_FILE_DURATION = 5;
const SW_URL = process.env.REACT_APP_SW_URL;
switch (NODE_ENV) {
    case 'prod':
        API_URL = 'https://app.vmaker.com/';
        SITE_URL = 'https://app.vmaker.com/';
        SOCKET_URL = 'https://vmaker-editor-sockets.vmaker.com/';
        ASSET_URL = "https://library.animaker.com/";
        ENVIRONMENT = 'PRODUCTION';
        STATIC_PATH = "https://static.vmaker.com/editor/assets/";
        MAX_FILE_DURATION = 3 * 60;  // 3 hours
        MAX_AUDIO_FILE_DURATION = 5;  // 5 minutes
        CNAME = "vmaker.com";
        break;
    case 'local':
        API_URL = 'https://snbx.vmakerdev.com/';
        SITE_URL = 'http://localhost:3000/';
        SOCKET_URL = 'https://sockets.vmakerdev.com/';
        // SOCKET_URL = 'http://localhost:5001/'; // Use if you are connecting locally,
        ASSET_URL = "https://demo-static.animaker.com/";
        ENVIRONMENT = 'LOCAL';
        STATIC_PATH = "/assets/";
        CNAME = "vmaker.com";
        VERSION_HISTORY_CONFIG = {
            ACTION_COUNT: 10,
            TIMER_COUNT: 0.5
        }
        MAX_FILE_DURATION = 0.5 * 60;  // 30 minutes
        MAX_AUDIO_FILE_DURATION = 5; // 30 minutes
        break;
    case 'demo':
        API_URL = 'https://snbx.vmakerdev.com/';
        SITE_URL = 'https://snbx.vmakerdev.com/';
        SOCKET_URL = 'https://sockets.vmakerdev.com/';
        ASSET_URL = "https://demo-static.animaker.com/";
        ENVIRONMENT = 'DEMO';
        STATIC_PATH = "https://dev-static.vmaker.com/editor-snbx/assets/";
        CNAME = "vmakerdev.com";
        VERSION_HISTORY_CONFIG = {
            ACTION_COUNT: 10,
            TIMER_COUNT: 1
        }
        MAX_FILE_DURATION = 0.2 * 60;  // 12 minutes
        MAX_AUDIO_FILE_DURATION = 5;  // 5 minutes
        break;
    case 'devanimaker':
        API_URL = 'https://dev.animaker.net/';
        SITE_URL = 'https://dev.animaker.net/';
        SOCKET_URL = 'https://sockets.vmakerdev.com/';
        ASSET_URL = "https://demo-static.animaker.com/";
        ENVIRONMENT = 'DEMO';
        STATIC_PATH = "https://dev-static.vmaker.com/editor-snbx/assets/";
        CNAME = "animaker.net";
        VERSION_HISTORY_CONFIG = {
            ACTION_COUNT: 10,
            TIMER_COUNT: 1
        }
        MAX_FILE_DURATION = 0.2 * 60;  // 12 minutes
        MAX_AUDIO_FILE_DURATION = 5;
        break;
    case 'vmakersnbx':
        API_URL = 'https://snbx.vmakersnbx.com/';
        SITE_URL = 'https://snbx.vmakersnbx.com/';
        SOCKET_URL = 'https://sockets.vmakerdev.com/';
        ASSET_URL = "https://demo-static.animaker.com/";
        ENVIRONMENT = 'DEMO';
        STATIC_PATH = "https://dev-static.vmaker.com/editor-snbx/assets/";
        CNAME = "vmakersnbx.com";
        VERSION_HISTORY_CONFIG = {
            ACTION_COUNT: 10,
            TIMER_COUNT: 1
        }
        MAX_FILE_DURATION = 3 * 60;  // 3 hours
        MAX_AUDIO_FILE_DURATION = 5;
        break;
    default:
        API_URL = 'https://dev.vmaker.org/';
        SITE_URL = 'https://dev.vmaker.org/';
        SOCKET_URL = 'https://sockets-dev.vmaker.asia/';
        ASSET_URL = "https://dev-static.animaker.com/";
        ENVIRONMENT = 'DEVELOPMENT';
        STATIC_PATH = "https://dev-static.vmaker.com/editor-dev/assets/";
        CNAME = "vmaker.org";
        VERSION_HISTORY_CONFIG = {
            ACTION_COUNT: 10,
            TIMER_COUNT: 1
        }
        MAX_FILE_DURATION = 0.2 * 60;  // 12 minutes
        MAX_AUDIO_FILE_DURATION = 5;
        break;
}

if (ANIMO_RENDER) {
    ENVIRONMENT = 'LOCAL';
    STATIC_PATH = "./assets/";
}

const AppConfig = {
    API: {
        GET_PROJECT: 'p/d/',
        GET_PROJECT_INFO: 'p/c2/',
        PREVIEW_PROJECT: 'p/p/',
        COLOR: 'api/eff/',
        MST: 'api/mst/', // Master data
        FPROP: 'api/fprop/', // Api for text data
        GLL: 'api/gll/', // To get the view all properties
        SFT: 'api/sft/', // To add favourite
        RFT: 'api/rft/', // To remove favourite
        FAVOURITES: 'api/favourites/', // To get favourites data
        SICOL: 'api/sicol/', // To get the view all images
        SVCOL: 'api/svcol/', // To get the view all videos
        SOAK: 'api/soak/', // To get musics on search
        GIPHY_API: 'api/gif/gd/', // To get Giphy data
        LIBRARY_API: 'api/media/', // To get Pexels data
        USER_DETAILS: 'usr/d/',
        TEAM_DETAILS: 'vmaker/uvd',
        SWITCH_TEAM: 'brand/st',
        EXPORT_VIDEO: 'api/exportVideo/',
        VC: 'api/exp/vc/',
        SAVE_PROJECT_REST: 'api/autosave/',
        GET_FF: 'app/f/gf/',
        RESIZE_VIDEO: 'api/createproject/',
        CREATE_FOLDER: 'app/f/cf/',
        DELETE_FOLDER: 'app/f/df/',
        RENAME_FOLDER: 'app/f/rf/',
        MOVE_FOLER: 'app/f/mf/',
        GET_UPLOADED_FILES: 'api/f/guploads/',
        COPY_VIDEO: 'api/duplicate/',
        NEW_BLANK_VIDEO: 'api/blankproject/',
        GET_ANIMO_PROJECT: 'api/grp/',
        ENTERPRISE_FORM: 'p/pes/',
        USER_PROJECT_DETAILS: 'api/upd/',
        GET_NONCE: 'api/p/bt/gnn/',
        GET_ACCESS: 'get/access/',
        CREATE_VERSION: 'api/version/',
        GET_VERSION_LIST: 'api/versionlist/',
        RESTORE_VERSION: 'api/restore/',
        GET_VERSION: 'api/getversion/',
        CONTACTSALES: "contactsupportrequest/", // contact sales form
        SHARE_API: "api/project/share",
        GET_SHARE: "api/project/getsharedetails/",
        CHANGE_SHARE: "api/project/share/changePermission/",
        REMOVE_PERMISSION: "api/project/share/removePermission/",
        VIRAL_STYLES: "viralstyles/",
        GET_PRESETS: "gsp/",
        SUPPORT: "submitsupportrequest/",
        ONBOARDING: "api/onboarding/",
        ENTERPRISE_ONBOARDING: "p/opes/",
        COUNTRY: "countries",
        SEND_MAIL: "renderMail/",
        PLAN_PRISING: "payments/plans/",
        GET_RENDER_PROJECT: "api/getrenderproject/",
        GET_SQS_UPDATE: "get_sqs_update/",
        GET_EXPORT_STATUS: "api/get_export_status/",
        MANAGE_EXPORTS: "manageexports/",
        EXPORT_LIST: "exportlist/",
        EXPORT_DETAIL: "export/details/",
        SUBTITLE: {
            CREATE: "subtitle/create/",
            STATUS: "subtitle/status/",
            UPDATE: "subtitle/update/",
            GET: "subtitle/",
            INSERT: "subtitle/insert/",
            TRANSLATE_SUBTITLE: "subtitle/translate/",
            GET_SUBTITLE_LIST: "subtitle/translate/subtitleData/",
            LANGUAGES: "subtitle/translate/language/",
            TRANSLATE_STATUS: "subtitle/translate/status/",
        },
        SHORTS: {
            CREATE: "api/ai/clipping/spc/"
        },
        GET_CREDITS: "api/ucd/",
        AVATAR: {
            GET: "api/pav/",
            CREATE: "api/pvg/",
            STATUS: "api/pvg/status/",
        },
        YOUTUBE: {
            GET_CHANNEL: "yt/gcl/",
            ADD_CHANNELS: "api/yauth/",
            SHARE_VIDEO: "yt/sharevideo/",
            CHANNEL_DELETE: "yt/cdel/"
        }
    },
    PAGE: {
        DASHBOARD: "dashboard/",
        PLANS: "pricing/",
        TEAM: "myteam/",
        TERMS_AND_CONDITION: "https://www.animaker.com/terms-policy",
        MY_PLAN: "myplan/",
        ACCOUNT: "account/",
        SUPPORT: "support/"
    },
    SENTRY_URL: 'https://107374741aa941c692218c5f0dc71bf5@o890069.ingest.sentry.io/4505322153115648',
    THUMBNAIL_URL: 'https://dash.animaker.com/a/u/',
    PLACEHOLDER_IMAGE: 'https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg',
    API_URL,
    ASSET_URL,
    CNAME,
    IS_LOCAL: NODE_ENV === 'local',
    IS_PROD: NODE_ENV === 'prod',
    ENVIRONMENT,
    SOCKET_URL,
    CLOUD_FRONT_URL,
    USER_ASSETS,
    STATIC_PATH,
    PUBLIC_URL,
    ANIMO_RENDER,
    ITEM_CONFIG: {
        IMAGE: {
            DEFAULT_FILTER: "6464646464640032",
            SWAP_OPACITY: 0.5
        },
        FOLDER: {
            DROP_OPACITY: 0.5
        },
        TEXT: {
            limit: {
                lineHeight: {
                    min: 0.8,
                    max: 2.5
                }
            }
        },
        FRAME: {
            DROP_OPACITY: 0.5
        },
        SWAP_TARGETS: {
            FRAME_CLIP: "frame-clip",
            GRID_CELL: "grid-cell",
            WORKSPACE: "workspace",
            WORKSPACE_CONTAINER: "workspace-container",
            UPLOAD_CONTAINER: "upload-container",
            TRANSITIONS: "transitions",
            NONE: null
        },
        SWAP_SOURCE: {
            LIBRARY: "library",
            WORKSPACE: "workspace",
            NONE: null
        },
    },
    SW_URL,
    VERSION_HISTORY_CONFIG,
    SW_VERSION: SWConfig.SW_VERSION,
    "WORKSPACE_LOADER": `${STATIC_PATH}chr-loading.gif`,
    "PROP": {
        "src": `${ASSET_URL}animaker/properties/images/`,
        "thumb": `${ASSET_URL}animaker/properties/thumbnails/`,
        "animdata": `${ASSET_URL}animaker/properties/animdata/`,
        "gif": `${ASSET_URL}animaker/properties/gif/`,
    },
    "EPROP": {
        "src": `${ASSET_URL}animaker/properties/eprops/images/`,
        "thumb": `${ASSET_URL}animaker/properties/eprops/thumbnails/`,
        "animdata": `${ASSET_URL}animaker/properties/eprops/animdata/`,
    },
    "GETTYICON": {
        "src": `${ASSET_URL}animaker/properties/gprops/src/`,
        "thumb": `${ASSET_URL}animaker/properties/gprops/thumbnails/`,
        "animdata": `${ASSET_URL}animaker/properties/gprops/animdata/`,
    },
    "GRAD": {
        "src": `${ASSET_URL}animaker/properties/images/`,
        "thumb": `${ASSET_URL}animaker/properties/thumbnails/`,
    },
    "SCR": {
        "src": `${ASSET_URL}animaker/screeneffects/source/`,
        "thumb": `${ASSET_URL}animaker/screeneffects/source/`,
        "animdata": `${ASSET_URL}animaker/screeneffects/animdata/`,
        "proxy": `${ASSET_URL}animaker/screeneffects/source/`,
        "libSrc": `${ASSET_URL}animaker/screeneffects/source/`,
    },
    "SCREEN": {
        "src": `${ASSET_URL}animaker/scene-transition/source/`,
        "thumb": `${ASSET_URL}animaker/scene-transition/thumbnail/`,
        "animdata": `${ASSET_URL}animaker/scene-transition/animdata/`,
        "proxy": `${ASSET_URL}animaker/scene-transition/source/`,
    },
    "CHR": {
        "src": `${ASSET_URL}animaker/characters/source/`,
        "thumb": `${ASSET_URL}animaker/characters/source/`,
        "animdata": `${ASSET_URL}animaker/characters/animdata/`,
    },
    "CCHR": {
        "src": `${ASSET_URL}animaker/customcharacter/source/`,
        "thumb": `${ASSET_URL}animaker/customcharacter/userfiles/`,
        "animdata": `${ASSET_URL}animaker/customcharacter/animdata/`,
        "part_src": `${ASSET_URL}animaker/customcharacter/parts/`,
        "action_gif": `${ASSET_URL}animaker/customcharacter/gif/`
    },
    "ECHR": {
        "src": `${ASSET_URL}animaker/characters/echar/source/`,
        "thumb": `${ASSET_URL}animaker/characters/echar/source/`,
        "animdata": `${ASSET_URL}animaker/characters/echar/animdata/`,
        "action_gif": `${ASSET_URL}animaker/characters/echar/gif/`
    },
    "BG": {
        "src": `${ASSET_URL}animaker/backgrounds/images/`,
        "thumb": `${ASSET_URL}animaker/backgrounds/thumbnails/`,
        "proxy": `${ASSET_URL}animaker/backgrounds/proxy/`,
        "obgsrc": `${ASSET_URL}animaker/backgrounds/`,
    },
    "EBG": {
        "src": `${ASSET_URL}animaker/backgrounds/ebg/images/`,
        "thumb": `${ASSET_URL}animaker/backgrounds/ebg/thumbnails/`,
    },
    "VIDEO": {
        "src": `${ASSET_URL}animaker/backgrounds/images/`,
        "thumb": "https://media.animaker.com/api/v1/e/",
        "proxy": "https://media.animaker.com/api/v1/a/",
        "thumbStrip": "https://media.animaker.com/api/v1/c/",
    },
    "TEXT": {
        "src": `${ASSET_URL}animaker/texts/images/`,
        "thumb": `${ASSET_URL}animaker/texts/thumbnails/`,
        "proxy": `${ASSET_URL}app/eff/`,
    },
    "TXTGRP": {
        "src": `${ASSET_URL}animaker/texts/images/`,
        "thumb": `${ASSET_URL}animaker/texts/thumbnails/`,
        "proxy": `${ASSET_URL}animaker/texts/prebuild/`,
    },
    "GROUP": {
        "src": `${ASSET_URL}animaker/photos/proxy-images/`,
        "thumb": `${ASSET_URL}animaker/photos/thumb/`,
        "proxy": `${ASSET_URL}animaker/photos/fhd/`,
    },
    "MUSIC": {
        "src": `${ASSET_URL}animaker/musics/background-music/`,
        "BGM": `${ASSET_URL}animaker/music/`,
        "SFX": `${ASSET_URL}animaker/soundeffects/`,
        "thumb": `${ASSET_URL}animaker/musics/background-music/`,
        "proxy": `${ASSET_URL}animaker/musics/background-music/`,
        "EAB": `${EAB_URL}animaker/music/e-s/proxy/`,
    },
    "EAB": {
        "src": `${EAB_URL}animaker/music/e-s/proxy/`,
        "paidSrc": `${EAB_URL}animaker/music/e-s/proxy2/`,
    },
    "IMG": {
        "img_src": `${ASSET_URL}animaker/photos/proxy-images/`,
        "img_thumb": `${ASSET_URL}animaker/photos/thumb/`,
        "img_proxy_1": `${ASSET_URL}animaker/photos/fhd/`,
        "img_proxy_2": `${ASSET_URL}animaker/photos/hd/`,
        "img_proxy_3": `${ASSET_URL}animaker/photos/sd/`,
    },
    "UPLOADS": {
        "image_src": USER_ASSETS,
        "image_thumb": USER_ASSETS,
        "video_src": USER_ASSETS,
        "audio_src": USER_ASSETS,
        "video_thumb": USER_ASSETS,
        "font_src": USER_ASSETS,
        "VIDEO_THUMB": `${USER_ASSETS}/video/thumb/`,
        "AUDIO": `${USER_ASSETS}/audio/`,
        "VIDEO": `${USER_ASSETS}/video/`,
        "PRE_UPLOAD": `${API_URL}editor/prepareUpload/`,
        "S3_UPLOAD": "https://anim-user-uploads.s3-us-west-2.amazonaws.com/",
        "POST_UPLOAD": `${API_URL}editor/postupload/`,
        "POST_UPLOAD_STATUS": `${API_URL}editor/postupload/status/`,
        "UPLOAD_MULTIPART_URL": "https://dash.animaker.com/a/",
        "UPLOAD_BUCKET": "anim-user-uploads",
        "UPLOAD_BKEY": "a/u",
        "PREFLIGHT_URL": `${API_URL}editor/preflightcheck/`,
        "UP_IMG": "image/png, image/gif, image/svg+xml, image/jpg, image/jpeg, image/webp, image/x-icon, image/svg xml, image/heic, image/heif",
        "UP_VID": "video/mp4, video/mov, video/mpeg, video/x-msvideo, video/3gpp, video/quicktime, video/avi, video/x-flv, video/webm, video/x-ms-wma",
        "UP_AUD": "audio/mp3, audio/mpeg, audio/x-wav, audio/x-m4a, audio/wave, audio/x-pn-wav, audio/wav, audio/m4a, audio/aac, audio/aiff",
        "SUPPORTED_FORMATS": ".png, .gif, .svg+xml, .jpg, .jpeg, .heic, .webp, .mp4, .mov, .mpeg, .x-msvideo, .3gpp, .quicktime, .avi, .x-flv, .webm, .x-ms-wma, .wma, .mp3, .aac, .aiff, .m4a, .wav, .x-pn-wav, .wave, .x-m4a, .x-wav, .heif",
        "SUPPORTED_AUDIO_TYPES": ".mp3"
    },
    "GIPHY": {
        "src": "",
        "thumb": "",
    },
    "STOCKIMG": {
        "src": "",
        "thumb": "",
    },
    "PEXELS": {
        "src": "",
        "thumb": "",
    },
    "PIXABAY": {
        "src": "",
        "thumb": "",
    },
    "PEXELS_VIDEOS": {
        "src": "",
        "thumb": "",
    },
    "UNSPLASH": {
        "src": "",
        "thumb": "",
    },
    "PIXABAY_VIDEOS": {
        "src": "",
        "thumb": "",
    },
    "TRANS": {
        "src": `${ASSET_URL}animaker/transitionimages/`,
        "thumb": `${ASSET_URL}animaker/transitionimages/`,
        "proxy": `${ASSET_URL}animaker/transitionimages/`,
    },
    "SHAPE": {
        "src": `${ASSET_URL}animaker/shapes/images/`,
        "thumb": `${ASSET_URL}animaker/shapes/thumbnails/`,
    },
    "FRAME": {
        "src": `${STATIC_PATH}frames/`,
        "DEFAULT_IMAGE": `${STATIC_PATH}frames/bg-frame.png`,
        "thumb": `${ASSET_URL}animaker/properties/thumbnails/`,
    },
    "USER_UPLOADS": {
        "videoSrc": "https://animaker-dev.s3.amazonaws.com/animaker/uploads/",
        "thumb": "thumb",
        "vProxy1": "video/proxy1",
        "vProxy2": "video/proxy2",
        "vProxy3": "video/proxy3",
        "imgProxy": "image/proxy1"
    },
    "STOCKVID": {
        "src": "https://media.animaker.com/api/v1/a/",
        "thumb": "https://media.animaker.com/api/v1/e/",
        "proxy": "https://media.animaker.com/api/v1/a/",
        "proxyThumb": "https://media.animaker.com/api/v1/f/",
    },
    "ZOOM": {
        "src": "",
        "thumb": "",
        "proxy": "",
        "proxyThumb": "",
    },
    "VERSION": {
        "maxCount": 50,
        "maxSeconds": 120
    },
    "BGR": {
        "dev": "https://devbgr2.picmaker.com/",
        "demo": "https://snbx-bgr2.picmaker.com/",
        "live": "https://bgr2.picmaker.com/",
        "current": "dev"
    },
    SITE_URL,
    "SUBTITLE_UPLOAD": {
        "SUPPORTED_FORMATS": ".srt, .vtt, .sbv, .ass"
    },
    MAX_FILE_DURATION,
    MAX_AUDIO_FILE_DURATION
};

module.exports = AppConfig;