import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fromJS } from "immutable";
import { Scroll } from "../../panel-components";
import usePanelScrollHeight from "../../../../helper/hooks/useScrollHeight";
import Tile from "../../../../common-components/Tile";
import { STATIC_PATH } from "../../../../constants/config";
import { FlexWrapper, Loader } from "./subtitleproperty-components";
import vmTheme from "../../../../constants/theme";
import {
  DEFAULT_ANIMDATA,
  getSampleSubtitleText,
  getUpdatedSubTitleList,
} from "../../../../helper/subtitleHelper";
import { updateTimelineTime } from "../../../../redux/actions/timelineUtils";
import {
  getSubtitlesFontToLoad,
  loadSubtitleFontFamily,
} from "../../../../helper/fontLoadHelper";

const SubtitlePreset = ({ height, width }) => {
  const [isEffectLoading, setIsEffectLoading] = useState(false);

  const panelRef = useRef();
  const dispatch = useDispatch();
  const appReducer = useSelector((state) => state.app);
  const { dimensionName } = useSelector((state) => state.userDetails);
  const workspaceStage = appReducer.get("workspaceStage");
  const workspaceWidth = appReducer.get("projectWidth");
  const workspaceHeight = appReducer.get("projectHeight");
  const loadedFonts = appReducer.getIn(["loadedFonts", "fonts"]);
  const theme = appReducer.get("theme");
  const isShortsPlayer = appReducer.get("isShortsPlayer")
  const presetEffects = appReducer.get("preset");
  const subtitle = useSelector((state) => state.projectDetails.get("subtitle"));
  const projectDetails = useSelector((state) => state.projectDetails);
  const mainScrollableHeight = usePanelScrollHeight({
    panelRef,
    workspaceStage,
    shouldUpdate: true,
  });

  /** @description reduce the fontSize for ver and sqr projects. */
  const validateFontSize = (fontSize) => {
    fontSize = parseFloat(fontSize);
    if (fontSize > 24) {
      const ignoreOffSet = 50;
      if (workspaceWidth === workspaceHeight) {
        fontSize -= 2;
      } else if (Math.abs(workspaceWidth - workspaceHeight) <= ignoreOffSet) {
        fontSize -= 2;
      } else if (workspaceWidth < workspaceHeight) {
        fontSize -= 4;
      }
    }
    return `${fontSize}px`;
  };

  const applyTextEffects = async (effect, index, key) => {
    setIsEffectLoading(true);
    const toUpdate = [];
    const textStyles = { ...effect.REC_TEXTSTYLES };
    const animData = { ...(effect.ANIM_DATA || DEFAULT_ANIMDATA) };
    const textEffects = {
      hasTextEffect: index !== 0,
      effectIndex: index,
      key,
      ...effect.TEXTEFFECTS,
    };
    const langId = projectDetails.get("defaultSubtitle");
    textStyles.fontSize = validateFontSize(textStyles.fontSize);
    let fontToLoad = getSubtitlesFontToLoad({}, loadedFonts, textStyles);
    if (fontToLoad.length) {
      const sampleSubtitleText = getSampleSubtitleText(subtitle.get("data").toJS());
      fontToLoad = await loadSubtitleFontFamily(fontToLoad, sampleSubtitleText);
    }
    const updatedDataList = getUpdatedSubTitleList(
      subtitle.get("data"),
      { ...textStyles },
      workspaceWidth,
      workspaceHeight,
      subtitle.get("textStyles"),
      fromJS(textEffects),
      true
    );
    Object.entries(updatedDataList).forEach(([dropId, data]) => {
      Object.entries(data).forEach(([id, { toUpdatePos }]) => {
        // timelineId is needed only for delete action. So, ignoring timelineId.
        toUpdate.push({
          container: "subtitleData",
          id,
          dropId,
          langId,
          toUpdate: toUpdatePos,
        });
      });
    });

    toUpdate.push({
      container: "subtitleGlobal",
      langId: projectDetails.get("defaultSubtitle"),
      toUpdate: { textStyles, textEffects, animData },
    });
    dispatch(updateTimelineTime({ toUpdate }));
    setIsEffectLoading(false);
  };

  const projectHeight = projectDetails.get("height");
  let dimension = dimensionName.substring(0, 3);
  dimension = dimension === 'squ' ? 'sqr' : dimension;
  let presetWindowSize = 360;
  if (dimension === 'ver') {
    presetWindowSize = 640;
  }

  return (
    <Scroll ref={panelRef} height={isShortsPlayer ? "85%" : mainScrollableHeight} width="100%">
      <FlexWrapper>
        {presetEffects && presetEffects.map((item) => {
          const effect = item._source.data
          const { isActive, src, thumbnail, INDEX, KEY, common } = item._source;
          const fSize = common?.fontSize?.[dimension];
          const currentWindowFSize = ((projectHeight * fSize) / presetWindowSize);
          const updatedEffect = {
            ...effect,
            REC_TEXTSTYLES: {
              ...effect.REC_TEXTSTYLES || {},
              fontSize: `${Math.ceil(currentWindowFSize)}px`
            }
          };
          const isEqual = subtitle.getIn(["textEffects", "effectIndex"]) === INDEX
          if (!isActive) {
            return null;
          }
          return (
            <Tile
              key={INDEX}
              src={thumbnail}
              videoSrc={src}
              height={height || "102px"}
              width={width || "178px"}
              border={
                (isEqual &&
                  `2px solid ${vmTheme[theme].secondaryBorderColor}`) ||
                ""
              }
              onTileClick={() => applyTextEffects(updatedEffect, INDEX, KEY)}
              isSelected={isEqual}
              selectedIcon={`${STATIC_PATH}selected-preset.svg`}
              hoverEffect={!isEqual}
              isDisable={isEqual}
              isOpacityEnable={isEqual}
              isVideoTile
            />
          );
        })}
        {isEffectLoading && <Loader />}
      </FlexWrapper>
    </Scroll>
  );
};

SubtitlePreset.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
}

export default SubtitlePreset;
