import { fromJS } from "immutable";

export const DEFAULT_CHILDREN_SELECTION = fromJS({
  childIds: [],
  itemId: "",
});

export const ITEM_OUTLINE_COLOR = {
  CHR: "#2CB9FF",
  TEXT: "#2CB9FF",
  PROP: "#2CB9FF",
  SCR: "#2CB9FF",
  IMAGE: "#2CB9FF",
  GROUP: "#2CB9FF",
  GIF: "#2CB9FF",
  DEFAULT: "#2CB9FF",
  VIDEO: "#2CB9FF",
  TRANSPARENT: "#00000000"
};

/**
 * @param {object} params
 * @param {object} params.childrenSelection
 * @param {object} params.selectedItems
 */
const isChildSelection = (params = {}) => {
  const { childrenSelection, selectedItems } = params;

  return (
    childrenSelection.get("childIds").size > 0 &&
    selectedItems.size === 1 &&
    selectedItems.get(0) === childrenSelection.get("itemId")
  );
};

/**
 * @param {object} params
 * @param {object} params.childrenSelection
 * @param {object} params.selectedItems
 * @param {object} params.workspaceItems
 * @param {object} params.workspaceChildren
 */
export const getSelectedObjects = (params = {}) => {
  const { childrenSelection, selectedItems } = params;
  const { workspaceItems, workspaceChildren } = params;

  if (isChildSelection({ childrenSelection, selectedItems })) {
    const selectedChildIds = childrenSelection.get("childIds");
    return workspaceChildren
      .filter((obj, key) => selectedChildIds.keyOf(key) !== undefined);
  }
  return workspaceItems
    .filter(
      (obj, key) => selectedItems.keyOf(key) !== undefined
    );
};

/**
 * @param {object} params
 * @param {object} params.childrenSelection
 * @param {object} params.selectedItems
 */
export const getSelectionContainer = (params = {}) => {
  if (isChildSelection(params)) {
    return "workspaceChildren";
  }
  return "workspaceItems";
};

/**
 * @param {object} params
 * @param {object} params.childrenSelection
 * @param {object} params.selectedItems
 */
export const getSelectedItemIds = (params) => {
  if (isChildSelection(params)) {
    return params.childrenSelection.get("childIds");
  }
  return params.selectedItems;
};

/**
 * @param {object} params
 * @param {object} params.childrenSelection
 * @param {object} params.selectedItems
 * @param {object} params.workspaceItems
 */
export const getSelectedParent = (params) => {
  if (isChildSelection(params)) {
    return params.workspaceItems.get(params.selectedItems.get(0));
  }
  return null;
};
