/* eslint-disable camelcase */

import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fromJS } from "immutable";
import {
  MultiSelectBounds,
  OverflowProp,
  SceneContainer,
  WorkspaceContainer,
  WorkspaceWrapper,
} from "./workspace-components";
import WorkspaceBGColor from "./workspace-bgcolor";
import WorkspaceBG from "./workspace-bg";
import WorkspaceItem from "./workspace-item";
import { ITEM_CONFIG, ANIMO_RENDER } from "../../constants/config";
import {
  addTween,
  fontLoaded,
  prefetchComplete,
  selectFrameClip,
  setChildrenSelection,
  setInitStages,
  setPlayAll,
  setPlayHeadTime,
  setPropertyPanel,
  setPropertyWindow,
  setSelectedItems,
  setTextOptions,
  setWorkspaceDragging,
  updateTextStatus,
} from "../../redux/actions/appUtils";
import Player from "../player/player";
import WorkspaceBackdrop from "./workspace-backdrop";
import {
  isMouseOn,
  isMouseOnWorkspace,
  isMouseOverSelectionBox,
  getMouseClientPosition,
} from "../../helper/TransformManagerHelper";
import { TextStatusRecord } from "../text/text-constants";
import { FRAME_CLASSES } from "../frame/frame-helper";
import TransformManager from "../transformmanager/transformmanager";
import TMOverlay from "../transformmanager/tm-overlay";
import SelectionHighlight from "../transformmanager/tm-selectionhighlight";
import { BgSelectionHighlight } from "../transformmanager/tm-components";
import FrameImage from "../transformmanager/tm-frameimage";
import Crop from "../transformmanager/tm-crop";
import ContextMenu from "./contextmenu/workspace-contextmenu";
import ItemToolBar from "../itemtoolbar/itemtoolbar";
import SwapDropArea from "./workspace-swaphoverdrop";
import WaterMark from "./watermark";
import {
  isImageOnly,
  isUpImageSVG,
  isVideoOnly,
} from "../timeline/timeline-helper";
import { showUpgrade } from "../../redux/actions/modalAction";
import { PANEL, PLAN } from "../../constants";
import PreviewWorkspace from "./preview-workspace";
import SubtitleTextComponent from "../text/subtitle-text/subtitle-text";
import { addFont } from "../../helper/fontLoadHelper";
import { updateBlobItem } from "../../redux/actions/timelineUtils";

class WorkspaceComponent extends Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line no-unused-vars
    const { isMobile } = this.props;

    this.state = {
      selectionBoxMouseDown: null,
      multiselectBounds: null,
      isMultiSelecting: false,
      mouseStartPosition: null,
      contextMenu: false,
      contextMenuClickPos: null,
      isToolBarVisible: false,
    };

    // generic handlers
    this.isRightMouse = this.isRightMouse.bind(this);

    // workspace assemblers
    this.getWorkspaceBGList = this.getWorkspaceBGList.bind(this);
    this.getWorkspaceItemList = this.getWorkspaceItemList.bind(this);
    this.getSubtitleItemList = this.getSubtitleItemList.bind(this);

    // functions related to text status and text options
    this.getColorsFromHtml = this.getColorsFromHtml.bind(this);
    this.getAllColors = this.getAllColors.bind(this);
    this.getTextFormats = this.getTextFormats.bind(this);

    // font preload callbacks
    this.trackLoading = this.trackLoading.bind(this);

    // workspace wrapper mouse handlers
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.initiateMultiselect = this.initiateMultiselect.bind(this);
    this.multiselect = this.multiselect.bind(this);
    this.stopMultiselect = this.stopMultiselect.bind(this);
    this.multiselectByBounds = this.multiselectByBounds.bind(this);
    this.addMultiselectListeners = this.addMultiselectListeners.bind(this);
    this.removeMultiselectListeners =
      this.removeMultiselectListeners.bind(this);

    // functions related to transform manager and selection
    this.isPlayheadInSelectionTimeRange =
      this.isPlayheadInSelectionTimeRange.bind(this);
    this.isMouseOnSelectionBox = this.isMouseOnSelectionBox.bind(this);
    this.clearChildSelection = this.clearChildSelection.bind(this);
    this.getHoverClass = this.getHoverClass.bind(this);

    // workspace item mouse handlers
    this.handleItemMouseUp = this.handleItemMouseUp.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);

    // context menu handlers
    this.openContextMenu = this.openContextMenu.bind(this);
    this.hideContextMenu = this.hideContextMenu.bind(this);

    this.showItems = this.showItems.bind(this);
    this.textAnimPause = this.textAnimPause.bind(this);
    this.enablePlayer = this.enablePlayer.bind(this);
    this.showSubtitle = this.showSubtitle.bind(this);
    this.handleWorkspaceClick = this.handleWorkspaceClick.bind(this);

    // refs
    /** @type {React.MutableRefObject<HTMLElement | null>} */
    this.transformManagerRef = createRef();
    /** @type {React.MutableRefObject<HTMLElement | null>} */
    this.workspaceRef = createRef();

    // interval timerids and timestamps
    this.itemMouseUpTime = null;
    this.clearBlob = false;
  }

  componentDidMount() {
    if (this.props.initStages.get("projectLoaded")) {
      if (this.props.loadedFonts.get("fonts").size > 0) {
        this.props.loadedFonts
          .get("fonts")
          .valueSeq()
          .forEach((fontDetail) => {
            addFont(fontDetail, this.props.fontLoaded);
          });
        this.trackLoading();
      } else {
        this.props.setInitStages({ fontsLoaded: true });
      }
    }

    // this.props.t1.pause(0);
    if (ANIMO_RENDER) {
      window.textAnimPause = this.textAnimPause;
      window.enablePlayer = this.enablePlayer;
      window.showItems = this.showItems;
      window.showSubtitle = this.showSubtitle;
    }
  }

  componentDidUpdate(prevProps) {
    const currentLoadedFonts = this.props.loadedFonts;
    const prevLoadedFonts = prevProps.loadedFonts;

    if (
      // this.props.initStages.get("fontsLoaded") &&
      currentLoadedFonts !== prevLoadedFonts &&
      currentLoadedFonts.get("fonts") &&
      currentLoadedFonts.get("fonts").size > 0 &&
      prevLoadedFonts.get("fonts") &&
      !currentLoadedFonts.get("fonts").equals(prevLoadedFonts.get("fonts"))
    ) {
      const toAdd = currentLoadedFonts.get("fonts").filter((value) => {
        return (
          !value.get("isLoaded") &&
          prevLoadedFonts
            .get("fonts")
            .find((x) => x.get("load") === value.get("load")) === undefined
        );
      });
      if (toAdd.size > 0) {
        toAdd
          .valueSeq()
          .forEach((fontDetail) => addFont(fontDetail, this.props.fontLoaded));
      }
    }

    if (
      (this.props.isCropping !== prevProps.isCropping &&
        this.props.isCropping) ||
      (this.props.isFraming !== prevProps.isFraming && this.props.isFraming) ||
      (this.props.isPlayAll !== prevProps.isPlayAll && this.props.isPlayAll)
    ) {
      // reset selection state whenever TransformManager unmounts
      if (this.state.selectionBoxMouseDown !== null) {
        this.setState({
          selectionBoxMouseDown: null,
        });
      }
    }

    if (prevProps.textStatus !== this.props.textStatus) {
      if (
        (prevProps.textStatus.get("isFocused") !==
          this.props.textStatus.get("isFocused") &&
          this.props.textStatus.get("isFocused")) ||
        (prevProps.textStatus.get("isSelected") !==
          this.props.textStatus.get("isSelected") &&
          this.props.textStatus.get("isSelected")) ||
        prevProps.textStatus.get("id") !== this.props.textStatus.get("id")
      ) {
        if (
          !this.props.textStatus.get("isGrouped") &&
          this.props.textStatus.get("id") !== null
        ) {
          const textFormats = this.getTextFormats(
            this.props.projectDetails.getIn([
              this.props.textStatus.get("container"),
              this.props.textStatus.get("id"),
            ])
          );
          const colors = this.getAllColors(
            this.props,
            this.props.textStatus.get("id"),
            this.props.textStatus.get("container")
          );

          if (colors.length > 0) Object.assign(textFormats, { colors });
          this.props.setTextOptions(textFormats);
        } else if (
          this.props.textStatus.get("isGrouped") &&
          this.props.textStatus.get("isFocused")
        ) {
          const textFormats = this.getTextFormats(
            this.props.projectDetails.getIn([
              this.props.textStatus.get("container"),
              this.props.textStatus.get("id"),
            ])
          );
          const colors = this.getAllColors(
            this.props,
            this.props.textStatus.get("id"),
            this.props.textStatus.get("container")
          );

          if (colors.length > 0) Object.assign(textFormats, { colors });

          this.props.setTextOptions(textFormats);
        }
      } else if (
        prevProps.textStatus.get("fontSize") !==
        this.props.textStatus.get("fontSize")
      ) {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(this.props.textStatus.get("fontSize")))
          this.props.setTextOptions({
            fontSize: this.props.textStatus.get("fontSize"),
          });
      }
    } else if (
      (this.props.textStatus.get("isFocused") ||
        this.props.textStatus.get("isSelected")) &&
      this.props.textStatus.get("id") !== null
    ) {
      if (!this.props.textStatus.get("isGrouped")) {
        const nextFormats = this.props.projectDetails.getIn([
          this.props.textStatus.get("container"),
          this.props.textStatus.get("id"),
          "textData",
          "formats",
        ]);
        const prevFormats = prevProps.projectDetails.getIn([
          this.props.textStatus.get("container"),
          this.props.textStatus.get("id"),
          "textData",
          "formats",
        ]);
        if (nextFormats && prevFormats && !nextFormats.equals(prevFormats)) {
          const textFormats = this.getTextFormats(
            this.props.projectDetails.getIn([
              this.props.textStatus.get("container"),
              this.props.textStatus.get("id"),
            ])
          );
          const colors = this.getAllColors(
            this.props,
            this.props.textStatus.get("id"),
            this.props.textStatus.get("container")
          );
          if (colors.length > 0) Object.assign(textFormats, { colors });

          this.props.setTextOptions(textFormats);
        }
      } else if (
        this.props.textStatus.get("isSelected") &&
        this.props.selectedItems.get(0) === this.props.textStatus.get("id")
      ) {
        const selectedData = this.props.projectDetails.getIn([
          "workspaceItems",
          this.props.textStatus.get("id"),
        ]);
        const childIds = selectedData.get("groupChildren");
        const textChildren = this.props.projectDetails
          .getIn(["workspaceChildren"])
          .filter((obj, key) => {
            return (
              childIds.keyOf(key) !== undefined && obj.get("type") === "TEXT"
            );
          });

        const textKeys = textChildren.keySeq().toArray();
        const nextFormats = this.props.projectDetails.getIn([
          "workspaceChildren",
          textKeys[0],
          "textData",
          "formats",
        ]);
        const prevFormats = prevProps.projectDetails.getIn([
          "workspaceChildren",
          textKeys[0],
          "textData",
          "formats",
        ]);

        if (nextFormats && prevFormats && !nextFormats.equals(prevFormats)) {
          const colors = this.getAllColors(
            this.props,
            this.props.textStatus.get("id"),
            this.props.textStatus.get("container"),
            true
          );
          this.props.setTextOptions({ colors });
        }
      } else if (
        this.props.textStatus.get("isGrouped") &&
        this.props.textStatus.get("isFocused")
      ) {
        const textItem = this.props.projectDetails.getIn([
          this.props.textStatus.get("container"),
          this.props.textStatus.get("id"),
        ]);
        const nextFormats = this.props.projectDetails.getIn([
          "workspaceChildren",
          this.props.textStatus.get("id"),
          "textData",
          "formats",
        ]);
        const prevFormats = prevProps.projectDetails.getIn([
          "workspaceChildren",
          this.props.textStatus.get("id"),
          "textData",
          "formats",
        ]);
        if (
          textItem != null &&
          nextFormats &&
          prevFormats &&
          !nextFormats.equals(prevFormats)
        ) {
          const textFormats = this.getTextFormats(textItem);
          const colors = this.getAllColors(
            this.props,
            this.props.textStatus.get("id"),
            this.props.textStatus.get("container")
          );

          if (colors.length > 0) Object.assign(textFormats, { colors });

          this.props.setTextOptions(textFormats);
        }
      }
    }

    if (!this.props.selectedItems.equals(prevProps.selectedItems)) {
      if (
        this.props.selectedItems.size === 0 &&
        this.state.selectionBoxMouseDown
      ) {
        // reset selection state on deselection
        this.setState({
          selectionBoxMouseDown: null,
        });
      }

      const firstSelectionId = this.props.selectedItems.get(0);
      if (
        firstSelectionId &&
        this.props.selectedItems.size === 1 &&
        (!this.props.textStatus.get("isFocused") ||
          this.props.textStatus.get("id") !== this.props.selectedItems.get(0))
      ) {
        const firstSelectedItem = this.props.projectDetails.getIn([
          "workspaceItems",
          firstSelectionId,
        ]);
        if (
          firstSelectedItem &&
          (firstSelectedItem.get("type") === "TEXT" ||
            firstSelectedItem.get("type") === "SHAPE")
        ) {
          if (firstSelectedItem.get("type") === "SHAPE") {
            this.props.updateTextStatus({
              id: firstSelectionId,
              isFocused: false,
              isSelected: true,
              container: "workspaceItems",
              isRTL: firstSelectedItem.getIn([
                "textData",
                "formats",
                "others",
                "isRTL",
              ]),
              isGrouped: false,
              fontSize: parseFloat(
                firstSelectedItem.getIn([
                  "textData",
                  "formats",
                  "containerStyle",
                  "fontSize",
                ])
              ),
              type: firstSelectedItem.get("type"),
            });
          } else if (firstSelectedItem.get("type") === "TEXT") {
            this.props.updateTextStatus({
              id: firstSelectionId,
              isSelected: true,
              container: "workspaceItems",
              isRTL: firstSelectedItem.getIn([
                "textData",
                "formats",
                "others",
                "isRTL",
              ]),
              isGrouped: false,
              fontSize: parseFloat(
                firstSelectedItem.getIn([
                  "textData",
                  "formats",
                  "containerStyle",
                  "fontSize",
                ])
              ),
              type: firstSelectedItem.get("type"),
            });
          }
          const textFormats = this.getTextFormats(firstSelectedItem);
          const colors = this.getAllColors(
            this.props,
            firstSelectionId,
            "workspaceItems"
          );
          if (colors.length > 0) Object.assign(textFormats, { colors });
          this.props.setTextOptions(textFormats);
        } else if (
          firstSelectedItem.get("type") !== "GROUP" ||
          (firstSelectedItem.get("type") === "GROUP" &&
            this.props.childrenSelection.getIn(["childIds", 0]) !== this.props.textStatus.get("id")) // different child item is selected
        ) {
          if (this.props.textStatus.get("id") !== null) {
            this.props.updateTextStatus({
              id: null,
              isFocused: false,
              isSelected: false,
              container: null,
              isRTL: false,
              isGrouped: false,
              fontSize: null,
            });
          }
        }
      }
    }

    if (!this.props.selectedSubtitles.equals(prevProps.selectedSubtitles)) {
      if (
        this.props.selectedSubtitles.size === 0 &&
        this.state.selectionBoxMouseDown
      ) {
        // reset selection state on subtitle deselection
        this.setState({
          selectionBoxMouseDown: null,
        });
      }
    }

    // Need to delete blob item in initial load. (Refresh while uploado)
    if (this.clearBlob === false && this.props.projectDetails.get("duration") > 0) {
      this.clearBlob = true;
      this.props.projectDetails.get("workspaceItems").forEach((item) => {
        if (item.get("isBlob")) {
          this.props.updateBlobItem({
            toUpdate: [
              {
                container: "workspaceItems",
                blobId: item.get("id"),
                isDelete: true,
              },
            ],
          });
        }
      })
    }

  }

  // eslint-disable-next-line react/sort-comp
  trackLoading() {
    if (
      this.props.hasAllFontsLoaded
      // commenting below check as browser reports font has loaded before we have the chance to preload workspace's font
      // || document.fonts.status === "loaded"
    ) {
      this.props.setInitStages({ fontsLoaded: true });
      document
        .querySelectorAll("[data-dummy]")
        .forEach((node) => document.body.removeChild(node));
    } else {
      setTimeout(this.trackLoading, 100);
    }
  }

  isPlayheadInSelectionTimeRange() {
    const { playhead, selectedItems, projectDetails } = this.props;
    const workspaceItems = projectDetails.get("workspaceItems");

    return (
      selectedItems.size &&
      selectedItems.some((id) => {
        const item = workspaceItems.get(id);
        return (
          item &&
          playhead >= item.get("enterStart") &&
          playhead <= item.get("exitEnd")
        );
      })
    );
  }

  /**
   * @param {object} params
   * @param params.event mouse/touch event
   * @param {string | null} params.uniqueClassName transform manager unique classname (ref will be used when null)
   * @returns {boolean} whether event triggered on selection box
   */
  isMouseOnSelectionBox({ event, uniqueClassName = null } = {}) {
    let result = false;
    try {
      if (
        this.isPlayheadInSelectionTimeRange() &&
        (isMouseOnWorkspace(event) || isMouseOn(event, "scene-item"))
      ) {
        const selectionBoxMouse = isMouseOverSelectionBox({
          ref: !uniqueClassName ? this.transformManagerRef : null,
          uniqueClassName: uniqueClassName || null,
          workspaceRef: this.workspaceRef,
          event,
        });
        if (selectionBoxMouse && selectionBoxMouse.result) {
          result = true;
        }
      }
    } catch (error) {
      result = false;
    }
    return result;
  }

  handleMouseDown(e) {
    if (this.props.isPlayAll) {
      return;
    }

    const mouseDownPosition = getMouseClientPosition(e);
    // const clientX = mouseDownPosition.get("x");
    // const clientY = mouseDownPosition.get("y");

    const mouseDownOnSelectionBox = this.isMouseOnSelectionBox({ event: e });

    if (isMouseOnWorkspace(e)) {
      if (mouseDownOnSelectionBox) {
        this.setState({ selectionBoxMouseDown: mouseDownPosition });
      } else {
        this.initiateMultiselect(e);
      }
    }
  }

  addMultiselectListeners() {
    window.addEventListener("pointermove", this.multiselect);
    window.addEventListener("pointerup", this.stopMultiselect);
  }

  removeMultiselectListeners() {
    window.removeEventListener("pointermove", this.multiselect);
    window.removeEventListener("pointerup", this.stopMultiselect);
  }

  initiateMultiselect(e) {
    const mouseDownPosition = getMouseClientPosition(e);

    const multiselectBounds = {
      x: mouseDownPosition.get("x"),
      y: mouseDownPosition.get("y"),
      width: 0,
      height: 0,
    };

    this.addMultiselectListeners();
    this.props.setWorkspaceDragging(true);
    this.props.setSelectedItems({
      selectedItems: [],
      currentPosition: {
        x: mouseDownPosition.get("x"),
        y: mouseDownPosition.get("y"),
      },
    });
    this.setState({
      isMultiSelecting: true,
      multiselectBounds,
      mouseStartPosition: mouseDownPosition,
      selectionBoxMouseDown: null,
    });
  }

  multiselect(e) {
    if (!this.state.isMultiSelecting) {
      return;
    }

    const mousePosition = getMouseClientPosition(e);
    const { mouseStartPosition } = this.state;

    const multiselectBounds = {
      x: mouseStartPosition.get("x"),
      y: mouseStartPosition.get("y"),
      width: Math.round(mousePosition.get("x") - mouseStartPosition.get("x")),
      height: Math.round(mousePosition.get("y") - mouseStartPosition.get("y")),
    };

    if (multiselectBounds.width < 0) {
      multiselectBounds.x += multiselectBounds.width;
      multiselectBounds.width = Math.abs(multiselectBounds.width);
    }
    if (multiselectBounds.height < 0) {
      multiselectBounds.y += multiselectBounds.height;
      multiselectBounds.height = Math.abs(multiselectBounds.height);
    }

    this.setState({ multiselectBounds });
  }

  multiselectByBounds(x, y, width, height) {
    const workspaceEl = this.workspaceRef.current;
    if (!workspaceEl) {
      return;
    }

    if (width >= 5 || height >= 5) {
      const workspaceBounds = workspaceEl.getBoundingClientRect();
      const allItems = this.props.projectDetails.get("workspaceItems");
      const workspaceBoundsX = workspaceBounds.x;
      const workspaceBoundsY = workspaceBounds.y;
      const multiSelectedItems = [];

      allItems.entrySeq().forEach(([key, item]) => {
        const isVisible =
          this.props.playhead >= item.get("enterStart") &&
          this.props.playhead <= item.get("exitEnd");
        const current_item = document.getElementById(key);
        if (!isVisible || !current_item) {
          return;
        }

        const current_item_bounds = current_item.getBoundingClientRect();
        const item_x = current_item_bounds.left - workspaceBoundsX;
        const item_y = current_item_bounds.top - workspaceBoundsY;
        if (
          x < item_x + current_item_bounds.width &&
          x + width > item_x &&
          y < item_y + current_item_bounds.height &&
          height + y > item_y &&
          !item.get("isLocked")
        ) {
          multiSelectedItems.push(key);
        }
      });

      if (multiSelectedItems.length > 0) {
        const selectedDetails = { selectedItems: multiSelectedItems };
        if (multiSelectedItems.length === 1) {
          selectedDetails.type = this.props.projectDetails.getIn([
            "workspaceItems",
            multiSelectedItems[0],
            "type",
          ]);
          selectedDetails.subType = this.props.projectDetails.getIn([
            "workspaceItems",
            multiSelectedItems[0],
            "subType",
          ]);
        }

        this.props.setSelectedItems(selectedDetails);
      }
    }
  }

  stopMultiselect() {
    let { multiselectBounds } = this.state;
    const workspaceEl = this.workspaceRef.current;
    const workspaceBounds = workspaceEl.getBoundingClientRect();

    this.removeMultiselectListeners();
    this.props.setWorkspaceDragging(false);
    this.setState({
      isMultiSelecting: false,
      multiselectBounds: null,
      mouseStartPosition: null,
    });

    if (!workspaceEl) {
      return;
    }

    multiselectBounds = {
      ...multiselectBounds,
      x: multiselectBounds.x - workspaceBounds.x,
      y: multiselectBounds.y - workspaceBounds.y,
    };
    this.multiselectByBounds(
      multiselectBounds.x,
      multiselectBounds.y,
      multiselectBounds.width,
      multiselectBounds.height
    );
  }

  isRightMouse(e) {
    let isRightMB;
    try {
      e = e || window.event;
      if ("which" in e) isRightMB = e.which === 3;
      else if ("button" in e) isRightMB = e.button === 2;
    } catch (error) {}
    return isRightMB;
  }

  /**
   * @param {React.PointerEvent<HTMLElement>} event
   */
  openContextMenu(event) {
    event.preventDefault();

    const mousePos = getMouseClientPosition(event);
    this.setState({
      contextMenu: true,
      contextMenuClickPos: { x: mousePos.get("x"), y: mousePos.get("y") },
    });
  }

  hideContextMenu() {
    this.setState({ contextMenu: false, contextMenuClickPos: null });
  }

  handleItemMouseUp(e, id, type, subType, childId) {
    try {
      if (
        this.state.isMultiSelecting ||
        this.props.swapDetails ||
        this.props.transformStatus.get("transforming") // dont handle selection when transform manager is moved/rotated
      ) {
        this.itemMouseUpTime = null;
        return;
      }

      let currentTimeStamp = e.timeStamp;
      let clearSelection = false;
      const ctrlPressed = e.ctrlKey || e.metaKey;
      const isTargetTextContainer =
        isMouseOn(e, "text-container") &&
        !(
          e.target instanceof HTMLElement &&
          // isMouseOn will choose callout-text-container class if text-container is not clicked (false positive), this check makes sure it doesn't happen
          e.target.classList.contains("callout-text-container")
        );
      const isDoubleClick =
        this.itemMouseUpTime !== null &&
        currentTimeStamp - this.itemMouseUpTime < 250 &&
        !this.isRightMouse(e);

      if (childId && !ctrlPressed) {
        let selectionToUpdate = fromJS({
          itemId: id,
          childIds: [childId],
        });
        const item = this.props.projectDetails.getIn(["workspaceChildren", childId]);
        // to allow clip selection/deselection inside group
        const ignoreFrameClick =
          (type === "FRAME" || item.get("isSingleClipFrame")) &&
          (isMouseOn(e, FRAME_CLASSES.DROP_AREA) ||
            this.props.selectedFrameClip.get("clipId"));
        if (
          this.props.childrenSelection.get("childIds").includes(childId) &&
          !ignoreFrameClick &&
          type !== "TEXT" && // need not deselect text as text may be clicked multiple times to move caret
          !(type === "SHAPE" && isTargetTextContainer) // need not deselect shape when its text is clicked as text may be clicked multiple times to move caret
        ) {
          selectionToUpdate = selectionToUpdate.set(
            "childIds",
            this.props.childrenSelection
              .get("childIds")
              .filter((id) => id !== childId)
          );
        }

        if (type === "TEXT" || (type === "SHAPE" && isTargetTextContainer)) {
          selectionToUpdate = selectionToUpdate.set("isTextSelection", true);
        }
        this.props.setChildrenSelection(selectionToUpdate);

        if (
          // update text focus if text container is selected
          (type === "TEXT" || (type === "SHAPE" && isTargetTextContainer)) &&
          (!this.props.textStatus.get("isFocused") /* no text was focused */ ||
            // different text was focused
            (this.props.textStatus.get("isFocused") &&
              this.props.textStatus.get("id") !== childId))
        ) {
          const text = this.props.projectDetails.getIn([
            "workspaceChildren",
            childId,
          ]);
          const parent = this.props.projectDetails.getIn([
            "workspaceItems",
            id,
          ]);
          this.props.updateTextStatus({
            id: childId,
            isFocused: true,
            isSelected: true,
            container: "workspaceChildren",
            isRTL: text.getIn(["textData", "formats", "others", "isRTL"]),
            isGrouped: true,
            fontSize: parseFloat(
              text.getIn(["textData", "formats", "containerStyle", "fontSize"])
            ),
            type,
            textGroup: parent.get("subType") === "TXTGRP",
          });
        } else if (
          // reset text status if some other child is selected
          this.props.textStatus.get("id") !== childId ||
          // shape will be deseleted, so clear its text status
          (type === "SHAPE" &&
            !isTargetTextContainer &&
            this.props.textStatus.get("isFocused") &&
            this.props.textStatus.get("id") === childId)
        ) {
          this.props.updateTextStatus(TextStatusRecord());
        }
      } else if (
        (this.props.childrenSelection.get("childIds").size > 0 &&
          id !== this.props.childrenSelection.get("itemId")) || // different item is selected
        (id === this.props.childrenSelection.get("itemId") &&
          e.target === e.currentTarget) // clicked on selected group itself instead of its children
      ) {
        clearSelection = true;
      }

      if (clearSelection) {
        this.clearChildSelection();
      }

      if (isDoubleClick) {
        if (
          !childId &&
          !ctrlPressed &&
          type === "SHAPE" &&
          !this.props.textStatus.get("isFocused")
        ) {
          // focus shape text on double click
          const text = this.props.projectDetails.getIn(["workspaceItems", id]);
          this.props.updateTextStatus({
            id,
            isFocused: true,
            isSelected: true,
            container: "workspaceItems",
            isRTL: text.getIn(["textData", "formats", "others", "isRTL"]),
            isGrouped: false,
            fontSize: parseFloat(
              text.getIn(["textData", "formats", "containerStyle", "fontSize"])
            ),
            type,
          });
        } else if (type === "SUBTITLE") {
          this.props.setLibraryPanel(PANEL.SUBTITLE, true);
        }
        currentTimeStamp = null;
      } else if (
        !childId &&
        !ctrlPressed &&
        type === "SHAPE" &&
        !isTargetTextContainer &&
        this.props.textStatus.get("isFocused")
      ) {
        // single click && unfocus shape text on shape click
        currentTimeStamp = null;
        this.props.updateTextStatus({ isFocused: false });
      }

      this.itemMouseUpTime = currentTimeStamp;
    } catch (error) {}
  }

  clearChildSelection() {
    if (
      this.props.childrenSelection
        .get("childIds")
        .includes(this.props.textStatus.get("id"))
    ) {
      // remove focus if child text is focused
      this.props.updateTextStatus(TextStatusRecord());
    }

    const selectionToUpdate = fromJS({
      itemId: "",
      childIds: [],
    });
    this.props.setChildrenSelection(selectionToUpdate);
  }

  handleItemClick(e, id, type, subType, childId) {
    try {
      if (this.props.swapDetails) {
        return;
      }

      if (type === "GROUP") {
        const grpEl = document.getElementById(id);
        if (e.target !== grpEl) {
          // process click only when group itself is clicked, not when child is clicked
          return;
        }
      }

      const mouseClientPosition = getMouseClientPosition(e);
      if (
        !this.props.textStatus.get("isFocused") ||
        /* check not clicked on focused text */
        !(
          this.props.textStatus.get("isFocused") &&
          ((childId &&
            childId === this.props.textStatus.get("id")) /* child text */ ||
            /* ungrouped text */
            id === this.props.textStatus.get("id"))
        )
      ) {
        this.setState({
          selectionBoxMouseDown: mouseClientPosition,
        });
      }

      const clientX = mouseClientPosition.get("x");
      const clientY = mouseClientPosition.get("y");

      const ctrlPressed = e.ctrlKey || e.metaKey;
      let existItems;
      if (this.props.selectedSubtitles.size > 0) {
        existItems = this.props.selectedSubtitles;
      } else {
        existItems = this.props.selectedItems;
      }
      const hasThis = existItems.keyOf(id);
      let selectedItems;

      if (
        this.props.selectedFrameClip.get("clipId") &&
        ((!this.props.selectedFrameClip.get("groupId") &&
          this.props.selectedFrameClip.get("id") !== id) || // switching from frame item to different item/child
          (this.props.selectedFrameClip.get("groupId") &&
            this.props.selectedFrameClip.get("groupId") !== id) || // switching from frame child to different item/child
          (childId && this.props.selectedFrameClip.get("id") !== childId)) // switching to different child item
      ) {
        // deselect clip on different item click
        this.props.setPropertyWindow({ component: "" });
        this.props.selectFrameClip({
          clipId: "",
        });
      }

      if (hasThis === undefined) {
        selectedItems = fromJS([]);
        selectedItems = selectedItems.push(id);

        if (ctrlPressed) {
          selectedItems = existItems.push(id);
        }

        // initial state
        const propData = {
          selectedItems: selectedItems.toArray(),
          currentPosition: { x: clientX, y: clientY },
          selectedSubtitles: [],
        };

        if (selectedItems.size === 1) {
          propData.type = type;
          propData.subType = subType;

          const selectedItem = this.props.projectDetails.getIn([
            "workspaceItems",
            selectedItems.get(0),
          ]);
          let selectedSubtitle;
          // If the selected item is not a workspace item
          if (!selectedItem) {
            this.props.projectDetails
              .get("localSubtitle")
              .valueSeq()
              .forEach((item) => {
                if (item.get("id") === id) {
                  selectedSubtitle = item.getIn(["subtitleId", "timelineId"]);
                }
              });
          }
          if (!selectedItem && selectedSubtitle) {
            propData.selectedItems = [];
            propData.selectedSubtitles.push(selectedSubtitle);
          }
          if (selectedItem) {
            if (selectedItem.get("isSingleClipFrame")) {
              // this frame does not have settings panel yet
            } else if (selectedItem.get("type") === "TEXT" && !childId) {
              propData.propWindowType = "TEXT";
            } else if (
              isImageOnly(
                selectedItem.get("type"),
                selectedItem.get("subType")
              ) &&
              !isUpImageSVG(selectedItem)
            ) {
              propData.propWindowType = "IMAGE_SETTINGS";
            } else if (
              isVideoOnly(selectedItem.get("type"), selectedItem.get("subType"))
            ) {
              propData.propWindowType = "VIDEO_SETTINGS";
            }
          }
        }

        if (!this.props.isPlayAll) {
          this.props.setSelectedItems(propData);
        }
      } else if (hasThis !== undefined && this.props.selectedItems.size > 1) {
        if (ctrlPressed) {
          selectedItems = existItems.delete(hasThis);
          const propData = {
            selectedItems: selectedItems.toArray(),
            currentPosition: { x: clientX, y: clientY },
          };
          if (selectedItems.size === 1) {
            propData.type = type;
            propData.subType = subType;

            const selectedItem = this.props.projectDetails.getIn([
              "workspaceItems",
              selectedItems.get(0),
            ]);
            if (selectedItem.get("isSingleClipFrame")) {
              // this frame does not have settings panel yet
            } else if (selectedItem.get("type") === "TEXT" && !childId) {
              propData.propWindowType = "TEXT";
            } else if (
              isImageOnly(
                selectedItem.get("type"),
                selectedItem.get("subType")
              ) &&
              !isUpImageSVG(selectedItem)
            ) {
              propData.propWindowType = "IMAGE_SETTINGS";
            } else if (
              isVideoOnly(selectedItem.get("type"), selectedItem.get("subType"))
            ) {
              propData.propWindowType = "VIDEO_SETTINGS";
            }
          }
          this.props.setSelectedItems(propData);
        }
      } else if (hasThis !== undefined) {
        if (
          this.props.textStatus.get("isFocused") &&
          this.props.textStatus.get("id") !== id
        ) {
          if (
            childId === undefined ||
            this.props.textStatus.get("id") !== childId
          ) {
            if (type !== "TEXT")
              this.props.updateTextStatus({ isFocused: false });
          }
        }
      }
    } catch (error) {}
  }

  getColorsFromHtml(textItem, existColors) {
    const rgbRegex =
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
    const extractedColors = [];
    // eslint-disable-next-line one-var
    let x, elemStyles, styleValue, rgbVal;
    const toGetColors = document.createElement("div");
    toGetColors.setAttribute("id", "toGetColors");
    toGetColors.style.position = "absolute";
    toGetColors.style.hidden = "true";
    toGetColors.innerHTML = textItem.getIn(["textData", "htmlText"]);

    document.body.appendChild(toGetColors);
    elemStyles = window.getComputedStyle(toGetColors);

    const elems = toGetColors.getElementsByTagName("*");
    const total = elems.length;
    let hasColors = false;
    for (x = 0; x < total; x += 1) {
      elemStyles = window.getComputedStyle(elems[x]);

      styleValue = elemStyles.color || elemStyles.color;

      if (styleValue) {
        rgbVal = styleValue.match(rgbRegex);

        if (rgbVal && !hasColors && rgbVal.input !== "rgb(0, 0, 0)") {
          hasColors = true;
        } else {
          rgbVal.input = textItem.getIn([
            "textData",
            "formats",
            "containerStyle",
            "color",
          ]);
        }
        if (
          rgbVal &&
          existColors.indexOf(rgbVal.input) === -1 &&
          extractedColors.indexOf(rgbVal.input) === -1
        ) {
          extractedColors.push(rgbVal.input);
        }
      }
    }

    toGetColors.remove();

    return extractedColors;
  }

  getAllColors(props, id, container, isGrouped) {
    let textItems;
    let allColors = [];
    if (isGrouped !== undefined && isGrouped) {
      const childIds = props.projectDetails.getIn([
        "workspaceItems",
        id,
        "groupChildren",
      ]);
      textItems = props.projectDetails
        .get("workspaceChildren")
        .filter((obj, key) => {
          return childIds.keyOf(key) !== undefined;
        });

      textItems.entrySeq().forEach(([, textItem]) => {
        const colors = this.getColorsFromHtml(textItem, allColors);
        if (colors.length > 0) allColors = allColors.concat(colors);
      });
    } else {
      const textItem = props.projectDetails.getIn([container, id]);
      const colors = this.getColorsFromHtml(textItem, allColors);
      if (colors.length > 0) allColors = allColors.concat(colors);
    }

    return allColors;
  }

  getTextFormats(item) {
    const obj = {
      textAlign: "left",
      color: "#fff",
      lineHeight: 1,
      fontWeight: null,
      fontStyle: null,
      textDecoration: null,
      textTransform: null,
      wordSpacing: 0,
      letterSpacing: 0,
      opacity: 1,
      isGrouped: false,
    };

    const formats = item.getIn(["textData", "formats"]);

    Object.assign(obj, formats.get("containerStyle").toObject());

    const bullet = formats.get("bullet");
    obj.fontSize = parseFloat(obj.fontSize, 10);
    obj.letterSpacing = parseFloat(obj.letterSpacing, 10);

    if (bullet) {
      obj.isBullet = bullet.get("isBullet");
      obj.bullet = bullet.get("type");
    }

    if (item.getIn(["textData", "formats", "others", "isRTL"]))
      Object.assign(obj, { isRTL: true });

    return obj;
  }

  getHoverClass(item) {
    let result = "";
    const isChildSelected =
      item.get("type") === "GROUP" &&
      this.props.childrenSelection.get("itemId") === item.get("id") &&
      this.props.childrenSelection.get("childIds").size > 0;

    const isSelected = this.props.selectedItems.includes(item.get("id"));

    if (
      this.state.isMultiSelecting ||
      this.props.isLibraryItemDragging ||
      isChildSelected ||
      this.props.transformStatus.get("transforming") ||
      isSelected
    ) {
      return result;
    }

    if (
      item !== undefined &&
      (item.get("type") === "SHAPE" ||
        item.get("type") === "PROP" ||
        item.get("type") === "FRAME" ||
        item.get("isSingleClipFrame"))
    )
      result = "ws-hover-prop";
    else if (item !== undefined && item.get("type") === "CHR")
      result = "ws-hover-chr";
    else if (item !== undefined && item.get("type") === "TEXT")
      result = "ws-hover-txt";
    else if (
      item !== undefined &&
      (item.get("type") === "STOCKIMG" ||
        item.get("subType") === "UPIMAGE" ||
        item.get("type") === "PIXABAY" ||
        item.get("type") === "PEXELS")
    )
      result = "ws-hover-img";
    else if (item !== undefined && item.get("type") === "GROUP")
      result = "ws-hover-group";
    else if (
      item !== undefined &&
      item.get("type") === "IMG" &&
      item.get("GIPHY") === undefined
    )
      result = "ws-hover-gif";

    if (!result) {
      result = "ws-hover-default";
    }

    return result;
  }

  getWorkspaceBGList() {
    const bgItems = [];

    this.props.projectDetails.get("workspaceBG").forEach((bgItem) => {
      const isVisible =
        this.props.playhead >= bgItem.get("playStart") &&
        this.props.playhead <= bgItem.get("playEnd");
      if (this.props.isPlayAll || isVisible) {
        bgItems.push(
          <WorkspaceBG
            key={bgItem.get("id")}
            isVisible={isVisible}
            projectWidth={this.props.workspaceWidth}
            projectHeight={this.props.workspaceHeight}
            workspaceBG={bgItem}
            zoomFactor={this.props.zoomFactor}
            browserName={this.props.browserName}
            playhead={this.props.playhead}
          />
        );
      }
    });

    return bgItems;
  }

  showItems(id) {
    const element = document.getElementById(window.current);
    if (window.current && element) {
      element.style.opacity = 0;
    }
    window.current = id;
    document.getElementById(id).style.opacity = this.props.projectDetails.getIn(
      ["workspaceItems", id, "opacity"]
    );
  }

  textAnimPause(duration, id, isSubtitle) {
    let elementName;
    // Determine the element name
    if (id === "backgroundgradient") {
      elementName = "workspace-bgcolor";
    } else if (isSubtitle) {
      elementName = id;
    } else {
      elementName = `${id}animo`;
    }

    // Reset opacity for the previously active element
    if (window.current) {
      const el = document.getElementById(window.current);
      if (el && el.style) {
        el.style.opacity = 0;
      }
    }
    window.current = elementName;
    const currentElement = document.getElementById(elementName);
    if (currentElement && currentElement.style) {
      currentElement.style.opacity = 1;
    }
    this.props.setPlayHeadTime({ playhead: duration });
  }

  showSubtitle(duration) {
    this.props.setPlayHeadTime({ playhead: duration });
  }

  enablePlayer() {
    this.props.setPlayAll(true);
  }

  getWorkspaceItemList() {
    const items = [];
    this.props.projectDetails.get("workspaceItems").forEach((item) => {
      let isVisible =
        this.props.playhead >= item.get("enterStart") &&
        this.props.playhead < item.get("exitEnd");
      isVisible = ANIMO_RENDER || isVisible;
      const isSelected = this.props.selectedItems.includes(item.get("id"));
      const renderAnyway = item.get("type") === "TEXT"; // warn: text apply all will fail if all text items are not mounted

      if (this.props.isPlayAll || isSelected || isVisible || renderAnyway) {
        let groupChildren = null;
        const isClickable = isVisible;

        if (item.get("type") === "GROUP" && item.get("groupChildren")) {
          groupChildren = item.get("groupChildren").map((childId) => {
            const childData = this.props.projectDetails.getIn([
              "workspaceChildren",
              childId,
            ]);

            let pointerEvents;
            if (!isClickable) {
              pointerEvents = "none";
            }

            if (childData) {
              return (
                <WorkspaceItem
                  key={childId}
                  className={this.getHoverClass(childData)}
                  projectWidth={this.props.workspaceWidth}
                  projectHeight={this.props.workspaceHeight}
                  id={childId}
                  item={childData}
                  pointerEvents={pointerEvents}
                  groupId={item.get("id")}
                  groupItem={item}
                  browserName={this.props.browserName}
                  isVisible={isVisible}
                  zoomFactor={this.props.zoomFactor}
                  selectedItems={this.props.selectedItems}
                  isPlayAll={this.props.isPlayAll}
                  setPlayAll={this.props.setPlayAll}
                  addTween={this.props.addTween}
                  swapDetails={this.props.swapDetails}
                  prefetchToken={this.props.prefetchToken}
                  prefetchComplete={this.props.prefetchComplete}
                  isMouseOnSelectionBox={this.isMouseOnSelectionBox}
                  onPointerDown={(e) =>
                    this.handleItemClick(
                      e,
                      item.get("id"),
                      childData.get("type"),
                      childData.get("subType"),
                      childId
                    )
                  }
                  onPointerUp={(e) =>
                    this.handleItemMouseUp(
                      e,
                      item.get("id"),
                      childData.get("type"),
                      childData.get("subType"),
                      childId
                    )
                  }
                />
              );
            }
            return undefined;
          });
        }

        const addItemMouseDown =
          item.get("type") !== "GROUP" ||
          !this.props.textStatus.get("isFocused");

        let opacity;
        let pointerEvents;
        if (
          this.props.transformStatus.get("moving") &&
          this.props.selectedItems.size === 1 &&
          this.props.selectedItems.get(0) === item.get("id")
        ) {
          pointerEvents = "none";
          if (
            this.props.swapHoverDrop.get("hovering") &&
            this.props.swapHoverDrop.get("sourceType") ===
            ITEM_CONFIG.SWAP_SOURCE.WORKSPACE &&
            this.props.swapHoverDrop.get("targetType") ===
            ITEM_CONFIG.SWAP_TARGETS.FRAME_CLIP
            // || this.props.swapHoverDrop.get("targetType") === ITEM_CONFIG.SWAP_TARGETS.GRID_CELL
          ) {
            const objectType = "FRAME";
            // if (this.props.swapHoverDrop.get("targetType") === ITEM_CONFIG.SWAP_TARGETS.GRID_CELL) {
            //   objectType = "GRID";
            // }
            opacity = ITEM_CONFIG[objectType].DROP_OPACITY;
          }
        }

        if (this.props.isCropping && isSelected) {
          opacity = 0;
          pointerEvents = "none";
        }

        if (!isClickable) {
          pointerEvents = "none";
        }

        opacity = ANIMO_RENDER ? 0 : opacity;
        items.push(
          <WorkspaceItem
            key={item.get("id")}
            className={this.getHoverClass(item)}
            browserName={this.props.browserName}
            id={item.get("id")}
            isVisible={isVisible}
            item={item}
            opacity={opacity}
            pointerEvents={pointerEvents}
            projectWidth={this.props.workspaceWidth}
            projectHeight={this.props.workspaceHeight}
            zoomFactor={this.props.zoomFactor}
            selectedItems={this.props.selectedItems}
            isPlayAll={this.props.isPlayAll}
            setPlayAll={this.props.setPlayAll}
            addTween={this.props.addTween}
            swapDetails={this.props.swapDetails}
            prefetchToken={this.props.prefetchToken}
            prefetchComplete={this.props.prefetchComplete}
            isMouseOnSelectionBox={this.isMouseOnSelectionBox}
            libraryDragItem={this.props.libraryDragItem}
            onPointerDown={
              addItemMouseDown
                ? (e) =>
                  this.handleItemClick(
                    e,
                    item.get("id"),
                    item.get("type"),
                    item.get("subType")
                  )
                : undefined
            }
            onPointerUp={(e) =>
              this.handleItemMouseUp(
                e,
                item.get("id"),
                item.get("type"),
                item.get("subType")
              )
            }
          >
            {groupChildren}
          </WorkspaceItem>
        );
      }
    });

    return items;
  }

  getSubtitleItemList() {
    if (!this.props.projectDetails.get("localSubtitle")) {
      return null;
    }
    const items = [];
    this.props.projectDetails
      .get("localSubtitle")
      .valueSeq()
      .forEach((item, index) => {
        const isVisible = this.props.playhead >= item.get("enterStart") && this.props.playhead <= item.get("exitEnd");

        const isSelected = this.props.selectedSubtitles.includes(
          item.getIn(["subtitleId", "timelineId"])
        );

        const isClickable = isVisible;

        let pointerEvents;

        if (!isClickable) {
          pointerEvents = "none";
        }

        const opacity = (ANIMO_RENDER && (!this.props.isAnimoSubtitle || this.props.subtitleCallback === "textAnimPause")) ? 0 : undefined;

        if (this.props.isPlayAll || isSelected || isVisible) {
          items.push(
            <SubtitleTextComponent
              key={item.getIn(["subtitleId", "timelineId"])}
              id={item.getIn(["subtitleId", "timelineId"])}
              workspaceHeight={this.props.workspaceHeight}
              workspaceWidth={this.props.workspaceWidth}
              workspaceZoom={this.props.zoomFactor}
              pointerEvents={pointerEvents}
              subtitle={item}
              subtitleIndex={index}
              opacity={isVisible && !this.props.isPlayAll ? opacity : 0}
              onPointerDown={(e) =>
                this.handleItemClick(
                  e,
                  item.get("id"),
                  item.get("type"),
                  item.get("subType")
                )
              }
              onPointerUp={(e) =>
                this.handleItemMouseUp(
                  e,
                  item.get("id"),
                  item.get("type"),
                  item.get("subType")
                )
              }
            />
          );
        }
      });
    return items;
  }

  handleWorkspaceClick = (newValue) => {
    this.setState({ isToolBarVisible: newValue });
  };

  render() {
    const {
      workspaceBounds,
      zoomFactor,
      isFit,
      userDetails,
      showUpgrade,
      isMobile,
      isAnimoPlayer,
      isShortsPlayer,
    } = this.props;

    const { isToolBarVisible } = this.state;

    const workspaceWidth = this.props.workspaceWidth * zoomFactor;
    const workspaceHeight = this.props.workspaceHeight * zoomFactor;

    let stageWidth = workspaceWidth;
    let stageHeight = workspaceHeight;
    let willWorkspaceOverflow = false;

    const workspaceContainerStyles = {
      overflowX: "visible",
      overflowY: "visible",
      transform: "translate(0, 0)",
      width: "0px",
      height: "0px",
    };

    if (!isFit) {
      if (workspaceBounds.width < workspaceWidth) {
        workspaceContainerStyles.overflowX = "scroll";
        workspaceContainerStyles.overflowY = "hidden";
        stageWidth = workspaceBounds.width;
        willWorkspaceOverflow = true;
      }
      if (workspaceBounds.height < workspaceHeight) {
        workspaceContainerStyles.overflowY = "scroll";
        if (workspaceContainerStyles.overflowX !== "scroll") {
          workspaceContainerStyles.overflowX = "hidden";
        }
        stageHeight = workspaceBounds.height;
        willWorkspaceOverflow = true;
      }
    }

    const workspaceStagePlot = {
      x: workspaceBounds.cx - stageWidth / 2,
      y: workspaceBounds.cy - stageHeight / 2,
      width: stageWidth,
      height: stageHeight,
    };
    workspaceContainerStyles.width = `${workspaceStagePlot.width}px`;
    workspaceContainerStyles.height = `${workspaceStagePlot.height}px`;
    if (!ANIMO_RENDER && !isMobile) {
      workspaceContainerStyles.transform = `translate(${workspaceStagePlot.x}px, ${workspaceStagePlot.y}px)`;
    }
    if (!ANIMO_RENDER && isMobile) {
      workspaceContainerStyles.transform = `translate(calc(${workspaceStagePlot.x}px - 35px), calc(${workspaceStagePlot.y}px - 8px))`;
    }
    if (this.props.isPlayAll || this.props.isAnimoPlayer || this.props.isShortsPlayer) {
      workspaceContainerStyles.overflowX = "hidden";
      workspaceContainerStyles.overflowY = "hidden";
    }

    if (isShortsPlayer) {
      try {
        const shortPreviewEl = document.getElementById(this.props.shortContainerId);
        if (shortPreviewEl) {
          // Setting the workspaceContainer style for the shorts page preview video container and video as workspace item.
          Object.assign(shortPreviewEl.style, workspaceContainerStyles);
          shortPreviewEl.querySelector('video').style.left = `-${workspaceStagePlot.x}px`;
        }
      } catch (err) { }
    }

    const sceneStyles = {
      width: `${workspaceWidth}px`,
      height: `${workspaceHeight}px`,
      overflowX: "visible",
      overflowY: "visible",
    };

    if (willWorkspaceOverflow) {
      sceneStyles.overflowX = "hidden";
      sceneStyles.overflowY = "hidden";
    }

    const sceneOverflowProps = [];
    if (!this.props.isPlayAll && !willWorkspaceOverflow) {
      sceneOverflowProps.push(
        <OverflowProp
          key="left"
          className="overflowProp scene-overflow"
          style={{
            width: `${workspaceStagePlot.x}px`,
            height: `${window.innerHeight}px`,
            left: `${-workspaceStagePlot.x}px`,
            top: `${-workspaceStagePlot.y}px`,
          }}
        />
      );
      sceneOverflowProps.push(
        <OverflowProp
          key="right"
          className="overflowProp scene-overflow"
          style={{
            width: `${window.innerWidth - (workspaceStagePlot.x + workspaceWidth)}px`,
            height: `${window.innerHeight}px`,
            left: `${workspaceWidth}px`,
            top: `${-workspaceStagePlot.y}px`,
          }}
        />
      );
      sceneOverflowProps.push(
        <OverflowProp
          key="top"
          className="overflowProp scene-overflow"
          style={{
            width: `${workspaceWidth}px`,
            height: `${workspaceStagePlot.y}px`,
            left: "0px",
            top: `${-workspaceStagePlot.y}px`,
          }}
        />
      );
      sceneOverflowProps.push(
        <OverflowProp
          key="bottom"
          className="overflowProp scene-overflow"
          style={{
            width: `${workspaceWidth}px`,
            height: `${window.innerHeight - (workspaceStagePlot.y + workspaceHeight)}px`,
            left: "0px",
            top: `${workspaceHeight}px`,
          }}
        />
      );
    }

    const bgItems = this.getWorkspaceBGList();
    const workspaceItems = this.getWorkspaceItemList();
    const subtitleItems = this.getSubtitleItemList();

    let backdrop = null;
    if (this.props.isPlayAll) {
      backdrop = (
        <WorkspaceBackdrop
          workspaceStagePlot={workspaceStagePlot}
          isMobile={isMobile}
        />
      );
    }

    let multiselectBounds = null;
    if (this.state.isMultiSelecting && this.state.multiselectBounds) {
      multiselectBounds = (
        <MultiSelectBounds
          data-html2canvas-ignore="true"
          $x={this.state.multiselectBounds.x}
          $y={this.state.multiselectBounds.y}
          $width={this.state.multiselectBounds.width}
          $height={this.state.multiselectBounds.height}
        />
      );
    }

    let tmOverlays = null;
    const transformManagers = [];

    if (
      !this.props.isPlayAll &&
      this.isPlayheadInSelectionTimeRange() &&
      (this.props.selectedItems.size > 1 /* multiple item selection */ ||
        // single group item and children selection
        (this.props.selectedItems.size === 1 &&
          this.props.childrenSelection.get("itemId") ===
          this.props.selectedItems.get(0) &&
          this.props.childrenSelection.get("childIds").size > 0) ||
        // frame clip selection
        (this.props.selectedItems.size === 1 &&
          this.props.selectedFrameClip.get("id") &&
          this.props.selectedFrameClip.get("clipId")))
    ) {
      transformManagers.push(<SelectionHighlight key="itemselection" />);
    }

    if (
      !this.props.isPlayAll &&
      !this.props.selectedItems.size &&
      !this.props.selectedAudios.size &&
      !this.props.selectedSubtitles.size
    ) {
      transformManagers.push(
        <BgSelectionHighlight
          key={"bgselection"}
          data-html2canvas-ignore="true"
          $x={0}
          $y={0}
          $width={this.props.workspaceWidth * this.props.zoomFactor}
          $height={this.props.workspaceHeight * this.props.zoomFactor}
        />
      );
    }

    if (
      !this.props.isPlayAll &&
      !this.props.isFraming &&
      !this.props.isCropping &&
      (this.props.selectedItems.size > 0 ||
        this.props.selectedSubtitles.size > 0)
    ) {
      tmOverlays = <TMOverlay />;
      transformManagers.push(
        <TransformManager
          key={"transformmanager"}
          selectionBoxMouseDown={this.state.selectionBoxMouseDown}
          transformManagerRef={this.transformManagerRef}
          workspaceRef={this.workspaceRef}
          cornerResize={true}
          shortcutName={this.props.shortcutName}
          lockTransform={this.props.swapDetails !== null}
        />
      );
    }

    if (this.props.isFraming && !this.props.isPlayAll) {
      transformManagers.push(
        <FrameImage key="frame" workspaceRef={this.workspaceRef} />
      );
    }

    if (this.props.isCropping && !this.props.isPlayAll) {
      transformManagers.push(
        <Crop
          key="crop"
          workspaceRef={this.workspaceRef}
          shortcutName={this.props.shortcutName}
        />
      );
    }

    let contextMenu = null;
    if (this.state.contextMenu) {
      contextMenu = (
        <ContextMenu
          hideContextMenu={this.hideContextMenu}
          menuClickPos={this.state.contextMenuClickPos}
        />
      );
    }

    let dropArea = null;
    if (this.props.isLibraryItemDragging) {
      dropArea = (
        <SwapDropArea type={ITEM_CONFIG.SWAP_TARGETS.WORKSPACE_CONTAINER} />
      );
    }

    // eslint-disable-next-line no-unused-vars
    const watermark =
      (userDetails.plan === PLAN.STARTER || userDetails.plan === PLAN.LITE) &&
        !ANIMO_RENDER ? (
        <WaterMark
          isPlayAll={this.props.isPlayAll}
          workspaceWidth={this.props.workspaceWidth}
          workspaceHeight={this.props.workspaceHeight}
          zoomFactor={this.props.zoomFactor}
          showUpgrade={showUpgrade}
        />
      ) : null;

    const workspaceOpacity = {
      opacity: this.props.previewProjectData ? 0 : 1,
    };

    let workspace = (
      <WorkspaceContainer
        ref={this.workspaceRef}
        id="workspace"
        style={{ ...workspaceContainerStyles, ...workspaceOpacity }}
      >
        {!isAnimoPlayer && !isShortsPlayer && dropArea}
        <SceneContainer style={sceneStyles}>
            <WorkspaceBGColor
              bgColor={this.props.projectDetails.get("bgColor")}
            />
          {bgItems}
          {workspaceItems}
          {subtitleItems}
          { /* {!isAnimoPlayer && !isShortsPlayer && watermark} */}
        </SceneContainer>
        {!isAnimoPlayer && !isShortsPlayer && sceneOverflowProps}
        {ANIMO_RENDER || isAnimoPlayer || isShortsPlayer ? null : transformManagers}
      </WorkspaceContainer>
    );

    if (this.props.isPlayAll && !this.props.isFullScreen) {
      // key is added to destroy and remount the Player on any props change (Subtitle style change).
      workspace = <Player key={this.props.projectDetails}>{workspace}</Player>;
    }

    if (this.props.isPlayAll && this.props.isFullScreen) {
      // key is added to destroy and remount the Player on any props change (Subtitle style change).
      workspace = <Player key={this.props.projectDetails}>{workspace}</Player>;
    }

    return (
      <>
        <WorkspaceWrapper
          $isPlayAll={this.props.isPlayAll}
          onPointerDown={
            !this.props.isEnableVersionHistory
              ? this.handleMouseDown
              : undefined
          }
          onContextMenu={
            !this.props.isEnableVersionHistory
              ? this.openContextMenu
              : undefined
          }
          onClick={this.handleWorkspaceClick}
          isAnimoPlayer={isAnimoPlayer || isShortsPlayer}
        >
          {workspace}
          {this.props.previewProjectData && (
            <PreviewWorkspace
              workspaceContainerStyles={workspaceContainerStyles}
              sceneStyles={sceneStyles}
            />
          )}
          {!isAnimoPlayer && !isShortsPlayer && tmOverlays}
          {!isAnimoPlayer && !isShortsPlayer && multiselectBounds}
          {backdrop}
          {!isAnimoPlayer && !isShortsPlayer && contextMenu}
        </WorkspaceWrapper>
        {!ANIMO_RENDER && isMobile && isToolBarVisible && (
          <ItemToolBar
            isMobile={isMobile}
            shortcutName={this.props.shortcutName}
            currentState={this.state.parentState}
            onStateChange={this.handleWorkspaceClick}
            outerLayerVisible={this.props.outerLayerVisible} />
        )}
        {!ANIMO_RENDER && !isMobile && !isAnimoPlayer && !isShortsPlayer ? (
          <ItemToolBar
            isMobile={isMobile}
            shortcutName={this.props.shortcutName}
          />
        ) : null}
      </>
    );
  }
}

WorkspaceComponent.propTypes = {
  workspaceWidth: PropTypes.number,
  workspaceHeight: PropTypes.number,
  workspaceBounds: PropTypes.object,
  projectDetails: PropTypes.object,
  previewProjectData: PropTypes.object,
  zoomFactor: PropTypes.number,
  isFit: PropTypes.bool,
  isMobile: PropTypes.bool,
  isAnimoPlayer: PropTypes.bool,
  isShortsPlayer: PropTypes.bool,
  playhead: PropTypes.number,
  isPlayAll: PropTypes.bool,
  browserName: PropTypes.string,
  selectedItems: PropTypes.object,
  prefetchToken: PropTypes.string,
  setPlayAll: PropTypes.func,
  prefetchComplete: PropTypes.func,
  addTween: PropTypes.func,
  swapDetails: PropTypes.object,
  fontLoaded: PropTypes.func,
  initStages: PropTypes.object,
  loadedFonts: PropTypes.object,
  setInitStages: PropTypes.func,
  hasAllFontsLoaded: PropTypes.bool,
  setWorkspaceDragging: PropTypes.func,
  childrenSelection: PropTypes.object,
  setChildrenSelection: PropTypes.func,
  textStatus: PropTypes.object,
  updateTextStatus: PropTypes.func,
  transformStatus: PropTypes.object,
  selectedFrameClip: PropTypes.object,
  selectFrameClip: PropTypes.func,
  setSelectedItems: PropTypes.func,
  isLibraryItemDragging: PropTypes.bool,
  libraryDragItem: PropTypes.object,
  swapHoverDrop: PropTypes.object,
  isCropping: PropTypes.bool,
  isFraming: PropTypes.bool,
  shortcutName: PropTypes.string,
  userDetails: PropTypes.object,
  setPropertyWindow: PropTypes.func,
  setTextOptions: PropTypes.func,
  selectedAudios: PropTypes.object,
  showUpgrade: PropTypes.func,
  isEnableVersionHistory: PropTypes.bool,
  setPlayHeadTime: PropTypes.func,
  isFullScreen: PropTypes.bool,
  selectedSubtitles: PropTypes.array,
  setLibraryPanel: PropTypes.func,
  isAnimoSubtitle: PropTypes.bool,
  outerLayerVisible: PropTypes.bool,
  subtitleCallback: PropTypes.string,
  shortContainerId: PropTypes.string,
  updateBlobItem: PropTypes.func,
};

const mapStateToProps = (state) => ({
  projectDetails: state.projectDetails,
  workspaceWidth: state.projectDetails.get("width"),
  workspaceHeight: state.projectDetails.get("height"),
  workspaceBounds: state.app.get("workspaceBounds"),
  zoomFactor: state.app.get("zoomFactor"),
  isFit: state.app.get("isFit"),
  playhead: state.app.get("playhead"),
  isPlayAll: state.app.get("isPlayAll"),
  browserName: state.app.get("browserName"),
  isAnimoPlayer: state.app.get("isAnimoPlayer"),
  isShortsPlayer: state.app.get("isShortsPlayer"),
  shortContainerId: state.app.get("shortContainerId"),
  selectedItems: state.app.get("selectedItems"),
  swapDetails: state.app.get("swapDetails"),
  initStages: state.app.get("initStages"),
  loadedFonts: state.app.get("loadedFonts"),
  hasAllFontsLoaded: state.app.get("hasAllFontsLoaded"),
  isPlayerLoaded: state.app.get("isLoaded"),
  prefetchToken: state.app.get("prefetchToken"),
  prefetchCompleteCount: state.app.get("prefetchCompleteCount"),
  prefetchCount: state.app.get("prefetchCount"),
  childrenSelection: state.app.get("childrenSelection"),
  textStatus: state.app.get("textStatus"),
  transformStatus: state.app.get("transformStatus"),
  selectedFrameClip: state.app.get("selectedFrameClip"),
  isLibraryItemDragging: Boolean(state.app.getIn(["libraryDragItem", "data"])),
  libraryDragItem: state.app.get("libraryDragItem"),
  swapHoverDrop: state.app.get("swapHoverDrop"),
  isFraming: state.app.get("isFraming"),
  isCropping: state.app.get("isCropping"),
  selectedAudios: state.app.get("selectedAudios"),
  userDetails: state.userDetails,
  isEnableVersionHistory: state.app.get("isEnableVersionHistory"),
  previewProjectData: state.app.get("previewProjectData"),
  isFullScreen: state.app.get("isFullScreen"),
  selectedSubtitles: state.app.get("selectedSubtitles"),
  isAnimoSubtitle: state.app.get("isAnimoSubtitle"),
  subtitleCallback: state.app.get("subtitleCallback")
});

const mapDispatchToProps = (dispatch) => ({
  setPlayAll: (data) => dispatch(setPlayAll(data)),
  prefetchComplete: (token, count) => dispatch(prefetchComplete(token, count)),
  addTween: (data, tweenType) => dispatch(addTween(data, tweenType)),
  fontLoaded: (payload) => dispatch(fontLoaded(payload)),
  setInitStages: (payload) => dispatch(setInitStages(payload)),
  setWorkspaceDragging: (payload) => dispatch(setWorkspaceDragging(payload)),
  setSelectedItems: (data) => dispatch(setSelectedItems(data)),
  setChildrenSelection: (data) => dispatch(setChildrenSelection(data)),
  updateTextStatus: (data) => dispatch(updateTextStatus(data)),
  selectFrameClip: (data) => dispatch(selectFrameClip(data)),
  setPropertyWindow: (data) => dispatch(setPropertyWindow(data)),
  setTextOptions: (data) => dispatch(setTextOptions(data)),
  showUpgrade: (data) => dispatch(showUpgrade(data)),
  setPlayHeadTime: (data) => dispatch(setPlayHeadTime(data)),
  setLibraryPanel: (data, isExpand) => dispatch(setPropertyPanel(data, isExpand)),
  updateBlobItem: (payload) => dispatch(updateBlobItem(payload)),
});

const Workspace = connect(mapStateToProps, mapDispatchToProps)(WorkspaceComponent);

export default Workspace;
