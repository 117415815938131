/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import AppConfig, { STATIC_PATH } from "../constants/config";
import Action from "./Action";
import Typography from "./Typography";
import { font } from "../constants/font";
import content from "../constants/content";
import { SmartImage } from "./SmartImage";
import PricingTooltipComponent from "./PricingTooltip";
import { getPlanName } from "../helper/getPlanName";
import { PLAN } from "../constants";
import vmTheme from "../constants/theme";

const { TEXT_TILE_DUMMY, UPGRADE } = content;

const { normalMini_12 } = font;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.blackColor};
  display: none;
  border: 1px solid ${(props) => props.theme.secondaryBorderColor};
  border-radius: 2px;
  opacity: 0.7;
`;

const OverlayWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: absolute;
  top: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & .tile-action {
    position: absolute;
    margin: auto;
    transform: translateY(154px);
    border-radius: 8px;
  }
`;

const TileWrapper = styled.div`
  width: fit-content;
  cursor: pointer;
  position: relative;
  padding-bottom: ${(props) => (props.isAutoAlign ? "6px" : "6px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${(props) => (props.align ? props.align : "center")};

  &.ver,
  &.sqr,
  &.hor {
    .tile-container {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
      }
    }
  }

  & .favourite-icon {
    opacity: 0;
    &:hover {
      ${(props) =>
    !props.favourite &&
    css`
          opacity: 1;
          circle {
            fill: none;
            stroke: ${(props) => props.theme.secondaryBorderColor};
            stroke: ${(props) => props.theme.favoriteIconColor};
          }
          & #heart-path {
            fill: ${(props) => props.theme.favoriteIconColor};
            stroke: none;
          }
        `}
    }
    ${(props) =>
    props.favourite &&
    css`
        opacity: 1;
        circle {
          opacity: 1;
          fill: ${(props) => props.theme.favoriteIconColor};
        }
        & #heart-path {
          fill: ${(props) => props.theme.polarColor};
          stroke: none;
        }
      `}
  }

  & .play-icon {
    position: relative;
    z-index: 1;
    bottom: 26px;
    left: 6px;
  }

  & .selected-icon {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  & .premium-flag {
    position: absolute;
    top: -3px;
    right: 0;
    #Path_29652 {
      fill: ${(props) => props.flagColor};
    }
  }

  &:hover {
    & .overlay {
      display: ${(props) => props.display};
    }
    & .tile-action {
      transform: translateY(0px);
      transition: transform 0.25s ease-in-out;
    }
    svg {
      opacity: ${(props) => (props.hideOnHover ? 0 : 1)};
    }
    img {
      opacity: ${(props) => (props.hideOnHover ? 0 : 1)};
    }
  }

  ${(props) =>
    props.isDisable &&
    css`
      opacity: ${props.isOpacityEnable ? 1 : 0.5};
      pointer-events: none;
    `}
`;

const IconContainer = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: ${(props) => (props.showFavouriteIcon ? "block" : "none")};
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  padding: 0px 5px;
  z-index: 1;
  bottom: 5px;
  width: 100%;
`;

const EliteContainer = styled.div`
  position: absolute;
  cursor: pointer;
  height: 20px;
  width: 20px;
  z-index: 1;
  inset: ${(props) => props.inset || "auto"};
  &:hover {
    &::after {
      content: "Elite";
      position: absolute;
      width: auto;
      right: 20px;
      background-color: ${(props) => props.theme.rgbaBlack};
      border-radius: 4px;
      padding: 2px 8px;
      color: ${(props) => props.theme.polarColor};
      font: ${font.normalMini};
      top: 1px;
    }
  }
`;

const Container = styled.div`
  margin-bottom: ${(props) => (props.isAutoAlign ? "0px" : "8px")};
  cursor: pointer;
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  overflow: hidden;
  background: ${(props) =>
    props.isVideoTile ? props.theme.blackColor : props.theme.polarColor};

  .smart-img {
    width: 100%;
    height: auto;
    max-height: 103%;
    object-fit: contain;
  }

  ${(props) =>
    props.textTile &&
    css`
      display: flex;
      justify-content: center;
      align-items: flex-end;
    `}
  ${(props) =>
    props.isVideoTile &&
    css`
      video {
        display: none;
        max-height: 101%;
      }
      .smart-img {
        display: block;
      }
      &:hover {
        video {
          display: block;
        }
        ${IconContainer} {
          display: block;
        }
        .smart-img {
          display: none;
        }
      }
    `}
  ${(props) =>
    !props.isVideoTile &&
    css`
      .smart-img {
        display: block;
      }

      &:hover {
        ${IconContainer} {
          display: block;
        }
        ${EliteContainer} {
          display: block;
        }
      }
    `};

  ${(props) =>
    props.isAutoAlign &&
    css`
      .smart-img {
        &:hover {
          ${props.hoverEffect}
        }
      }
    `}

  &:hover {
    ${(props) =>
    !props.isDisableHover &&
    css`
        box-shadow: 0 0 11px ${props.theme.rgbaBlack26};
        outline: 2px solid ${props.theme.secondaryBorderColor};
      `}
    ${(props) =>
    props.hoverEffect &&
    !props.isAutoAlign &&
    css`
        ${props.hoverEffect}
      `}
  }
  @media only screen and (max-width: 768px){
    width: 43vw;
  }
`;

const TextTileContent = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Tile = ({
  src,
  videoSrc,
  showFavouriteIcon,
  isFavourite,
  isVideoTile,
  hoverEffect,
  width,
  height,
  border,
  borderRadius,
  rightIcon,
  isPremium,
  textTile,
  font,
  label,
  showLabel,
  textStyles,
  onTileClick,
  onPointerDown,
  toggleFavourite,
  assetId,
  containerRef,
  className,
  style,
  showPlayIcon,
  libItemData,
  isElite,
  category,
  isAutoAlign,
  margin,
  isDisable,
  isDisableHover,
  align,
  isSelected,
  selectedIcon,
  isOpacityEnable
}) => {
  const [favourite, setFavourite] = useState(false);
  const [pricingInfo, setPrisingInfo] = useState(null);
  const [isGif, setIsGif] = useState(false);
  const [planName, setPlanName] = useState("");
  const videoRef = useRef();
  const theme = useSelector((state) => state.app.get("theme"));

  useEffect(() => {
    setFavourite(isFavourite);
  }, [isFavourite]);

  useEffect(() => {
    if (libItemData) {
      const {
        title,
        artist,
        collection_name,
        cost,
        sub_type,
        subType,
        subtype,
        brandinfo,
        type,
        username,
        plan,
      } = libItemData;

      if (plan) {
        setPlanName(getPlanName(plan));
      }

      let collectionName = "";
      if (sub_type === "EMOJI") {
        collectionName = "Twitter. Inc.";
      } else if (
        (subType === "GETTY" || subType === "ISSIG") &&
        subtype !== "OBGIMG" &&
        subtype !== "OBGVID"
      ) {
        collectionName = "Getty";
      } else if (subtype === "OBGIMG" || subtype === "OBGVID") {
        collectionName = brandinfo;
      } else {
        collectionName = "iStock";
      }
      let pricingData;
      if (type === "gif") {
        setIsGif(true);
        pricingData = {
          category,
          username,
        };
      } else {
        pricingData = {
          title,
          artist,
          collection_name: collection_name || collectionName,
          cost,
        };
      }
      setPrisingInfo(pricingData);
    }
  }, [category, libItemData]);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.src = videoSrc;
      videoRef.current.load();
      videoRef.current.addEventListener('canplaythrough', () => {
        videoRef?.current?.play().catch(() => { });
      }, { once: true });
    }
  };

  const stopVideo = () => {
    if (videoRef.current && videoRef.current.currentTime > 0) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      videoRef.current.src = "";
      videoRef.current.load();
    }
  };

  return (
    <TileWrapper
      className={`${className} tile-wrapper`}
      display={isPremium ? "block" : "none"}
      favourite={favourite}
      hideOnHover={showPlayIcon}
      style={style}
      margin={margin}
      onMouseEnter={playVideo}
      onMouseLeave={stopVideo}
      isAutoAlign={isAutoAlign}
      isDisable={isDisable}
      align={align}
      isOpacityEnable={isOpacityEnable}
      flagColor={
        planName === PLAN.TEAM
          ? vmTheme[theme].teamPlanColor
          : planName === PLAN.ENTERPRISE
            ? vmTheme[theme].enterpriseColor
            : ""
      }
    >
      {isPremium ? (
        <OverlayWrapper
          width={width}
          height={isAutoAlign ? "100%" : height}
          data-lib-ignore={true}
        >
          <Overlay className="overlay" />
          <Action
            background={vmTheme[theme].headerColor}
            width="80px"
            height="30px"
            text={UPGRADE}
            customClass="tile-action"
            fontColor={vmTheme[theme].polarColor}
            enableTrim={false}
            onClick={onTileClick}
          />
        </OverlayWrapper>
      ) : null}
      <Container
        ref={containerRef}
        className="tile-container"
        isVideoTile={isVideoTile}
        hoverEffect={hoverEffect}
        width={width}
        height={isAutoAlign ? "100%" : height}
        border={border}
        borderRadius={borderRadius}
        textTile={textTile}
        onClick={onTileClick}
        onPointerDown={onPointerDown}
        isAutoAlign={isAutoAlign}
        isDisableHover={isDisableHover}
      >
        {isElite && (
          <EliteContainer inset="5px 5px 5px auto">
            <img src={`${STATIC_PATH}eliteImg.svg`} alt="stock-img" />
          </EliteContainer>
        )}
        {videoSrc && (
          <video
            ref={videoRef}
            draggable="false"
            playsInline={true}
            crossOrigin="anonymous"
            muted
            width="100%"
            height="100%"
          />
        )}
        {!textTile ? (
          <SmartImage
            imageSource={src}
            placeHolderImage={AppConfig.PLACEHOLDER_IMAGE || src}
            altImage={AppConfig.PLACEHOLDER_IMAGE}
            className="smart-img"
          />
        ) : (
          <TextTileContent>
            <Typography
              cursor="pointer"
              content={TEXT_TILE_DUMMY}
              font={font}
              enableTrim={false}
              textStyles={textStyles}
            />
            <Typography
              content={label}
              padding="30px 0px 10px 0px"
              font={normalMini_12}
              enableTrim={false}
            />
          </TextTileContent>
        )}
        {isPremium && (
          <img
            className="premium-flag"
            src={`${STATIC_PATH}premium-flag.svg`}
            data-lib-ignore={true}
            alt="premium-flag"
          />
        )}
        {(showFavouriteIcon || rightIcon) && (
          <IconWrapper
            justifyContent={showFavouriteIcon ? "space-between" : "flex-end"}
          >
            {showFavouriteIcon && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="favourite-icon"
                data-lib-ignore={true}
                onClick={() => {
                  setFavourite(!favourite);
                  toggleFavourite(!favourite, assetId, libItemData);
                }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <g
                  id="Group_64287"
                  data-name="Group 64287"
                  transform="translate(22582 -994)"
                >
                  <circle
                    id="Ellipse_4172"
                    data-name="Ellipse 4172"
                    cx="10"
                    cy="10"
                    r="10"
                    transform="translate(-22582 994)"
                    opacity="0.6"
                  />
                  <g
                    id="Group_56273"
                    data-name="Group 56273"
                    transform="translate(-22578.666 998.167)"
                  >
                    <rect
                      id="Rectangle_10897"
                      data-name="Rectangle 10897"
                      width="13.333"
                      height="13.333"
                      fill="none"
                    />
                    <path
                      id="heart-path"
                      data-name="Path 59540"
                      d="M75.776,109.97a3.244,3.244,0,0,0-2.684,1.407l-.124.158-.083-.106a3.2,3.2,0,0,0-2.69-1.459,3.1,3.1,0,0,0-3.21,3.209c0,2.745,4.971,6.792,5.333,7.083a.916.916,0,0,0,1.265,0c.364-.294,5.4-4.4,5.4-7.083A3.1,3.1,0,0,0,75.776,109.97Z"
                      transform="translate(-66.318 -108.636)"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="1"
                    />
                  </g>
                </g>
              </svg>
            )}
            {rightIcon && (
              <PricingTooltipComponent
                tooltipId={`${src}-tooltip`}
                tooltipPosition="top-left"
                pricingInfo={pricingInfo || null}
                isGif={isGif}
              >
                <IconContainer data-tooltip-id={`${src}-tooltip`}>
                  <img src={rightIcon} alt="stock-img" />
                </IconContainer>
              </PricingTooltipComponent>
            )}
          </IconWrapper>
        )}
        {showPlayIcon && (
          <img
            className="play-icon"
            src={`${STATIC_PATH}play-button.svg`}
            data-lib-ignore={true}
            alt="play-icon"
          />
        )}
        {isSelected && (
          <img
            className="selected-icon"
            src={selectedIcon}
            data-lib-ignore={true}
            alt="play-icon"
          />
        )}
      </Container>
      {showLabel && (
        <Typography
          cursor="pointer"
          content={label}
          enableTrim={false}
          font={font}
        />
      )}
    </TileWrapper>
  );
};

Tile.defaultProps = {
  width: "150px",
  height: "84.5px",
  border: "none",
  isPremium: false,
  textTile: false,
  showLabel: false,
  isFavourite: false,
  onTileClick: () => { },
  className: "",
  isElite: false,
  margin: "0px",
  isDisable: false,
  showFavouriteIcon: false,
  isVideoTile: false,
  isDisableHover: false,
  isSelected: false,
  isOpacityEnable: false
};

Tile.propTypes = {
  src: PropTypes.string,
  videoSrc: PropTypes.string,
  showFavouriteIcon: PropTypes.bool,
  isFavourite: PropTypes.bool,
  isVideoTile: PropTypes.bool,
  hoverEffect: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  rightIcon: PropTypes.string,
  isPremium: PropTypes.bool,
  font: PropTypes.string,
  label: PropTypes.string,
  textTile: PropTypes.bool,
  showLabel: PropTypes.bool,
  textStyles: PropTypes.object,
  onTileClick: PropTypes.func,
  onPointerDown: PropTypes.func,
  toggleFavourite: PropTypes.func,
  assetId: PropTypes.string,
  className: PropTypes.string,
  containerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  style: PropTypes.object,
  showPlayIcon: PropTypes.bool,
  libItemData: PropTypes.object,
  isElite: PropTypes.bool,
  category: PropTypes.string,
  margin: PropTypes.string,
  isAutoAlign: PropTypes.bool,
  isDisable: PropTypes.bool,
  isDisableHover: PropTypes.bool,
  align: PropTypes.string,
  isSelected: PropTypes.bool,
  selectedIcon: PropTypes.string,
  isOpacityEnable: PropTypes.bool
};

export default Tile;
