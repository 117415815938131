/// <reference path="../timeline-types.js" />
/* eslint-disable no-restricted-syntax, no-continue */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  MatchingSnapPointType,
  PlotType,
  SelectedSliderType,
  SliderType,
  StepperType,
} from "../timeline-proptypes";
import {
  PLAYER_CONTROLS_HEIGHT,
  RULER_OPTIONS,
  SLIDER_GAP_TYPES,
  SLIDER_TYPES,
  TRACK_OPTIONS,
  TRACK_TYPES,
} from "../timeline-constants";
import MouseDrag from "../helper-components/timeline-mousedrag";
import {
  TimelineScrollContext,
  TimelineScrollUpdateContext,
} from "../contexts/timeline-scroll-context";
import {
  copyHandler,
  duplicateItemHelper,
  getSliderSelectionIndex,
  getSlidersUnderSelection,
  isSliderInView,
  isVideoOnly,
  memoize,
  pasteHandler,
  pxToStep,
} from "../timeline-helper";
import {
  InsertIndicator,
  SliderMultiSelect,
  TrackContainer,
  TrackDivider,
  TrackListContainer,
} from "./timeline-track-components";
import ContextMenu from "../../../common-components/ContextMenu";
import Slider from "../slider/timeline-slider";
import SnapLineSVG from "../snaplinesvg/timeline-snaplinesvg";
import { resetSwap, setAdvancedEdit, setClipboardData } from "../../../redux/actions/appUtils";
import {
  deleteObject,
  updateTimelineTime,
} from "../../../redux/actions/timelineUtils";
import withNotify from "../../../helper/hoc/withNotify";
import TransitionLayer from "./transition-layer";
import { addNotification } from "../../../redux/actions/notificationUtils";
import uuid from "../../../helper/uuid";
import content from "../../../constants/content";

const tracksOffsetY = PLAYER_CONTROLS_HEIGHT + RULER_OPTIONS.height;

class TrackListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderMultiSelect: { x: 0, y: 0, width: 0, height: 0 },
      /** @type {{x: number, y: number, eventType: string} | null} */
      contextMenuPosition: null,
      isContextOpen: false,
      canPaste: false,
      pasteDuration: null,
    };

    this.handleSliderMultiSelect = this.handleSliderMultiSelect.bind(this);
    this.handleSliderMultiSelectEnd =
      this.handleSliderMultiSelectEnd.bind(this);
    this.startSliderMultiSelect = this.startSliderMultiSelect.bind(this);
    this.assignTrackListRef = this.assignTrackListRef.bind(this);
    this.assignSliderRef = this.assignSliderRef.bind(this);
    this.menuHandler = this.menuHandler.bind(this);
    this.menuClickHandler = this.menuClickHandler.bind(this);
    this._getContextOption = this._getContextOption.bind(this);
    this.handleShortcuts = this.handleShortcuts.bind(this);
    this.closeContextMenu = this.closeContextMenu.bind(this);

    /** @type {React.MutableRefObject<HTMLElement | null>} */
    this.trackListContainerRef = React.createRef(null);
    /** @type {React.MutableRefObject<HTMLElement | null>} */
    this.trackListRef = React.createRef(null);
    /** @type {React.MutableRefObject<HTMLElement | null>} */
    this.sliderListRef = React.createRef(null);
    /** @type {{ [key: string]: HTMLElement | null }} */
    this.gapSlidersRef = {};

    this.multiSelectStarted = false;
    this.isContextClick = false; // To prevent pointerUp on context menu click.
    this.getContextOption = memoize(this._getContextOption);
    this.pasteTrackInfo = null;
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.canUseMagnet &&
      this.props.canUseMagnet !== prevProps.canUseMagnet
    ) {
      this.closeContextMenu();
    }

    if (
      this.props.copiedItem &&
      this.props.copiedItem !== prevProps.copiedItem
    ) {
      (async () => {
        try {
          let showPaste = false;
          try {
            const clipboardItems = await navigator.clipboard.readText();
            const pastedData = JSON.parse(
              decodeURIComponent(escape(atob(clipboardItems)))
            );
            if (pastedData.key === "VMAKER_CLIPBOARD") {
              showPaste = true;
            }
          } catch (error) {
            // reading copied item from file manager (outside browser) or permission denied
            showPaste = false;
          }

          if (showPaste === false && this.props.copiedItem !== null) {
            showPaste = true;
          }
          this.setState({ canPaste: showPaste });
        } catch (e) {
          // e
        }
      })();
    }
    if (
      this.props.shortcutName !== prevProps.shortcutName &&
      this.props.shortcutName !== ""
    ) {
      this.handleShortcuts(this.props.shortcutName);
    }
  }

  /**
   * Function to handle shortcut events
   * @param {string} shortcutKey
   */
  handleShortcuts(shortcutKey) {
    const { handleGapRemove, setSelectedSliders, sliders, isAdvancedEdit, setAdvancedEdit } = this.props;

    switch (shortcutKey) {
      case "COPY_ITEM":
        this.copyToClipboard();
        break;
      case "PASTE_ITEM_AGAIN":
      case "PASTE_ITEM":
        this.pasteFromClipboard(this.props.playhead);
        break;
      case "DELETE_ITEM":
      case "DELETE_ITEM_MAC":
        if (this.props.swapDetails) {
          this.props.resetSwap();
        }
        if (isAdvancedEdit) {
          setAdvancedEdit("");
        }
        handleGapRemove({ deleteType: "gap-delete-selected" });
        break;
      case "SELECT_ALL_OBJECT":
        {
          const selectedSliders = Object.keys(sliders)
            .filter(
              (slider) =>
                !["ruler", "playheadThumb", "playheadIndicator"].includes(
                  slider
                )
            )
            .map((slider) => ({ sliderId: slider }));
          setSelectedSliders({ selectedSliders });
        }
        break;
      case "DESELECT_ALL_OBJECT":
        setSelectedSliders({ selectedSliders: [] });
        break;
      default:
        break;
    }
  }

  /**
   * handles multiselect drag
   * @param {MouseDragParams} params
   */
  handleSliderMultiSelect(params = {}) {
    const {
      event,
      mouseDownPosition,
      mouseCurrentPosition,
      scrollLeftOnMouseDown,
      scrollTopOnMouseDown,
    } = params;
    const { timelineInnerRef, updateIsSliderMultiSelect } = this.props;

    const DRAG_START_THRESHOLD = 5;

    if (
      this.trackListContainerRef.current instanceof HTMLElement &&
      timelineInnerRef &&
      timelineInnerRef.current instanceof HTMLElement
    ) {
      const { scrollLeft } = timelineInnerRef.current;
      const { scrollTop } = this.trackListContainerRef.current;
      const adjMouseDown = {
        x: scrollLeftOnMouseDown + mouseDownPosition.x,
        y: scrollTopOnMouseDown + mouseDownPosition.y,
      };
      const adjMouseCurPos = {
        x: scrollLeft + mouseCurrentPosition.x,
        y: scrollTop + mouseCurrentPosition.y,
      };
      const mouseMovedBy = {
        x: adjMouseCurPos.x - adjMouseDown.x,
        y: adjMouseCurPos.y - adjMouseDown.y,
      };

      if (
        !this.multiSelectStarted &&
        (Math.abs(mouseMovedBy.x) > DRAG_START_THRESHOLD ||
          Math.abs(mouseMovedBy.y) > DRAG_START_THRESHOLD)
      ) {
        this.multiSelectStarted = true;
        updateIsSliderMultiSelect({
          event,
          isSelecting: true,
          selectionToSet: [],
        });
      }

      const plot = { x: 0, y: 0, width: 0, height: 0 };
      plot.width = Math.abs(mouseMovedBy.x);
      plot.height = Math.abs(mouseMovedBy.y);
      if (mouseMovedBy.x >= 0) {
        plot.x = adjMouseDown.x;
      } else {
        plot.x = adjMouseDown.x - plot.width;
      }
      if (mouseMovedBy.y >= 0) {
        plot.y = adjMouseDown.y;
      } else {
        plot.y = adjMouseDown.y - plot.height;
      }

      this.setState({ sliderMultiSelect: plot });
    }
  }

  /**
   * handles multiselect end
   * @param {MouseDragEndParams} params
   */
  handleSliderMultiSelectEnd(params = {}) {
    const { event } = params;
    const { sliders, tracks, updateIsSliderMultiSelect } = this.props;
    const { sliderMultiSelect } = this.state;

    let selectionToSet;
    if (this.multiSelectStarted) {
      selectionToSet = getSlidersUnderSelection({
        plot: sliderMultiSelect,
        sliders,
        tracks,
      });
    }

    updateIsSliderMultiSelect({
      event,
      isSelecting: false,
      selectionToSet,
      isGapSlider: this.isMouseOnGapSlider(event.target),
      isContextClick: this.isContextClick,
    });
    this.setState({ sliderMultiSelect: { x: 0, y: 0, width: 0, height: 0 } });
  }

  copyToClipboard = () => {
    const { projectDetails, selectedSliders, setClipboard } = this.props;
    const result = copyHandler({ projectDetails, selectedSliders });
    if (result) {
      setClipboard(result);
    }
  };

  pasteFromClipboard = (duration = null, itemDetails = null) => {
    const { updateTimelineItems, copiedItem, projectDetails, notify } =
      this.props;
    const { canPaste } = this.state;
    let pasteDuration = duration;
    const trackToPaste = itemDetails?.track;
    pasteHandler(canPaste, copiedItem).then((pastedData) => {
      if (pastedData?.key === "VMAKER_CLIPBOARD") {
        if (
          (pastedData.data[0].item.id !== itemDetails?.itemId ||
            itemDetails?.isGapSlider) &&
          duration === null
        ) {
          // if the paste in context menu is clicked from different slider then paste the item in the slider start.
          pasteDuration = itemDetails.startTime;
        }
        if (
          itemDetails === null ||
          (itemDetails.container &&
            itemDetails.container === pastedData.data[0].container)
        ) {
          // Duplicating the copied items to paste.
          const toUpdate = duplicateItemHelper({
            itemsToDuplicate: pastedData.data,
            projectDetails,
            pasteDuration,
            trackToPaste: trackToPaste !== null ? trackToPaste : null,
          });
          // To update contains the copy of the items in the clipboard.
          updateTimelineItems({ toUpdate });
        } else {
          notify.warn("Cannot paste this item type here.");
        }
      }
    });
  };

  duplicateHandler = () => {
    const { projectDetails, selectedSliders, updateTimelineItems } = this.props;

    const itemsToDuplicate = [];
    ["workspaceItems", /* "workspaceBG", */ "audios"].forEach((container) => {
      selectedSliders.forEach(({ sliderId }) => {
        if (projectDetails.getIn([container, sliderId])) {
          itemsToDuplicate.push({
            container,
            item: projectDetails.getIn([container, sliderId]).toJS(),
          });
        }
      });
    });
    const toUpdate = duplicateItemHelper({ itemsToDuplicate, projectDetails });
    updateTimelineItems({ toUpdate });
  };

  deleteHandler = () => {
    const { deleteItems, selectedSliders, sliders, projectDetails } = this.props;

    const itemsToDelete = [];
    selectedSliders.forEach(({ sliderId }) => {
      const slider = sliders[sliderId];
      const { sliderType } = slider;
      if (sliderType === SLIDER_TYPES.SUBTITLE && projectDetails.getIn(["localSubtitle", sliderId])) {
        const subtitleId = projectDetails.getIn(["localSubtitle", sliderId, "subtitleId"]);
        itemsToDelete.push({
          container: "subtitleData",
          id: subtitleId.get("id"),
          dropId: subtitleId.get("dropId"),
          langId: subtitleId.get("langId"),
          timelineId: subtitleId.get("timelineId"),
          isDelete: true,
        });
      } else if (sliderType === TRACK_TYPES.AUDIO && projectDetails.getIn(["audios", sliderId])) {
        const isBlob = projectDetails && projectDetails.getIn(["workspaceItems", sliderId, "isBlob"])
        if (isBlob) {
          this.props.addNotifications({
            id: uuid(),
            toastType: "WARNING",
            description: content.UPLOAD_UNABLE_TO_DELETE,
          })
        } else {
          itemsToDelete.push({
            id: sliderId,
            container: "audios",
            isDelete: true,
          });
        }
      } else if (sliderType === TRACK_TYPES.OBJECT && projectDetails.getIn(["workspaceItems", sliderId])) {
        const isBlob = projectDetails && projectDetails.getIn(["workspaceItems", sliderId, "isBlob"])
        if (isBlob) {
          this.props.addNotifications({
            id: uuid(),
            toastType: "WARNING",
            description: content.UPLOAD_UNABLE_TO_DELETE,
          })
        } else {
          itemsToDelete.push({
            id: sliderId,
            container: "workspaceItems",
            isDelete: true,
          });
        }
      }
    });

    if (itemsToDelete.length > 0) {
      deleteItems({
        toUpdate: itemsToDelete,
      });
    }
  };

  menuHandler(e) {
    const {
      timelineScrollX,
      timelinePlot,
      canUseMagnet,
      selectedSliders,
      timelineScrollY,
      sliders,
      tracks,
    } = this.props;
    const { canPaste } = this.state;
    e.preventDefault();
    this.isContextClick = true;
    const x = e.clientX;
    const y = e.clientY;
    const clickY = y - (timelinePlot.y + tracksOffsetY) + timelineScrollY;
    for (const trackType of Reflect.ownKeys(tracks)) {
      const trackList = tracks[trackType];
      const container = trackType === "object" ? "workspaceItems" : "audios";
      for (const track of trackList) {
        /** @todo check if itemPlot should be used instead of plot for pasting */
        const trackBottom = track.plot.height + track.plot.y;
        const trackTop = track.plot.y;
        if (clickY >= trackTop && clickY <= trackBottom) {
          this.pasteTrackInfo = { trackIndex: track.trackIndex, container };
        }
      }
    }
    const posX = timelineScrollX + (e.clientX - timelinePlot.x);
    const pasteDuration =
      pxToStep({ rulerSlider: sliders[SLIDER_TYPES.RULER], x: posX }) *
      RULER_OPTIONS.interval; // Multiplying step with interval to get the duration in sec.
    this.setState({
      isContextOpen:
        (canUseMagnet && selectedSliders.length > 1) ||
        (canPaste && selectedSliders.length === 0),
      contextMenuPosition: { x, y, eventType: e.type },
      pasteDuration,
    });
  }

  /**
   * Function returns boolean if mouse on gap slider.
   * @param {event.target} target
   * @returns boolean
   */
  isMouseOnGapSlider(target) {
    return Reflect.ownKeys(this.gapSlidersRef).some((sliderId) => {
      const gapSliderEl = this.gapSlidersRef[sliderId];
      return gapSliderEl instanceof HTMLElement && gapSliderEl.contains(target);
    });
  }

  /**
   * Function to start multi-select
   * @param {React.PointerEvent} event
   */
  startSliderMultiSelect(event) {
    const {
      timelineInnerRef,
      initiateMultiSelectDrag,
      timelinePlot,
      updateIsSliderMultiSelect,
    } = this.props;
    this.isContextClick = false;

    const isMouseOnGapSlider = this.isMouseOnGapSlider(event.target);

    if (
      isMouseOnGapSlider ||
      (this.trackListRef.current &&
        this.trackListRef.current.contains(event.target))
    ) {
      this.multiSelectStarted = false;
      initiateMultiSelectDrag({
        event,
        onMouseDrag: this.handleSliderMultiSelect,
        onMouseDragEnd: this.handleSliderMultiSelectEnd,
        clientOffset: {
          x: -timelinePlot.x,
          y: -(timelinePlot.y + tracksOffsetY),
        },
        scrollLeftElRef: timelineInnerRef,
        scrollTopElRef: this.trackListContainerRef,
      });
      if (!isMouseOnGapSlider) {
        updateIsSliderMultiSelect({
          event,
          isSelecting: false,
          selectionToSet: [],
        });
      }
    }
  }

  menuClickHandler(actionName) {
    const { handleGapRemove } = this.props;
    const { pasteDuration } = this.state;

    this.closeContextMenu();
    switch (actionName) {
      case "MAGNET":
        handleGapRemove({ deleteType: "magnet" });
        break;
      case "COPY":
        this.copyToClipboard();
        break;
      case "PASTE":
        this.pasteFromClipboard(pasteDuration, {
          track: this.pasteTrackInfo.trackIndex,
          container: this.pasteTrackInfo.container,
        });
        break;
      case "DUPLICATE":
        this.duplicateHandler();
        break;
      case "DELETE":
        this.deleteHandler();
        break;
      default:
    }
  }

  _getContextOption() {
    const { canPaste } = this.state;
    const { selectedSliders } = this.props;
    const contextMenuOptions = [];
    if (selectedSliders.length > 1) {
      contextMenuOptions.push(
        {
          title: "Magnet",
          clickHandler: this.menuClickHandler,
          actionName: "MAGNET",
        },
        {
          title: "Duplicate",
          clickHandler: this.menuClickHandler,
          actionName: "DUPLICATE",
        },
        {
          title: "Copy",
          clickHandler: this.menuClickHandler,
          actionName: "COPY",
        },
        {
          title: "Delete",
          clickHandler: this.menuClickHandler,
          actionName: "DELETE",
        }
      );
    }
    if (canPaste) {
      const pasteMenuItem = {
        title: "Paste",
        clickHandler: this.menuClickHandler,
        actionName: "PASTE",
      };
      // Find the index of the "COPY" title in the array
      const copyIndex = contextMenuOptions.findIndex(
        (item) => item.actionName === "COPY"
      );
      // Insert the new object below the "COPY" title
      contextMenuOptions.splice(copyIndex + 1, 0, pasteMenuItem);
    }
    return contextMenuOptions;
  }

  closeContextMenu() {
    this.setState({ isContextOpen: false });
  }

  assignTrackListRef(r) {
    const { trackListContainerRef: ref, registerScrollTopEl } = this.props;

    this.trackListContainerRef.current = r;
    if (typeof registerScrollTopEl === "function") {
      registerScrollTopEl(r);
    }
    if (typeof ref === "function") {
      ref(r);
    } else if (ref && typeof ref === "object") {
      ref.current = r;
    }
  }

  assignSliderRef(r, slider) {
    if (r === null) {
      delete this.gapSlidersRef[slider.id];
    } else if (slider.sliderType === SLIDER_TYPES.GAP) {
      this.gapSlidersRef[slider.id] = r;
    }
  }

  render() {
    const {
      selectedSliders,
      sliders,
      tracks,
      stepper,
      isDragging,
      sliderMoved,
      onScrollTop,
      canUseMagnet,
      sliderMultiSelectStatus,
    } = this.props;
    const {
      isSliderDragging,
      selectSlider,
      timelinePlot,
      handleGapRemove,
      matchingSnapPoints,
      projectDetails,
      copiedItem,
    } = this.props;
    const { timelineScrollX, timelineScrollY } = this.props;
    const { contextMenuPosition, isContextOpen, sliderMultiSelect, canPaste } =
      this.state;

    const defaultTrackHeight = timelinePlot.height - tracksOffsetY;
    const rulerWidth =
      RULER_OPTIONS.paddingLeft +
      (stepper.steps + stepper.videoExcessSteps) * stepper.stepSizePx +
      RULER_OPTIONS.paddingRight;
    const isThumbDrag = Boolean(
      selectedSliders.length === 1 &&
      selectedSliders[0].thumbId &&
      sliders[selectedSliders[0].sliderId] &&
      sliders[selectedSliders[0].sliderId][selectedSliders[0].thumbId]
    );

    let trackListHeight = 0;
    let newTrackToRender = null;
    const tracksToRender = [];
    for (const trackType of Reflect.ownKeys(tracks)) {
      const trackList = tracks[trackType];
      const trackOptions = TRACK_OPTIONS[trackType];
      let currentTrackIndex = 0;

      for (const track of trackList) {
        if (track.hide) {
          continue;
        }

        if (track.trackIndex === 0 && trackOptions && trackOptions.dividerAbove) {
          const dividerHeight = trackOptions.dividerHeight !== undefined ? trackOptions.dividerHeight : 1;
          tracksToRender.push(
            <TrackDivider
              $plot={{
                x: 0,
                y: track.trackGapMiddle,
                width: rulerWidth,
                height: dividerHeight,
              }}
            />
          )
        }

        trackListHeight = track.plot.y + track.plot.height;
        tracksToRender.push(
          <TrackContainer
            key={trackType + currentTrackIndex}
            className={"timeline-track"}
            $plot={track.plot}
          />
        );
        currentTrackIndex += 1;
      }
    }
    trackListHeight += TRACK_OPTIONS.tracksMarginBottom;

    const slidersToRender = [];
    const transitionLayer = [];
    for (const sliderId of Reflect.ownKeys(sliders)) {
      /** @type {Slider} */
      const slider = sliders[sliderId];

      /** @type {"itemPlot" | "subtitlePlot"} */
      let sliderTrackPlotKey = "itemPlot";
      if (
        slider.sliderType === SLIDER_TYPES.SUBTITLE
        || slider.sliderType === SLIDER_TYPES.MINI_SUBTITLE
        || (slider.sliderType === SLIDER_TYPES.GAP && slider.itemType === SLIDER_GAP_TYPES.SUBTITLE)
      ) {
        sliderTrackPlotKey = "subtitlePlot";
      }

      let { position } = slider.enterStart;
      const { trackType: originalTrackType, trackIndex: originalTrackIndex } =
        position;
      if (slider.enterStart.trackPosition) {
        position = slider.enterStart.trackPosition;
      }
      const { trackType, trackIndex } = position;

      if (
        trackType === TRACK_TYPES.AUDIO ||
        trackType === TRACK_TYPES.VIDEO ||
        trackType === TRACK_TYPES.OBJECT
      ) {
        let itemContainer = "";
        const { sliderType } = slider;
        const isItem =
          SLIDER_TYPES.OBJECT === sliderType ||
          SLIDER_TYPES.VIDEO === sliderType ||
          SLIDER_TYPES.AUDIO === sliderType ||
          SLIDER_TYPES.SUBTITLE === sliderType;
        if (slider.sliderType === SLIDER_TYPES.SUBTITLE) {
          itemContainer = "subtitle";
        } else if (trackType === TRACK_TYPES.OBJECT) {
          itemContainer = "workspaceItems";
        } else if (trackType === TRACK_TYPES.VIDEO) {
          itemContainer = "workspaceBG";
        } else if (trackType === TRACK_TYPES.AUDIO) {
          itemContainer = "audios";
        }

        let item;
        if (itemContainer === "subtitle") {
          const { subtitleId } = slider;
          item = projectDetails.getIn([
            "subtitle",
            "data",
            subtitleId.dropId,
            subtitleId.id,
          ]);
        } else {
          item = projectDetails.getIn([itemContainer, slider.id]);
        }

        let trackHeight = 0;
        let originalTrackHeight = 0;
        const currentTrack = tracks[trackType][trackIndex];

        if (currentTrack) {
          trackHeight = currentTrack[sliderTrackPlotKey].height;
          originalTrackHeight = currentTrack[sliderTrackPlotKey].height;

          if (slider.toNewTrackAbove || slider.toNewTrackBelow) {
            let height = 3;
            if (
              TRACK_OPTIONS[trackType] &&
              TRACK_OPTIONS[trackType].newTrackHeight !== undefined
            ) {
              height = TRACK_OPTIONS[trackType].newTrackHeight;
            }

            const { marginTop = 0 } = TRACK_OPTIONS[trackType];
            let newGapCenterY = marginTop / 2;
            if (slider.toNewTrackAbove) {
              newGapCenterY = -newGapCenterY;
            } else {
              newGapCenterY = currentTrack.plot.height + newGapCenterY;
            }
            newGapCenterY = currentTrack.plot.y + newGapCenterY;

            const newTrackPlot = {
              x: currentTrack.plot.x,
              y: newGapCenterY - height / 2,
              width: currentTrack.plot.width,
              height,
            };

            newTrackToRender = <InsertIndicator $plot={newTrackPlot} />;
          }
        }

        if (
          (originalTrackType !== trackType ||
            originalTrackIndex !== trackIndex) &&
          tracks[originalTrackType][originalTrackIndex]
        ) {
          originalTrackHeight =
            tracks[originalTrackType][originalTrackIndex][sliderTrackPlotKey]
              .height;
        }

        const { isSelected /* selectionIndex */ } = getSliderSelectionIndex({
          selectedSliders,
          slider,
        });

        let canRenderSlider = isSelected;
        if (!isSelected) {
          canRenderSlider = isSliderInView({
            slider,
            timelineWidth: timelinePlot.width,
            timelineScroll: { x: timelineScrollX, y: timelineScrollY },
            trackHeight,
            trackListHeight: defaultTrackHeight,
          });
        }
        if (isItem && !item) {
          // dont mount slider if relevant item is not found
          // on item delete, its slider will delete only on next timeline render
          canRenderSlider = false;
        }

        if (canRenderSlider) {
          const { min } = slider.enterStart;
          const prevSlider = sliders[min && min.sliderId];
          const currentEnterPosition = slider?.enterStart?.position;
          const prevExitPosition = prevSlider?.exitEnd?.position;
          const exitEndPosition = slider?.exitEnd?.position;
          const enterStartPosition = slider?.enterStart?.position;
          const prevExitEnd = prevSlider?.exitEnd?.position;
          const prevEnterStart = prevSlider?.enterStart?.position;
          const nextSliderWidth = (exitEndPosition?.x ?? 0) - (enterStartPosition?.x ?? 0);
          const prevSliderWidth = (prevExitEnd?.x ?? 0) - (prevEnterStart?.x ?? 0);

          if (
            currentEnterPosition?.step === prevExitPosition?.step &&
            prevExitPosition?.sliderId !== SLIDER_TYPES.RULER &&
            isVideoOnly(prevSlider.itemType, prevSlider.itemSubType) && isVideoOnly(slider.itemType, slider.itemSubType)
          ) {
            transitionLayer.push(
              <TransitionLayer
                shortcutName={this.props.shortcutName}
                x={currentEnterPosition.x}
                y={currentEnterPosition.y}
                prevItem={prevExitPosition.sliderId}
                nextItem={currentEnterPosition.sliderId}
                prevWidth={prevSliderWidth}
                nextWidth={nextSliderWidth}
              />
            );
          }

          slidersToRender.push(
            <Slider
              key={sliderId}
              sliderRef={this.assignSliderRef}
              isDragging={isDragging}
              sliderMoved={sliderMoved}
              isSliderDragging={isSliderDragging}
              isSelected={isSelected}
              selectSlider={selectSlider}
              slider={slider}
              trackType={trackType}
              trackHeight={trackHeight}
              forDragPosition={false}
              isThumbDrag={isThumbDrag}
              timelinePlot={timelinePlot}
              handleGapRemove={handleGapRemove}
              canUseMagnet={canUseMagnet}
              stepper={stepper}
              itemContainer={itemContainer}
              item={item}
              canPaste={canPaste}
              rulerSlider={sliders[SLIDER_TYPES.RULER]}
              enableContext={!canUseMagnet || selectedSliders?.length <= 1}
              copyToClipboard={this.copyToClipboard}
              pasteFromClipboard={this.pasteFromClipboard}
              duplicateHandler={this.duplicateHandler}
              deleteHandler={this.deleteHandler}
            />
          );

          if (isSliderDragging && isSelected) {
            slidersToRender.push(
              <Slider
                key={`${sliderId}dragSlider`}
                isDragging={isDragging}
                sliderMoved={sliderMoved}
                isSliderDragging={isSliderDragging}
                isSelected={isSelected}
                selectSlider={selectSlider}
                slider={slider}
                trackType={originalTrackType}
                trackHeight={originalTrackHeight}
                forDragPosition={true}
                isThumbDrag={isThumbDrag}
                timelinePlot={timelinePlot}
                handleGapRemove={handleGapRemove}
                canUseMagnet={canUseMagnet}
                stepper={stepper}
                itemContainer={itemContainer}
                item={item}
                canPaste={canPaste}
              />
            );
          }
        }
      }
    }

    if (trackListHeight < defaultTrackHeight) {
      trackListHeight = defaultTrackHeight;
    }

    const canRenderSnapLines = Reflect.ownKeys(matchingSnapPoints).length > 0;
    const contextMenuOptions = this.getContextOption.executor([
      copiedItem,
      canPaste,
      isContextOpen,
    ]);

    return (
      <TrackListContainer
        ref={this.assignTrackListRef}
        $width={rulerWidth}
        onPointerDown={!isDragging ? this.startSliderMultiSelect : undefined}
        onScroll={onScrollTop}
        onContextMenu={this.menuHandler}
      >
        {isContextOpen &&
          ((canUseMagnet && selectedSliders.length > 1) ||
            (canPaste && selectedSliders.length === 0)) && (
            <ContextMenu
              positionEvent={contextMenuPosition}
              options={contextMenuOptions}
              closeContextMenu={this.closeContextMenu}
              width="150px"
            />
          )}
        <div
          ref={this.trackListRef}
          className="timeline-track--track-list"
          style={{ height: `${trackListHeight}px` }}
        >
          {tracksToRender}
          {newTrackToRender}
        </div>
        <div
          ref={this.sliderListRef}
          className="timeline-track--slider-list"
          style={{ height: `${trackListHeight}px`, zIndex: 0 }}
        >
          {slidersToRender}
        </div>
        <div>
          {transitionLayer}
        </div>
        {canRenderSnapLines && (
          <div
            className="timeline-track--snaplinesvg"
            style={{ height: `${trackListHeight}px` }}
          >
            <SnapLineSVG
              matchingSnapPoints={matchingSnapPoints}
              sliders={sliders}
              tracks={tracks}
              rulerWidth={rulerWidth}
              trackListHeight={trackListHeight}
            />
          </div>
        )}
        {sliderMultiSelectStatus.isDragging && (
          <SliderMultiSelect $plot={sliderMultiSelect} />
        )}
      </TrackListContainer>
    );
  }
}

TrackListComponent.propTypes = {
  stepper: StepperType.isRequired,
  selectedSliders: PropTypes.arrayOf(SelectedSliderType).isRequired,
  sliders: PropTypes.objectOf(SliderType).isRequired,
  isDragging: PropTypes.bool.isRequired,
  sliderMoved: PropTypes.bool.isRequired,
  isSliderDragging: PropTypes.bool.isRequired,
  updateIsSliderMultiSelect: PropTypes.func.isRequired,
  selectSlider: PropTypes.func.isRequired,
  tracks: PropTypes.object.isRequired,
  timelinePlot: PlotType.isRequired,
  matchingSnapPoints: PropTypes.objectOf(MatchingSnapPointType).isRequired,
  timelineInnerRef: PropTypes.shape({
    current: PropTypes.instanceOf(HTMLElement),
  }).isRequired,
  setSelectedSliders: PropTypes.func,
  handleGapRemove: PropTypes.func,
  canUseMagnet: PropTypes.bool,
  initiateMultiSelectDrag: PropTypes.func,
  sliderMultiSelectStatus: PropTypes.object,
  onScrollTop: PropTypes.func,
  registerScrollTopEl: PropTypes.func,
  trackListContainerRef: PropTypes.object,
  projectDetails: PropTypes.object,
  timelineScrollX: PropTypes.number,
  timelineScrollY: PropTypes.number,
  copiedItem: PropTypes.object,
  setClipboard: PropTypes.func,
  updateTimelineItems: PropTypes.func,
  deleteItems: PropTypes.func,
  shortcutName: PropTypes.string,
  playhead: PropTypes.number,
  swapDetails: PropTypes.object,
  resetSwap: PropTypes.func,
  notify: PropTypes.object,
  addNotifications: PropTypes.func,
  isAdvancedEdit: PropTypes.bool,
  setAdvancedEdit: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    project: state.projectDetails,
    copiedItem: state.app.get("clipboardData"),
    playhead: state.app.get("playhead"),
    swapDetails: state.app.get("swapDetails"),
    isAdvancedEdit: state.app.get('isAdvancedEdit'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setClipboard: (data) => dispatch(setClipboardData(data)),
  updateTimelineItems: (data) => dispatch(updateTimelineTime(data)),
  deleteItems: (payload) => dispatch(deleteObject(payload)),
  resetSwap: () => dispatch(resetSwap()),
  addNotifications: (payload) => dispatch(addNotification(payload)),
  setAdvancedEdit: (data) => dispatch(setAdvancedEdit(data)),
});

const TrackList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotify(TrackListComponent));

const TrackListWithEvents = (props) => {
  return (
    <MouseDrag>
      {(multiSelectProps) => (
        <TimelineScrollUpdateContext.Consumer>
          {(scrollUpdateCallbacks) => (
            <TimelineScrollContext.Consumer>
              {(timelineScrollPos) => (
                <TrackList
                  {...props}
                  initiateMultiSelectDrag={multiSelectProps.initiateDrag}
                  sliderMultiSelectStatus={multiSelectProps.dragStatus}
                  onScrollTop={scrollUpdateCallbacks.onScrollTop}
                  registerScrollTopEl={
                    scrollUpdateCallbacks.registerScrollTopEl
                  }
                  timelineScrollX={timelineScrollPos.x}
                  timelineScrollY={timelineScrollPos.y}
                />
              )}
            </TimelineScrollContext.Consumer>
          )}
        </TimelineScrollUpdateContext.Consumer>
      )}
    </MouseDrag>
  );
};

export default TrackListWithEvents;
