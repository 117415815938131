import ActionTypes from "../../constants/action-types";

export function setModalProps({ isShowModal, isDisableDrag, child, ...args }) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SET_MODAL,
      payload: {
        isShowModal,
        isDisableDrag,
        child,
        ...args
      },
    });
}

export function showUpgrade(payload) {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SHOW_UPGRADE_MODAL,
      payload: {
        show: true,
        ...(payload || {}),
      },
    });
}

export function hideUpgrade() {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.SHOW_UPGRADE_MODAL,
      payload: {
        show: false,
        headerText: null,
        subText: null,
        enterpriseContent: null,
        upgradesource: ''
      },
    });
}