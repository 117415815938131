import { STATIC_PATH } from "../../constants/config";
import content from "../../constants/content";

const isMobile = window.innerWidth <= 768;

export default {
    basePath: STATIC_PATH,
    actions: {
        settings: {
            action: "settings",
            actionIfActive: "settings-active",
            langId: content.SETTINGS,
            hasIcon: true,
            hasText: true,
            iconPath: {
                default: "settings.svg",
                hover: "settings.svg",
                active: "settings.svg",
            },
        },
        editMusic: {
            action: "editMusic",
            langId: content.EDIT_MUSIC,
            hasIcon: true,
            hasText: true,
            iconPath: {
                default: "edit-music.svg",
                hover: "edit-music.svg",
                active: "edit-music.svg",
            },
        },
        animate: {
            action: "animate",
            actionIfActive: "animate-active",
            langId: content.ANIMATE,
            hasIcon: true,
            hasText: true,
            iconPath: {
                default: "animation-icon.svg",
                hover: "animation-icon.svg",
                active: "animation-icon.svg",
            },
            ifDisabledSwapWith: "animateDisabled",
        },
        animateDisabled: {
            action: "animate-disabled",
            langId: content.ANIMATE,
            hasIcon: true,
            hasText: true,
            isDisabled: true,
            iconPath: {
                default: "animation-icon.svg",
            },
        },
        crop: {
            action: "crop",
            langId: content.CROP,
            hasIcon: true,
            hasText: true,
            iconPath: {
                default: "crop.svg",
                hover: "crop.svg",
            },
        },
        frame: {
            action: "frame",
            langId: content.CROP,
            hasIcon: true,
            hasText: true,
            iconPath: {
                default: "crop.svg",
                hover: "crop.svg",
            },
        },
        clipExtract: {
            action: "clipExtract",
            langId: content.CLIP_EXTRACT,
            hasIcon: false,
            hasText: true,
        },
        swap: {
            action: "swap",
            langId: content.REPLACE,
            hasIcon: isMobile,
            hasText: true,
            iconPath: {
                default: "swap-black.svg",
                hover: "swap-black.svg",
            },
        },
        duplicate: {
            action: "duplicate",
            langId: content.DUPLICATE,
            hasIcon: true,
            hasText: true,
            iconPath: {
                default: "duplicate-toolbar.svg",
                hover: "duplicate-toolbar.svg",
            },
        },
        copy: {
            action: "copy",
            langId: content.COPY,
            hasIcon: true,
            hasText: true,
            iconPath: {
                default: "copy-toolbar.svg",
                hover: "copy-toolbar.svg",
            },
        },
        radius: {
            action: "radius",
            actionIfActive: "popup-active",
            langId: content.RADIUS,
            hasIcon: true,
            hasText: isMobile,
            ignoreTooltipIfActive: true,
            iconPath: {
                default: "radius.svg",
                hover: "radius.svg",
                active: "radius.svg",
            },
        },
        transform: {
            action: "transform",
            actionIfActive: "popup-active",
            langId: content.FLIP,
            hasIcon: true,
            hasText: isMobile,
            ignoreTooltipIfActive: true,
            iconPath: {
                default: "rotation.svg",
                hover: "rotation.svg",
                active: "rotation.svg",
            },
        },
        transparency: {
            action: "transparency",
            actionIfActive: "popup-active",
            langId: content.TRANSPARENCY,
            hasIcon: true,
            hasText: isMobile,
            ignoreTooltipIfActive: true,
            iconPath: {
                default: "transparency.svg",
                hover: "transparency.svg",
                active: "transparency.svg",
            },
        },
        layer: {
            action: "layer",
            actionIfActive: "popup-active",
            langId: content.POSITION,
            hasIcon: true,
            hasText: isMobile,
            ignoreTooltipIfActive: true,
            iconPath: {
                default: "align.svg",
                hover: "align.svg",
                active: "align.svg",
            },
        },
        align: {
            action: "align",
            actionIfActive: "popup-active",
            langId: content.ALIGNMENT,
            hasIcon: true,
            hasText: isMobile,
            ignoreTooltipIfActive: true,
            iconPath: {
                default: "align.svg",
                hover: "align.svg",
                active: "align.svg"
            }
        },
        delete: {
            action: "delete",
            langId: content.DELETE,
            hasIcon: true,
            hasText: isMobile,
            iconPath: {
                default: "dustbin.svg",
                hover: "dustbin.svg",
                active: "dustbin.svg",
            },
        },
        color: {
            action: "color",
            actionIfActive: "settings-active",
            langId: content.COLOR,
        },
        direction: {
            action: "direction",
            actionIfActive: "direction-active",
            langId: content.DIRECTION,
            hasIcon: true,
            hasText: true,
            iconPath: {
                default: "bgdirection.svg",
                hover: "bgdirection.svg",
                active: "bgdirection.svg",
            },
            ifDisabledSwapWith: "directionDisabled",
        },
        directionDisabled: {
            action: "direction-disabled",
            langId: content.DIRECTION,
            hasIcon: true,
            hasText: true,
            isDisabled: true,
            iconPath: {
                default: "bgdirection.svg",
            },
        },
        expand: {
            action: "expand",
            langId: content.EXPAND,
            hasIcon: true,
            ignoreTooltipIfActive: true,
            iconPath: {
                default: "item-tool-expand.svg",
                hover: "item-tool-expand.svg",
                active: "item-tool-expand.svg"
            }
        },
    },
    SUBTITLE: {
        subtitle: {
            setOne: {
                actionList: ["settings"],
            },
            setTwo: {
                actionList: ["delete"],
            },
        },
    },
    // for both image and video... split this into IMAGE and VIDEO if unique actions are required.
    VISUAL_MEDIA: {
        workspaceItems: {
            setOne: {
                actionList: ["settings", "crop", "animate"]
            },
            setTwo: {
                actionList: [
                    "swap",
                    "radius",
                    "transform",
                    "transparency",
                    "layer",
                    "delete",
                ],
            },
        },
        workspaceChildren: {
            setOne: {
                actionList: ["settings"],
            },
            setTwo: {
                actionList: ["radius", "transform", "transparency"],
            },
        },
    },
    AUDIO: {
        audios: {
            setOne: {
                actionList: ["settings", "editMusic"],
            },
            setTwo: {
                actionList: ["swap", "delete"],
            },
        },
    },
    FRAME: {
        workspaceItems: {
            setOne: {
                actionList: ["color", "animate"],
            },
            setTwo: {
                actionList: ["swap", "transform", "transparency", "layer", "delete"],
            },
        },
        workspaceChildren: {
            setOne: {
                actionList: ["color"],
            },
            setTwo: {
                actionList: ["transform", "transparency"],
            },
        },
    },
    FRAME_EMPTY_CLIP: {
        workspaceItems: {
            setOne: {
                actionList: ["color"],
            },
            setTwo: {
                actionList: [/* "swap", */"transform", "transparency", "layer", "delete"],
            },
        },
        workspaceChildren: {
            setOne: {
                actionList: ["color"],
            },
            setTwo: {
                actionList: ["transform", "transparency"],
            },
        },
    },
    // for both image and video... split this into IMAGE and VIDEO if unique actions are required.
    FRAME_CLIP_VISUAL_MEDIA: {
        workspaceItems: {
            setOne: {
                actionList: ["color", "settings", "frame", "clipExtract"],
            },
            setTwo: {
                actionList: ["swap", "transform", "transparency", "delete"],
            },
        },
        workspaceChildren: {
            setOne: {
                actionList: ["color", "settings"],
            },
            setTwo: {
                actionList: ["transform", "transparency"],
            },
        },
    },
    PROP: {
        workspaceItems: {
            setOne: {
                actionList: ["color", "animate"],
            },
            setTwo: {
                actionList: ["swap", "transform", "transparency", "layer", "delete"],
            },
        },
        workspaceChildren: {
            setOne: {
                actionList: ["color"],
            },
            setTwo: {
                actionList: ["transform", "transparency"],
            },
        },
    },
    SHAPE: {
        workspaceItems: {
            setOne: {
                actionList: ["color", "settings", "animate"],
            },
            setTwo: {
                actionList: ["swap", "transform", "transparency", "layer", "delete"],
            },
        },
        workspaceChildren: {
            setOne: {
                actionList: ["color", "settings"],
            },
            setTwo: {
                actionList: ["transform", "transparency"],
            },
        },
    },
    UPIMAGE_SVG: {
        workspaceItems: {
            setOne: {
                actionList: ["color", "animate"],
            },
            setTwo: {
                actionList: [/* "swap", */"transform", "transparency", "layer", "delete"],
            },
        },
        workspaceChildren: {
            setOne: {
                actionList: ["color"],
            },
            setTwo: {
                actionList: ["transform", "transparency"],
            },
        },
    },
    TEXT: {
        workspaceItems: {
            setOne: {
                actionList: ["color", "settings", "animate"],
            },
            setTwo: {
                actionList: ["transform", "transparency", "layer", "delete"],
            },
        },
        workspaceChildren: {
            setOne: {
                actionList: ["color", "settings"],
            },
            setTwo: {
                actionList: ["transparency"],
            },
        },
    },
    GROUP: {
        workspaceItems: {
            setOne: {
                actionList: [],
            },
            setTwo: {
                actionList: ["transform", "transparency", "layer", "delete"],
            },
        },
    },
    MULTI_SELECT: {
        workspaceItems: {
            setOne: {
                actionList: [],
            },
            setTwo: {
                actionList: ["duplicate", "copy", "transform", "transparency", "align", "delete"],
            },
        },
        mixed: {
            setOne: {
                actionList: [],
            },
            setTwo: {
                actionList: ["delete"],
            },
        },
        audios: {
            setOne: {
                actionList: [],
            },
            setTwo: {
                actionList: ["duplicate", "copy", "delete"],
            },
        }
    },
    PROJECT: {
        project: {
            setOne: {
                actionList: ["color", "direction"],
            },
        },
    },
};
