import { PLAN } from "../constants"

const isPremiumUser = (plan) => {
    return plan !== PLAN.LITE && plan !== PLAN.STARTER
}

const isEnterPrise = (plan) => {
    return plan !== PLAN.LITE && plan !== PLAN.STARTER && plan !== PLAN.TEAM && plan !== PLAN.TIER1 &&
    plan !== PLAN.TIER2 && plan !== PLAN.TIER3 && plan !== PLAN.TIER4 && plan !== PLAN.TIER5 &&
    plan !== PLAN.PG_Tier1 && plan !== PLAN.PG_Tier2 && plan !== PLAN.PG_Tier3 && 
    plan !== PLAN.PG_Tier4 && plan !== PLAN.PG_Tier5
}

const isPaidUser = (plan) => {
    return plan !== PLAN.LITE
}

const isTierPlan = (plan) => {
    return plan === PLAN.TIER1 ||
    plan === PLAN.TIER2 || plan === PLAN.TIER3 || plan === PLAN.TIER4 || plan === PLAN.TIER5 ||
    plan === PLAN.PG_Tier1 || plan === PLAN.PG_Tier2 || plan === PLAN.PG_Tier3 || 
    plan === PLAN.PG_Tier4 || plan === PLAN.PG_Tier5
}

export {
    isPremiumUser,
    isEnterPrise,
    isPaidUser,
    isTierPlan
}