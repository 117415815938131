import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { font } from "../constants/font";
import Typography from "./Typography";
import { STATIC_PATH } from "../constants/config";

const DropDown = styled.div`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  background: ${(props) => props.theme.polarColor};
  border: ${(props) =>
    props.border || `1px solid ${props.theme.primaryBorderColor}`};
  border-radius:${(props) => props.radius || "6px"};
  margin: ${(props) => props.margin || "8px 0px 12px"};
  position: ${(props) => props.position || "relative"};
  ${(props) =>
    props.isOpen &&
    css`
      border: ${(props) =>
        props.borderActive || `1px solid ${props.theme.secondaryBorderColor}`};
    `}
`;

const OptionWrapper = styled.ul`
  list-style: none;
  margin-top: 5px;
  padding: 0px;
  background-color: ${(props) => props.theme.polarColor};
  border-radius: ${(props) => props.borderRadius};
  box-shadow: 0px 0px 4px ${(props) => props.theme.boxShadowColor};
  width: ${(props) => props.optionWidth || "262px"};
  height: ${(props) => props.optionHeight};
  padding: 0px;
  position: absolute;
  z-index: 9;
  top: ${(props) => props.top + 47}px;
  overflow-y: ${(props) => props.optionHeight ? "auto" : "unset"};
`;

const Option = styled.div`
  color: ${(props) => props.theme.propertyColor};
  width: 100%;
  display: inline-block;
  width: -webkit-fill-available;
  padding: 12px 10px;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  font: ${font.normalBase};
  position: relative;
  &:after {
      content: "";
      position: absolute;
      right: 15px;
      top: auto;
      width: 4px;
      height: 12px;
      border: solid
        ${(props) => (props.isDisabled ? "grey" : props.theme.sideBarproperty)};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      ${props => !props.showTick && css`
        display: none;
      `}
    }
  &:hover {
    background: ${(props) => props.theme.mercuryColor};
  }
  img {
    margin: 2px 0px -5px 5px;
    &.upgradeIcon{
      float: right;
      position: relative;
      top: 50%;
      transform: translateY(27%);
    }
  }
`;

const Selected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.propertyColor};
  padding: 10px;
  font: ${font.normalBase};
  position: relative;
  cursor: pointer;
`;

const Backdrop = styled.div`
  width: ${(props) => props.backdropWidth || "100%"};
  width: ${(props) => props.backdropHeight || "100%"};
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${(props) => props.zIndex};
`;

const Label = styled.label`
  display: block;
  font: ${props => props.labelFont || font.normalBase_16};
  color: ${props => props.labelColor};
  margin: ${(props) => props.labelMargin || "0px 0px 10px"};
  top: -25px;
`;

const RequiredStar = styled.span`
  color: ${(props) => props.theme.warningColor};
  margin-left: 4px;
  font: ${font.normalBase_16};
`;
const Subtext = styled.span`
display: inline-block;
font: ${(props) => (props.languageSubText ? `${font.normalBase}` : `${font.normalMicro_16}`)};
letter-spacing: 0px;
color: ${props => props.labelSubtextColor};
width: ${(props) => (props.languageSubText ? "auto" : "100%")};
margin: ${(props) => (props.languageSubText ? "0px 10px;" : "4px 0px 0px;")};
`;

const LabelDropdown = ({
  options,
  width,
  height,
  right,
  border,
  labelEnable,
  labelText,
  borderActive,
  margin,
  optionWidth,
  fontStyle,
  defaultValue,
  onChange,
  enableInputBox,
  position,
  enableAutoAlign,
  labelFont,
  labelColor,
  requiredStar = true,
  labelMargin,
  isBottom,
  isScroll,
  borderRadius,
  optionHeight,
  radius,
  backdropWidth,
  backdropHeight,
  languageSubText
}) => {
  const optionRef = useRef(null);
  const openRef = useRef(null);
  const [top, setTop] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownBottom, setDropdownBottom] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    label: options[0].label,
    value: options[0].value,
    subtext: options[0].subtext,
  });
  useEffect(() => {
    if (defaultValue) {
      const selectedData = options.filter(a => a.value === defaultValue);
      // eslint-disable-next-line no-unused-expressions
      selectedData.length && setSelectedOption({
        ...selectedData[0]
      });
    }

  }, [defaultValue, options]);
  // backup
  // useEffect(() => {
  //   if (optionRef.current) {
  //     const clientRect = optionRef.current.getBoundingClientRect();
  //     // 12 is added because of header size and elemnt hight considered
  //     setTop(clientRect.top - 12);
  //   }
  // }, [isOpen]);


  useEffect(() => {
    if (optionRef.current) {
      const clientRect = optionRef.current.getBoundingClientRect();
      if (isBottom) {
        setDropdownBottom("52px");
        setTop("auto");
      } else {
        setTop(clientRect.top - 12);
      }
    }
    if (isOpen && !isBottom && isScroll) {
      openRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [isOpen, isBottom, isScroll]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onOptionClick = useCallback((option) => {
    onChange(option);
    if (!option.isUpgrade) {
      setSelectedOption(option);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInputChange = useCallback((e) => {
    const { value } = e.target;
    onOptionClick({
      label: value,
      value: value !== "" && Number(value),
    });
  });

  return (
    <>
      {labelEnable && (
        <Label labelFont={labelFont} labelColor={labelColor} labelMargin={labelMargin}>
          {labelText}
          {requiredStar && <RequiredStar>*</RequiredStar>}
        </Label>
      )}
      <DropDown
        position={position}
        className="drop-down"
        onClick={() => setIsOpen(!isOpen)}
        width={width}
        height={height}
        isOpen={isOpen}
        margin={margin}
        border={border}
        radius={radius}
        borderActive={borderActive}
        labelEnable={labelEnable}
        ref={optionRef}
        isScroll={isScroll}
      >
        <Selected className="selected-option">
          {!enableInputBox ? (
            <Typography
              innerContent={selectedOption.label}
              {...fontStyle}
              font={font.normalBase}
              cursor="pointer"
            />
          ) : (
            <input
              className="dropdown-input"
              type="number"
              value={selectedOption.value}
              onChange={onInputChange}
              onBlur={() =>
                setTimeout(() => {
                  setIsOpen(false);
                })
              }
            />
          )}
          <img
            src={`${STATIC_PATH}downArrow.svg`}
            alt="close"
            style={isOpen ? { transform: "rotate(180deg)" } : {}}
          />
        </Selected>
        {isOpen && (
          <>
            <Backdrop backdropWidth={backdropWidth} backdropHeight={backdropHeight} onClick={() => setIsOpen(false)} zIndex={1} />
            <OptionWrapper
              className="dropdown-options-wrapper"
              optionWidth={optionWidth}
              borderRadius={borderRadius}
              optionHeight={optionHeight}
              top={enableAutoAlign ? top : "35px"}
              style={{ bottom: dropdownBottom }}
              ref={openRef}
            >
              {options.map((option, index) => (
                <Option
                  className="dropdown-option-item"
                  key={`${option.label + index}`}
                  onClick={() => {
                    if (!option.isDisabled) {
                      onOptionClick(option);
                    }
                  }}
                  right={right}
                  isDisabled={option.isDisabled}
                  showTick={!option.isUpgrade && option.label === selectedOption.label && option.value === selectedOption.value}
                >
                  <Typography
                    font={!option.isUpgrade && option.label === selectedOption.label ? font.mediumBase_21 : font.normalBase}
                    innerContent={option.label}
                    {...fontStyle}
                    subtext={option.subtext}
                    cursor={option.isDisabled ? "not-allowed" : "pointer"}
                  />

                  {option.isUpgrade &&
                    <img src={`${STATIC_PATH}${option.icon || `vm-bg-star.svg`}`} alt="upgrade-icon" className="upgradeIcon" />
                  }
                  {option.subtext &&
                    (languageSubText ? (
                      <Subtext languageSubText={languageSubText}>({option.subtext})</Subtext>
                    ) : (
                      <Subtext>{option.subtext}</Subtext>
                    ))}
                </Option>
              ))}
            </OptionWrapper>
          </>
        )}
      </DropDown>
    </>
  );
};

LabelDropdown.defaultProps = {
  onChange: () => { },
  enableInputBox: false,
  enableAutoAlign: false,
};

LabelDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      subtext: PropTypes.string,
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  border: PropTypes.string,
  borderActive: PropTypes.string,
  optionWidth: PropTypes.string,
  right: PropTypes.string,
  fontStyle: PropTypes.object,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  enableInputBox: PropTypes.bool,
  labelEnable: PropTypes.bool,
  enableAutoAlign: PropTypes.bool,
  labelText: PropTypes.string,
  position: PropTypes.string,
  labelColor: PropTypes.string,
  labelFont: PropTypes.string,
  requiredStar: PropTypes.bool,
  labelMargin: PropTypes.string,
  isBottom: PropTypes.bool,
  isScroll: PropTypes.bool,
  borderRadius: PropTypes.string,
  optionHeight: PropTypes.string,
  radius: PropTypes.string,
  backdropWidth: PropTypes.string,
  backdropHeight: PropTypes.string,
  languageSubText: PropTypes.bool
};

export default LabelDropdown;
