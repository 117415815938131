import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ANIMO_RENDER, ITEM_CONFIG } from "../../constants/config";
import WorkspaceItem from "./workspace-item";
import {
  addTween,
  fontLoaded,
  prefetchComplete,
  selectFrameClip,
  setChildrenSelection,
  setInitStages,
  setPlayAll,
  setPropertyWindow,
  setSelectedItems,
  setTextOptions,
  setWorkspaceDragging,
  updateTextStatus,
} from "../../redux/actions/appUtils";
import WorkspaceBG from "./workspace-bg";
import { SceneContainer, WorkspaceContainer } from "./workspace-components";
import WorkspaceBGColor from "./workspace-bgcolor";
import WaterMark from "./watermark";
import { PLAN } from "../../constants";
import { showUpgrade } from "../../redux/actions/modalAction";

export class PreviewWorkspace extends Component {
  constructor(props) {
    super(props);

    this.getHoverClass = this.getHoverClass.bind(this);
    this.getWorkspaceItemList = this.getWorkspaceItemList.bind(this);
  }

  getHoverClass(item) {
    let result = "";
    const isChildSelected =
      item.get("type") === "GROUP" &&
      this.props.childrenSelection.get("itemId") === item.get("id") &&
      this.props.childrenSelection.get("childIds").size > 0;

    if (
      this.props.isLibraryItemDragging ||
      isChildSelected ||
      this.props.transformStatus.get("transforming")
    ) {
      return result;
    }

    if (
      item !== undefined &&
      (item.get("type") === "SHAPE" ||
        item.get("type") === "PROP" ||
        item.get("type") === "FRAME")
    )
      result = "ws-hover-prop";
    else if (item !== undefined && item.get("type") === "CHR")
      result = "ws-hover-chr";
    else if (item !== undefined && item.get("type") === "TEXT")
      result = "ws-hover-txt";
    else if (
      item !== undefined &&
      (item.get("type") === "STOCKIMG" ||
        item.get("subType") === "UPIMAGE" ||
        item.get("type") === "PIXABAY" ||
        item.get("type") === "PEXELS")
    )
      result = "ws-hover-img";
    else if (item !== undefined && item.get("type") === "GROUP")
      result = "ws-hover-group";
    else if (
      item !== undefined &&
      item.get("type") === "IMG" &&
      item.get("GIPHY") === undefined
    )
      result = "ws-hover-gif";

    if (!result) {
      result = "ws-hover-default";
    }

    return result;
  }

  getWorkspaceBGList() {
    const bgItems = [];

    this.props.previewProjectData.get("workspaceBG").forEach((bgItem) => {
      const isVisible =
        this.props.playhead >= bgItem.get("playStart") &&
        this.props.playhead <= bgItem.get("playEnd");
      if (this.props.isPlayAll || isVisible) {
        bgItems.push(
          <WorkspaceBG
            key={bgItem.get("id")}
            isVisible={isVisible}
            projectWidth={this.props.workspaceWidth}
            projectHeight={this.props.workspaceHeight}
            workspaceBG={bgItem}
            zoomFactor={this.props.zoomFactor}
            browserName={this.props.browserName}
            playhead={this.props.playhead}
          />
        );
      }
    });

    return bgItems;
  }

  getWorkspaceItemList() {
    const items = [];

    this.props.previewProjectData.get("workspaceItems").forEach((item) => {
      let isVisible =
        this.props.playhead >= item.get("enterStart") &&
        this.props.playhead <= item.get("exitEnd");
      isVisible = ANIMO_RENDER || isVisible;
      const isSelected = this.props.selectedItems.includes(item.get("id"));
      const renderAnyway = item.get("type") === "TEXT"; // warn: text apply all will fail if all text items are not mounted

      if (this.props.isPlayAll || isSelected || isVisible || renderAnyway) {
        let groupChildren = null;
        const isClickable = isVisible;

        if (item.get("type") === "GROUP" && item.get("groupChildren")) {
          groupChildren = item.get("groupChildren").map((childId) => {
            const childData = this.props.previewProjectData.getIn([
              "workspaceChildren",
              childId,
            ]);

            let pointerEvents;
            if (!isClickable) {
              pointerEvents = "none";
            }

            if (childData) {
              return (
                <WorkspaceItem
                  key={childId}
                  className={this.getHoverClass(childData)}
                  projectWidth={this.props.workspaceWidth}
                  projectHeight={this.props.workspaceHeight}
                  id={childId}
                  item={childData}
                  pointerEvents={pointerEvents}
                  groupId={item.get("id")}
                  groupItem={item}
                  browserName={this.props.browserName}
                  isVisible={isVisible}
                  zoomFactor={this.props.zoomFactor}
                  selectedItems={this.props.selectedItems}
                  isPlayAll={this.props.isPlayAll}
                  setPlayAll={this.props.setPlayAll}
                  addTween={this.props.addTween}
                  swapDetails={this.props.swapDetails}
                  prefetchToken={this.props.prefetchToken}
                  prefetchComplete={this.props.prefetchComplete}
                  isMouseOnSelectionBox={this.isMouseOnSelectionBox}
                />
              );
            }
            return undefined;
          });
        }

        let opacity;
        let pointerEvents;
        if (
          this.props.transformStatus.get("moving") &&
          this.props.selectedItems.size === 1 &&
          this.props.selectedItems.get(0) === item.get("id")
        ) {
          pointerEvents = "none";
          if (
            this.props.swapHoverDrop.get("hovering") &&
            this.props.swapHoverDrop.get("sourceType") ===
              ITEM_CONFIG.SWAP_SOURCE.WORKSPACE &&
            this.props.swapHoverDrop.get("targetType") ===
              ITEM_CONFIG.SWAP_TARGETS.FRAME_CLIP
          ) {
            const objectType = "FRAME";
   
            opacity = ITEM_CONFIG[objectType].DROP_OPACITY;
          }
        }

        if (this.props.isCropping && isSelected) {
          opacity = 0;
          pointerEvents = "none";
        }

        if (!isClickable) {
          pointerEvents = "none";
        }

        opacity = ANIMO_RENDER ? 0 : opacity;
        items.push(
          <WorkspaceItem
            key={item.get("id")}
            className={this.getHoverClass(item)}
            browserName={this.props.browserName}
            id={item.get("id")}
            isVisible={isVisible}
            item={item}
            opacity={opacity}
            pointerEvents={pointerEvents}
            projectWidth={this.props.workspaceWidth}
            projectHeight={this.props.workspaceHeight}
            zoomFactor={this.props.zoomFactor}
            selectedItems={this.props.selectedItems}
            isPlayAll={this.props.isPlayAll}
            setPlayAll={this.props.setPlayAll}
            addTween={this.props.addTween}
            swapDetails={this.props.swapDetails}
            prefetchToken={this.props.prefetchToken}
            prefetchComplete={this.props.prefetchComplete}
            isMouseOnSelectionBox={this.isMouseOnSelectionBox}
            libraryDragItem={this.props.libraryDragItem}
          >
            {groupChildren}
          </WorkspaceItem>
        );
      }
    });

    return items;
  }

  render() {
    const {userDetails} = this.props;
    const bgItems = this.getWorkspaceBGList();
    const workspaceItems = this.getWorkspaceItemList();

    const watermark =
    (userDetails.plan === PLAN.STARTER || userDetails.plan === PLAN.LITE) &&
    !ANIMO_RENDER ? (
      <WaterMark
        isPlayAll={this.props.isPlayAll}
        workspaceWidth={this.props.workspaceWidth}
        workspaceHeight={this.props.workspaceHeight}
        zoomFactor={this.props.zoomFactor}
        showUpgrade={this.props.showUpgrade}
      />
    ) : null;

    const workspace = (
      <WorkspaceContainer
        // ref={this.workspaceRef}
        id="workspace"
        style={this.props.workspaceContainerStyles}
      >
        <SceneContainer style={this.props.sceneStyles}>
          <WorkspaceBGColor
            bgColor={this.props.previewProjectData.get("bgColor")}
          />
          {bgItems}
          {workspaceItems}
          {watermark}
        </SceneContainer>
      </WorkspaceContainer>
    );
    return workspace;
  }
}

PreviewWorkspace.propTypes = {
  workspaceWidth: PropTypes.number,
  workspaceHeight: PropTypes.number,
  previewProjectData: PropTypes.object,
  zoomFactor: PropTypes.number,
  playhead: PropTypes.number,
  isPlayAll: PropTypes.bool,
  browserName: PropTypes.string,
  selectedItems: PropTypes.object,
  prefetchToken: PropTypes.string,
  setPlayAll: PropTypes.func,
  prefetchComplete: PropTypes.func,
  showUpgrade: PropTypes.func,
  addTween: PropTypes.func,
  swapDetails: PropTypes.object,
  childrenSelection: PropTypes.object,
  transformStatus: PropTypes.object,
  isLibraryItemDragging: PropTypes.bool,
  libraryDragItem: PropTypes.object,
  swapHoverDrop: PropTypes.object,
  isCropping: PropTypes.bool,
  sceneStyles: PropTypes.object,
  workspaceContainerStyles: PropTypes.object,
  userDetails: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userDetails: state.userDetails,
  isFit: state.app.get("isFit"),
  playhead: state.app.get("playhead"),
  isPlayAll: state.app.get("isPlayAll"),
  isFraming: state.app.get("isFraming"),
  zoomFactor: state.app.get("zoomFactor"),
  initStages: state.app.get("initStages"),
  textStatus: state.app.get("textStatus"),
  isCropping: state.app.get("isCropping"),
  isPlayerLoaded: state.app.get("isLoaded"),
  browserName: state.app.get("browserName"),
  loadedFonts: state.app.get("loadedFonts"),
  swapDetails: state.app.get("swapDetails"),
  prefetchCount: state.app.get("prefetchCount"),
  prefetchToken: state.app.get("prefetchToken"),
  selectedItems: state.app.get("selectedItems"),
  swapHoverDrop: state.app.get("swapHoverDrop"),
  selectedAudios: state.app.get("selectedAudios"),
  transformStatus: state.app.get("transformStatus"),
  workspaceBounds: state.app.get("workspaceBounds"),
  libraryDragItem: state.app.get("libraryDragItem"),
  hasAllFontsLoaded: state.app.get("hasAllFontsLoaded"),
  childrenSelection: state.app.get("childrenSelection"),
  selectedFrameClip: state.app.get("selectedFrameClip"),
  versionHistoryData: state.app.get("versionHistoryData"),
  previewProjectData: state.app.get("previewProjectData"),
  workspaceWidth: state.app.getIn(["previewProjectData", "width"]),
  workspaceHeight: state.app.getIn(["previewProjectData", "height"]),
  prefetchCompleteCount: state.app.get("prefetchCompleteCount"),
  isLibraryItemDragging: Boolean(state.app.getIn(["libraryDragItem", "data"])),
});

const mapDispatchToProps = (dispatch) => ({
  setPlayAll: (data) => dispatch(setPlayAll(data)),
  showUpgrade: (data) => dispatch(showUpgrade(data)),
  fontLoaded: (payload) => dispatch(fontLoaded(payload)),
  setTextOptions: (data) => dispatch(setTextOptions(data)),
  selectFrameClip: (data) => dispatch(selectFrameClip(data)),
  setSelectedItems: (data) => dispatch(setSelectedItems(data)),
  setInitStages: (payload) => dispatch(setInitStages(payload)),
  updateTextStatus: (data) => dispatch(updateTextStatus(data)),
  setPropertyWindow: (data) => dispatch(setPropertyWindow(data)),
  addTween: (data, tweenType) => dispatch(addTween(data, tweenType)),
  setChildrenSelection: (data) => dispatch(setChildrenSelection(data)),
  setWorkspaceDragging: (payload) => dispatch(setWorkspaceDragging(payload)),
  prefetchComplete: (token, count) => dispatch(prefetchComplete(token, count)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewWorkspace);
