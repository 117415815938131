/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "../../common-components/Typography";
import { Modal } from "../../common-components/modal";
import content from "../../constants/content";
import { font } from "../../constants/font";
import PlanCard from "./planCard";
import ProfessionalForm from "./formdata";
import openUrl from "../../helper/openUrl";
import {
  Body,
  Container,
  Header,
  PricingCard,
} from "./upgrade-components";
import {
  PLAN,
  PLAN_TYPES,
  PLAN_CONTENT,
  PLAN_PRISING_RES,
} from "../../constants";
import AppConfig, { API_URL, PAGE, CNAME } from "../../constants/config";
import { isEnterPrise, isPremiumUser, isTierPlan } from "../../helper/checkUser";
import useUpgrade from "../../helper/hooks/useUpgrade";
import ActionTypes from "../../constants/action-types";
import useApi from "../../helper/hooks/useApi";
import vmTheme from "../../constants/theme";
import ToggleTab from "../../common-components/ToggleTab";

const filterData = (userPlan, modalType) => {
  const planOrder = [PLAN.LITE, PLAN.STARTER, PLAN.TEAM, PLAN.ENTERPRISE];
  const teamLikePlans = isTierPlan(userPlan)
  const effectivePlan = teamLikePlans ? PLAN.TEAM : userPlan;
  const userPlanIndex = planOrder.indexOf(effectivePlan);

  if (userPlan === PLAN.ENTERPRISE) {
    return [];
  }

  switch (modalType) {
    case "poly":
      return PLAN_TYPES.filter((e) => e.plan === PLAN.TEAM || e.plan === PLAN.ENTERPRISE);
    case "pipio":
      return PLAN_TYPES.filter((e) => e.plan === PLAN.STARTER || e.plan === PLAN.TEAM || e.plan === PLAN.ENTERPRISE);
    default:
      return PLAN_TYPES.filter((e) => planOrder.indexOf(e.plan) > userPlanIndex);
  }
};

const Upgrade = () => {
  const [showEnterprise, setShowEnterprise] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [planCost, setPlanCost] = useState(PLAN_PRISING_RES);
  const { plan } = useSelector((store) => store.userDetails);
  const dispatch = useDispatch();
  const { get } = useApi();

  const { user_details } = useSelector((state) => state.userDetails);
  const {
    show,
    headerText,
    subText,
    customForm = null,
    upgradesource,
    type = "default",
    isAvatarUpgrade,
    formContent,
    modalType
  } = useSelector((state) => state.app.get("upgrade").toJS());
  const [activeTab, setActiveTab] = useState(1);
  const tabs = [{ label: "monthly" }, { label: "yearly" }];
  const { hideUpgrade } = useUpgrade();
  const theme = useSelector((state) => state.app.get("theme"));

  const upgradeHandler = (e, planName, activeTab) => {
    const planCycle = activeTab === 0 ? 'monthly' : 'yearly';
    e && e.preventDefault() && e.stopPropagation();
    const source = upgradesource;
    switch (planName) {
      case PLAN.STARTER:
        openUrl(
          `${API_URL}${PAGE.MY_PLAN}?planname=${PLAN.STARTER}&plancycle=${planCycle}&upgradesource=${source}`
        );
        break;
      case PLAN.TEAM:
        openUrl(
          `${API_URL}${PAGE.MY_PLAN}?planname=${PLAN.TEAM}&plancycle=${planCycle}&upgradesource=${source}`
        );
        break;
      default:
        if (isAvatarUpgrade) {
          hideUpgrade();
          dispatch({
            type: ActionTypes.SHOW_AVATAR_FORM,
            payload: true,
          });
        }
        else if (isEnterPrise(planName)) {
          setShowEnterprise(true);
        }
        else {
          openUrl(`${API_URL}${PAGE.MY_PLAN}`);
        }
        break;
    }
  }

  useEffect(() => {
    get(`${API_URL}${AppConfig.API.PLAN_PRISING}`, {}, false).then((res) => {
      setPlanCost(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customForm) {
      switch (customForm) {
        case "SUPPORT_FORM":
          hideUpgrade();
          dispatch({
            type: ActionTypes.SHOW_SUPPORT_FORM,
            payload: true,
          });
          break;
        case "ENTER_PRISE_FORM":
          setShowEnterprise(true);
          break;
        default:
          break;
      }
    } else if (plan && show) {
      if (isEnterPrise(plan)) {
        hideUpgrade();
        dispatch({
          type: ActionTypes.SHOW_SUPPORT_FORM,
          payload: true,
        });
      } else if (modalType === "elevenlabs" || modalType === "custom-avatar") {
        setShowEnterprise(true);
      } else if (isPremiumUser(plan) && isAvatarUpgrade) {
        hideUpgrade();
        dispatch({
          type: ActionTypes.SHOW_AVATAR_FORM,
          payload: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, show, customForm, isAvatarUpgrade]);

  useEffect(() => {
    const data = filterData(plan, modalType);
    setPlanData(data);
  }, [plan, modalType]);

  const handleClose = () => {
    setShowEnterprise(false);
    hideUpgrade();
  };
  return (
    <PricingCard>
      <Modal
        width="fit-content"
        height="auto"
        showModal={show}
        onClose={handleClose}
        closeWidth="24"
        closeHeight="24px"
        closeTop="22px"
        closeRight="25px"
        closeIcon="vm-close.svg"
        className="upgrade-modal"
        borderRadius="16px"
      >
        {!showEnterprise ? (
          <Container>
            <Header>
              <Typography
                content={headerText || content.CHOOSE_PLAN}
                color={vmTheme[theme].tabTextColor}
                font={font.bold_20_16}
                padding="2px 0px"
                enableTrim={false}
              />
              <ToggleTab
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={(newTab) => setActiveTab(newTab)}
              />
            </Header>
            {subText && (
              <Typography
                content={subText}
                color={"#313345"}
                font={font.normalBase_19}
                padding="8px 0px"
                enableTrim={false}
              />
            )}
            <Body>
              {planData.length > 0
                ? planData.map((data) => {
                  return (
                    <PlanCard
                      key={data.name}
                      data={data}
                      planName={data.name}
                      titleSubtext={data.titleSubtext}
                      iconSrc={data.icon}
                      planContent={PLAN_CONTENT[data.plan]?.[type]}
                      action={data.action}
                      onClick={(e) => upgradeHandler(e, data.plan, activeTab)}
                      actionBgColor={data.actionBgColor}
                      enterpriseContent={type === 'avatarVoice' ? data.avatarDescription : data.description}
                      className={type === 'avatarVoice' ? 'avatarUpgrade' : ''}
                      monthlyPrice={activeTab === 0 ? (planCost[data.plan]?.monthly ?? 0) : ((planCost[data.plan]?.yearly ?? 0) / 12)}
                      yearlyPrice={activeTab === 1 ? (planCost[data.plan]?.monthly ?? 0) : ((planCost[data.plan]?.yearly ?? 0) / 12)}
                      billingCycle={activeTab}
                    />
                  )
                })
                : plan !== PLAN.ENTERPRISE && <p>No available upgrades</p>}
            </Body>
            <Typography
              content={content.COMPARE_PLANS}
              color={"#313345"}
              font={font.normalBase_19}
              display={"block"}
              align={"center"}
              margin={"18px 0px 0px"}
              cursor={"pointer"}
              textStyles={{ textDecoration: "underline" }}
              padding="8px 0px"
              href={`https://www.${CNAME}/pricing`}
              target="_blank"
              isAnchor
            />
          </Container>
        ) : (
          <ProfessionalForm
            selectedValue={user_details}
            onClose={handleClose}
            formContent={formContent}
          />
        )}
      </Modal>
    </PricingCard>
  );
};


export default Upgrade;
