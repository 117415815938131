import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Error, FormDataHeader, FormSection, TwoCol } from "./upgrade-components";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";
import LabelDropdown from "../../common-components/LabelDropdown";
import Action from "../../common-components/Action";
import LabeledInput from "../../common-components/LabeledInput";
import { supportForm } from "../../redux/actions/appUtils";
import content from "../../constants/content";
import vmTheme from "../../constants/theme";
import { Modal } from "../../common-components/modal";
import ActionTypes from "../../constants/action-types";
import { BUSSINESS_DOMAINS } from "../../constants";

const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid
    ${(props) =>
    props.hasAttachment
      ? props.theme.successColor
      : props.theme.shortsBorderColor};
  border-radius: 4px;
  background-color: ${(props) => props.theme.btnBgLightColor};
  cursor: pointer;
  width: fit-content;
  position: relative;
  input[type="file"] {
    display: none;
  }
`;

const AttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;

const AttachmentFile = styled.div`
  position: relative;
`;

const AttachmentLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font: ${font.normalBase_16};
  color: ${(props) => props.theme.panelPrimaryColor};
  position: relative;
  span {
    margin-left: 8px;
  }
`;

const CloseIcon = styled.span`
  position: absolute;
  top: 3px;
  right: auto;
  cursor: pointer;
`;

const FileName = styled.span`
  margin: 10px;
  font: ${font.normalBase_16};
`;

const SupportForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    companyName: "",
    subject: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    companyName: "",
    subject: "",
    message: "",
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(true);
  const [category, setCategory] = useState("Support");
  const [attachment, setAttachment] = useState(null);
  const [attachmentFileName, setAttachmentFileName] = useState("");
  const [userFile, setUserFile] = useState(null);
  const theme = useSelector((state) => state.app.get("theme"));
  const showSupportForm = useSelector((state) =>
    state.app.get("showSupportForm")
  );
  const handleDropdownChanges = (selectedValue) => {
    setCategory(selectedValue);
  };

  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch({
      type: ActionTypes.SHOW_SUPPORT_FORM,
      payload: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      [name]: value,
    }));
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setAttachment(file);
    setUserFile(file);
    setAttachmentFileName(file.name);
  };

  const handleRemoveAttachment = () => {
    setAttachment(null);
    setAttachmentFileName("");
    setUserFile(null);
  };
  const isBusinessEmail = (email) =>
    BUSSINESS_DOMAINS.includes(email.split("@")[1]);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formValue.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formValue.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValue.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    } else if (isBusinessEmail(formValue.email)) {
      errors.email = "Please enter a valid business email.";
      isValid = false;
    }

    if (!formValue.companyName) {
      errors.companyName = "Company Name is required";
      isValid = false;
    }
    if (!formValue.subject) {
      errors.subject = "Subject is required";
      isValid = false;
    }
    if (!formValue.message) {
      errors.message = "Message is required"; // Added validation for message
      isValid = false;
    }
  
    setFormErrors(errors);
    return isValid;
  };

  const submitHandler = async () => {
    if (validateForm()) {
      setLoading(true);

      try {
        const reqBody = {
          name: formValue.name,
          email: formValue.email,
          company: formValue.companyName,
          subject: formValue.subject,
          message: formValue.message,
          category,
          attachment_status: !!attachment,
        };

        if (userFile) {
          reqBody.userFile = userFile;
        }

        const response = await supportForm(reqBody);

        setLoading(false);
        setMessage(response.message);
        setError(response.error);
        setSuccess(response.error);
        if (!response.error) {
          setFormValue({
            name: "",
            email: "",
            companyName: "",
            subject: "",
            message: "",
          });
          setAttachment(null);
          setAttachmentFileName("");
          setUserFile(null);
        }
      } catch (error) {
        setLoading(false);
        setError(true);
        setSuccess(false);
        setMessage("Something went wrong. Please try again later.");
      }
    }
  };

  return (
    <Modal width="auto" showModal={showSupportForm} onClose={onClose}>
      <FormDataHeader padding="24px 0px 0px">
        <Typography
          content={content.SUPPORT_MAIN_TEXT}
          color={vmTheme[theme].panelPrimaryColor}
          font={font.bold_20_16}
          display="block"
          align="center"
          padding="5px 0"
          margin="0px 0"
          enableTrim={false}
        />
        <Typography
          content={content.SUPPORT_SUBTEXT}
          color={vmTheme[theme].panelPrimaryColor}
          font={font.normal14_400}
          display="block"
          align="center"
          padding="5px 0"
          enableTrim={false}
          margin="0px 0"
        />
        <FormSection className="form-data-tool-bar">
          <LabeledInput
            label="Name"
            value={formValue.name}
            onChange={handleChange}
            type="text"
            name="name"
            required
          />
          {formErrors.name && <Error>{formErrors.name}</Error>}
          <LabeledInput
            label="Company Email"
            value={formValue.email}
            onChange={handleChange}
            type="email"
            name="email"
            required
          />
          {formErrors.email && <Error>{formErrors.email}</Error>}
          <TwoCol>
            <div>
              <LabeledInput
                label="Organisation"
                value={formValue.companyName}
                onChange={handleChange}
                type="text"
                name="companyName"
                required
              />
              {formErrors.companyName && <Error>{formErrors.companyName}</Error>}
            </div>
            <div style={{ marginTop: "13px" }}>
              <LabelDropdown
                className="inputDropdown"
                width="100%"
                labelText="Category"
                labelEnable
                height="38px"
                border={`1px solid ${vmTheme[theme].shortsBorderColor}`}
                optionWidth="100%"
                options={[
                  { label: "Support", value: "Support" },
                  { label: "Feedback", value: "Feedback" },
                  { label: "Sales", value: "Sales" },
                  { label: "PR", value: "PR" },
                  { label: "Enquiry", value: "Enquiry" },
                ]}
                value={category}
                requiredStar={false}
                onChange={handleDropdownChanges}
              />
            </div>
          </TwoCol>
          <LabeledInput
            label="Subject"
            value={formValue.subject}
            onChange={handleChange}
            type="text"
            name="subject"
          />
          {formErrors.subject && <Error>{formErrors.subject}</Error>}
          <LabeledInput
            label="Message"
            value={formValue.message}
            onChange={handleChange}
            name="message"
            className="message-input"
            type="text"
          />
          {formErrors.message && <Error>{formErrors.message}</Error>}
          <AttachmentWrapper>
            <AttachmentContainer hasAttachment={!!attachment}>
              <input type="file" id="file-input" onChange={handleFileChange} />
              <AttachmentLabel htmlFor="file-input">
                Attachments
              </AttachmentLabel>
            </AttachmentContainer>
            <AttachmentFile>
              {attachmentFileName && <FileName>{attachmentFileName}</FileName>}
              {attachmentFileName && (
                <CloseIcon onClick={handleRemoveAttachment}>✕</CloseIcon>
              )}
            </AttachmentFile>
          </AttachmentWrapper>
          <Action
            text={content.SUBMIT}
            background={vmTheme[theme].subtitleSubTextColor}
            hoverColor={vmTheme[theme].subtitleSubTextColor}
            color={"#ffffff"}
            type="submit"
            onClick={submitHandler}
            isLoading={isLoading}
          />
          {!success && (
            <>
              <Typography
                content={content.SALES_QUERY}
                align={"center"}
                padding={"6px 0px"}
                display={"block"}
                color={vmTheme[theme].successColor}
              />
              <Typography
                content={content.THANKS_FOR_REACHING}
                align={"center"}
                padding={"6px 0px"}
                display={"block"}
                color={vmTheme[theme].successColor}
                enableTrim={false}
              />
            </>
          )}
          <Error>{error && message}</Error>
        </FormSection>
      </FormDataHeader>
    </Modal>
  );
};

export default SupportForm;
