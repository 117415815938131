import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../common-components/modal";
import Typography from "../../../common-components/Typography";
import content from "../../../constants/content";
import { font } from "../../../constants/font";
import { UploadMain, UploadWrapper, customStyles } from "./upload-components";
import DragUpload from "./dragUpload";
import Library from "./library";
import TabToggleComponent from "../../../common-components/TabToggleComponent";
import { setUploadModal } from "../../../redux/actions/appUtils";

const UPLOAD_AUDIOS = "Upload Audios";
const LIBRARY = "Library"
const tabs = [{ label: UPLOAD_AUDIOS }, { label: LIBRARY }];

const UploadModal = () => {
    const dispatch = useDispatch();
    const appReducer = useSelector((state) => state.app);
    const { show } = appReducer.get("upload").toJS();
    const enableFooter = appReducer.isEnableFooter;
    const uploadData = appReducer.get("upload").toJS();

    const [, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState(UPLOAD_AUDIOS);


    const onClose = useCallback((e, isLoad = false) => {
        dispatch(setUploadModal({ show: false }));
        setLoading(isLoad);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const toggleTab = (tab) => setCurrentTab(tab);

    return (
         <UploadMain enableFooter={enableFooter}  style={{
            visibility: uploadData?.assetData?.length > 0 ? "visible" : "hidden"
        }}>
            <Modal
                width="73.055vw"
                height="580px"
                showModal={show}
                onClose={onClose}
                className="upload--modal"
                isRender={true}
            >
                <UploadWrapper>
                    <Typography
                        content={content.UPLOAD_IMPORT_AUDIOS}
                        font={font.bold_14}
                        display={"block"}
                        color="#313345"
                        padding="12px 24px 20px"
                    />
                    <TabToggleComponent
                        tabs={tabs}
                        currentTab={currentTab}
                        setCurrentTab={toggleTab}
                        customStyles={customStyles}
                    />
                    {currentTab === UPLOAD_AUDIOS && <DragUpload />}
                    {currentTab === LIBRARY && <Library />}
                </UploadWrapper>
            </Modal>
        </UploadMain>
    );
};

export default UploadModal;
