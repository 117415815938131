import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Column, Container, ImageSection, Scroll } from "../../panel-components";
import Typography from "../../../../common-components/Typography";
import content from "../../../../constants/content";
import { font } from "../../../../constants/font";
import vmTheme from "../../../../constants/theme";
import Tile from "../../../../common-components/Tile";
import { getViralStyleDataById, getViralStyles, setBackdropLoader } from "../../../../redux/actions/appUtils";
import { updateTimelineTime } from "../../../../redux/actions/timelineUtils";
import { getSampleSubtitleText, getUpdatedSubTitleList, commonStyle } from "../../../../helper/subtitleHelper";
import { checkFontFamilyLoadedPromise, getSubtitlesFontToLoad, loadSubtitleFontFamily } from "../../../../helper/fontLoadHelper";
import useNotify from "../../../../helper/hooks/useNotify";
import usePanelScrollHeight from "../../../../helper/hooks/useScrollHeight";

const { NO_ASSETS_FOUND, APPLY_VIRAL_FAILED } = content;

const ImgColumn = styled.div`
    margin-bottom: 5px;
    display: inline-block;
    &:hover {
        label {
            font-weight: 600;
        }
    }
`;

const ViralStyle = ({ workspaceStage }) => {
    const theme = useSelector((state) => state.app.get("theme"));
    const { dimensionName } = useSelector((state) => state.userDetails);
    const dispatch = useDispatch();
    const { warn } = useNotify();
    const filteredViralStyles = useSelector((state) => state.library.dataSource.viralStyles);
    const projectDetails = useSelector((state) => state.projectDetails);
    const appReducer = useSelector((state) => state.app);
    const subtitleData = projectDetails.getIn(["subtitle", "data"]);
    const globalStyles = projectDetails.getIn(["subtitle", "textStyles"]);
    const globalTextEffects = projectDetails.getIn(["subtitle", "textEffects"]);
    const workspaceWidth = projectDetails.get("width");
    const workspaceHeight = projectDetails.get("height");
    const loadedFonts = appReducer.getIn(["loadedFonts", "fonts"]);

    const loadFont = async (toLoadFont) => {
        let fontToLoad = getSubtitlesFontToLoad({}, loadedFonts, toLoadFont);
        const sampleSubtitleText = getSampleSubtitleText(projectDetails.getIn(["subtitle", "data"]).toJS());
        if (fontToLoad.length) {
            fontToLoad = await loadSubtitleFontFamily(fontToLoad, sampleSubtitleText);
        } else {
            await checkFontFamilyLoadedPromise(toLoadFont.fontFamily, false, sampleSubtitleText);
        }
    };

    const handleStyleData = async (data) => {
        const { subtitleId } = data.data;
        const stylesdata = data.data;
        const toUpdate = [];
        const {
            style: updateStyle,
            animData: updateAnimData,
            textEffects: updateTextEffects,
            position: updatePosition,
        } = stylesdata[subtitleId];
        const toLoadFont = {
            fontFamily: updateStyle.fontFamily,
            fontFamilyName: updateStyle.fontFamilyName,
            lineHeight: updateStyle.lineHeight,
            others: { isUserFont: false, cssUrl: "" },
        };

        // To merge the viral style with the default styles
        const textStyles = {
            ...commonStyle,
            ...updateStyle
        }

        await loadFont(toLoadFont);

        const updatedDataList = await getUpdatedSubTitleList(
            subtitleData,
            textStyles,
            workspaceWidth,
            workspaceHeight,
            globalStyles,
            globalTextEffects,
            false,
            updatePosition
        );

        Object.entries(updatedDataList).forEach(([dropId, data]) => {
            Object.entries(data).forEach(([id, { toUpdatePos }]) => {
                const xVal = workspaceWidth / 2;
                const width = toUpdatePos.width / 2;
                toUpdatePos.x = xVal - width;
                toUpdate.push({
                    container: "subtitleData",
                    id,
                    dropId,
                    langId: projectDetails.get("defaultSubtitle"),
                    toUpdate: toUpdatePos,
                });
            });
        });

        toUpdate.push({
            container: "subtitleGlobal",
            langId: projectDetails.get("defaultSubtitle"),
            toUpdate: {
                textStyles,
                animData: updateAnimData,
                textEffects: updateTextEffects,
            },
        });
        dispatch(updateTimelineTime({ toUpdate }));
        dispatch(setBackdropLoader({ isLoading: false, loaderMessage: "" }));
    };

    const getStyleData = (style, styleName) => {
        dispatch(
            setBackdropLoader({
                isLoading: true,
                loaderMessage: `Applying ${styleName} style...`,
            })
        );
        dispatch(getViralStyleDataById(style)).then((res) => {
            handleStyleData(res, style);
        }).catch(() => {
            dispatch(setBackdropLoader({ isLoading: false, loaderMessage: "" }));
            warn(APPLY_VIRAL_FAILED);
        });
    };

    useEffect(() => {
        dispatch(getViralStyles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const panelRef = useRef(null);
    const mainScrollableHeight = usePanelScrollHeight({
        panelRef,
        workspaceStage,
        shouldUpdate: true,
    });

    return (
        <Container>
            <Scroll width="100%" ref={panelRef} height={mainScrollableHeight}>
                <ImageSection width="auto">
                    <Column columnCount="3" style={{ padding: "0px 16px" }} className={filteredViralStyles && filteredViralStyles.length > 0 && dimensionName === 'vertical' ? "verticalColumn" : ""}>
                        {filteredViralStyles && filteredViralStyles.length > 0 ? (
                            filteredViralStyles.map((style) => (
                                <ImgColumn key={style._source.id} onClick={() => getStyleData(style._source.id, style._source.name)}>
                                    <Tile className={style._source.dimension} src={style._source.thumbnail} alt="" style={{
                                        borderRadius: "8px",
                                        ...(style._source.dimension === "ver" && { width: "90%", height: "80%", margin: "0px 3px" }),
                                        ...(style._source.dimension === "sqr" && { width: "90%", height: "90%" }),
                                        ...(style._source.dimension === "hor" && { width: "175px", height: "108px" }),
                                    }} />
                                    <Typography
                                        content={style._source.name}
                                        color={vmTheme[theme].tabTextColor}
                                        font={font.normalMicroLarge}
                                        height="19px"
                                        display="initial"
                                        align="center"
                                        maxWidth="100px"
                                        enableTrim={false}
                                    />
                                </ImgColumn>
                            ))
                        ) : (
                            <Typography
                                content={NO_ASSETS_FOUND}
                                color={vmTheme[theme].noAssetTextColor}
                                font={font.largeMiniBold_14}
                                display="block"
                                align="center"
                                float="left"
                                margin="50px auto 10px 40px"
                            />
                        )}
                    </Column>
                </ImageSection>
            </Scroll>
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getViralStyle: bindActionCreators(getViralStyles, dispatch),
        getViralStyleData: bindActionCreators(getViralStyleDataById, dispatch),
    };
};

const mapStateToProps = ({ app }) => {
    return {
        workspaceStage: app.get("workspaceStage"),
    };
}
ViralStyle.propTypes = {
    workspaceStage: PropTypes.object,
}
export default connect(mapStateToProps, mapDispatchToProps)(ViralStyle);