export default (file) => {
    return new Promise((resolve, reject) => {
        const audio = document.createElement('audio');
        const objectURL = URL.createObjectURL(file);
        audio.src = objectURL;
        audio.onloadedmetadata = () => {
            const durationInSeconds = audio.duration;
            URL.revokeObjectURL(objectURL);
            resolve(durationInSeconds);
        };

        audio.onerror = (error) => {
            console.error(error)
            URL.revokeObjectURL(objectURL);
            reject(new Error('Failed to load audio file'));
        };
    });
};
