import { useDispatch } from "react-redux";
import { setModalProps } from "../../redux/actions/modalAction";

const useModal = () => {

    const dispatch = useDispatch();

    const showModal = (args) => {
        dispatch(
            setModalProps({
                isShowModal: true,
                ...args
            })
        )
    }

    const hideModal = () => {
        dispatch(
            setModalProps({
                isShowModal: false,
                width: null
            })
        )
    }

    return {
        showModal,
        hideModal
    }

}

export default useModal;