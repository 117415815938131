import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { STATIC_PATH } from "../../../constants/config";
import {
  LayerWrapper,
  Image,
  TransitionLayerContainer,
} from "./timeline-track-components";
import content from "../../../constants/content";
import SwapDropArea from "../../workspace/workspace-swaphoverdrop";
import {
  setDraggedTransition,
  setPropertyPanel,
  setSelectedItems,
} from "../../../redux/actions/appUtils";
import { updateTimelineTime } from "../../../redux/actions/timelineUtils";
import ContextMenu from "../../../common-components/ContextMenu";
import useNotify from "../../../helper/hooks/useNotify";
import { transitionTime } from "../timeline-constants";
import { isVideoOnly } from "../timeline-helper";

const TransitionLayer = ({
  x,
  y,
  prevItem,
  nextItem,
  nextWidth,
  prevWidth,
  shortcutName,
}) => {
  const dispatch = useDispatch();
  const { success } = useNotify();
  const contextRef = useRef(null);
  const [srcf, setSrc] = useState("");
  const [isTransitionOn, setTransitionOn] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState({
    prevId: "",
    nextId: "",
    isDragged: false,
    effect: "",
  });
  const [contextMenu, setContextMenu] = useState({
    isContextOpen: true,
    contextMenuPosition: { x: 0, y: 0, eventType: "" },
  });

  const appReducer = useSelector((state) => state.app);
  const projectDetails = useSelector((state) => state.projectDetails);
  const workspaceItem = projectDetails.get("workspaceItems");
  const transitionEnterEffect = workspaceItem.getIn([prevItem, "transitionEnterEffect"]);
  const transitionEnterId = workspaceItem.getIn([nextItem, "transitionEnterId"]);
  const prevItemType = workspaceItem.getIn([prevItem, "type"]);
  const prevItemSubType = workspaceItem.getIn([prevItem, "subType"]);
  const nextItemType = workspaceItem.getIn([nextItem, "type"]);
  const nextItemSubType = workspaceItem.getIn([nextItem, "subType"]);
  const selectedTransitionItem = appReducer.get("selectedTransitionItems");
  const draggedLayerData = appReducer.get("draggedTransitionData");
  const sliderSelectedItems = appReducer.get("selectedItems");
  const selectedPanel = appReducer.get("propertyPanel");
  const libraryDrag = appReducer.get("libraryDragItem");

  const isDraging = libraryDrag.getIn(["data", "type"]) === "TRANSITIONS";
  const preWidthSize = prevWidth > 50 ? 30 : prevWidth / 2;
  const nextWidthSize = nextWidth > 50 ? 30 : nextWidth / 2;
  const wrapperWidth = nextWidthSize + preWidthSize;

  const isPanelOpen =
    selectedPanel.get("selectedPanel") === content.TRANSITIONS &&
    selectedPanel.get("isExpand");
  const transitionApplied =
    transitionEnterEffect !== "none_transition" &&
    transitionEnterEffect !== undefined;

  const isTransitionEligible =
    (prevWidth > 17 && nextWidth > 17) || transitionApplied;
  const isItemMoreThanOneSec = prevWidth > 17 && nextWidth > 17

  const dispatchHanlder = (prevId, nextId, effectName) => {
    if (prevId && nextId && effectName) {
      dispatch(updateTimelineTime({
        toUpdate: [
          {
            container: "workspaceItems",
            id: prevId,
            toUpdate: {
              transitionEnterEffect: effectName,
              transitionEnterId: nextId,
              transitionDuration: transitionTime,
            },
          },
          {
            container: "workspaceItems",
            id: nextId,
            toUpdate: {
              transitionExitEffect: effectName,
              isTransition: effectName !== "none_transition",
              transitionExitId: prevId,
              transitionDuration: transitionTime,
            },
          },
        ],
      }));
    }
  };

  const closeContextMenus = useCallback(() => {
    setContextMenu({
      isContextOpen: false,
    });
  }, [contextMenu]);

  const handleShortcuts = (shortcutKey, prevItem, nextItem) => {
    switch (shortcutKey) {
      case "DELETE_ITEM":
      case "DELETE_ITEM_MAC":
        if (isTransitionOn && transitionEnterEffect !== "none_transition") {
          dispatchHanlder(prevItem, nextItem, "none_transition");
          success(content.TRANSITION_REMOVED);
        }
        break;
      default:
        break;
    }
  };

  const menuClickHandler = () => {
    dispatchHanlder(prevItem, nextItem, "none_transition");
    closeContextMenus();
    success(content.TRANSITION_REMOVED);
  };

  const menuOptions = [
    {
      title: "Delete",
      actionName: "DELETE",
      clickHandler: menuClickHandler,
    },
  ];

  const onClick = (prevItemId, nextItemId) => {
    if (!transitionEnterEffect) {
      dispatchHanlder(prevItemId, nextItemId, "none_transition");
    }
    const context = {
      prevId: prevItemId,
      nextId: nextItemId,
      isSelected: true,
    };
    dispatch(setPropertyPanel(content.TRANSITIONS, true, context));
    setTransitionOn(true);
    dispatch(setSelectedItems([]));
  };

  const handleContextMenu = useCallback(
    (e, effect) => {
      e.preventDefault();
      if (effect !== "none_transition") {
        const xVal = e.clientX;
        const yVal = e.clientY;
        setContextMenu({
          isContextOpen: true,
          contextMenuPosition: { x: xVal, y: yVal, eventType: e.type },
        });
      }
    },
    [contextMenu]
  );

  useEffect(() => {
    // Set respective icons
    if (libraryDrag && libraryDrag.getIn(["data", "type"]) === "TRANSITIONS") {
      setSrc("transition-drag.svg");
    } else if (selectedLayer && selectedLayer.prevId === prevItem) {
      const selectedPrvId = selectedLayer.prevId;
      const transitionEffect = workspaceItem.getIn([
        selectedPrvId,
        "transitionEnterEffect",
      ]);
      const isTransitionApplied = transitionEffect !== "none_transition";
      if (isTransitionApplied) {
        setSrc("transition-effects-icon.svg");
      } else {
        setSrc("transition-noeffect-icon.svg");
      }
    } else if (
      transitionEnterEffect === undefined ||
      transitionEnterEffect === "none_transition"
    ) {
      setSrc("transition-layer-icon.svg");
    } else if (isPanelOpen) {
      setSrc("transition-unselect-icon.svg");
    } else {
      setSrc("transition-effects-icon.svg");
    }
  }, [
    libraryDrag,
    transitionEnterEffect,
    selectedLayer,
    isPanelOpen,
    sliderSelectedItems,
  ]);

  useEffect(() => {
    // Reset if slider are clicked
    if (
      sliderSelectedItems &&
      sliderSelectedItems.size > 0 &&
      selectedLayer.prevId
    ) {
      const context = {
        isSelected: false,
      };
      dispatch(setPropertyPanel(content.TRANSITIONS, true, context));
      dispatch(setDraggedTransition({ dragging: false }));
      setSelectedLayer({
        prevId: "",
        nextId: "",
        isDragged: false,
      });
    }
  }, [sliderSelectedItems]);

  useEffect(() => {
    const isPrevIdExist = workspaceItem.getIn([prevItem]);
    if (transitionEnterEffect) {
      dispatchHanlder(prevItem, nextItem, transitionEnterEffect);
    } else if (!transitionEnterEffect && !transitionEnterId && isPrevIdExist) {
      dispatchHanlder(prevItem, nextItem, "none_transition");
    }
  }, [nextItem]);

  useEffect(() => {
    // shortcut delete
    if (
      (selectedTransitionItem && selectedTransitionItem.get("prevItem") && selectedTransitionItem.get("nextItem")) &&
      (shortcutName === "DELETE_ITEM" || (shortcutName === "DELETE_ITEM_MAC" && selectedTransitionItem.get("prevItem") === prevItem && selectedTransitionItem.get("nextItem") === nextItem))
    ) {
      handleShortcuts(shortcutName, selectedTransitionItem.get("prevItem"), selectedTransitionItem.get("nextItem"));
    }
    return () => {
      setTransitionOn(false);
    };
  }, [shortcutName]);

  useEffect(() => {
    // Dragged to timeline transition layer
    if (draggedLayerData) {
      setSelectedLayer({
        prevId: draggedLayerData.get("prevItemId"),
        nextId: draggedLayerData.get("nextItemId"),
        effect: draggedLayerData.get("effect"),
        isDragged: true,
      });
    }
  }, [draggedLayerData]);

  useEffect(() => {
    // Clicked timeline transition layer
    if (
      selectedTransitionItem &&
      sliderSelectedItems &&
      sliderSelectedItems.size === 0
    ) {
      setSelectedLayer({
        prevId: selectedTransitionItem.get("prevItem"),
        nextId: selectedTransitionItem.get("nextItem"),
        isDragged: false,
      });
    }
  }, [selectedTransitionItem, sliderSelectedItems]);

  return (
    <div id="transitions-timeline">
      {isVideoOnly(prevItemType, prevItemSubType) &&
        isVideoOnly(nextItemType, nextItemSubType) &&
        isTransitionEligible &&
        (isPanelOpen || transitionApplied) && (
          <TransitionLayerContainer
            xplot={x}
            yplot={y}
            scaleX={isItemMoreThanOneSec ? 1 : 0.3}
            onClick={() => onClick(prevItem, nextItem)}
            onContextMenu={(e) => handleContextMenu(e, transitionEnterEffect)}
          >
            <Image
              hight={srcf === "transition-drag.svg" ? "32px" : "24px"}
              src={`${STATIC_PATH}${srcf}`}
              alt="transition"
              selectedIcon={
                !isDraging &&
                selectedLayer.effect !== "none_transition" &&
                selectedLayer.isDragged &&
                selectedLayer.prevId === prevItem &&
                selectedLayer.nextId === nextItem
              }
            />
            {isDraging && (
              <LayerWrapper
                width={wrapperWidth}
                left={wrapperWidth - nextWidthSize}
              >
                <SwapDropArea
                  type={"transitions"}
                  prevItem={prevItem}
                  nextItem={nextItem}
                />
              </LayerWrapper>
            )}

            {contextMenu.isContextOpen && (
              <ContextMenu
                ref={contextRef}
                options={menuOptions}
                positionEvent={contextMenu.contextMenuPosition}
                closeContextMenu={closeContextMenus}
                width={"218px"}
              />
            )}
          </TransitionLayerContainer>
        )}
    </div>
  );
};

TransitionLayer.propTypes = {
  x: PropTypes.string,
  y: PropTypes.string,
  prevItem: PropTypes.string,
  nextItem: PropTypes.string,
  prevWidth: PropTypes.string,
  nextWidth: PropTypes.string,
  shortcutName: PropTypes.string,
};

export default React.memo(TransitionLayer);
