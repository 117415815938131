/* eslint-disable no-restricted-syntax, radix */
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BackgroundWrapper,
  ColorPaletteWrapper,
  ColorPickerButton,
  Column,
  EffectWrapper,
  SubWrapper,
  SubtitleEffectsWrapper,
  TextButtonWrapper,
} from "./subtitleproperty-components";
import TextStyleButton from "../../../../common-components/TextStyleButton";
import { STATIC_PATH } from "../../../../constants/config";
import content from "../../../../constants/content";
import vmTheme from "../../../../constants/theme";
import Typography from "../../../../common-components/Typography";
import { font } from "../../../../constants/font";
import { updateTimelineTime } from "../../../../redux/actions/timelineUtils";
import { SUBTITLE_BACKGROUND_EFFECTS } from "../../../../helper/subtitleHelper";
import { calculatedThickness, reverseCalculatedThickness } from "../../../text/TextEffectsHelper";
import ColorPaletteWindow from "../color/propertywindow-colorpalette";
import { PORTAL_ID } from "../../../../constants";
import SubtitleSliderSection from "./subtitle-slider";
import usePanelScrollHeight from "../../../../helper/hooks/useScrollHeight";

const BLUR_RATIO = 1.3;

const SubtitleEffects = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.app.get("theme"));
  const zoomFactor = useSelector((state) => state.app.get("zoomFactor"));
  const projectDetails = useSelector((state) => state.projectDetails);
  const textStylesData = projectDetails.getIn(["subtitle", "textStyles"]);
  const backgroundEffects = projectDetails.getIn(["subtitle", "bgEffects"]);

  const [activeButton, setActiveButton] = useState("NONE");
  const [showBgColorPalette, setshowBgColorPalette] = useState(false);
  const [colorCode, setColorCode] = useState(backgroundEffects?.get("background") || textStylesData.get("background") || "#000000");
  const [outline, setOutline] = useState(backgroundEffects?.get("outline") || 0);
  const [textFillColor, setTextFillColor] = useState(backgroundEffects?.get("textFillColor") || "#905CE3");
  const [textShadowValue, setTextShadowValue] = useState(backgroundEffects?.get("textShadowValue") || 0);
  const [textShadowDistance, setTextShadowDistance] = useState(backgroundEffects?.get("textShadowDistance") || 0);
  const [textShadowAngle, setTextShadowAngle] = useState(backgroundEffects?.get("textShadowAngle") || -180);
  const [textShadowColor, setTextShadowColor] = useState(backgroundEffects?.get("textShadowColor") || "#905CE3");

  const panelRef = useRef(null);
  const workspaceStage = useSelector((state) => state.app.get("workspaceStage"));
  const mainScrollableHeight = usePanelScrollHeight({
    panelRef,
    workspaceStage,
    shouldUpdate: true,
  });

  const findAngleByOffset = (xOffset, yOffset) => {
    // Calculate distance using Pythagoras theorem
    const distance = parseInt(Math.sqrt(xOffset * xOffset + yOffset * yOffset));

    // Calculate angle in radians
    const angleInRadians = Math.atan2(yOffset, xOffset);

    // Convert radians to degrees
    let angleInDegrees = (angleInRadians * 180) / Math.PI;

    // Adjust angle to be in the range [-180, 180]
    angleInDegrees = parseInt((angleInDegrees + 180) % 360 - 180);

    return { angleInDegrees, distance };

  };

  const resetEffects = (distance, ShadowValue, angle, shadowColor) => {
    if (textShadowAngle !== angle) {
      setTextShadowAngle(angle);
    }
    if (textShadowDistance !== distance) {
      setTextShadowDistance(distance);
    }
    if (textShadowValue !== ShadowValue) {
      setTextShadowValue(ShadowValue);
    }
    if (textShadowColor !== shadowColor) {
      setTextShadowColor(shadowColor);
    }
    const toUpdate = [];
    const bgEffects = {
      textShadowDistance: distance,
      textShadowValue: ShadowValue,
      textShadowAngle: angle,
      textShadowColor: shadowColor
    }
    toUpdate.push({
      container: "subtitleGlobal",
      langId: projectDetails.get("defaultSubtitle"),
      toUpdate: { bgEffects },
    });
    dispatch(updateTimelineTime({ toUpdate }));
  }

  const resetOutline = (strokeWith, strokeColor) => {
    setTextFillColor(strokeColor);
    setOutline(strokeWith);
    const toUpdate = [];
    const bgEffects = {
      textFillColor: strokeColor,
      outline: strokeWith
    }
    toUpdate.push({
      container: "subtitleGlobal",
      langId: projectDetails.get("defaultSubtitle"),
      toUpdate: { bgEffects },
    });
    dispatch(updateTimelineTime({ toUpdate }));
  }

  useEffect(() => {
    const { background, borderRadius } = textStylesData.toJS();
    if (background !== "transparent") {
      if (parseFloat(borderRadius || "0")) {
        setActiveButton("BG_EFF_4");
      } else {
        setActiveButton("BG_EFF_3");
      }
    } else {
      setActiveButton("NONE");
    }
  }, [textStylesData]);

  useEffect(() => {
    const textShadowArr = textStylesData.get("textShadow")?.split(" ");
    const webkitStrokeArr = textStylesData.get("webkitTextStroke")?.split(" ");
    if (textShadowArr?.length > 1) {
      const { angleInDegrees, distance } = findAngleByOffset(parseFloat(textShadowArr[0]), parseFloat(textShadowArr[1]));
      const shadowValue = parseInt(parseFloat(textShadowArr[2]) * 8 * BLUR_RATIO);
      resetEffects(distance, shadowValue, angleInDegrees, textShadowArr[3]);
    } else {
      resetEffects(0, 0, -180, textShadowColor);
    }

    if (webkitStrokeArr?.length > 1) {
      const fontSize = textStylesData.get("fontSize");
      const strokeWidth = parseInt(reverseCalculatedThickness(parseFloat(webkitStrokeArr[0]), fontSize)) / 2;
      resetOutline(strokeWidth, webkitStrokeArr[1])
    } else {
      resetOutline(0, "#905CE3");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const setShadowByAngle = (angleInDegrees, distance) => {
    // Convert degrees to radians
    const angleInRadians = (angleInDegrees * Math.PI) / 180;

    // Calculate horizontal and vertical offsets using trigonometry
    const xOffset = distance * Math.cos(angleInRadians);
    const yOffset = distance * Math.sin(angleInRadians);

    return { xOffset, yOffset };
  };

  const applyBgEffect = (bgEffect) => {
    setActiveButton(bgEffect.KEY);
    let { background } = bgEffect.BG_STYLES;
    if (textStylesData.get("background") !== "transparent" && background !== "transparent") {
      background = textStylesData.get("background");
    }
    setColorCode(background);
    const toUpdate = [];
    const bgEffects = {
      hasBgEffect: bgEffect.KEY !== "NONE",
      bgEffectKey: bgEffect.KEY,
    };
    const textStyles = {
      ...bgEffect.BG_STYLES,
      background
    };
    toUpdate.push({
      container: "subtitleGlobal",
      langId: projectDetails.get("defaultSubtitle"),
      toUpdate: { bgEffects, textStyles },
    });
    dispatch(updateTimelineTime({ toUpdate }));
  };

  const applyOutline = (outlineValue, colorValue) => {
    const fontSize = textStylesData.get("fontSize");
    let strokewidth;
    if (outlineValue > 0) {
      strokewidth = calculatedThickness(outlineValue, fontSize);
    } else {
      strokewidth = outlineValue;
    }
    const toUpdate = [];
    const bgEffects = {};
    const textStyles = {};
    textStyles.webkitTextStroke = `${strokewidth}px ${colorValue}`;
    bgEffects.outline = outlineValue;
    toUpdate.push({
      container: "subtitleGlobal",
      langId: projectDetails.get("defaultSubtitle"),
      toUpdate: { bgEffects, textStyles },
    });
    dispatch(updateTimelineTime({ toUpdate }));
  };

  const applyTextShadowEffect = (textShadow, effects = {}) => {
    const toUpdate = [];
    const bgEffects = { ...effects };
    const textStyles = { textShadow };
    toUpdate.push({
      container: "subtitleGlobal",
      langId: projectDetails.get("defaultSubtitle"),
      toUpdate: { bgEffects, textStyles },
    });
    dispatch(updateTimelineTime({ toUpdate }));
  };

  const colorSelectionHandler = (color, option) => {
    const textStyles = {};
    const bgEffects = {};

    switch (option) {
      case "BACKGROUND":
        textStyles.background = color;
        setColorCode(color);
        break;
      case "OUTLINE":
        bgEffects.textFillColor = color;
        setTextFillColor(color);
        applyOutline(outline * 2, color);
        break;
      case "SHADOW": {
        setTextShadowColor(color);
        let x = textShadowValue / 8;
        let y = 0;
        if (textShadowDistance) {
          x = -textShadowDistance;
          if (textShadowAngle !== -180) {
            const { xOffset, yOffset } = setShadowByAngle(
              textShadowAngle,
              textShadowDistance
            );
            x = xOffset;
            y = yOffset;
          }
        }
        const textShadow = `${x}px ${y}px ${textShadowValue / (8 * BLUR_RATIO)}px ${color}`;
        applyTextShadowEffect(textShadow, { textShadowColor: color });
      }
        break;
      default:
        break;
    }
    const toUpdate = [
      {
        container: "subtitleGlobal",
        langId: projectDetails.get("defaultSubtitle"),
        toUpdate: { textStyles, bgEffects },
      },
    ];
    dispatch(updateTimelineTime({ toUpdate }));
  };

  const toggleColorPalette = () => {
    setshowBgColorPalette(!showBgColorPalette);
  };

  const handleClick = (value, heading) => {
    const parseValue = parseFloat(value);
    switch (heading) {
      case "OUTLINE":
        setOutline(parseValue);
        applyOutline(parseValue * 2, textFillColor);
        break;
      case "SHADOW":
        {
          setTextShadowValue(parseValue);
          let x = parseValue / 8;
          let y = 0;
          if (textShadowDistance) {
            x = -textShadowDistance;
            if (textShadowAngle !== -180) {
              const { xOffset, yOffset } = setShadowByAngle(
                textShadowAngle,
                textShadowDistance
              );
              x = xOffset;
              y = yOffset;
            }
          }
          const textShadow = `${x}px ${y}px ${parseValue / (8 * BLUR_RATIO)}px ${textShadowColor}`;
          applyTextShadowEffect(textShadow, { textShadowValue: parseValue });
        }
        break;
      case "DISTANCE":
        {
          setTextShadowDistance(parseValue);
          let x = -parseValue;
          let y = 0;
          if (textShadowAngle !== -180) {
            const { xOffset, yOffset } = setShadowByAngle(
              textShadowAngle,
              parseValue
            );
            x = xOffset;
            y = yOffset;
          }
          const textShadow = `${x}px ${y}px ${textShadowValue / (8 * BLUR_RATIO)}px ${textShadowColor}`;
          applyTextShadowEffect(textShadow, { textShadowDistance: parseValue });
        }
        break;
      case "ANGLE":
        {
          setTextShadowAngle(parseValue);
          const { xOffset, yOffset } = setShadowByAngle(
            parseValue,
            textShadowDistance
          );
          const textShadow = `${xOffset}px ${yOffset}px ${textShadowValue / (8 * BLUR_RATIO)}px ${textShadowColor}`;
          applyTextShadowEffect(textShadow, { textShadowAngle: parseValue });
        }
        break;
      default:
        break;
    }
  };

  const onChangeHandler = (value, heading) => {
    const parseValue = parseFloat(value);
    const subtitleWrapperEl =
      document.getElementsByClassName("subtitle-wrapper");
    for (const itemEl of subtitleWrapperEl) {
      switch (heading) {
        case "OUTLINE":
          {
            setOutline(parseValue);
            const fontSize = textStylesData.get("fontSize")
            let strokewidth;
            if (parseValue > 0) {
              strokewidth = calculatedThickness(parseValue * 2, fontSize);
            } else {
              strokewidth = parseValue;
            }
            itemEl.style.webkitTextStroke = `${strokewidth}px ${textFillColor}`;
          }
          break;
        case "SHADOW":
          {
            setTextShadowValue(parseValue);
            let x = parseValue / 8;
            let y = 0;
            if (textShadowDistance) {
              x = -textShadowDistance;
              if (textShadowAngle !== -180) {
                const { xOffset, yOffset } = setShadowByAngle(
                  textShadowAngle,
                  textShadowDistance
                );
                x = xOffset;
                y = yOffset;
              }
            }
            const blur = (parseValue / (8 * BLUR_RATIO)) * zoomFactor;
            itemEl.style.textShadow = `${x * zoomFactor}px ${y * zoomFactor}px ${blur}px ${textShadowColor}`;
          }
          break;
        case "DISTANCE": {
          setTextShadowDistance(parseValue);
          let x = -parseValue;
          let y = 0;
          if (textShadowAngle !== -180) {
            const { xOffset, yOffset } = setShadowByAngle(
              textShadowAngle,
              parseValue
            );
            x = xOffset;
            y = yOffset;
          }
          itemEl.style.textShadow = `${x * zoomFactor}px ${y * zoomFactor}px ${textShadowValue / (8 * BLUR_RATIO)}px ${textShadowColor}`;
        }
          break;
        case "ANGLE":
          {
            setTextShadowAngle(parseValue);
            const { xOffset, yOffset } = setShadowByAngle(
              parseValue,
              textShadowDistance
            );
            itemEl.style.textShadow = `${xOffset * zoomFactor}px ${yOffset * zoomFactor}px ${textShadowValue / (8 * BLUR_RATIO)}px ${textShadowColor}`;
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <SubtitleEffectsWrapper height={mainScrollableHeight} ref={panelRef}>
      <BackgroundWrapper>
        <Typography
          content={content.BACKGROUND}
          display="block"
          color={vmTheme[theme].tabTextColor}
          font={font.boldBase}
        />
        <TextButtonWrapper>
          {SUBTITLE_BACKGROUND_EFFECTS.map((bgEffect) => {
            return (
              <TextStyleButton
                key={bgEffect.KEY}
                isDisabled={bgEffect.KEY === "BG_EFF_1" || bgEffect.KEY === "BG_EFF_2"}
                isActive={activeButton === bgEffect.KEY}
                src={`${STATIC_PATH}subtitle-property/st-bg-${bgEffect.KEY}${activeButton === bgEffect.KEY ? "-active" : ""}.svg`}
                onClick={() => applyBgEffect(bgEffect)}
                width="88px"
              />
            );
          })}
          <ColorPickerButton
            background={colorCode}
            isDisabled={activeButton === "NONE"}
            onClick={activeButton !== "NONE" ? toggleColorPalette : undefined}
          />
        </TextButtonWrapper>
        {showBgColorPalette &&
          ReactDOM.createPortal(
            <ColorPaletteWrapper top="345px" left="390px">
              <ColorPaletteWindow
                colorSelected={(color) => colorSelectionHandler(color, "BACKGROUND")}
                closePopup={toggleColorPalette}
                currentColor={colorCode}
                itemId={"colorSelector"}
                stopSlidePropogation={true}
                from="TextEffectsSection"
                componentFrom="TXT_EFF_SEC"
              />
            </ColorPaletteWrapper>,
            document.getElementById(PORTAL_ID)
          )}
      </BackgroundWrapper>
      <EffectWrapper>
        <SubtitleSliderSection
          heading={content.OUTLINE}
          value={outline}
          paletteColor={textFillColor}
          mouseUpHandler={handleClick}
          handleClick={handleClick}
          onChangeHandler={onChangeHandler}
          colorSelectionHandler={colorSelectionHandler}
          leftIcon="subtitle-property/outline-1.svg"
          rightIcon="subtitle-property/outline-2.svg"
          width="336px"
        />
      </EffectWrapper>
      <EffectWrapper>
        <SubtitleSliderSection
          heading={content.SHADOW}
          value={textShadowValue}
          paletteColor={textShadowColor}
          mouseUpHandler={handleClick}
          handleClick={handleClick}
          onChangeHandler={onChangeHandler}
          colorSelectionHandler={colorSelectionHandler}
          leftIcon="subtitle-property/st-shadow-1.svg"
          rightIcon="subtitle-property/st-shadow-2.svg"
          width="336px"
        />
        <SubWrapper>
          <Column>
            <SubtitleSliderSection
              heading={content.DISTANCE}
              value={textShadowDistance}
              mouseUpHandler={handleClick}
              handleClick={handleClick}
              onChangeHandler={onChangeHandler}
              width="172px"
              enableColorPick={false}
              numberWidth="60px"
              titleIcon="subtitle-property/distance.svg"
              borderRight
            />
          </Column>
          <Column>
            <SubtitleSliderSection
              heading={content.ANGLE}
              value={textShadowAngle}
              type="angle"
              mouseUpHandler={handleClick}
              handleClick={handleClick}
              onChangeHandler={onChangeHandler}
              width="172px"
              enableColorPick={false}
              min={-180}
              max={180}
              numberWidth="60px"
              titleIcon="subtitle-property/angle.svg"
            />
          </Column>
        </SubWrapper>
      </EffectWrapper>
    </SubtitleEffectsWrapper>
  );
};

export default SubtitleEffects;
