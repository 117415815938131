/* eslint-disable no-restricted-syntax */

import Immutable, { OrderedMap, fromJS } from "immutable";
import ActionTypes from "../../constants/action-types";
import { applyTimelineUpdaterObject } from "../../containers/timeline/timeline-autosavehelper";
import { buildLocalSubtitles } from "../../containers/timeline/timeline-helper";
import { ANIMO_RENDER } from "../../constants/config";
import { animoProjectGeneration } from "../../helper/animoProjectGeneration";

const { SET_PROJECT } = ActionTypes;

export const initialState = fromJS({
  duration: 0,
  name: "Untitled Video",
  width: 800,
  height: 450,
  workspaceItems: OrderedMap(),
  workspaceChildren: OrderedMap(),
  workspaceBG: OrderedMap(),
  audios: OrderedMap(),
  localSubtitle: OrderedMap(),
  shorts: OrderedMap(),
  bgColor: OrderedMap(),
});

export default function projectReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_PROJECT: {
      payload.localSubtitle = {}
      let project = fromJS(payload);
      project = project.set(
        "workspaceItems",
        project
          .get("workspaceItems")
          .toOrderedMap()
          .sort((itemA, itemB) => {              // Transition slider
            let result = itemA.get("track") - itemB.get("track")
            if (!result) {
              result = itemB.get("enterStart") - itemA.get("enterStart");
            }
            return result;
          })
      );
      if (project.get("shorts")) {
        project = project.set(
          "shorts",
          project.get("shorts").toOrderedMap()
        );
      } else {
        project = project.set("shorts", OrderedMap());
      }
      if (project.get("workspaceChildren")) {
        project = project.set(
          "workspaceChildren",
          project.get("workspaceChildren").toOrderedMap()
        );
      } else {
        project = project.set("workspaceChildren", OrderedMap());
      }
      if (project.get("workspaceBG")) {
        project = project.set(
          "workspaceBG",
          project.get("workspaceBG").toOrderedMap()
        );
      } else {
        project = project.set("workspaceBG", OrderedMap());
      }
      project = project.set(
        "audios",
        project
          .get("audios")
          .toOrderedMap()
          .sortBy((audio) => audio.get("track"))
      );

      if (project.get("subtitle")) {
        for (const [dropId, subtitleData] of project
          .getIn(["subtitle", "data"])
          .entrySeq()) {
          project = project.setIn(
            ["subtitle", "data", dropId],
            subtitleData.toOrderedMap().sortBy((item) => item.get("start"))
          );
        }
        if (!ANIMO_RENDER || (ANIMO_RENDER && action.isAnimoSubtitle)) {
          project = buildLocalSubtitles(project);
        }
      }
      if (ANIMO_RENDER) {
        project = animoProjectGeneration(project, action.isAnimoSubtitle);
      }
      return project;
    }
    case ActionTypes.UPDATE_PROJECT_DATA_COMPLETE: {
      state = state.merge(fromJS(payload.toUpdate));
      return state;
    }
    case ActionTypes.UPDATE_TIMELINE_COMPLETE:
    case ActionTypes.BULK_OBJECT_UPDATE_COMPLETE: {
      const updaterObject = fromJS(payload.data);
      return applyTimelineUpdaterObject({
        projectDetails: state,
        updaterObject,
      });
    }
    case ActionTypes.MOVE_UPDATE_COMPLETE: {
      const { data } = payload;
      const workspaceItems = fromJS(data.workspaceItems);
      const workspaceChildren = fromJS(data.workspaceChildren);

      if (workspaceItems.size > 0) {
        const allWorkspaceItems = state.get(data.currentContainer);
        workspaceItems.entrySeq().forEach(([key, item]) => {
          state = state.setIn(
            [data.currentContainer, key],
            allWorkspaceItems.get(key).merge(item)
          );
        });
      }

      if (workspaceChildren.size > 0) {
        const allWorkspaceChildren = state.get("workspaceChildren");
        workspaceChildren.entrySeq().forEach(([key, item]) => {
          state = state.setIn(
            ["workspaceChildren", key],
            allWorkspaceChildren.get(key).merge(item)
          );
        });
      }
      return state;
    }
    case ActionTypes.UPDATE_OBJECT_COMPLETE:
    case ActionTypes.UPDATE_TEXT_EFFECTS_COMPLETE: {
      const { data } = payload;
      state = state.setIn(
        [data.container, data.selectedItem],
        state
          .getIn([data.container, data.selectedItem])
          .merge(fromJS(data.toUpdate))
      );
      return state;
    }
    case ActionTypes.UPDATE_GROUP_TEXT_COMPLETE: {
      const { data } = payload;
      for (const key of Reflect.ownKeys(data.workspaceChildren)) {
        state = state.setIn(
          ["workspaceChildren", key],
          state
            .getIn(["workspaceChildren", key])
            .merge(fromJS(data.workspaceChildren[key]))
        );
      }

      for (const key of Reflect.ownKeys(data.workspaceItems)) {
        state = state.setIn(
          ["workspaceItems", key],
          state
            .getIn(["workspaceItems", key])
            .merge(fromJS(data.workspaceItems[key]))
        );
      }

      return state;
    }
    case ActionTypes.APPLY_FLIP_COMPLETE: {
      const updateList = fromJS(payload.data);

      for (const updater of updateList.valueSeq()) {
        const id = updater.get("id");
        const container = updater.get("container");
        const clipId = updater.get("clipId");
        const data = updater.get("data");

        if (clipId) {
          let imgDetails = state.getIn([container, id, "clipDetails", clipId, "imgDetails"]);
          imgDetails = imgDetails.merge(data);
          state = state.setIn([container, id, "clipDetails", clipId, "imgDetails"], imgDetails);
        } else {
          let item = state.getIn([container, id]);
          item = item.merge(data);
          state = state.setIn([container, id], item);
        }
      }
      return state;
    }
    case ActionTypes.APPLY_COLOR_COMPLETE: {
      const updateList = fromJS(payload.data);

      for (const updater of updateList.valueSeq()) {
        const container = updater.get("container");
        const replaceAllColor = updater.get("replaceAllColor"); // for theme
        const color = updater.get("color");
        const colors = updater.get("colors");
        const colorKey = updater.get("colorKey");
        const direction = updater.get("direction");
        const selectedKey = updater.get("selectedKey");
        const type = updater.get("type");
        const radius = updater.get("radius");

        if (container === "workspaceItems" || container === "workspaceChildren") {
          const id = updater.get("id");
          const isFrameClip = updater.get("isFrameClip");
          const isFrameSVG = updater.get("isFrameSVG");

          if (isFrameClip) {
            // frame clip
            state = state.setIn([container, id, "clipDetails", colorKey, "imgDetails", "color"], color);
          } else if (isFrameSVG) {
            // frame svg path
            state = state.setIn([container, id, "d", colorKey, "fill"], color);
          } else if (replaceAllColor) {
            // item theme change or reset
            state = state.setIn([container, id, "colors"], colors);
          } else {
            // item specific color change
            state = state.setIn([container, id, "colors", colorKey], color);
          }
        } else if (container === "bgColor") {
          switch (type) {
            case "remove":
              state = state.deleteIn(["bgColor", "colors", colorKey]);
              break;

            case "deleteAll":
              state = state
                .setIn(["bgColor", "colors"], Immutable.List())
                .setIn(["bgColor", "direction"], null)
                .setIn(["bgColor", "selectedKey"], null)
                .setIn(["bgColor", "colors", 0], "#ffffff")
                .setIn(["bgColor", "radius"], null);
              break;

            default:
              if (replaceAllColor) {
                state = state.setIn(["bgColor", "colors"], colors);
              } else {
                state = state
                  .setIn(["bgColor", "colors", colorKey], color)
                  .setIn(["bgColor", "direction"], direction)
                  .setIn(["bgColor", "radius"], radius)
                  .setIn(["bgColor", "selectedKey"], selectedKey);
              }
              break;
          }
        }
      }
      return state;
    }
    case ActionTypes.APPLY_FRAME_CLIP_PLOT_COMPLETE: {
      const { data } = payload;
      const { container, id, clipId } = data;
      const original = fromJS(data.original);

      let item = state.getIn([container, id]);
      item = item.setIn(
        ["clipDetails", clipId, "imgDetails", "original"],
        original
      );
      state = state.setIn([container, id], item);

      return state;
    }
    case ActionTypes.SET_EXIT_EFFECT_NAME: {
      state = state.setIn(
        ["workspaceItems", payload.item, "exitEffectName"],
        payload.effectName
      );
      state = state.setIn(
        ["workspaceItems", payload.item, "exitStart"],
        payload.exitStart
      );
      state = state.setIn(
        ["workspaceItems", payload.item, "exitEffectIcon"],
        payload.exitEffectIcon
      );
      return state;
    }
    case ActionTypes.SET_ENTER_EFFECT_NAME: {
      state = state.setIn(
        ["workspaceItems", payload.item, "enterEffectName"],
        payload.effectName
      );
      state = state.setIn(
        ["workspaceItems", payload.item, "enterEnd"],
        payload.enterEnd
      );
      state = state.setIn(
        ["workspaceItems", payload.item, "enterEffectIcon"],
        payload.enterEffectIcon
      );
      return state;
    }
    case ActionTypes.INSERT_SUBTITLE_DATA_COMPLETE: {
      const { subtitleId, langCode } = action.payload.subtitleData;
      const subtitle = fromJS(action.payload.subtitleData);
      state = state.set("subtitle", subtitle);
      state = state.set("defaultSubtitle", subtitleId);
      for (const [dropId, subtitleData] of state
        .getIn(["subtitle", "data"])
        .entrySeq()) {
        state = state.setIn(
          ["subtitle", "data", dropId],
          subtitleData.toOrderedMap().sortBy((item) => item.get("start"))
        );
      }
      if (action.payload.subtitleList) {
        state = state.set("subtitleData", fromJS(action.payload.subtitleList));
      }
      if (!state.get("subtitleData")) {
        // WARN: subtitleList should be sent in payload
        state = state.set(
          "subtitleData",
          fromJS([
            {
              subtitleId,
              langCode,
              default_subtitle: true,
              isActive: true,
            },
          ])
        );
      }
      return buildLocalSubtitles(state);
    }
    case ActionTypes.UPDATE_SUBTITLE_DATA_COMPLETE: {
      const { data } = action.payload.subtitleData;
      const subtitle = data.map((subtitleData) => subtitleData.toOrderedMap().sortBy((item) => item.get("start")));
      state = state.updateIn(["subtitle", "data"], (subtitles) => subtitles.merge(subtitle));
      state = state.set("subtitleData", fromJS(action.payload.subtitleList));
      return buildLocalSubtitles(state);
    }
    case ActionTypes.UPDATE_SUBTITLE_LIST_COMPLETE: {
      state = state.set("subtitleData", fromJS(action.payload.data));
      const activeSubtitle = state
        .get("subtitleData")
        .find((entry) => entry.get("isActive"));
      state = state.set("defaultSubtitle", activeSubtitle.get("subtitleId"));
      return state;
    }
    default: {
      return state;
    }
  }
}
