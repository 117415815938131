/* eslint-disable react/no-unknown-property */
/* eslint-disable react/sort-comp, jsx-a11y/media-has-caption */

import React, { createRef, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { clamp, scaleWithinRange } from "../timeline/timeline-helper";
import {
  addTween,
  prefetchComplete,
  setPlayAll,
  updateVideoBufferStatus,
} from "../../redux/actions/appUtils";
import { PlayerAudioCallbackContext } from "./player-context";
import CanvasImage from "./canvas-image";
import TransparentVideo from "./transparent-video";
import CanvasVideo from "./canvas-video";
import { addCacheClearQuery } from "../../helper/addCacheClearQuery";

class MediaComponent extends React.Component {
  constructor(props) {
    super(props);

    this.changeMediaVolume = this.changeMediaVolume.bind(this);
    this.changeMediaTime = this.changeMediaTime.bind(this);
    this.isTimePropChanged = this.isTimePropChanged.bind(this);
    this.checkMediaHasEnoughData = this.checkMediaHasEnoughData.bind(this);
    this.onWaiting = this.onWaiting.bind(this);
    this.prefetchMedia = this.prefetchMedia.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.playPauseTweenCallback = this.playPauseTweenCallback.bind(this);
    this.assignRef = this.assignRef.bind(this);

    /** @type {React.MutableRefObject<HTMLVideoElement | HTMLAudioElement | null>} */
    this.mediaElRef = createRef(null);
    this.imageRef = createRef(null);

    this.mediaPrefetchTimer = null;
    this.prefetchCheckInterval = 500; // ms
    this.prefetchTries = 10;

    this.bufferStartMediaTime = null;
    this.bufferCheckTimer = null;
    this.bufferCheckInterval = 200; // ms

    this.preload = null;
  }

  componentDidMount() {
    const { runningState } = this.props;
    const { speed } = this.props;

    let { playhead } = this.props;
    if (this.props.isPlayAll) {
      playhead = runningState.get("seekPlayhead");
    }

    this.changeMediaTime({
      playStart: this.props.playStart,
      playEnd: this.props.playEnd,
      mediaStart: this.props.mediaStart,
      mediaEnd: this.props.mediaEnd,
      speed: this.props.speed,
      playhead,
    });
    // player component will take care of audio
    // this.changeMediaVolume(this.props.volume);

    if (this.props.isPlayAll) {
      this.prefetchMedia();
    }
    if (speed) {
      this.mediaElRef.current.playbackRate = speed;
    }
  }

  componentDidUpdate(prevProps) {
    const isPlayStarted =
      prevProps.isPlayAll === false && this.props.isPlayAll === true;
    const isPlayEnded =
      prevProps.isPlayAll === true && this.props.isPlayAll === false;
    const isPlayerLoaded =
      prevProps.isLoaded === false && this.props.isLoaded === true;
    const isSeeked =
      this.props.runningState.get("seekToken") !==
      prevProps.runningState.get("seekToken");
    const isIdleTimeChanged =
      !this.props.isPlayAll && this.isTimePropChanged(prevProps, this.props);

    const isBuffering = this.props.videoBufferStatus.size > 0;
    const isCurrentMediaBuffering = this.props.videoBufferStatus.has(
      this.props.id
    );

    if (isPlayStarted) {
      const playhead = this.props.runningState.get("seekPlayhead");
      this.changeMediaTime({
        playStart: this.props.playStart,
        playEnd: this.props.playEnd,
        mediaStart: this.props.mediaStart,
        mediaEnd: this.props.mediaEnd,
        speed: this.props.speed,
        playhead,
      });
      this.prefetchMedia();
    } else if (isSeeked || isPlayerLoaded) {
      const playhead = this.props.runningState.get("seekPlayhead");
      const isWithinPlayRange =
        playhead >= this.props.playStart && playhead < this.props.playEnd;

      if (!isWithinPlayRange) {
        this.pause(playhead);
        if (isCurrentMediaBuffering) {
          clearInterval(this.bufferCheckTimer);
          this.props.updateVideoBufferStatus({
            videoKey: this.props.id,
            isBuffering: false,
          });
        }
      } else if (
        (isBuffering && !isCurrentMediaBuffering) ||
        this.props.isPausePlayer
      ) {
        this.pause(playhead);
      } else if (isWithinPlayRange && !isBuffering) {
        this.play(playhead);
      }
    } else if (isIdleTimeChanged) {
      this.changeMediaTime({
        playStart: this.props.playStart,
        playEnd: this.props.playEnd,
        mediaStart: this.props.mediaStart,
        mediaEnd: this.props.mediaEnd,
        speed: this.props.speed,
        playhead: this.props.playhead,
      });
    }

    if (isPlayEnded || isPlayerLoaded) {
      clearInterval(this.bufferCheckTimer);
      clearInterval(this.mediaPrefetchTimer);
      if (isPlayEnded) {
        this.pause(this.props.playhead);
      }
    }

    // player component will take care of audio
    // if (this.props.volume !== prevProps.volume) {
    //   this.changeMediaVolume(this.props.volume);
    // }
  }

  componentWillUnmount() {
    clearInterval(this.bufferCheckTimer);
    clearInterval(this.mediaPrefetchTimer);

    if (this.mediaElRef.current) {
      this.mediaElRef.current.pause();
      this.mediaElRef.current.src = "";
      this.mediaElRef.current.removeAttribute("src");
      this.mediaElRef.current.load();
    }
  }

  /**
   * @param {object} params
   * @param {number} params.playStart
   * @param {number} params.playEnd
   * @param {number} params.mediaStart
   * @param {number} params.mediaEnd
   * @param {number} params.playhead
   * @param {number} params.speed
   */
  changeMediaTime(params = {}) {
    try {
      const { playStart, playEnd, mediaStart, mediaEnd, playhead } = params;
      const playDuration = mediaEnd - mediaStart;

      if (this.props.isPlayAll) {
        const isWithinPlayRange =
          playStart - 20 <= playhead && playhead <= playEnd + 20;
        if (!isWithinPlayRange) {
          // changing currentTime will make browser fetch video
          // if there are multiple videos, it will be heavy
          // so skip changing current time if video is not going to be played anytime soon
          return;
        }
      }

      let currentTime =
        mediaStart +
        scaleWithinRange({
          fromRange: { start: playStart, end: playEnd },
          toRange: { start: 0, end: playDuration },
          num: playhead,
        });
      currentTime = clamp(currentTime, mediaStart, mediaEnd);
      this.mediaElRef.current.currentTime = currentTime;
    } catch (error) {
      if (this.props.isPlayAll) {
        this.props.setPlayAll(false);
      }
    }
  }

  play(playhead) {
    try {
      if (this.mediaElRef.current.preload !== "auto") {
        this.preload = "auto";
        this.mediaElRef.current.preload = "auto";
        this.mediaElRef.current.src = addCacheClearQuery(
          this.props.src,
          this.props.allowCustomCaching
        );
        this.mediaElRef.current.load();
      }

      this.changeMediaTime({
        playStart: this.props.playStart,
        playEnd: this.props.playEnd,
        mediaStart: this.props.mediaStart,
        mediaEnd: this.props.mediaEnd,
        speed: this.props.speed,
        playhead,
      });

      this.props.playPauseAudio({
        id: this.props.id,
        playhead,
        mediaType: this.props.mediaType,
      });

      if (this.mediaElRef.current.paused) {
        this.mediaElRef.current
          .play()
          .then(() => {
            this.changeMediaTime({
              playStart: this.props.playStart,
              playEnd: this.props.playEnd,
              mediaStart: this.props.mediaStart,
              mediaEnd: this.props.mediaEnd,
              speed: this.props.speed,
              playhead: this.props.t1.time(),
            });
          })
          .catch(() => {});
      }
    } catch (error) {}
  }

  pause(playhead) {
    try {
      if (
        this.props.playStart - 20 <= playhead &&
        playhead < this.props.playEnd
      ) {
        if (this.mediaElRef.current.preload !== "auto") {
          this.preload = "auto";
          this.mediaElRef.current.preload = "auto";
          this.mediaElRef.current.src = addCacheClearQuery(
            this.props.src,
            this.props.allowCustomCaching
          );
          this.mediaElRef.current.load();
        }
      } else {
        this.preload = "none";
        this.mediaElRef.current.preload = "none";
        this.mediaElRef.current.removeAttribute("src");
        this.mediaElRef.current.load();
      }

      if (!this.mediaElRef.current.paused) {
        this.mediaElRef.current.pause();
      }
      this.changeMediaTime({
        playStart: this.props.playStart,
        playEnd: this.props.playEnd,
        mediaStart: this.props.mediaStart,
        mediaEnd: this.props.mediaEnd,
        speed: this.props.speed,
        playhead,
      });
    } catch (error) {}
  }

  playPauseTweenCallback(isPausedSeek) {
    // use current time from gsap instead of playStart
    // gsap will call this function even if user seeks to a time beyond playStart
    // addCallback is actually a zero duration tween with onComplete attached
    const playhead = this.props.t1.time();
    if (playhead >= this.props.playEnd || playhead < this.props.playStart) {
      this.pause(playhead);
    } else if (!isPausedSeek) {
      this.play(playhead);
    }
  }

  prefetchMedia() {
    try {
      this.props.addTween(
        {
          callBack: this.playPauseTweenCallback,
          startTime: this.props.playStart,
          dataArr: [false],
        },
        "addCallback"
      );
      this.props.addTween(
        {
          callBack: this.playPauseTweenCallback,
          startTime: this.props.playEnd,
          dataArr: [false],
        },
        "addCallback"
      );
      if (this.props.playStart - 19 > 0) {
        this.props.addTween(
          // add this tween to seek paused media to proper time
          {
            callBack: this.playPauseTweenCallback,
            startTime: this.props.playStart - 19,
            dataArr: [true],
          },
          "addCallback"
        );
      }

      let tries = 0;
      if (
        // skip checking video that's not going to be played immediately
        this.props.playhead > this.props.playEnd ||
        this.props.playhead - this.props.playStart < -10
      ) {
        this.props.prefetchComplete(this.props.prefetchToken);
      } else if (this.checkMediaHasEnoughData({ useReadyState: true })) {
        const { prefetchToken } = this.props;
        this.mediaElRef.current.muted = true;
        this.mediaElRef.current
          .play()
          .catch(() => {})
          .finally(() => {
            if (prefetchToken === this.props.prefetchToken) {
              this.mediaElRef.current.pause();
              this.mediaElRef.current.muted = false;
              this.props.prefetchComplete(this.props.prefetchToken);
            }
          });
      } else {
        clearInterval(this.mediaPrefetchTimer);
        const { prefetchToken } = this.props;
        this.mediaPrefetchTimer = setInterval(() => {
          if (
            this.checkMediaHasEnoughData({ useReadyState: true }) ||
            tries >= this.prefetchTries
          ) {
            clearInterval(this.mediaPrefetchTimer);
            this.mediaElRef.current.muted = true;
            this.mediaElRef.current
              .play()
              .catch(() => {})
              .finally(() => {
                if (prefetchToken === this.props.prefetchToken) {
                  this.mediaElRef.current.pause();
                  this.mediaElRef.current.muted = false;
                  this.props.prefetchComplete(this.props.prefetchToken);
                }
              });
          }
          tries += 1;
        }, this.prefetchCheckInterval);
      }
    } catch (error) {}
  }

  /**
   * @param {number} volume
   */
  changeMediaVolume(volume) {
    try {
      if (volume !== undefined) {
        this.mediaElRef.current.volume = volume;
      }
    } catch (error) {}
  }

  isTimePropChanged(prevProps, currentProps) {
    return (
      prevProps.playStart !== currentProps.playStart ||
      prevProps.playEnd !== currentProps.playEnd ||
      prevProps.mediaStart !== currentProps.mediaStart ||
      prevProps.mediaEnd !== currentProps.mediaEnd ||
      prevProps.playhead !== currentProps.playhead
    );
  }

  checkMediaHasEnoughData({ useReadyState, currentTime = null }) {
    let isBuffered = false;

    if (useReadyState) {
      try {
        if (this.mediaElRef.current) {
          isBuffered =
            this.mediaElRef.current.readyState >=
            this.mediaElRef.current.HAVE_CURRENT_DATA;
        }
      } catch (error) {}
    } else {
      try {
        if (this.mediaElRef.current) {
          let bufferLeftDuration = 0; // in secs
          let bufferRightDuration = 1; // in secs

          const { buffered, duration } = this.mediaElRef.current;

          if (!Number.isFinite(currentTime)) {
            currentTime = this.mediaElRef.current.currentTime;
          }

          if (duration < 1) { // Fix for Buffering detection for audios/video with duration less than a second & Float comparison
            bufferRightDuration = 0.1;
        }

          if (currentTime < bufferLeftDuration) {
            bufferLeftDuration = currentTime;
          }
          if (duration - currentTime < bufferRightDuration) {
            bufferRightDuration = duration - currentTime;
          }

          for (let i = 0; i < buffered.length; i += 1) {
            const start = buffered.start(i);

            // Commenting below code since service worker is implemented, uncomment if any issue is faced
            // if (start < 1) {
            //     // in certain cases, browser does not load first few milliseconds of video for longer time. e.g. start would be 0.5
            //     start = 0;
            // }

            isBuffered = (((currentTime - start) - bufferLeftDuration) >= -0.009) &&
              (((buffered.end(i) - currentTime) - bufferRightDuration) >= -0.009);

            if (isBuffered) {
              break;
            }
          }
        }
      } catch (error) {}
    }

    return isBuffered;
  }

  onWaiting() {
    try {
      const currentPlayhead = this.props.t1.time();
      const { currentTime } = this.mediaElRef.current;
      const isWithinPlayTime =
        currentPlayhead >= this.props.playStart &&
        currentPlayhead < this.props.playEnd;

      const isBuffering = this.props.videoBufferStatus.size > 0;
      const isCurrentVideoBuffering =
        isBuffering && this.props.videoBufferStatus.has(this.props.id);

      if (
        this.props.isLoaded &&
        !isCurrentVideoBuffering &&
        isWithinPlayTime &&
        !this.checkMediaHasEnoughData({ useReadyState: false, currentTime })
      ) {
        this.props.updateVideoBufferStatus({
          videoKey: this.props.id,
          isBuffering: true,
          bufferStartedAt: currentPlayhead,
          currentTime,
        });

        this.bufferStartMediaTime = currentTime;
        clearInterval(this.bufferCheckTimer);
        this.bufferCheckTimer = setInterval(() => {
          try {
            const buffered = this.checkMediaHasEnoughData({
              useReadyState: false,
              currentTime,
            });
            if (buffered) {
              this.bufferStartMediaTime = null;
              clearInterval(this.bufferCheckTimer);
              this.props.updateVideoBufferStatus({
                videoKey: this.props.id,
                isBuffering: false,
              });
            } else {
              const isBuffering = this.props.videoBufferStatus.size > 0;
              const { currentTime } = this.mediaElRef.current;
              const isCurrentVideoBuffering =
                isBuffering && this.props.videoBufferStatus.has(this.props.id);

              if (
                isCurrentVideoBuffering &&
                Number.isFinite(this.bufferStartMediaTime) &&
                currentTime - this.bufferStartMediaTime >= 1
              ) {
                this.pause(currentPlayhead);
              }
            }
          } catch (error) {}
        }, this.bufferCheckInterval);
      }
    } catch (error) {}
  }

  assignRef(r) {
    this.mediaElRef.current = r;
    if (typeof this.props.mediaElRef === "function") {
      this.props.mediaElRef(r, this.props.id);
    } else if (
      this.props.mediaElRef &&
      typeof this.props.mediaElRef === "object"
    ) {
      this.props.mediaElRef.current = r;
    }
  }

  render() {
    const src = this.props.isBlob ? this.props.src : addCacheClearQuery(this.props.src, this.props.allowCustomCaching);
    const isBuffering = this.props.videoBufferStatus.size > 0;
    const isWithinPlayRange = this.props.playStart <= this.props.playhead && this.props.playhead <= this.props.playEnd;
    let media = null;
    const VideoComponent = this.props.isBackgroundRemoval ? TransparentVideo : CanvasVideo;

    let preload = "none";
    if (this.preload) {
      preload = this.preload;
    } else if (isWithinPlayRange) {
      preload = "auto";
    }

    if (this.props.mediaType === "video") {
      media =
        this.props.isBackgroundRemoval || this.props.chromaKey ? (
          <>
            {!this.props.isPlayAll && (
              <CanvasImage
                className={this.props.className}
                style={this.props.style}
                src={this.props.poster}
                alt=""
                isBackgroundRemoval={this.props.isBackgroundRemoval}
                chromaKey={this.props.chromaKey}
                videoRef={this.imageRef}
                tolerance={this.props.tolerance}
              />
            )}
            <VideoComponent
              assignRef={this.assignRef}
              src={src}
              className={this.props.className}
              style={this.props.style}
              preload={preload}
              crossOrigin={this.props.crossOrigin || "anonymous"}
              muted={isBuffering}
              isPlayAll={this.props.isPlayAll}
              onWaiting={this.onWaiting}
              mediaElRef={this.mediaElRef}
              isBackgroundRemoval={this.props.isBackgroundRemoval}
              chromaKey={this.props.chromaKey}
              imageRef={this.imageRef}
              tolerance={this.props.tolerance}
            />
          </>
        ) : (
          <>
            {!this.props.isPlayAll && !this.props.isAvatar && !this.props.item?.get("isTransparentAvatar") && (
              // hide thumbnail on play for transparent videos
              <img
                className={this.props.className}
                style={this.props.style}
                src={this.props.poster}
                alt=""
              />
            )}
            <video
              ref={this.assignRef}
              key={src}
              className={this.props.className}
              style={this.props.style}
              preload={preload}
              playsInline={true}
              crossOrigin={this.props.crossOrigin || "anonymous"}
              muted={isBuffering}
              onWaiting={this.props.isPlayAll ? this.onWaiting : undefined}
            >
              <source src={src} />
              {/* {this.props.fallbackSrc && <source src={this.props.fallbackSrc} />} */}
            </video>
          </>
        );
    } else if (this.props.mediaType === "audio") {
      media = (
        <audio
          ref={this.assignRef}
          className={this.props.className}
          preload={preload}
          crossOrigin={this.props.crossOrigin || "anonymous"}
          src={src}
          muted={isBuffering}
          onWaiting={this.props.isPlayAll ? this.onWaiting : undefined}
        />
      );
    }

    return media;
  }
}

MediaComponent.propTypes = {
  src: PropTypes.string,
  // fallbackSrc: PropTypes.string,
  poster: PropTypes.string,
  // volume: PropTypes.number,
  playStart: PropTypes.number,
  playEnd: PropTypes.number,
  mediaStart: PropTypes.number,
  mediaEnd: PropTypes.number,
  playhead: PropTypes.number,
  isPlayAll: PropTypes.bool,
  mediaElRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  style: PropTypes.object,
  className: PropTypes.string,
  runningState: PropTypes.object,
  prefetchToken: PropTypes.string,
  prefetchComplete: PropTypes.func,
  setPlayAll: PropTypes.func,
  isLoaded: PropTypes.bool,
  addTween: PropTypes.func,
  videoBufferStatus: PropTypes.object,
  t1: PropTypes.object,
  updateVideoBufferStatus: PropTypes.func,
  id: PropTypes.string,
  mediaType: PropTypes.oneOf(["video", "audio"]),
  crossOrigin: PropTypes.string,
  playPauseAudio: PropTypes.func,
  isPausePlayer: PropTypes.bool,
  isBackgroundRemoval: PropTypes.bool,
  chromaKey: PropTypes.string,
  tolerance: PropTypes.number,
  speed: PropTypes.number,
  isAvatar: PropTypes.bool,
  allowCustomCaching: PropTypes.bool,
  isBlob: PropTypes.bool,
  item: PropTypes.object
};

const mapStateToProps = (state) => ({
  t1: state.app.get("t1"),
  runningState: state.app.get("runningState"),
  playhead: state.app.get("playhead"),
  isPlayAll: state.app.get("isPlayAll"),
  prefetchToken: state.app.get("prefetchToken"),
  isLoaded: state.app.get("isLoaded"),
  videoBufferStatus: state.app.get("videoBufferStatus"),
  isPausePlayer: state.app.get("isPausePlayer"),
});

const mapDispatchToProps = (dispatch) => ({
  prefetchComplete: (token, count) => dispatch(prefetchComplete(token, count)),
  addTween: (data, tweenType) => dispatch(addTween(data, tweenType)),
  setPlayAll: (data) => dispatch(setPlayAll(data)),
  updateVideoBufferStatus: (data) => dispatch(updateVideoBufferStatus(data)),
});

const Media = connect(mapStateToProps, mapDispatchToProps)(MediaComponent);

const MediaWithEvents = (props) => {
  const playerAudioCallback = useContext(PlayerAudioCallbackContext);
  return (
    <Media
      {...props}
      mediaElRef={playerAudioCallback.assignMediaRef}
      playPauseAudio={playerAudioCallback.playPauseAudio}
    />
  );
};

export default MediaWithEvents;
