/* eslint-disable camelcase, no-restricted-syntax */
import React from "react";
import PropTypes from "prop-types";
import { fromJS, OrderedMap } from "immutable";
import { TweenMax, Back, Bounce, Linear, Power1, Elastic, Expo, Power0  } from 'gsap';
import SplitText from "gsap/SplitText";
import { connect } from "react-redux";
import { addTween, playPausePlayer, setPlayAll, startPlay } from "../../redux/actions/appUtils";
import PlayerAudio from "./player-audio";
import { isVideoOnly, memoize } from "../timeline/timeline-helper";
import { getFrameInnerImageId, singleClipFrameToFrame } from "../frame/frame-helper";
import { transitionTime } from "../timeline/timeline-constants";
import { getSplittedDom } from "../../helper/splitTextHelper";
import { getElementAll } from "../../helper/getElement";

class PlayerComponent extends React.Component {
  constructor(props) {
    super(props);

    // project data filter handlers
    this.filterVideos = memoize(this._filterVideos);

    /* register effect handlers here to keep if else chain minimal in addEffectsToItem */
    this.enterEffectHandlers = {
      no_Effect: this.applyNoEnterEffect,
      rotateZoomInCw: this.rotateZoomin,
      rotateZoomInCcw: this.rotateZoomin,
      fadeIn: this.fadeInEffect,
      fadeinWithZoom: this.fadeinWithZoom,
      slideUp: this.enterMoveEffects,
      slideRight: this.enterMoveEffects,
      slideLeft: this.enterMoveEffects,
      slideDown: this.enterMoveEffects,
      enterPixel: this.pixelEnterHandler,
      brightSquare: this.brightSquareEnterHandler,
      bounce: this.bounceEnterHandler,
      bigToNormal: this.bigToNormal,
      enterFromLeftBottom: this.enterEffects,
      enterFromLeftTop: this.enterEffects,
      enterFromRightBottom: this.enterEffects,
      enterFromRightTop: this.enterEffects,
      enterFromTop: this.enterEffects,
      enterFromLeft: this.enterEffects,
      enterFromBottom: this.enterEffects,
      enterFromRight: this.enterEffects,
      verticalSplit: this.enterSplitEffects,
      horizontalSplit: this.enterSplitEffects,
      wipeUp: this.wipeEnterHandler,
      wipeDown: this.wipeEnterHandler,
      wipeRight: this.wipeEnterHandler,
      wipeLeft: this.wipeEnterHandler,
      rotateZoomInPopupCw: this.rotateZoominPopup,
      rotateZoomInPopupCcw: this.rotateZoominPopup,
      squareCrop: this.irishEnterHandler,
      circleCrop: this.irishEnterHandler,
      horizontalLeftSlice: this.blindsEnterHandler,
      horizontalRightSlice: this.blindsEnterHandler,
      verticalUpSlice: this.blindsEnterHandler,
      verticalDownSlice: this.blindsEnterHandler,
      squeezev: this.squeeze,
      squeezeh: this.squeeze,
      wipeBottomLeft: this.wipeEnterHandler,
      wipeBottomRight: this.wipeEnterHandler,
      wipeTopRight: this.wipeEnterHandler,
      wipeTopLeft: this.wipeEnterHandler,
      popupFromLeft: this.popupTransformHandler,
      popupFromRight: this.popupTransformHandler,
      popupFromTop: this.popupTransformHandler,
      popupFromBottom: this.popupTransformHandler,
      popupFromBottomRight: this.popupTransformHandler,
      popupFromBottomLeft: this.popupTransformHandler,
      popupFromTopLeft: this.popupTransformHandler,
      popupFromTopRight: this.popupTransformHandler, 
      popupFromCenter: this.popupTransformHandler, 
      bounceWords: this.bounceWordsEnterHandler,
      lettersRise: this.lettersRiseEnterHandler,
      pulseWords: this.pulseWordsEnterHandler,
      shiftWords: this.shiftWordsEnterHandler,
      stompWords: this.stompWordsEnterHandler,
      bounceLetters: this.bounceLettersEnterHandler,
      skateLetters: this.skateLettersEnterHandler,
      skateWords: this.skateWordsEnterHandler,
      lineSide: this.lineSlideEnterHandler,
      lineSideZigZag: this.lineSlideZigZagEnterHandler,
      pan: this.panInEnterHandler,
      emerge: this.emergeEnterHandler,
      slideOpen: this.slideOpenEnterHandler
    };
    /* register effect handlers here to keep if else chain minimal in addEffectsToItem */
    this.exitEffectHandlers = {
      no_Effect: this.applyNoExitEffect,
      rotateZoomOutCw: this.applyRotateZoomOutEffect,
      rotateZoomOutCcw:  this.applyRotateZoomOutEffect,
      rotateZoomOutPopupCcw: this.rotateZoomoutPopup,
      rotateZoomOutPopupCw: this.rotateZoomoutPopup,
      fadeOut: this.fadeOutEffect,
      fadeoutWithZoom: this.fadeoutWithZoom,
      pixel: this.exitPixelHandler,
      brightSquare: this.brightSquareExitHandler,
      normalToBig: this.normalToBig,
      exitToLeft: this.exitEffects,
      exitToLeftBottom: this.exitEffects,
      exitToTop: this.exitEffects,
      exitToRight: this.exitEffects,
      exitToBottom: this.exitEffects,
      exitToRightTop: this.exitEffects,
      exitToRightBottom: this.exitEffects,
      exitToLeftTop: this.exitEffects,
      slideDown: this.exitHideEffects,
      slideRight: this.exitHideEffects,
      slideUp: this.exitHideEffects,
      slideLeft: this.exitHideEffects,
      wipeUp: this.wipeExitHandler,
      wipeDown: this.wipeExitHandler,
      wipeRight: this.wipeExitHandler,
      wipeLeft: this.wipeExitHandler,
      squareCrop: this.irishExitHandler,
      circleCrop: this.irishExitHandler,
      exitZoomIn: this.exitZoomIn,
      verticalUpSlice: this.blindsExitHandler,
      verticalDownSlice: this.blindsExitHandler,
      horizontalLeftSlice: this.blindsExitHandler,
      horizontalRightSlice: this.blindsExitHandler,
      wipeBottomLeft: this.wipeExitHandler,
      wipeBottomRight: this.wipeExitHandler,
      wipeTopRight: this.wipeExitHandler,
      wipeTopLeft: this.wipeExitHandler,
      popDownToLeft: this.popdownTransformHandler, 
      popDownToRight: this.popdownTransformHandler, 
      popDownToTop: this.popdownTransformHandler, 
      popDownToBottom: this.popdownTransformHandler, 
      popDownToTopLeft: this.popdownTransformHandler, 
      popDownToBottomLeft: this.popdownTransformHandler, 
      popDownToBottomRight: this.popdownTransformHandler, 
      popDownToTopRight: this.popdownTransformHandler, 
      popDownToCenter: this.popdownTransformHandler, 
      bounceWords: this.bounceWordsExitHandler,
      lettersRise: this.lettersRiseExitHandler,
      pulseWords: this.pulseWordsExitHandler,
      shiftWords: this.shiftWordsExitHandler,
      stompWords: this.stompWordsExitHandler,
      bounceLetters: this.bounceLettersExitHandler,
      skateLetters: this.skateLettersExitHandler,
      skateWords: this.skateWordsExitHandler,
      lineSide: this.lineSlideExitHandler,
      lineSideZigZag: this.lineSlideZigZagExitHandler,
      pan: this.panInExitHandler,
      emerge: this.emergeExitHandler,
      slideOpen: this.slideOpenExitHandler
    };

    this.subtitleEnterEffectHandlers = {
      default: this.applyNoEnterEffect,
      textReveal: this.textReveal,
      textHighlight: this.textHighlight,
      textFlip: this.textFlip,
      textAlphaFadeIn: this.textAlphaFadeEffect,
      textAlphaFadeOut: this.textAlphaFadeEffect,
      textScaleIn: this.textScaleIn,
      textRevealWordbyWord: this.textRevealWordbyWord,
      textFloatDown: this.textFloat,
      textFloatUp: this.textFloat,
      textDropIn: this.textDropIn,
      textColorHighlight: this.textColorHighlight,
      textPopupWordbyWord: this.textPopupWordbyWord,
    };

    this.subtitleExitEffectHandlers = {
      default: this.applyNoExitEffect,
    };

    this.transitionEnterEffHandlers = {
      none_transition: this.transitionNoEnterEffect,
      exitToLeft: this.transitionSlideExitEffect,
      exitToRight: this.transitionSlideExitEffect,
      exitToTop: this.transitionSlideExitEffect,
      exitToBottom: this.transitionSlideExitEffect,
      wipeLeft: this.transitionWipeExitEffect,
      wipeUp: this.transitionWipeExitEffect,
      wipeDown: this.transitionWipeExitEffect,
      wipeRight: this.transitionWipeExitEffect,
      wipetl: this.transitionWipeExitEffect,
      wipetr: this.transitionWipeExitEffect,
      wipebl: this.transitionWipeExitEffect,
      wipebr: this.transitionWipeExitEffect,
      vuslice: this.transitionSliceExitEffect,
      vdslice: this.transitionSliceExitEffect,
      hrslice: this.transitionSliceExitEffect,
      hlslice: this.transitionSliceExitEffect,
      fade: this.transitionFade

    }
    this.transitionExitEffHandlers = {
      none_transition: this.transitionNoExitEffect,
      exitToLeft: this.transitionSlideEnterEffect,
      exitToRight: this.transitionSlideEnterEffect,
      exitToTop: this.transitionSlideEnterEffect,
      exitToBottom: this.transitionSlideEnterEffect,
      wipeLeft: this.transitionNoExitEffect,
      wipeUp: this.transitionNoExitEffect,
      wipeDown: this.transitionNoExitEffect,
      wipeRight: this.transitionNoExitEffect,
      wipetl: this.transitionNoExitEffect,
      wipetr: this.transitionNoExitEffect,
      wipebl: this.transitionNoExitEffect,
      wipebr: this.transitionNoExitEffect,
      vuslice: this.transitionNoExitEffect,
      vdslice: this.transitionNoExitEffect,
      hrslice: this.transitionNoExitEffect,
      hlslice: this.transitionNoExitEffect,
      fade: this.transitionNoExitEffect
    }
    
  }

  componentDidMount() {
    this.t1 = this.props.t1;
    window.t1 = this.t1
    this.addPlayerEssentials(this.props);
  }


  // eslint-disable-next-line react/sort-comp
  addMaskDOM = (domName, idVal, xVal, yVal, widthVal, heightVal, strokeWidthVal) => {
    const newDOM = document.createElementNS('http://www.w3.org/2000/svg', domName);
    newDOM.setAttributeNS(null, "id", idVal);
    newDOM.setAttributeNS(null, "x", xVal);
    newDOM.setAttributeNS(null, "y", yVal);
    newDOM.setAttributeNS(null, "width", widthVal);
    newDOM.setAttributeNS(null, "height", heightVal);
    newDOM.setAttributeNS(null, "stroke-width", strokeWidthVal);
    newDOM.setAttributeNS(null, "fill", "beige");
    newDOM.setAttributeNS(null, "stroke", "beige");
    return newDOM;
  }


  // showWhiteSpace = (targetElm) => {
  //   let nextSibling = targetElm.nextElementSibling;
  //   while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
  //     nextSibling.style.opacity = 1;
  //     nextSibling = nextSibling.nextElementSibling;
  //   }
  // }

  //  showLiTag = (targetElm) => {
  //   let prevElemSib = targetElm.previousElementSibling;
  //   while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
  //     prevElemSib.style.opacity = 1;
  //     prevElemSib = prevElemSib.previousElementSibling;
  //   }
  //   if (prevElemSib === null) {
  //     if (targetElm.parentElement.nodeName === "LI") {
  //       targetElm.parentElement.style.visibility = "visible";
  //     }
  //   }
  // }

  removeMask = (elementId) => {
    const mask_name = `mymask_${elementId}`;
    let elements = [];
    let tmpsvg; let par;
    elements = getElementAll(`*[id^="${mask_name}"]`);

    for (let cnt = 0; cnt < elements.length; cnt++) {
      tmpsvg = document.getElementById(elements[cnt].id);
      if (tmpsvg !== null) {
        par = tmpsvg.parentNode;
        par.removeChild(tmpsvg);
        document.getElementById(elementId).removeAttribute("mask");
      }
    }
  }

  currentObjectHandlder = (item) => {
    const currentObj = fromJS({
      enter: item.get("enterEffectName"),
      exit: item.get("exitEffectName"),
      enterduration: item.get("enterEnd") - item.get("enterStart"),
      exitduration: item.get("exitEnd") - item.get("exitStart"),
      xval: item.get("x"),
      yval: item.get("y"),
      width: item.get("width"),
      height: item.get("height"),
      rotation: item.get("angle"),
      parentRotation: item.get("angle"),
      // parentRotation: nextProps.get("gAngle"),
    });
    return currentObj;
  }

  addMask = (elementId, type, direction, rotation, scaleX, scaleY, effectType) => {
    const val = document.getElementById(elementId);
    const bounds = val.getBoundingClientRect();
    if (!scaleX) scaleX = 1; if (!scaleY) scaleY = 1;
    if (!direction)
      direction = "";
    if (!type)
      type = "";
    if (val !== null) {
      let mask_rect = `mask_${elementId}_rect`;
      const mask_tag = document.createElementNS('http://www.w3.org/2000/svg', "clipPath");
      let mask_name; let myRect;
      const mySVG = document.createElementNS('http://www.w3.org/2000/svg', "svg");
      let count;
      const totalCount = 10;
      if (type === "pixelsquare" || type === "square") {

        if (effectType !== "exit") {
          mask_name = `mymask_${elementId}${type}`;
        }
        else {
          mask_name = `mymask_${elementId}exitsquare`;
          mask_rect += "exit"
        }

        val.style.clipPath = `url(#${mask_name})`;
        val.style.webkitClipPath = `url(#${mask_name})`; val.style.mozClipPath = `url(#${mask_name})`; val.style.msClipPath = `url(#${mask_name})`; val.style.oClipPath = `url(#${mask_name})`;
        mask_tag.setAttributeNS(null, "id", mask_name);
        const h = document.getElementById(elementId).offsetHeight;
        const w = document.getElementById(elementId).offsetWidth;
        let totalSquare = 6;
        if (type === "square") totalSquare = 6;
        for (count = 0; count < totalSquare; count++) {
          for (let cnt = 0; cnt < totalSquare; cnt++) {

            const changeValY = cnt * h / totalSquare;
            const changeValX = count * w / totalSquare;
            myRect = this.addMaskDOM("rect", (mask_rect + count + cnt), changeValX, changeValY, ((w / totalSquare) + 1), ((h / totalSquare) + 1), val.getAttribute("stroke-width"));
            mask_tag.appendChild(myRect);
          }
        }

        mySVG.setAttributeNS(null, "width", 0);
        mySVG.setAttributeNS(null, "height", 0);

        mySVG.appendChild(mask_tag);
        // document.getElementById(elementId).parentElement.appendChild(mySVG);
        document.getElementById(elementId).appendChild(mySVG);
      } else if (type === "multiWidth" || type === "multiHeight") {
        mask_name = `mymask_${elementId}${type}`;
        const h = val.offsetHeight;
        const w = val.offsetWidth;
        val.style.clipPath = `url(#${mask_name})`;
        val.style.webkitClipPath = `url(#${mask_name})`; val.style.mozClipPath = `url(#${mask_name})`; val.style.msClipPath = `url(#${mask_name})`; val.style.oClipPath = `url(#${mask_name})`;
        mask_tag.setAttributeNS(null, "id", mask_name);
        for (count = 0; count < totalCount; count++) {
          if (type === "multiWidth")
            myRect = this.addMaskDOM("rect", (mask_rect + count), ((count * (w / totalCount))), 0, ((w / totalCount) + 1), h, val.getAttribute("stroke-width"))
          else if (type === "multiHeight")
            myRect = this.addMaskDOM("rect", (mask_rect + count), 0, ((count * h / totalCount)), w, ((h / totalCount) + 1), val.getAttribute("stroke-width"))
          mask_tag.appendChild(myRect);
        }

        mySVG.setAttributeNS(null, "width", 0);
        mySVG.setAttributeNS(null, "height", 0);

        mySVG.appendChild(mask_tag);
        document.getElementById(elementId).appendChild(mySVG);
      }
      else if (type === "circle") {
        mask_name = `mymask_${elementId}${type}`;
        val.style.clipPath = `url(#${mask_name})`;
        val.style.webkitClipPath = `url(#${mask_name})`;
        val.style.mozClipPath = `url(#${mask_name})`;
        val.style.msClipPath = `url(#${mask_name})`;
        val.style.oClipPath = `url(#${mask_name})`;

        mask_tag.setAttributeNS(null, "id", mask_name);

        const myCir = document.createElementNS('http://www.w3.org/2000/svg', "circle");
        myCir.setAttributeNS(null, "id", mask_rect);
        myCir.setAttributeNS(null, "cx", (document.getElementById(elementId).offsetWidth) / 2);
        myCir.setAttributeNS(null, "cy", (document.getElementById(elementId).offsetHeight) / 2);
        myCir.setAttributeNS(null, "r", (document.getElementById(elementId).offsetWidth + document.getElementById(elementId).offsetHeight) / 2);
        myCir.setAttributeNS(null, "stroke-width", val.getAttribute("stroke-width"));
        myCir.setAttributeNS(null, "fill", "beige");
        myCir.setAttributeNS(null, "stroke", "beige");

        mask_tag.appendChild(myCir);
        mySVG.setAttributeNS(null, "width", 0);
        mySVG.setAttributeNS(null, "height", 0);
        mySVG.appendChild(mask_tag);
        document.getElementById(elementId).appendChild(mySVG);

      } else if (type === "splitVer" || type === "splitHor") {
        const totalCount = 2;
        mask_name = `mymask_${elementId}split`;
        val.style.clipPath = `url(#${mask_name})`;
        val.style.webkitClipPath = `url(#${mask_name})`; val.style.mozClipPath = `url(#${mask_name})`; val.style.msClipPath = `url(#${mask_name})`; val.style.oClipPath = `url(#${mask_name})`;
        mask_tag.setAttributeNS(null, "id", mask_name);
        const h = bounds.height;
        const w = bounds.width;
        for (count = 0; count < totalCount; count++) {
          if (type === "splitHor")
            myRect = this.addMaskDOM("rect", (mask_rect + count), 0, ((count * h / 2)), w, ((h / totalCount) + 1), val.getAttribute("stroke-width"))
          else
            myRect = this.addMaskDOM("rect", (mask_rect + count), ((count * w / 2)), 0, ((w / totalCount) + 1), h, val.getAttribute("stroke-width"))

          mask_tag.appendChild(myRect);
        }
        mySVG.setAttributeNS(null, "width", 0);
        mySVG.setAttributeNS(null, "height", 0);
        mySVG.appendChild(mask_tag);
        document.getElementById(elementId).appendChild(mySVG);

      } else {
        const totalCount = 2;
        mask_name = `mymask_${elementId}${type}`;
        val.style.clipPath = `url(#${mask_name})`;
        val.style.webkitClipPath = `url(#${mask_name})`; val.style.mozClipPath = `url(#${mask_name})`; val.style.msClipPath = `url(#${mask_name})`; val.style.oClipPath = `url(#${mask_name})`;

        mask_tag.setAttributeNS(null, "id", mask_name);

        let h = bounds.height > document.getElementById(elementId).offsetHeight ? bounds.height : document.getElementById(elementId).offsetHeight; //* Math.abs(Math.cos(rotation)) + bounds.width * Math.abs(Math.sin(rotation));
        let w = bounds.width > document.getElementById(elementId).offsetWidth ? bounds.width : document.getElementById(elementId).offsetWidth; //* Math.abs(Math.cos(rotation)) + bounds.height * Math.abs(Math.sin(rotation));
        if (type === "reveal" || type === "irish") {
          h = document.getElementById(elementId).offsetHeight;
          w = document.getElementById(elementId).offsetWidth;
        }

        if (type === "multiWipe") {
          for (count = 0; count < totalCount; count++) {
            myRect = this.addMaskDOM("rect", (mask_rect + count), 0, 0, w, h);
            mask_tag.appendChild(myRect);
          }
        } else {
          myRect = this.addMaskDOM("rect", mask_rect, 0, 0, w, h);
          mask_tag.appendChild(myRect);
        }

        if (type === "multiWipe") {
          type = "wipe"
        }

        mySVG.setAttributeNS(null, "width", 0);
        mySVG.setAttributeNS(null, "height", 0);
        mySVG.style.top = 0;
        if (rotation) {
          if (scaleX === scaleY)
            rotation *= -1;
          const rotVal = `rotate(${rotation})`
          myRect.setAttribute("transform", rotVal);
        }

        mySVG.appendChild(mask_tag);
        // document.getElementById(elementId).parentElement.appendChild(mySVG);
        // if(isScene)
        document.getElementById(elementId).appendChild(mySVG);
        // else
        //   document.getElementById(elementId).insertBefore(mySVG, document.getElementById(elementId).childNodes[0]);
        const element = document.getElementById(elementId);
        const maskElement = document.getElementById(mask_rect);
        if (type !== "wipe" || (type === "wipe" && rotation !== 0) && element && maskElement) {
          const newx = element.getBoundingClientRect().x - maskElement.getBoundingClientRect().x;
          const newy = element.getBoundingClientRect().y - maskElement.getBoundingClientRect().y;
          myRect.setAttribute("x", newx * scaleX);
          myRect.setAttribute("y", newy * scaleY);
        }
      }


    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoaded !== this.props.isLoaded && this.props.isLoaded) {
      this.t1.play(this.props.runningState.get("seekPlayhead"), false);
    }

    if (
      (this.props.isAnimLoaded || this.props.isLoaded) &&
      this.props.runningState.get("seekToken") !==
      prevProps.runningState.get("seekToken")
    ) {
      const isBuffering = this.props.videoBufferStatus.size > 0;
      if (isBuffering || (this.props.isAnimLoaded || this.props.isPausePlayer)) {
        this.t1.pause(this.props.runningState.get("seekPlayhead"), false);
      } else {
        this.t1.play(this.props.runningState.get("seekPlayhead"), false);
      }
    }
    if (
      !this.props.isLoaded &&
      this.props.prefetchCompleteCount !== prevProps.prefetchCompleteCount &&
      this.props.prefetchCompleteCount === this.props.prefetchCount
    ) {
      this.props.startPlay("workspace");
    }
  }

  componentWillUnmount() {
    this.filterVideos.clear();
  }

  playEnded = () => {
    if (this.props.isAnimoPlayer || this.props.isShortsPlayer) {
      const projDuration = this.props.projectData.get("duration");
      // Pause the player and seek to the start.
      this.t1.pause(projDuration, false);
      this.props.pausePlayer({ playhead: projDuration, isPause: true });
    } else {
      this.props.setPlayAll(false);
    }
  }

  addPlayerEssentials = (props) => {
    const { projectData } = props;
    const projDuration = projectData.get("duration");
    const workspaceItems = projectData.get("workspaceItems");
    const workspaceBG = projectData.get("workspaceBG");
    const subtitle = projectData.get("localSubtitle");
    // to make gsap run till project duration
    this.tweenSetHandler(projDuration);

    // to stop play on project end
    this.tweenAddCallbackHandler({
      callBack: this.playEnded,
      startTime: projDuration,
      dataArr: [],
    });

    // position of groupChildren is not handled. If needed, please refer filterWorkspaceItems function of Animaker App
    workspaceItems.valueSeq().forEach((item) => {
      this.addEffectsToItem(item);
    });

    workspaceBG.valueSeq().forEach((item) => {
      this.addEffectsToBG(item);
    });
    if (subtitle) {
      subtitle.valueSeq().forEach((subtitleItem) => {
        this.addEffectsToSubtitle(subtitleItem, projectData);
      });
    }

    this.props.startPlay("player");
  }

  addEffectsToBG = (item) => {
    const itemEl = document.getElementById(item.get("id"));

    if (!itemEl) {
      // this shouldn't happen but just in case
      return;
    }

    // for now reuse effect handler of ws item until transition is implemented
    item = item.set("opacity", 1);
    item = item.set("enterStart", item.get("playStart"));
    item = item.set("exitEnd", item.get("playEnd"));
    this.enterEffectHandlers.no_Effect(itemEl, item);
    this.exitEffectHandlers.no_Effect(itemEl, item);
  }

  addEffectsToItem = (item) => {
    const itemEl = document.getElementById(item.get("id"));

    if (!itemEl) {
      // this shouldn't happen but just in case
      return;
    }

    const enterEffect = item.get("enterEffectName")
      ? item.get("enterEffectName")
      : "no_Effect";
    const exitEffect = item.get("exitEffectName")
      ? item.get("exitEffectName")
      : "no_Effect";

    const transitionEnterEffect = item.get("transitionEnterEffect");

    const transitionExitEffect = item.get("transitionExitEffect");

    if (typeof this.enterEffectHandlers[enterEffect] === "function" && (!item.get("transitionExitId") || item.get("transitionExitId") === "none" || !item.get("transitionExitEffect") || item.get("transitionExitEffect") === "none_transition")) {
      this.enterEffectHandlers[enterEffect](itemEl, item);
    }
    if (typeof this.exitEffectHandlers[exitEffect] === "function" && (!item.get("transitionEnterId") || item.get("transitionEnterId") === "none" || !item.get("transitionEnterEffect") || item.get("transitionEnterEffect") === "none_transition")) {
      this.exitEffectHandlers[exitEffect](itemEl, item);
    }

    if (transitionEnterEffect && transitionEnterEffect !== "none_transition" && item.get("transitionEnterId") && item.get("transitionEnterId") !== "none"  && typeof this.transitionEnterEffHandlers[transitionEnterEffect] === "function") {
      this.transitionEnterEffHandlers[transitionEnterEffect](itemEl, item);
    }
   
    if (transitionExitEffect && transitionExitEffect !== "none_transition" && item.get("transitionExitId") &&  item.get("transitionExitId") !== "none" && typeof this.transitionExitEffHandlers[transitionExitEffect] === "function") {
      this.transitionExitEffHandlers[transitionExitEffect](itemEl, item);
    }

  }

  addEffectsToSubtitle = (item, projectData) => {
    const itemEl = document.getElementById(item.getIn(["subtitleId", "timelineId"]));
    if (!itemEl) {
      // this shouldn't happen but just in case
      return;
    }
    const effectName = projectData.getIn(["subtitle", "animData", "animation"]);
    if (typeof this.subtitleEnterEffectHandlers[effectName] === "function") {
      this.subtitleEnterEffectHandlers[effectName](itemEl, item, true);
    }
    if (typeof this.subtitleExitEffectHandlers[effectName] === "function") {
      this.subtitleExitEffectHandlers[effectName](itemEl, item, true);
    }
  }

  maskExist(elementId, type) {
    if (!type)
      type = "";

    if (document.getElementById(`mymask_${elementId}${type}`)) {
      return true;
    }
    return false;
  }

  _filterVideos = (workspaceBG, workspaceItems, workspaceChildren) => {
    let videos = OrderedMap();

    if (workspaceBG) {
      for (const [itemId, item] of workspaceBG.entrySeq()) {
        if (isVideoOnly(item.get("type"), item.get("subType"))) {
          videos = videos.set(itemId, item);
        }
      }
    }

    const setWorkspaceItemVideos = (items) => {
      for (const itemEntry of items.entrySeq()) {
        let item = itemEntry[1];
        if (item.get("isSingleClipFrame")) {
          item = singleClipFrameToFrame(item);
        }
        const itemId = itemEntry[0];

        if (isVideoOnly(item.get("type"), item.get("subType"))) {
          const videoParams = item
            .set("playStart", item.get("enterStart"))
            .set("playEnd", item.get("exitEnd"));

          videos = videos.set(itemId, videoParams);
        } else if (item.get("type") === "FRAME") {
          const clipDetails = item.get("clipDetails");

          for (const [clipId, clipDetail] of clipDetails.entrySeq()) {
            const imgDetails = clipDetail.get("imgDetails");

            if (
              isVideoOnly(imgDetails.get("type"), imgDetails.get("subType"))
            ) {
              const clipImgId = getFrameInnerImageId(itemId, clipId);
              const videoParams = imgDetails
                .set("id", clipImgId)
                .set("playStart", item.get("enterStart"))
                .set("playEnd", item.get("exitEnd"));

              videos = videos.set(clipImgId, videoParams);
            }
          }
        }
      }
    };

    if (workspaceItems) {
      setWorkspaceItemVideos(workspaceItems);
    }

    if (workspaceChildren) {
      setWorkspaceItemVideos(workspaceChildren);
    }

    return videos;
  }

  /* Put effect related code after this comment */

  rotateZoomin = (itemEl, item) => {
    const effect = item.get("enterEffectName");
    const enterStart = item.get("enterStart");
    const enterEnd = item.get("enterEnd");
    const rotationVal = item.get("angle");
    const duration = enterEnd - enterStart;
    const scaleXVal = 1;
    const scaleYVal = 1;
    this.removeMask(itemEl.id);

    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
    if (effect === "rotateZoomInCw") {
      this.tweenFromToHandler(itemEl, duration, {
        scale: 0, rotation: "-=180", transformOrigin: "50% 50%",
        immediateRender: false
      }, {
        scaleX: scaleXVal, scaleY: scaleYVal,
        rotation: rotationVal, transformOrigin: "50% 50%"
      }, enterStart);
    }
    else if (effect === "rotateZoomInCcw") {
      this.tweenFromToHandler(itemEl, duration, { rotation: "+=180", scale: 0, transformOrigin: "50% 50%" }, {
        rotation: rotationVal,
        scaleX: scaleXVal, scaleY: scaleYVal, transformOrigin: "50% 50%"
      }, enterStart);
    }
  }

  rotateZoominPopup = (itemEl, item) => {
    const effect = item.get("enterEffectName");
    const enterStart = item.get("enterStart");
    const enterEnd = item.get("enterEnd");
    const rotationVal = item.get("angle");
    const duration = enterEnd - enterStart;
    const scaleXVal = 1;
    const scaleYVal = 1;
    this.removeMask(itemEl.id);
    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
    this.handleTransformMatrix = (itemEl) => {
      itemEl.style.transform = itemEl.style.transform.replace(
        /matrix\(0, 0, 0, 0, (.*?), (.*?)\)/,
        "matrix(1, 0, 0, 1, $1, $2)"
      );
    };
    this.tweenFromToHandler(itemEl, duration - 0.0000001, { scale: 0, transformOrigin: "50% 50%", immediateRender: false }, { scaleX: scaleXVal, scaleY: scaleYVal, transformOrigin: "50% 50%", ease: Back.easeOut }, enterStart + 0.0000001);
    if (effect === "rotateZoomInPopupCw")
      this.tweenFromToHandler(itemEl, duration - duration / 2, { rotation: "-=180", transformOrigin: "50% 50%" }, { rotation: rotationVal, transformOrigin: "50% 50%", onComplete: () => this.handleTransformMatrix(itemEl), }, enterStart);
    else if (effect === "rotateZoomInPopupCcw") {
      this.tweenSetHandler(enterStart, itemEl, { scale: 0 });
      this.tweenFromToHandler(itemEl, duration - duration / 2, { rotation: "+=180", transformOrigin: "50% 50%" }, { rotation: rotationVal, transformOrigin: "50% 50%", onComplete: () => this.handleTransformMatrix(itemEl) }, enterStart);
    }
    // this.tweenSetHandler(enterEnd, itemEl, { opacity: item.get("opacity") });
  }

  pixelEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const enterEnd = item.get("enterEnd");
    const enterDuration = enterEnd - enterStart;
    this.removeMask(itemEl.id);
    this.addMask(itemEl.id, "pixelsquare");
    const mask_rect = `#mask_${itemEl.id}_rect`;
    const totalCount = 10;
    const shuffle = (array) => {
      let m = array.length; let t; let i;
      while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }
      return array;
    }

    let maskGroup = [];
    for (let count = 0; count < totalCount; count++) {
      for (let cnt = 0; cnt < totalCount; cnt++) {
        maskGroup.push(mask_rect + count + cnt);
      }
    }
    maskGroup = shuffle(maskGroup);

    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
    this.tweenStaggerFromToHandler(maskGroup, enterDuration, { transformOrigin: "50% 50%", display: "none" }, { transformOrigin: "50% 50%", display: "block" }, enterDuration / totalCount ** 2, enterStart);
    this.tweenToHandler(itemEl, 0.01, { "clip-path": "" }, enterEnd);

  }

  brightSquareEnterHandler = (itemEl, item) => {
    this.removeMask(itemEl.id);
    this.addMask(itemEl.id, "square");
    const enterduration = item.get("enterEnd") - item.get("enterStart")
    const enterStart = item.get("enterStart");
    const mask_rect = `#mask_${itemEl.id}_rect`;

    const totalCount = 6;
    for (let count = 0; count < totalCount; count++) {
      for (let cnt = 0; cnt < totalCount; cnt++) {
        this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
        this.tweenFromToHandler(mask_rect + count + cnt, enterduration, { transformOrigin: "50% 50%", scale: 0 }, { transformOrigin: "50% 50%", scale: 1 }, enterStart);
      }
    }
  }

  bounceEnterHandler = (itemEl, item) => {
    const opacity = item.get("opacity");
    const enterStart = item.get("enterStart");
    const enterduration = item.get("enterEnd") - item.get("enterStart")
    const heightVal = item.get("height")
    const yVal = item.get("y")

    this.removeMask(itemEl.id);
    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
    this.tweenFromToHandler(itemEl, enterduration,
      { y: ((heightVal) * 1) * this.props.zoomFactor },
      { opacity, y: (yVal * this.props.zoomFactor), ease: Bounce.easeOut, immediateRender: false },
      enterStart
    );
  }

  bigToNormal = (itemEl, item) => {
    const enterduration = item.get("enterEnd") - item.get("enterStart")
    const angle = item.get("angle");
    const opacity = item.get("opacity");
    const enterStart = item.get("enterStart");

    this.removeMask(itemEl.id);
    this.tweenFromToHandler(
      itemEl,
      enterduration,
      { opacity: 0, scale: 2, rotation: angle },
      { opacity, scaleX: 1, scaleY: 1, immediateRender: false },
      enterStart
    );
  }

  normalToBig = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    function onStartTween(blockId) {
      this.removeMask(blockId);
      // this.tweenToHandler(itemEl, duration / 2, { rotation: "+=360", transformOrigin: "center center" }, exitStart);
      this.tweenToHandler(itemEl, duration / 2, { scale: 5, transformOrigin: "center center", immediateRender: false, ease: Back.easeOut }, parseFloat(exitStart) + parseFloat(duration / 2));
      this.tweenToHandler(itemEl, duration / 5, { opacity: 0, transformOrigin: "center center" }, parseFloat(exitStart) + parseFloat(duration - duration / 5));
    }
    this.tweenfromHandler(itemEl.id, 0.1, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
  }

  enterMoveEffects = (itemEl, item) => {
    const effect = item.get("enterEffectName");
    const xval = item.get("x");
    const yval = item.get("y");
    const rotation = item.get("angle");
    const scaleX = 1;
    const scaleY = 1;
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");

    this.removeMask(itemEl.id);
    this.addMask(itemEl.id, "wipe", "", rotation, scaleX, scaleY);
    const mask_rect = `#mask_${itemEl.id}_rect`;

    const yvalue = document
      .getElementById(`mask_${itemEl.id}_rect`)
      .getAttribute("y");
    const xvalue = document
      .getElementById(`mask_${itemEl.id}_rect`)
      .getAttribute("x");
    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });

    switch (effect) {
      case "slideUp": {
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { y: yval * this.props.zoomFactor + itemEl.offsetHeight },
          { y: yval * this.props.zoomFactor },
          enterStart
        );
        this.tweenfromHandler(
          mask_rect,
          enterDuration,
          { attr: { y: parseFloat(yvalue) + itemEl.offsetHeight * -scaleY } },
          enterStart
        );
        break;
      } case "slideRight": {
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { x: xval * this.props.zoomFactor - itemEl.offsetWidth },
          { x: xval * this.props.zoomFactor },
          enterStart
        );
        this.tweenfromHandler(
          mask_rect,
          enterDuration,
          { attr: { x: parseFloat(xvalue) + itemEl.offsetWidth * scaleX } },
          enterStart
        );
        break;
      } case "slideLeft": {
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { x: xval * this.props.zoomFactor + itemEl.offsetWidth },
          { x: xval * this.props.zoomFactor },
          enterStart
        );
        this.tweenfromHandler(
          mask_rect,
          enterDuration,
          { attr: { x: parseFloat(xvalue) + itemEl.offsetWidth * -scaleX } },
          enterStart
        );
        break;
      } case "slideDown": {
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { y: yval * this.props.zoomFactor - itemEl.offsetHeight },
          { y: yval * this.props.zoomFactor },
          enterStart
        );
        this.tweenfromHandler(
          mask_rect,
          enterDuration,
          { attr: { y: parseFloat(yvalue) + itemEl.offsetHeight * scaleY } },
          enterStart
        );
        break;
      }
      default:
        break;
    }
  }

  fadeInEffect = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const enterEnd = item.get("enterEnd");
    const duration = enterEnd - enterStart;

    this.tweenSetHandler(enterStart, itemEl, { opacity: 0 });
    this.tweenToHandler(itemEl, duration, { opacity: 1, immediateRender: false, ease: Back.easeNone }, enterStart);

  }

  exitZoomIn = (itemEl, item) => {
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart
    const id = item.get("id");
    const currentElement = document.getElementById(`${id}animo`);

    this.tweenSetHandler(exitStart, itemEl, { opacity: 1 });
    this.tweenFromToHandler(currentElement, duration, { scale: 1, transformOrigin: "50% 50%", ease: "Power1.easeIn" }, { scale: 40, transformOrigin: "50% 50%", ease: "Power1.easeIn", }, exitStart);
    this.tweenSetHandler(exitEnd, currentElement, { opacity: 0 });
    this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });
  }

  irishEnterHandler = (itemEl, item) => {
    const effectType = item.get("enterEffectName");
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");

    this.removeMask(itemEl.id);
    if (effectType === "squareCrop")
      this.addMask(itemEl.id, "irish");
    else if (effectType === "circleCrop")
      this.addMask(itemEl.id, "circle");

    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    const mask_rect = `#mask_${itemEl.id}_rect`;
    this.tweenSetHandler(enterStart, mask_rect, { attr: { x: 0, y: 0 }, opacity: 1, transformOrigin: "50% 50%" });
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1, transformOrigin: "50% 50%" });
    this.tweenFromToHandler(mask_rect, enterDuration, { scale: 0, transformOrigin: "50% 50%" }, { scale: 1, transformOrigin: "50% 50%" }, enterStart);
  }

  irishExitHandler = (itemEl, item) => {
    const effectType = item.get("exitEffectName");
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart

    const onStartTween = (blockId, type) => {
      if (!type)
        type = "";
      const maskFound = this.maskExist(blockId, type);
      if (!maskFound) {
        this.removeMask(blockId);
        this.addMask(blockId, type);
      }

      const mask_rect = `#mask_${blockId}_rect`;
      this.tweenSetHandler(enterStart, mask_rect, { scale: 1, transformOrigin: "50% 50%" });
      this.tweenSetHandler(enterStart, itemEl, { scale: 1, transformOrigin: "50% 50%" });
      this.tweenToHandler(mask_rect, duration, { scale: 0, transformOrigin: "50% 50%" }, exitStart);
    }

    if (effectType === "squareCrop") {
      this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "irish"], opacity: 1 }, exitStart);
    }
    else if (effectType === "circleCrop") {
      this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "circle"], opacity: 1 }, exitStart);
    }
    this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });
  }

  fadeinWithZoom = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const enterEnd = item.get("enterEnd");
    const duration = enterEnd - enterStart;
    const opacity = item.get("opacity");
    const xVal = item.get("x");
    const yVal = item.get("y");

    this.removeMask(itemEl.id);
    this.tweenFromToHandler(itemEl, duration, { alpha: 0 }, { alpha: opacity, force3D: true }, enterStart);
    const popupHandler = (itemEl, startTime, enterTime, xval, yval) => {
      const groupXoffset = 0;
      const groupYoffset = 0;
      const rotateHeightOffset = 0;
      const rotateWidthOffset = 0;

      const updateWrapper = () => {
        if (!document.getElementById(`${itemEl.id}_tempWrapper`)) {
          const parent = itemEl.parentNode;
          const wrapper = document.createElement("div");
          parent.replaceChild(wrapper, itemEl);
          wrapper.appendChild(itemEl);
          itemEl.parentNode.style.width =`${itemEl.getBoundingClientRect().width}px`;
          itemEl.parentNode.style.height = `${itemEl.getBoundingClientRect().height}px`;
          itemEl.parentNode.style.display = "block";
          itemEl.parentNode.style.position = "absolute";
          itemEl.parentNode.setAttribute("id", `${itemEl.id}_tempWrapper`);
          const transformOriginVal = "50% 50%"

          this.removeMask(itemEl.id);
          this.tweenToHandler(itemEl.parentNode, startTime, { scaleX: 1, scaleY: 1, immediateRender: false, ease: Back.easeOut, }, enterTime);
          itemEl.style.left = `${-xval + rotateWidthOffset + groupXoffset}px`;
          itemEl.style.top = `${-yval + rotateHeightOffset + groupYoffset}px`;
          TweenMax.set(itemEl.parentNode, {
            x: xval - groupXoffset - rotateWidthOffset, y: yval - groupYoffset - rotateHeightOffset,
            transformOrigin: transformOriginVal, scaleX: 0, scaleY: 0
          });
        }
      }
      // Deleting the wrapper at the time effects have finished.
      function delWrapper() {
        if (document.getElementById(`${itemEl.id}_tempWrapper`))
          document.getElementById(`${itemEl.id}_tempWrapper`).replaceWith(...document.getElementById(`${itemEl.id}_tempWrapper`).childNodes)
      }
      this.t1.addCallback(delWrapper, startTime + enterTime);
      // To revert the position of the innerblock.
      function revertPosition() {
        itemEl.style.left = "0px";
        itemEl.style.top = "0px";
      }

      this.t1.addCallback(revertPosition, startTime + enterTime);
      this.t1.addCallback(updateWrapper, enterTime)
    }
    popupHandler(itemEl, duration - duration / 2, enterStart, xVal, yVal);

  }

  fadeOutEffect = (itemEl, item) => {
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart

    this.tweenSetHandler(exitStart, itemEl, { opacity: 1 });
    this.tweenToHandler(itemEl, duration, { opacity: 0, immediateRender: false, ease: Back.easeNone }, exitStart);
    this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });
  }

  fadeoutWithZoom = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    function onStartTween(blockId) {
      this.removeMask(blockId);
      this.tweenToHandler(itemEl, duration - duration / 2, { alpha: 0, transformOrigin: "50% 50%" }, exitStart);
      this.tweenToHandler(itemEl, duration, { scale: 0, transformOrigin: "50% 50%" }, exitStart);
    }
    this.tweenfromHandler(itemEl.id, 0.1, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
  }

  exitPixelHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    function onStartTween(blockId, type) {
      if (!type)
        type = "";
      const maskFound = this.maskExist(blockId, type);
      if (!maskFound) {
        this.removeMask(blockId);
        this.addMask(blockId, type);
      } else {
        itemEl.style.clipPath =`url(#mymask_${itemEl.id}${type}`;
      }

      const mask_rect =`#mask_${blockId}_rect`;
      const totalCount = 20;

      let maskGroup = [];
      for (let count = 0; count < totalCount; count++) {
        for (let cnt = 0; cnt < totalCount; cnt++) {
          maskGroup.push(mask_rect + count + cnt);
        }
      }
      function shuffle(array) {
        let m = array.length; let t; let i;
        while (m) {
          i = Math.floor(Math.random() * m--);
          t = array[m];
          array[m] = array[i];
          array[i] = t;
        }
        return array;
      }
      maskGroup = shuffle(maskGroup);
      this.tweenStaggerToHandler(maskGroup, duration, { transformOrigin: "50% 50%", display: "none" }, duration / (totalCount ** 2), exitStart - duration);
    }
    this.tweenfromHandler(itemEl.id, 0.1, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "pixelsquare"], opacity: 1 }, exitStart);
  }



  enterEffects = (itemEl, item) => {
    const effect = item.get("enterEffectName");
    const xval = item.get("x");
    const yval = item.get("y");
    const enterStart = item.get("enterStart");
    const widthVal = item.get("width");
    const heightVal = item.get("height");
    const projectHeight = this.props.projectData.get("height");
    const projectWidth = this.props.projectData.get("width");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");

    this.removeMask(itemEl.id);
    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });

    switch (effect) {
      case "enterFromLeftBottom": {
        const initialXVal = widthVal * -1;
        const initialYVal = projectHeight * this.props.zoomFactor;
        this.tweenFromToHandler(
          itemEl,
          1,
          { x: initialXVal, y: initialYVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart
        );
        break;
      } case "enterFromLeftTop": {
        const initialXVal = widthVal * -1;
        const initialYVal = heightVal * -1;
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { x: initialXVal, y: initialYVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart
        );
        break;
      } case "enterFromRightBottom": {
        const initialXVal = projectWidth * this.props.zoomFactor;
        const initialYVal = projectHeight * this.props.zoomFactor;
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { x: initialXVal, y: initialYVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart
        );
        break;
      } case "enterFromRightTop": {
        const initialXVal = projectWidth * this.props.zoomFactor;
        const initialYVal = heightVal * -1;
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { x: initialXVal, y: initialYVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart
        );
        break;
      } case "enterFromTop": {
        const initialYVal = heightVal * -1;
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { y: initialYVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart
        );
        break;
      } case "enterFromLeft": {
        const initialXVal = widthVal * -1;
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { x: initialXVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart
        );
        break;
      } case "enterFromBottom": {
        const initialYVal = projectHeight * this.props.zoomFactor;
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { y: initialYVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart
        );
        break;
      } case "enterFromRight": {
        const initialXVal = projectWidth * this.props.zoomFactor;
        this.tweenFromToHandler(
          itemEl,
          enterDuration,
          { x: initialXVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart
        );
        break;
      }
      default:
        break;
    }
  }

  transitionSlideEnterEffect = (itemEl, item) => {
    const effect = item.get("transitionExitEffect");
    const xval = item.get("x");
    const yval = item.get("y");
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd");
    const projectHeight = this.props.projectData.get("height");
    const projectWidth = this.props.projectData.get("width");
    const duration = transitionTime;

    this.removeMask(itemEl.id);
    this.tweenSetHandler(enterStart - duration, itemEl, { opacity: item.get("opacity") });

    switch (effect) {
      case "exitToLeft": {
        const initialXVal = projectWidth * this.props.zoomFactor;
        this.tweenFromToHandler(
          itemEl,
          duration,
          { x: initialXVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart - duration
        );
        break;
      }
      case "exitToRight": {
        const initialXVal = projectWidth * (-1) * this.props.zoomFactor;
        this.tweenFromToHandler(
          itemEl,
          duration,
          { x: initialXVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart - duration
        );
        break;
      } 
      case "exitToTop": {
        const initialYVal = projectHeight * this.props.zoomFactor;
        this.tweenFromToHandler(
          itemEl,
          duration,
          { y: initialYVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart - duration
        );
        break;
      }
      case "exitToBottom": {
        const initialYVal = projectHeight * (-1) * this.props.zoomFactor;
        this.tweenFromToHandler(
          itemEl,
          duration,
          { y: initialYVal },
          { x: xval * this.props.zoomFactor, y: yval * this.props.zoomFactor },
          enterStart - duration
        );
        break;
      }
      default:
        break;
    }
    this.tweenSetHandler(exitEnd, itemEl.id, { opacity: 0 });
  }

  transitionFade = (itemEl, item) => {

    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd");
    const duration = transitionTime;

    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    this.tweenToHandler(itemEl, duration, { opacity: 0,backgroundColor: 'black', immediateRender: false }, exitEnd - duration);
    this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });
  }

  transitionSlideExitEffect = (itemEl, item) => {
    const effect = item.get("transitionEnterEffect");
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitEnd");
    const projectHeight = this.props.projectData.get("height");
    const projectWidth = this.props.projectData.get("width");
    const duration = transitionTime;

    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
    switch (effect) {
      case "exitToLeft": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { x: (projectWidth * (-1) * this.props.zoomFactor) }, exitStart - duration);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 },
          exitStart - duration);
        break;
      }
      case "exitToRight": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { x: projectWidth * (1) * this.props.zoomFactor }, exitStart - duration);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 },
          exitStart - duration);
        break;
      }
      case "exitToTop": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { y: (projectHeight * (-1) * this.props.zoomFactor) }, exitStart - duration);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 },
          exitStart - duration);
        break;
      }
      case "exitToBottom": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { y: (projectHeight * (1) * this.props.zoomFactor) }, exitStart - duration);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 },
          exitStart - duration);
        break;
      }
      default:
        break;
    }
    this.tweenSetHandler(exitStart, itemEl.id, { opacity: 0 });
  }

  transitionWipeExitEffect = (itemEl, item) => {
    const rotation = item.get("angle");
    const scaleX = 1;
    const scaleY = 1;
    const effect = item.get("transitionEnterEffect");
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd");
    const duration = transitionTime;
    this.tweenSetHandler(enterStart , itemEl, { opacity: 1, transformOrigin: "50% 50%" });
    function onStartTween(blockId, type, rotation) {

        this.removeMask(blockId);
        this.addMask(blockId, "wipe", "", rotation, scaleX, scaleY, "", !itemEl.classList.contains("scene-item"));

      const mask_rect = `#mask_${blockId}_rect`;
      const idVal = document.getElementById(itemEl.id);
      const bboxVal = idVal.getBoundingClientRect();
      const offset = 2;
      const yval = document.getElementById(`mask_${itemEl.id}_rect`).getAttribute("y")
      const xval = document.getElementById(`mask_${itemEl.id}_rect`).getAttribute("x")
      const duration = transitionTime;

      TweenMax.set(mask_rect, { opacity: 1, transformOrigin: "50% 50%" });
      TweenMax.set(itemEl, { opacity: 1, transformOrigin: "50% 50%" });

      switch (effect) {
        case "wipeLeft": {
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * scaleX) } }, exitEnd - duration);
          break;
        }
        case "wipeUp": {
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * scaleY) } }, exitEnd - duration);
          break;
        }
        case "wipeDown": {
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * -scaleY) } }, exitEnd - duration);
          break;
        }
        case "wipeRight": {
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * -scaleX) } }, exitEnd - duration);
          break;
        }
        case "wipebr": {
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * scaleY) } }, exitEnd - duration);
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * scaleX) } }, exitEnd -duration);
          break;
        }
        case "wipebl": {
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * scaleY) } }, exitEnd - duration);
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * -scaleX) } }, exitEnd - duration);
          break;
        }
        case "wipetl": {
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * -scaleX) } }, exitEnd - duration);
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * -scaleY) } }, exitEnd - duration);
          break;
        }
        case "wipetr": {
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * scaleX) } }, exitEnd - duration);
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * -scaleY) } }, exitEnd - duration);
          break;
        }
        default:
          break;
      }
      this.tweenSetHandler(exitEnd, itemEl.id, { opacity: 0 });
    }

    this.tweenfromHandler(
      itemEl.id,
      duration,
      {
        onStart: onStartTween.bind(this),
        onStartParams: [itemEl.id, effect, rotation, 1],
        opacity: 1,
      },
      enterStart
    );
  }

  transitionSliceExitEffect = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd");
    const duration = transitionTime;
    const effectType = item.get("transitionEnterEffect");
    const end = exitEnd;
    const totalCount = 10;
    const equalTime = duration / totalCount;

    const onStartTween = (blockId, type) => {
      if (!type){
        type = "";
      }
        this.removeMask(blockId);
        this.addMask(blockId, type);
      const mask_rect = `#mask_${blockId}_rect`;
      this.tweenSetHandler(enterStart, mask_rect, { opacity: 1, transformOrigin: "50% 50%" });
      this.tweenSetHandler(enterStart, itemEl, { opacity: 1, transformOrigin: "50% 50%" });
      const duration = transitionTime;

      for (let count = 0; count < totalCount; count++) {
        switch (effectType) {
          case "vuslice": {
            const newCount = (totalCount - 1) - count;
            const startTimeVal = equalTime * count;
            const exitStart = end + startTimeVal
            this.tweenToHandler(mask_rect + newCount, equalTime, { transformOrigin: "bottom", scaleY: 0 }, exitStart - duration);
            break;
          }
          case "vdslice": {
            const startTimeVal = equalTime * count;
            const exitStart = end + startTimeVal
            this.tweenToHandler(mask_rect + count, equalTime, { transformOrigin: "top", scaleY: 0 }, exitStart- duration);
            break;
          }
          case "hrslice": {
            const startTimeVal = equalTime * count;
            const exitStart = end + startTimeVal
            this.tweenToHandler(mask_rect + count, equalTime, { scaleX: 0, transformOrigin: "0%" }, exitStart- duration);
            break;
          }
          case "hlslice": {
            const newCount = (totalCount - 1) - count;
            const startTimeVal = equalTime * count;
            const exitStart = end + startTimeVal
            this.tweenToHandler(mask_rect + newCount, equalTime, { scaleX: 0, transformOrigin: "100%" }, exitStart- duration);
            break;
          }
          default:
            break;
        }
      }

      this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });
    }

    if (effectType === "hlslice" || effectType === "hrslice") {
      this.tweenfromHandler(itemEl.id, 0.01, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "multiWidth"], opacity: 1 }, enterStart);
    } else if (effectType === "vuslice" || effectType === "vdslice") {
      this.tweenfromHandler(itemEl.id, 0.01, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "multiHeight"], opacity: 1 }, enterStart);
    }

  }

  // Enter - NEW TEXT EFFECTS

  lineSlideZigZagEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    this.tweenSetHandler(enterStart, itemEl, { overflow: `hidden` })

    const { lines } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    const parentWidth = currentObj.get("width") /* * this.props.zoomFactor */;
    const eachLineDur = (duration / lines.length)
    let startAt = enterStart;
    function handleAllDone() {
      itemEl.style.overflow = "visible";
    }
    for (let i = 0; i < lines.length; i++) {
      const lineWords = Array.from(lines[i].querySelectorAll(".sp-wd"));
      const whiteSpaces = Array.from(lines[i].querySelectorAll('[data-ws="true"]'));
      const setX = i % 2 === 0 ? `+${parentWidth}` : `-${parentWidth}`;
      this.tweenSetHandler(enterStart, lineWords, { x: setX })
      this.tweenSetHandler((startAt + (eachLineDur / 3)), whiteSpaces, { opacity: 1 })
      this.tweenStaggerToHandler(lineWords, eachLineDur, { x: `0`, ease: Elastic.easeOut.config(1, 0.7) }, 0, startAt, handleAllDone);
      startAt += eachLineDur /* / 2 */;
    }
  }

  lineSlideEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    this.tweenSetHandler(enterStart, itemEl, { overflow: `hidden` })
    const { lines } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    const parentWidth = currentObj.get("width");
    let startAt = enterStart;
    const eachDelayDur = (duration * 0.2) / (lines.length - 1);
    let lineDuration = duration;
    let durationOffset = 0;
    function handleAllDone() {
      itemEl.style.overflow = "visible";
    }
    for (let i = 0; i < lines.length; i++) {
      const lineWords = Array.from(lines[i].querySelectorAll(".sp-wd"));
      const whiteSpaces = Array.from(lines[i].querySelectorAll('[data-ws="true"]'));
      this.tweenSetHandler(enterStart, lineWords, { x: `+${parentWidth}` });
      this.tweenSetHandler(startAt, whiteSpaces, { opacity: 1 })
      this.tweenStaggerToHandler(lineWords, lineDuration, { x: `0`, ease: Elastic.easeOut.config(1, 0.75) }, 0, startAt, handleAllDone);
      startAt += eachDelayDur;
      durationOffset += eachDelayDur;
      lineDuration = duration - durationOffset;
    }
  }

  skateWordsEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    const { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    let delaytime = enterStart
    const dur = duration
    const eachDur = dur / words.length;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
      this.tweenSetHandler(enterDuration - 0.000001, listItems, { visibility: "hidden" });
    }

    function showLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 1;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "visible";
        }
      }
    }

    function onStartHandler() {
      if (hasListItem) {
        showLiTag(this.target);
      }
    }

    function showWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 1;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function handleOnComplete() {
      showWhiteSpace(this.target);
    }

    for (let i = 0; i < words.length; i++) {
      if (words[i] instanceof HTMLElement) {
        this.tweenFromToHandler(words[i], eachDur, { x: "+=40", opacity: 0 }, { x: "-=40", opacity: 1, onComplete: handleOnComplete, onStart: onStartHandler }, delaytime + (eachDur / 2));
      } else {
        for (let j = 0; j < words[i].length; j++) {
          this.tweenFromToHandler(words[i][j], eachDur, { x: "+=40", opacity: 0 }, { x: "-=40", opacity: 1, onComplete: handleOnComplete, onStart: onStartHandler }, delaytime + (eachDur / 2));
        }
      }
      delaytime += eachDur;
    }
  }

  skateLettersEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const { chars } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    const eachDur = duration / chars.length;
    const staggerDelay = eachDur;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
      this.tweenSetHandler(enterStart - 0.000001, listItems, { visibility: "hidden" });
    }

    function showWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 1;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function showLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 1;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "visible";
        }
      }
    }

    function handleOnComplete() {
      if (this.target.nextElementSibling === null) { /** @Note char based animation on the last element we pass the parentElement */
        showWhiteSpace(this.target.parentElement);
      }
    }

    function onStartHandler() {
      if (hasListItem) {
        if (this.target instanceof HTMLElement && this.target.previousElementSibling === null) {
          showLiTag(this.target.parentElement);
        }
      }
    }

    this.tweenSetHandler(enterStart - 0.00001, chars, { x: "+=40", opacity: 0 });
    this.tweenStaggerToHandler(chars, eachDur, { x: "-=40", opacity: 1, onComplete: handleOnComplete, onStart: onStartHandler }, staggerDelay, enterStart);
  }

  bounceLettersEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const { chars } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    const eachDir = duration / chars.length;
    let startAt = enterStart;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
      this.tweenSetHandler(enterDuration - 0.000001, listItems, { visibility: "hidden" });
    }

    function showLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 1;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "visible";
        }
      }
    }

    function showWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 1;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function onStartHandler() {
      if (hasListItem) {
        if (this.target instanceof HTMLElement && this.target.previousElementSibling === null) {
          showLiTag(this.target.parentElement);
        }
      }
    }

    function handleOnComplete() {
      if (this.target.nextElementSibling === null) { /** @Note char based animation on the last element we pass the parentElement */
        showWhiteSpace(this.target.parentElement);
      }
    }

    this.tweenSetHandler(enterStart, chars, { opacity: `0` })
    for (let i = 0; i < chars.length; i++) {
      const height = chars[i].offsetHeight;
      this.tweenFromToHandler(chars[i], eachDir, { immediateRender: false, y: `-=${height}` }, { y: `+=${height}`, opacity: 1, ease: Back.easeOut.config(2), onComplete: handleOnComplete, onStart: onStartHandler }, startAt)
      startAt += eachDir;
    }
  }

  panInEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    const dummyElement = itemEl.querySelectorAll(".splEff-wrapper")[1];
    const mainElement = itemEl.querySelectorAll(".splEff-wrapper")[0];
    const collideElement = itemEl.querySelectorAll(".mask-split-collide")[0];
    const width = currentObj.get("width");

    const whiteSpaces = Array.from(itemEl.querySelectorAll('[data-ws="true"]'));
    this.tweenSetHandler(enterStart, whiteSpaces, { opacity: 1 });

    this.tweenSetHandler(0, itemEl, { overflow: "hidden" });
    this.tweenToHandler(dummyElement, 0.0000001, { overflow: "hidden", background: "rgb(198 212 188)", opacity: 1 }, enterStart);
    this.tweenFromToHandler(mainElement.firstChild, duration, { x: `-=${width}` }, { x: `+=${width}`, ease: Power0.easeNone }, enterStart);
    this.tweenFromToHandler(dummyElement, duration, { x: `+=${width}` }, { x: `-=${width}`, ease: Power0.easeNone }, enterStart);
    this.tweenFromToHandler(dummyElement.firstChild, duration, { x: `-=${width * 2}`, color: "green" }, { x: `+=${width * 2}`, ease: Power0.easeNone }, enterStart)

    this.tweenToHandler(mainElement, 0.0000001, { overflow: "hidden", background: "rgb(198 212 188)", opacity: 1 }, (enterStart + duration));
    this.tweenToHandler(mainElement.firstChild, 0.0000001, { color: "green" }, (enterStart + duration));

    if (collideElement instanceof HTMLElement) {
      this.tweenSetHandler(enterStart + duration, [mainElement, dummyElement], { visibility: "hidden" });
      this.tweenSetHandler(enterStart + duration, collideElement, { visibility: 'visible' });
    }

  }

  emergeEnterHandler = (innerblock, item) => {
    const enterstart = item.get("enterStart");
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterstart, innerblock, { opacity: 1 });
    const dummyElement = innerblock.querySelectorAll(".splEff-wrapper")[1];
    const mainElement = innerblock.querySelectorAll(".splEff-wrapper")[0];
    const collideElement = innerblock.querySelectorAll(".mask-split-collide")[0];
    let clipPathOG = "";
    let clipPathDummy = "";
    const width = currentObj.get("width");
    const height = currentObj.get("height");
    const halfHeight = (0.5 * height) * this.props.zoomFactor;
    clipPathOG = `M 0 0  L ${width} 0 L ${width} ${halfHeight} L 0 ${halfHeight} L 0 0`;
    clipPathDummy = `M 0 ${halfHeight}  L ${width} ${halfHeight} L ${width} ${height} L 0 ${height} L 0 ${halfHeight}`;

    const whiteSpaces = Array.from(innerblock.querySelectorAll('[data-ws="true"]'));
    this.tweenSetHandler(enterstart, whiteSpaces, { opacity: 1 });

    this.tweenSetHandler(0, mainElement, { clipPath: `path('${clipPathOG}')`, webkitClipPath: `path('${clipPathOG}')`, overflow: "hidden" })
    this.tweenSetHandler(0, dummyElement, { clipPath: `path('${clipPathDummy}')`, webkitClipPath: `path('${clipPathDummy}')`, overflow: "hidden", opacity: 1 })
    this.tweenSetHandler(0, mainElement.firstChild, { y: `+=${halfHeight}` })
    this.tweenSetHandler(0, dummyElement.firstChild, { y: `-=${halfHeight}` })
    this.tweenToHandler(mainElement.firstChild, duration - 0.0000001, { y: `-=${halfHeight}` }, enterstart + 0.0000001)
    this.tweenToHandler(dummyElement.firstChild, duration - 0.0000001, { y: `+=${halfHeight}` }, enterstart + 0.0000001)

    this.tweenToHandler(mainElement, 0.0000001, { clipPath: 'none', webkitClipPath: 'none', overflow: "visible" }, (enterstart + duration) - 0.0000001)
    this.tweenToHandler(dummyElement, 0.0000001, { opacity: 0 }, (enterstart + duration) - 0.0000001)

    if (collideElement instanceof HTMLElement) {
      this.tweenSetHandler(enterstart + duration, [mainElement, dummyElement], { visibility: "hidden" });
      this.tweenSetHandler(enterstart + duration, collideElement, { visibility: 'visible' });
    }

  }

  slideOpenEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const dummyElement = itemEl.querySelectorAll(".splEff-wrapper")[1];
    const mainElement = itemEl.querySelectorAll(".splEff-wrapper")[0];
    const collideElement = itemEl.querySelectorAll(".mask-split-collide")[0];
    let clipPathOG = "";
    let clipPathDummy = "";
    const width = currentObj.get("width");
    const height = currentObj.get("height");
    const topCutSt = (0.5 * width) * this.props.zoomFactor;
    const bottomCutStart = (0.5 * width) * this.props.zoomFactor;
    clipPathOG = `M 0 0  L ${topCutSt} 0 L ${bottomCutStart} ${height} L 0 ${height} L 0 0`;
    clipPathDummy = `M ${topCutSt} 0 L ${width} 0 L ${width} ${height} L ${bottomCutStart} ${height} L ${topCutSt} 0`;

    const whiteSpaces = Array.from(itemEl.querySelectorAll('[data-ws="true"]'));
    this.tweenSetHandler(enterStart, whiteSpaces, { opacity: 1 });

    this.tweenSetHandler(0, mainElement, { clipPath: `path('${clipPathOG}')`, webkitClipPath: `path('${clipPathOG}')`, overflow: "hidden" })
    this.tweenSetHandler(0, dummyElement, { clipPath: `path('${clipPathDummy}')`, webkitClipPath: `path('${clipPathDummy}')`, overflow: "hidden", opacity: 1 })
    this.tweenSetHandler(0, mainElement.firstChild, { x: `+=${topCutSt}` })
    this.tweenSetHandler(0, dummyElement.firstChild, { x: `-=${topCutSt}` })
    this.tweenToHandler(mainElement.firstChild, duration - 0.0000001, { x: `-=${topCutSt}` }, enterStart + 0.0000001)
    this.tweenToHandler(dummyElement.firstChild, duration + 0.0000001, { x: `+=${topCutSt}` }, enterStart + 0.0000001)

    this.tweenToHandler(mainElement, 0.0000001, { clipPath: 'none', webkitClipPath: 'none', overflow: "visible" }, (enterStart + duration) - 0.0000001)
    this.tweenToHandler(dummyElement, 0.0000001, { opacity: 0 }, (enterStart + duration) - 0.0000001)

    if (collideElement instanceof HTMLElement) {
      this.tweenSetHandler(enterStart + duration, [mainElement, dummyElement], { visibility: "hidden" });
      this.tweenSetHandler(enterStart + duration, collideElement, { visibility: 'visible' });
    }
  }

  stompWordsEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });


    const parentWidth = currentObj.get("width");
    const parentHorCenter = parentWidth / 2;
    const { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    let delaytime = enterStart;
    const dur = duration;
    const eachDur = dur / words.length;

    const whiteSpaces = Array.from(itemEl.querySelectorAll('[data-ws="true"]'));
    this.tweenSetHandler(enterStart + duration, whiteSpaces, { opacity: 1 });

    for (let i = 0; i < words.length; i++) {
      if (words[i] instanceof HTMLElement) {
        const wordWidth = words[i].offsetWidth;
        const wordLeft = words[i].offsetLeft;
        const xTransForm = (parentHorCenter - wordLeft) - (wordWidth / 2);
        this.tweenToHandler(words[i], 0.000001, { opacity: 0 }, enterStart - 0.000001);
        this.tweenFromToHandler(words[i], eachDur - 0.000001, { scale: 4, immediateRender: false, x: xTransForm, transformOrigin: `50% 50%`, opacity: 1 }, { scale: 1, ease: Linear.easeOut }, delaytime);
        if (i !== words.length - 1) { /** ignore for last word */
          this.tweenToHandler(words[i], 0.000001, { opacity: 0 }, delaytime + eachDur);
        }
        this.tweenToHandler(words[i], 0.000001, { x: `0`, opacity: 1 }, (enterStart + duration));
      } else {
        let pseudoWidth = 0;
        const pseudoWordX = words[i][0].offsetLeft;
        for (let j = 0; j < words[i].length; j++) {
          pseudoWidth += words[i][j].offsetWidth;
        }
        const pseudoWordCenter = pseudoWordX + (pseudoWidth / 2);
        for (let j = 0; j < words[i].length; j++) {
          const xTransForm = (parentHorCenter - pseudoWordX) - (pseudoWidth / 2);
          const wordOrigin = pseudoWordCenter - (words[i][j].offsetLeft);
          this.tweenToHandler(words[i][j], 0.000001, { opacity: 0 }, enterStart - 0.000001);
          this.tweenFromToHandler(words[i][j], eachDur - 0.000001, { scale: 4, immediateRender: false, x: xTransForm, transformOrigin: `${wordOrigin}px 50%`, opacity: 1 }, { scale: 1, ease: Linear.easeOut }, delaytime);
          if (i !== words.length - 1) { /** ignore for last word */
            this.tweenToHandler(words[i][j], 0.000001, { opacity: 0 }, delaytime + eachDur);
          }
          this.tweenToHandler(words[i][j], 0.000001, { x: `0`, opacity: 1 }, (enterStart + duration - 0.000001));
        }
      }
      delaytime += eachDur;
    }
  }

  shiftWordsEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    let delaytime = enterStart;
    const eachDur = duration / words.length;
    let txtContWidthMid = currentObj.get("width") / 2;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
      this.tweenSetHandler(enterDuration - 0.000001, listItems, { visibility: "hidden" });
    }

    if (words.length) {
      if (words[0] instanceof HTMLElement) {
        txtContWidthMid -= words[0].offsetWidth / 2;
      } else {
        for (let j = 0; j < words[0].length; j++) {
          txtContWidthMid -= words[0][j].offsetWidth / 2;
        }
      }
    }
    function showWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 1;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function showLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 1;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "visible";
        }
      }
    }

    function handleOnComplete() {
      showWhiteSpace(this.target);
    }
    function onStartHandler() {
      if (hasListItem) {
        showLiTag(this.target);
      }
    }

    this.tweenToHandler(itemEl, 0.0000001, { x: `+=${txtContWidthMid}` }, enterStart);
    this.tweenToHandler(itemEl, duration, { x: `-=${txtContWidthMid}` }, enterStart + 0.00001);
    for (let i = 0; i < words.length; i++) {
      if (words[i] instanceof HTMLElement) {
        const itemHeight = words[i].offsetHeight;
        this.tweenFromToHandler(words[i], eachDur, { opacity: 0, y: `+=${itemHeight}` }, { opacity: 1, y: `-=${itemHeight}`, onComplete: handleOnComplete, onStart: onStartHandler }, delaytime);
      } else {
        const itemHeight = words[i][0].offsetHeight;
        for (let j = 0; j < words[i].length; j++) {
          this.tweenFromToHandler(words[i][j], eachDur, { opacity: 0, y: `+=${itemHeight}` }, { opacity: 1, y: `-=${itemHeight}`, onComplete: handleOnComplete, onStart: onStartHandler }, delaytime);
        }
      }
      delaytime += eachDur;
    }
  }

  pulseWordsEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const { x: parentX } = itemEl.getBoundingClientRect();
    const { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    let delaytime = enterStart;
    const eachDur = duration / words.length;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
      this.tweenSetHandler(enterDuration - 0.000001, listItems, { visibility: "hidden" });
    }
    function showWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 1;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function showLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 1;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "visible";
        }
      }
    }

    function handleOnComplete() {
      showWhiteSpace(this.target);
    }

    function onStartHandler() {
      if (hasListItem) {
        showLiTag(this.target);
      }
    }

    for (let i = 0; i < words.length; i++) {
      this.tweenSetHandler(enterStart, words[i], { opacity: "0" });
      if (words[i] instanceof HTMLElement) {
        this.tweenFromToHandler(words[i], eachDur, { transformOrigin: "120% -70px", scale: 1.3, immediateRender: false }, { ease: Back.easeOut, scale: 1, opacity: "1", onComplete: handleOnComplete, onStart: onStartHandler }, delaytime);
      } else {
        let pseudoWidth = 0;
        const pseudoWordX = words[i][0].getBoundingClientRect().x - parentX;
        for (let j = 0; j < words[i].length; j++) {
          pseudoWidth += words[i][j].getBoundingClientRect().width;
        }
        const pseudoWordCenter = pseudoWordX + (pseudoWidth / 2);
        for (let j = 0; j < words[i].length; j++) {
          const wordOrigin = pseudoWordCenter - (words[i][j].getBoundingClientRect().x - parentX);
          this.tweenFromToHandler(words[i][j], eachDur, { transformOrigin: `${wordOrigin}px -70px`, scale: 1.3, immediateRender: false }, { ease: Back.easeOut, scale: 1, opacity: "1", onComplete: handleOnComplete, onStart: onStartHandler }, delaytime);
        }
      }
      delaytime += eachDur;
    }
  }

  lettersRiseEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("enterduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const { chars } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    const staggerDur = ((duration) / chars.length) / 2;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
      this.tweenSetHandler(enterDuration - 0.000001, listItems, { visibility: "hidden" });
    }

    function showLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 1;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "visible";
        }
      }
    }

    function onStartHandler() {
      if (hasListItem) {
        if (this.target instanceof HTMLElement && this.target.previousElementSibling === null) {
          showLiTag(this.target.parentElement);
        }
      }
    }

    const whiteSpaces = Array.from(itemEl.querySelectorAll('[data-ws="true"]'));
    this.tweenSetHandler(enterStart + duration, whiteSpaces, { opacity: 1 });

    this.tweenSetHandler(enterStart, chars, { scale: 0 });
    this.tweenStaggerToHandler(chars.slice(), duration, { scale: 1, transformOrigin: "50% 100%", ease: Back.easeOut.config(1.7), onStart: onStartHandler }, staggerDur, enterStart);
  }

  bounceWordsEnterHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");
    const currentObj = this.currentObjectHandlder(item);
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    const duration = currentObj.get("enterduration");
    const isChild = false;
    const { x: parentX } = itemEl.getBoundingClientRect();

    function showWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 1;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function showLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 1;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "visible";
        }
      }
    }

    function removeChild() {
      showWhiteSpace(this.target);
    }
    const { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;

    if (listItems.length > 0) {
      hasListItem = true;
      this.tweenSetHandler(enterDuration - 0.000001, listItems, { visibility: "hidden" });
    }
    function onStartHandler() {
      if (hasListItem) {
        showLiTag(this.target);
      }
    }

    let delaytime = enterStart;
    const dur = duration;
    const eachDur = dur / words.length;
    for (let i = 0; i < words.length; i++) {
      if (words[i] instanceof HTMLElement) {
        this.tweenSetHandler(enterStart, words[i], { opacity: "0" });
        this.tweenFromToHandler(words[i], eachDur, { immediateRender: false, scale: 2, transformOrigin: "50% 50%" }, { transformOrigin: "50% 50%", opacity: 1, scale: 1, ease: Back.easeOut, onStart: onStartHandler, onComplete: removeChild }, delaytime);
      } else {
        let pseudoWidth = 0;
        const pseudoWordX = words[i][0].getBoundingClientRect().x - parentX;
        for (let j = 0; j < words[i].length; j++) {
          pseudoWidth += words[i][j].getBoundingClientRect().width;
        }
        const pseudoWordCenter = pseudoWordX + (pseudoWidth / 2);
        this.tweenSetHandler(enterStart, words[i], { opacity: "0" });
        for (let j = 0; j < words[i].length; j++) {
          const wordOrigin = pseudoWordCenter - (words[i][j].getBoundingClientRect().x - parentX);
          this.tweenFromToHandler(words[i][j], eachDur, { immediateRender: false, scale: 2, transformOrigin: `${wordOrigin}px 50%` }, { onStart: onStartHandler, opacity: 1, scale: 1, ease: Back.easeOut, onComplete: removeChild }, delaytime);
        }
      }
      delaytime += eachDur;
    }

  }

  popdownTransformHandler = (itemEl, item) => {
    const effect = item.get("exitEffectName");
    const exitStart = item.get("exitStart");
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart
    const xval = item.get("x");
    const yval = item.get("y");
    const groupXoffset = 0;
    const groupYoffset = 0;
    const rotateHeightOffset = 0;
    const rotateWidthOffset = 0;
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    let transformOriginVal = "0% 0%";

    switch (effect) {
      case "popDownToLeft":
        transformOriginVal = "0% 50%";
        break;
      case "popDownToRight":
        transformOriginVal = "100% 50%";
        break;
      case "popDownToTop":
        transformOriginVal = "50% 0%";
        break;
      case "popDownToBottom":
        transformOriginVal = "50% 100%";
        break;
      case "popDownToBottomLeft":
        transformOriginVal = "0% 100%";
        break;
      case "popDownToBottomRight":
        transformOriginVal = "100% 100%";
        break;
      case "popDownToTopLeft":
        transformOriginVal = "0% 0%";
        break;
      case "popDownToTopRight":
        transformOriginVal = "100% 0%";
        break;
      case "popDownToCenter":
        transformOriginVal = "50% 50%";
        break;
      default:
        transformOriginVal = "0% 0%";
        break;
    }
    

    const onStartTween = () => {
      if (!document.getElementById(`${itemEl.id}_tempWrapper`)) {
        this.removeMask(itemEl.id);
        const parent = itemEl.parentNode;
        const wrapper = document.createElement("div");
        wrapper.setAttribute("id",`${itemEl.id}_tempWrapper`);
        parent.replaceChild(wrapper, itemEl);
        wrapper.appendChild(itemEl);
        itemEl.parentNode.style.width = `${itemEl.getBoundingClientRect().width}px`;
        itemEl.parentNode.style.height = `${itemEl.getBoundingClientRect().height}px`;
        itemEl.parentNode.style.display = "block";
        itemEl.parentNode.style.position = "absolute";
        itemEl.style.left = `${-xval + groupXoffset + rotateWidthOffset}px`;
        itemEl.style.top = `${-yval + groupYoffset + rotateHeightOffset}px`;
        itemEl.parentNode.style.left =`${xval - rotateWidthOffset - groupXoffset}px`;
        itemEl.parentNode.style.top = `${yval - rotateHeightOffset - groupYoffset}px`;
        this.tweenFromToHandler(itemEl.parentNode, duration - 0.1, {
          scaleX: 1, scaleY: 1,
          transformOrigin: transformOriginVal
        }, { scaleX: 0, scaleY: 0, immediateRender: false, ease: Back.easeIn }, exitStart + 0.1);
      }
    }

    this.t1.addCallback(onStartTween, exitStart);
  }

  popupTransformHandler = (itemEl, item) => {
    const effect = item.get("enterEffectName");
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");
    const xval = item.get("x");
    const yval = item.get("y");
    const groupXoffset = 0;
    const groupYoffset = 0;
    const rotateHeightOffset = 0;
    const rotateWidthOffset = 0;
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const updateWrapper = () => {
      if (!document.getElementById(`${itemEl.id}_tempWrapper`)) {
        const parent = itemEl.parentNode;
        const wrapper = document.createElement("div");
        parent.replaceChild(wrapper, itemEl);
        wrapper.appendChild(itemEl);
        itemEl.parentNode.style.width = `${itemEl.getBoundingClientRect().width}px`;
        itemEl.parentNode.style.height = `${itemEl.getBoundingClientRect().height}px`;
        itemEl.parentNode.style.display = "block";
        itemEl.parentNode.style.position = "absolute";
        itemEl.parentNode.setAttribute("id",`${itemEl.id}_tempWrapper`);
        let transformOriginVal = "0% 0%"; // Default value
        switch (effect) {
          case "popupFromLeft":
            transformOriginVal = "0% 50%";
            break;
          case "popupFromRight":
            transformOriginVal = "100% 50%";
            break;
          case "popupFromTop":
            transformOriginVal = "50% 0%";
            break;
          case "popupFromBottom":
            transformOriginVal = "50% 100%";
            break;
          case "popupFromBottomLeft":
            transformOriginVal = "0% 100%";
            break;
          case "popupFromBottomRight":
            transformOriginVal = "100% 100%";
            break;
          case "popupFromTopLeft":
            transformOriginVal = "0% 0%";
            break;
          case "popupFromTopRight":
            transformOriginVal = "100% 0%";
            break;
          case "popupFromCenter":
            transformOriginVal = "50% 50%";
            break;
          default:
            transformOriginVal = "0% 0%";
            break;
        }        

        this.removeMask(itemEl.id);
        this.t1.to(itemEl.parentNode, enterDuration, { scaleX: 1, scaleY: 1, immediateRender: false, ease: Back.easeOut, }, enterStart);
        itemEl.style.left = `${-xval + rotateWidthOffset + groupXoffset}px`;
        itemEl.style.top = `${ -yval + rotateHeightOffset + groupYoffset}px`;
        TweenMax.set(itemEl.parentNode, {
          x: xval - groupXoffset - rotateWidthOffset,
          y: yval - groupYoffset - rotateHeightOffset, transformOrigin: transformOriginVal, scaleX: 0, scaleY: 0
        });
      }
    }

    function delWrapper() {
      if (document.getElementById(`${itemEl.id}_tempWrapper`))
        document.getElementById(`${itemEl.id}_tempWrapper`).replaceWith(...document.getElementById(`${itemEl.id}_tempWrapper`).childNodes)
    }

    this.t1.addCallback(delWrapper, enterDuration + enterStart);

    function revertPosition() {
      itemEl.style.left = "0px";
      itemEl.style.top = "0px";
    }

    this.t1.addCallback(revertPosition, enterDuration + enterStart);
    this.t1.addCallback(updateWrapper, enterStart - 0.1)
  }


  // Exit - NEW TEXT EFFECTS

  bounceWordsExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const { x: parentX } = itemEl.getBoundingClientRect();
    function hideWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 0;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function removeChild() {
      hideWhiteSpace(this.target);
    }
    let { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    words = words.reverse();

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
    }

    let delaytime = exitStart;
    const dur = duration;
    const eachDur = dur / words.length;
    function hideLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 0;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "hidden";
        }
      }
    }

    function onCompleteHandler() {
      if (hasListItem) {
        hideLiTag(this.target);
      }
    }

    for (let i = 0; i < words.length; i++) {
      if (words[i] instanceof HTMLElement) {
        this.tweenFromToHandler(words[i], eachDur, { opacity: 1, immediateRender: false, scale: 1, transformOrigin: "50% 50%" }, { opacity: 0, scale: 0, ease: Back.easeIn, onStart: removeChild, onComplete: onCompleteHandler }, delaytime);
      } else {
        let pseudoWidth = 0;
        const pseudoWordX = words[i][0].getBoundingClientRect().x - parentX;
        for (let j = 0; j < words[i].length; j++) {
          pseudoWidth += words[i][j].getBoundingClientRect().width;
        }
        const pseudoWordCenter = pseudoWordX + (pseudoWidth / 2);
        for (let j = 0; j < words[i].length; j++) {
          const wordOrigin = pseudoWordCenter - (words[i][j].getBoundingClientRect().x - parentX);
          this.tweenFromToHandler(words[i][j], eachDur, { immediateRender: false, opacity: 1, scale: 1, transformOrigin: `${wordOrigin}px 50%` }, { opacity: 0, scale: 0, ease: Back.easeIn, onStart: removeChild, onComplete: onCompleteHandler }, delaytime);
        }
      }
      delaytime += eachDur;
    }
  }

  lettersRiseExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    let { chars } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    chars = chars.reverse();

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
    }

    const whiteSpaces = Array.from(itemEl.querySelectorAll('[data-ws="true"]'));
    this.tweenSetHandler(exitStart + duration / 2, whiteSpaces, { opacity: 0 });

    const eachCharDur = (duration / chars.length);
    let startAt = exitStart;

    function hideLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 0;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "hidden";
        }
      }
    }

    function onCompleteHandler() {
      if (hasListItem) {
        if (this.target instanceof HTMLElement && this.target.previousElementSibling === null) {
          hideLiTag(this.target.parentElement);
        }
      }
    }

    for (let i = 0; i < chars.length; i++) {
      this.tweenToHandler(chars[i], duration / 2, { scale: 0, transformOrigin: "50% 100%", ease: Back.easeIn.config(1.7), onComplete: onCompleteHandler }, startAt);
      startAt += (eachCharDur / 2);
    }
  }

  pulseWordsExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const { x: parentX } = itemEl.getBoundingClientRect();
    let { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    words = words.reverse()

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
    }

    let delaytime = exitStart;
    const eachDur = duration / words.length;

    function hideLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 0;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "hidden";
        }
      }
    }

    function hideWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 0;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function handleOnStart() {
      hideWhiteSpace(this.target);
    }
    function handleOnComplete() {
      if (hasListItem) {
        hideLiTag(this.target);
      }
    }

    for (let i = 0; i < words.length; i++) {
      if (words[i] instanceof HTMLElement) {
        this.tweenFromToHandler(words[i], eachDur, { immediateRender: false, transformOrigin: "120% -70px", scale: 1 }, { ease: Back.easeIn, scale: 1.3, opacity: "0", onStart: handleOnStart, onComplete: handleOnComplete }, delaytime);
      } else {
        let pseudoWidth = 0;
        const pseudoWordX = words[i][0].getBoundingClientRect().x - parentX;
        for (let j = 0; j < words[i].length; j++) {
          pseudoWidth += words[i][j].getBoundingClientRect().width;
        }
        const pseudoWordCenter = pseudoWordX + (pseudoWidth / 2);
        for (let j = 0; j < words[i].length; j++) {
          const wordOrigin = pseudoWordCenter - (words[i][j].getBoundingClientRect().x - parentX);
          this.tweenFromToHandler(words[i][j], eachDur, { immediateRender: false, transformOrigin: `${wordOrigin}px -70px`, scale: 1 }, { ease: Back.easeIn, scale: 1.3, opacity: "0", onStart: handleOnStart, onComplete: handleOnComplete }, delaytime);
        }
      }
      delaytime += eachDur;
    }
  }

  shiftWordsExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    let { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    let delaytime = exitStart;
    const eachDur = duration / words.length;
    let txtContWidthMid = currentObj.get("width") / 2;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
    }

    if (words.length) {
      if (words[0] instanceof HTMLElement) {
        txtContWidthMid -= words[0].offsetWidth / 2;
      } else {
        for (let j = 0; j < words[0].length; j++) {
          txtContWidthMid -= words[0][j].offsetWidth / 2;
        }
      }
    }

    words = words.reverse();

    function hideLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 0;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "hidden";
        }
      }
    }

    function hideWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 0;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function handleOnStart() {
      hideWhiteSpace(this.target);
    }
    function handleOnComplete() {
      if (hasListItem) {
        hideLiTag(this.target);
      }
    }

    this.tweenToHandler(itemEl, duration, { x: `+=${txtContWidthMid}` }, exitStart);
    for (let i = 0; i < words.length; i++) {
      if (words[i] instanceof HTMLElement) {
        const itemHeight = words[i].offsetHeight;
        this.tweenToHandler(words[i], eachDur, { opacity: 0, y: `+=${itemHeight}`, onStart: handleOnStart, onComplete: handleOnComplete }, delaytime);
      } else {
        const itemHeight = words[i][0].offsetHeight;
        for (let j = 0; j < words[i].length; j++) {
          this.tweenToHandler(words[i][j], eachDur, { opacity: 0, y: `+=${itemHeight}`, onStart: handleOnStart, onComplete: handleOnComplete }, delaytime);
        }
      }
      delaytime += eachDur;
    }
  }

  stompWordsExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });


    this.tweenToHandler(itemEl, 0.000001, { willChange: "auto" }, exitStart - 0.000001); /** @Note the willChange applied in workspaceComp bulrs the text while scaling */
    const parentWidth = currentObj.get("width");
    const parentHorCenter = parentWidth / 2;
    let { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    words = words.reverse();
    let delaytime = exitStart + 0.000001;
    const dur = duration;
    const eachDur = dur / words.length;

    const whiteSpaces = Array.from(itemEl.querySelectorAll('[data-ws="true"]'));
    this.tweenSetHandler(exitStart, whiteSpaces, { opacity: 0 });

    for (let i = 0; i < words.length; i++) {
      if (words[i] instanceof HTMLElement) {
        const wordWidth = words[i].offsetWidth;
        const wordLeft = words[i].offsetLeft;
        const xTransForm = (parentHorCenter - wordLeft) - (wordWidth / 2);
        this.tweenToHandler(words[i], 0.000001, { opacity: 0, scale: 1, x: xTransForm, willChange: "auto", transformOrigin: `50% 50%` }, exitStart);
        this.tweenToHandler(words[i], eachDur - 0.000001, { opacity: 1, scale: 4, ease: Linear.easeOut }, delaytime);
        this.tweenToHandler(words[i], 0.000001, { opacity: 0 }, delaytime + eachDur);
      } else {
        let pseudoWidth = 0;
        const pseudoWordX = words[i][0].offsetLeft;
        for (let j = 0; j < words[i].length; j++) {
          pseudoWidth += words[i][j].offsetWidth;
        }
        const pseudoWordCenter = pseudoWordX + (pseudoWidth / 2);
        for (let j = 0; j < words[i].length; j++) {
          const xTransForm = (parentHorCenter - pseudoWordX) - (pseudoWidth / 2);
          const wordOrigin = pseudoWordCenter - (words[i][j].offsetLeft);
          this.tweenToHandler(words[i][j], 0.000001, { opacity: 0, scale: 1, x: xTransForm, willChange: "auto", transformOrigin: `${wordOrigin}px 50%` }, exitStart);
          this.tweenToHandler(words[i][j], eachDur - 0.000001, { opacity: 1, scale: 4, ease: Linear.easeOut }, delaytime);
          this.tweenToHandler(words[i], 0.000001, { opacity: 0 }, delaytime + eachDur);
        }
      }
      delaytime += eachDur;
    }
  }

  bounceLettersExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    let { chars } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    chars = chars.reverse();
    const eachDir = duration / chars.length;
    let startAt = exitStart;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
    }

    function hideLiTag(targetElm) {
      let prevElemSib = targetElm.previousElementSibling;
      while (prevElemSib !== null && (prevElemSib.dataset.ws === "true")) {
        prevElemSib.style.opacity = 0;
        prevElemSib = prevElemSib.previousElementSibling;
      }
      if (prevElemSib === null) {
        if (targetElm.parentElement.nodeName === "LI") {
          targetElm.parentElement.style.visibility = "hidden";
        }
      }
    }

    function hideWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 0;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function handleOnStart() {
      if (this.target.nextElementSibling === null) { /** @Note char based animation on the last element we pass the parentElement */
        hideWhiteSpace(this.target.parentElement);
      }
    }
    function onCompleteHandler() {
      if (hasListItem) {
        if (this.target instanceof HTMLElement && this.target.previousElementSibling === null) {
          hideLiTag(this.target.parentElement);
        }
      }
    }

    for (let i = 0; i < chars.length; i++) {
      const height = chars[i].offsetHeight;
      this.tweenToHandler(chars[i], eachDir, { y: `-=${height}`, opacity: 0, ease: Back.easeIn.config(2), onStart: handleOnStart, onComplete: onCompleteHandler }, startAt)
      startAt += eachDir;
    }

  }

  skateLettersExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const { chars } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    const eachDur = duration / chars.length;
    const staggerDelay = eachDur /* / 2 */;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
    }

    function onCompleteHandler() {
      if (hasListItem) {
        if (this.target instanceof HTMLElement && this.target.nextElementSibling === null) {
          const { parentElement }  = this.target;
          let parentNextSibLing = parentElement.nextElementSibling;
          while (parentNextSibLing !== null && (parentNextSibLing.nodeName === "BR" || parentNextSibLing.dataset.ws === "true")) {
            parentNextSibLing = parentNextSibLing.nextElementSibling;
          }
          if (parentNextSibLing === null) {
            this.target.closest("li").style.opacity = 0;
          }
        }
      }
    }
    function hideWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 0;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function handleOnStart() {
      if (this.target.nextElementSibling === null) { /** @Note char based animation on the last element we pass the parentElement */
        hideWhiteSpace(this.target.parentElement);
      }
    }

    this.tweenStaggerToHandler(chars, eachDur, { x: "-=40", opacity: 0, onStart: handleOnStart, onComplete: onCompleteHandler }, staggerDelay, exitStart);

  }


  skateWordsExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const { words } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    let delaytime = exitStart;
    const dur = duration;
    const eachDur = dur / words.length;

    const listItems = Array.from(itemEl.querySelectorAll('li')); let hasListItem = false;
    if (listItems.length > 0) {
      hasListItem = true;
    }

    function onCompleteHandler() {
      if (hasListItem) {
        let nextSibLingElm = this.target.nextElementSibling;
        let prevSiblingElm = this.target.previousElementSibling;
        while (prevSiblingElm !== null && (prevSiblingElm.dataset.ws === "true")) {
          if (prevSiblingElm.style.opacity === 1) {
            break;
          }
          prevSiblingElm.style.opacity = "0";
          prevSiblingElm = prevSiblingElm.previousElementSibling;
        }
        while (nextSibLingElm !== null && (nextSibLingElm.nodeName === "BR" || nextSibLingElm.dataset.ws === "true")) {
          nextSibLingElm = nextSibLingElm.nextElementSibling;
        }
        if (nextSibLingElm === null) {
          this.target.closest("li").style.opacity = 0;
        }
      }
    }

    function hideWhiteSpace(targetElm) {
      let nextSibling = targetElm.nextElementSibling;
      while (nextSibling instanceof HTMLElement && nextSibling.dataset.ws === "true") {
        nextSibling.style.opacity = 0;
        nextSibling = nextSibling.nextElementSibling;
      }
    }

    function handleOnStart() {
      hideWhiteSpace(this.target);
    }

    for (let i = 0; i < words.length; i++) {
      if (words[i] instanceof HTMLElement) {
        this.tweenToHandler(words[i], eachDur, { x: "-=40", opacity: 0, onStart: handleOnStart, onComplete: onCompleteHandler }, delaytime + (eachDur / 2));
      } else {
        for (let j = 0; j < words[i].length; j++) {
          this.tweenToHandler(words[i][j], eachDur, { x: "-=40", opacity: 0, onStart: handleOnStart, onComplete: onCompleteHandler }, delaytime + (eachDur / 2));
        }
      }
      delaytime += eachDur;
    }
  }

  lineSlideExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    this.tweenSetHandler(exitStart, itemEl, { overflow: `hidden` })
    let { lines } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    lines = lines.reverse();
    const parentWidth = currentObj.get("width");
    let startAt = exitStart;
    const eachDelayDur = (duration * 0.2) / (lines.length - 1);
    let lineDuration = duration;
    let durationOffset = 0;
    function handleAllDone() {
      itemEl.style.overflow = "visible";
    }
    for (let i = 0; i < lines.length; i++) {
      const lineWords = Array.from(lines[i].querySelectorAll(".sp-wd"));
      const whiteSpaces = Array.from(lines[i].querySelectorAll('[data-ws="true"]'));
      this.tweenSetHandler(startAt, whiteSpaces, { opacity: 0 })
      this.tweenStaggerToHandler(lineWords, lineDuration, { x: `+${parentWidth}`, ease: Back.easeIn.config(1.7) }, 0, startAt, handleAllDone);
      startAt += eachDelayDur;
      durationOffset += eachDelayDur;
      lineDuration = duration - durationOffset;
    }
  }

  lineSlideZigZagExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const isChild = false;
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    this.tweenSetHandler(exitStart, itemEl, { overflow: `hidden` })

    let { lines } = getSplittedDom(itemEl, isChild, currentObj, this.props.zoomFactor);
    lines = lines.reverse();
    const parentWidth = currentObj.get("width") /* * this.props.zoomFactor */;
    const eachLineDur = (duration / lines.length);
    let  startAt = exitStart;
    function handleAllDone() {
      itemEl.style.overflow = "visible";
    }
    for (let i = 0; i < lines.length; i++) {
      const lineWords = Array.from(lines[i].querySelectorAll(".sp-wd"));
      const whiteSpaces = Array.from(lines[i].querySelectorAll('[data-ws="true"]'));
      this.tweenSetHandler((startAt + (eachLineDur / 3)), whiteSpaces, { opacity: 0 })
      const setX = i % 2 === 0 ? `+${parentWidth}` : `-${parentWidth}`;
      this.tweenStaggerToHandler(lineWords, eachLineDur, { x: setX, ease: Elastic.easeIn.config(1, 0.7) }, 0, startAt, handleAllDone);
      startAt += eachLineDur /* / 2 */;
    }
  }

  panInExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    const dummyElement = itemEl.querySelectorAll(".splEff-wrapper")[1];
    const mainElement = itemEl.querySelectorAll(".splEff-wrapper")[0];
    const width = currentObj.get("width");
    this.tweenSetHandler(enterStart, itemEl, { overflow: "hidden" });
    this.tweenToHandler(mainElement, 0.0000001, { clearProps: "background" }, (exitStart));
    this.tweenToHandler(mainElement.firstChild, 0.0000001, { clearProps: "color" }, (exitStart));

    this.tweenToHandler(dummyElement, 0.0000001, { clipPath: 'none', webkitClipPath: 'none', overflow: "hidden", background: "rgb(198 212 188)", opacity: 1 }, exitStart);
    this.tweenToHandler(dummyElement.firstChild, 0.0000001, { color: "green" }, (exitStart));
    this.tweenToHandler(dummyElement, duration, { x: `-=${width}`, ease: Power0.easeNone }, exitStart);
    this.tweenToHandler(dummyElement.firstChild, duration, { x: `+=${width * 2}`, ease: Power0.easeNone }, exitStart);
    this.tweenToHandler(mainElement.firstChild, duration, { x: `+=${width}`, ease: Power0.easeNone }, exitStart);
  }

  emergeExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    const dummyElement = itemEl.querySelectorAll(".splEff-wrapper")[1];
    const mainElement = itemEl.querySelectorAll(".splEff-wrapper")[0];
    let clipPathOG = "";
    let clipPathDummy = "";
    const width = currentObj.get("width");
    const height = currentObj.get("height");
    const halfHeight = (0.5 * height);
    clipPathOG = `M 0 0  L ${width} 0 L ${width} ${halfHeight} L 0 ${halfHeight} L 0 0`;
    clipPathDummy = `M 0 ${halfHeight}  L ${width} ${halfHeight} L ${width} ${height} L 0 ${height} L 0 ${halfHeight}`;
    this.tweenToHandler(mainElement, 0.0000001, { clipPath: `path('${clipPathOG}')`, webkitClipPath: `path('${clipPathOG}')`, overflow: "hidden" }, exitStart)
    this.tweenToHandler(dummyElement, 0.0000001, { clipPath: `path('${clipPathDummy}')`, webkitClipPath: `path('${clipPathDummy}')`, overflow: "hidden", opacity: 1 }, exitStart)
    this.tweenToHandler(mainElement.firstChild, duration - 0.0000001, { y: `+=${halfHeight}` }, exitStart + 0.0000001)
    this.tweenToHandler(dummyElement.firstChild, duration - 0.0000001, { y: `-=${halfHeight}` }, exitStart + 0.0000001)
  }


  slideOpenExitHandler  = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const currentObj = this.currentObjectHandlder(item);
    const duration = currentObj.get("exitduration");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    const dummyElement = itemEl.querySelectorAll(".splEff-wrapper")[1];
    const mainElement = itemEl.querySelectorAll(".splEff-wrapper")[0];
    let clipPathOG = "";
    let clipPathDummy = "";
    const width = currentObj.get("width");
    const height = currentObj.get("height");
    const topCutSt = (0.5 * width) * this.props.zoomFactor;
    const bottomCutStart = (0.5 * width) * this.props.zoomFactor;
    clipPathOG = `M 0 0  L ${topCutSt} 0 L ${bottomCutStart} ${height} L 0 ${height} L 0 0`;
    clipPathDummy = `M ${topCutSt} 0 L ${width} 0 L ${width} ${height} L ${bottomCutStart} ${height} L ${topCutSt} 0`;
    this.tweenToHandler(mainElement, 0.0000001, { clipPath: `path('${clipPathOG}')`, webkitClipPath: `path('${clipPathOG}')`, overflow: "hidden" }, exitStart)
    this.tweenToHandler(dummyElement, 0.0000001, { clipPath: `path('${clipPathDummy}')`, webkitClipPath: `path('${clipPathDummy}')`, overflow: "hidden", opacity: 1 }, exitStart)
    this.tweenToHandler(mainElement.firstChild, duration - 0.0000001, { x: `+=${topCutSt}` }, exitStart + 0.0000001)
    this.tweenToHandler(dummyElement.firstChild, duration + 0.0000001, { x: `-=${topCutSt}` }, exitStart + 0.0000001)
  }

  wipeEnterHandler = (itemEl, item) => {
    const effect = item.get("enterEffectName");
    const enterStart = item.get("enterStart");
    const enterEnd = item.get("enterEnd")
    const enterDuration = item.get("enterEnd") - item.get("enterStart");
    const rotation = item.get("angle");
    const scaleX = 1;
    const scaleY = 1;
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    this.removeMask(itemEl.id);
    if (itemEl.getElementsByClassName("text-container")[0]) {
      this.addMask(itemEl.id, "wipe", "", rotation, scaleX, scaleY, true);
    } else if (effect === "wipeBottomLeft" || effect === "wipeBottomRight" || effect === "wipeTopRight" || effect === "wipeTopLeft") {
      this.addMask(itemEl.id, "multiWipe", "", rotation, scaleX, scaleY, false);
    }
    else {
      this.addMask(itemEl.id, "wipe", "", rotation, scaleX, scaleY, false);
    }

    const mask_rect = `#mask_${itemEl.id}_rect`;
    const idVal = document.getElementById(itemEl.id);
    const bboxVal = idVal.getBoundingClientRect();
    this.tweenSetHandler(enterStart, mask_rect, { opacity: 1, transformOrigin: "50% 50%" });
    this.tweenSetHandler(enterStart, itemEl, { opacity: 0, transformOrigin: "50% 50%" });
    const yval = 0;
    const xval = 0;
    const totalCount = 2;

    switch (effect) {
      case "wipeUp": {
        this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
        this.tweenfromHandler(mask_rect, enterDuration, { attr: { y: parseFloat(yval) + ((bboxVal.height) * -scaleY) } }, enterStart);
        break;
      }
      case "wipeDown": {
        this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
        this.tweenfromHandler(mask_rect, enterDuration, { attr: { y: parseFloat(yval) + ((bboxVal.height) * scaleY) } }, enterStart);
        break;
      }
      case "wipeRight": {
        this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
        this.tweenfromHandler(mask_rect, enterDuration, { attr: { x: parseFloat(xval) + ((bboxVal.width) * scaleX) } }, enterStart);
        break;
      }
      case "wipeLeft": {
        this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
        this.tweenfromHandler(mask_rect, enterDuration, { attr: { x: parseFloat(xval) + ((bboxVal.width) * -scaleX) } }, enterStart)
        break;
      }
      case "wipeBottomLeft": {
        for (let count = 0; count < totalCount; count++) {
          this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
          if (count === 0) {
            this.tweenfromHandler(mask_rect + count, enterDuration, { attr: { y: parseFloat(yval) + ((bboxVal.height) * -scaleY) } }, enterStart);
          }
          else {
            this.tweenfromHandler(mask_rect + count, enterDuration, { attr: { x: parseFloat(xval) + ((bboxVal.width) * scaleX) } }, enterStart);
          }
        }
        break;
      }
      case "wipeBottomRight": {
        for (let count = 0; count < totalCount; count++) {
          this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
          if (count === 0) {
            this.tweenfromHandler(mask_rect + count, enterDuration, { attr: { y: parseFloat(yval) + ((bboxVal.height) * -scaleY) } }, enterStart);

          }
          else {
            this.tweenfromHandler(mask_rect + count, enterDuration, { attr: { x: parseFloat(xval) + ((bboxVal.width) * -scaleX) } }, enterStart)

          }
        }
        break;
      }
      case "wipeTopRight": {
        for (let count = 0; count < totalCount; count++) {
          this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
          if (count === 0) {
            this.tweenfromHandler(mask_rect + count, enterDuration, { attr: { y: parseFloat(yval) + ((bboxVal.height) * scaleY) } }, enterStart);

          }
          else {
            this.tweenfromHandler(mask_rect + count, enterDuration, { attr: { x: parseFloat(xval) + ((bboxVal.width) * -scaleX) } }, enterStart)

          }
        }
        break;
      }
      case "wipeTopLeft": {
        for (let count = 0; count < totalCount; count++) {
          this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
          if (count === 0) {
            this.tweenfromHandler(mask_rect + count, enterDuration, { attr: { y: parseFloat(yval) + ((bboxVal.height) * scaleY) } }, enterStart);
          }
          else {
            this.tweenfromHandler(mask_rect + count, enterDuration, { attr: { x: parseFloat(xval) + ((bboxVal.width) * scaleX) } }, enterStart);

          }
        }
        break;
      }
      default:
        break;
    }

    this.tweenToHandler(itemEl, 0.01, { "clip-path": "" }, enterEnd);
  }

  squeeze = (itemEl, item) => {
    const effectName = item.get("enterEffectName");
    const enterStart = item.get("enterStart");
    const enterDuration = item.get("enterEnd") - item.get("enterStart");
    this.removeMask(itemEl.id);
    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });

    if (effectName === "squeezev") {
      this.addMask(itemEl.id, "splitVer");
    } else {
      this.addMask(itemEl.id, "splitHor");
    }

    const mask_rect = `#mask_${itemEl.id}_rect`;

    const totalCount = 2;

    for (let count = 0; count < totalCount; count++) {
      if (effectName === "squeezev") {
        if (count === 0) {
          this.tweenFromToHandler(mask_rect + count, enterDuration, { transformOrigin: "0%", scaleX: 0 }, { transformOrigin: "0%", scaleX: 1 }, enterStart);
        }
        else {
          this.tweenFromToHandler(mask_rect + count, enterDuration, { transformOrigin: "100%", scaleX: 0 }, { transformOrigin: "100%", scaleX: 1 }, enterStart);
        }
      } else if (effectName === "squeezeh") {
        if (count === 0) {
          this.tweenFromToHandler(mask_rect + count, enterDuration, { scaleY: 0 }, { scaleY: 1 }, enterStart);
        }
        else {
          this.tweenFromToHandler(mask_rect + count, enterDuration, { transformOrigin: "bottom", scaleY: 0 }, { transformOrigin: "bottom", scaleY: 1 }, enterStart);
        }
      }
    }
  }

  blindsEnterHandler = (itemEl, item) => {
    const enterStartValue = item.get("enterStart");
    const enterEnd = item.get("enterEnd");
    const effectName = item.get("enterEffectName");
    const duration = enterEnd - enterStartValue;
    const totalCount = 10;

    this.removeMask(itemEl.id);
    this.tweenSetHandler(enterStartValue, itemEl, { opacity: item.get("opacity") });

    if (effectName === "horizontalLeftSlice" || effectName === "horizontalRightSlice")
      this.addMask(itemEl.id, "multiWidth");
    else if (effectName === "verticalUpSlice" || effectName === "verticalDownSlice")
      this.addMask(itemEl.id, "multiHeight");
    const mask_rect = `#mask_${itemEl.id}_rect`;
    const equalTime = duration / totalCount;

    for (let count = 0; count < totalCount; count++) {
      switch (effectName) {
        case "horizontalLeftSlice": {
          const newCount = (totalCount - 1) - count;
          const startTimeVal = equalTime * count;
          const enterStart = enterStartValue + startTimeVal
          this.tweenFromToHandler(mask_rect + newCount, equalTime + 0.4, { transformOrigin: "0%", scaleX: 0 }, { transformOrigin: "0%", scaleX: 1 }, enterStart - 0.03);
          break;
        }
        case "horizontalRightSlice": {
          const startTimeVal = equalTime * count;
          const enterStart = enterStartValue + startTimeVal
          this.tweenFromToHandler(mask_rect + count, equalTime + 0.4, { transformOrigin: "100%", scaleX: 0 }, { transformOrigin: "100%", scaleX: 1 }, enterStart - 0.03);
          break;
        }
        case "verticalUpSlice": {
          const newCount = (totalCount - 1) - count;
          const startTimeVal = equalTime * count;
          const enterStart = enterStartValue + startTimeVal
          this.tweenFromToHandler(mask_rect + newCount, equalTime + 0.4, { scaleY: 0 }, { scaleY: 1 }, enterStart - 0.03);
          break;
        }
        case "verticalDownSlice": {
          const startTimeVal = equalTime * count;
          const enterStart = enterStartValue + startTimeVal
          this.tweenFromToHandler(mask_rect + count, equalTime + 0.4, { transformOrigin: "bottom", scaleY: 0 }, { transformOrigin: "bottom", scaleY: 1 }, enterStart - 0.03);
          break;
        }
        default:
          break;
      }
    }
  }

  blindsExitHandler = (itemEl, item) => {
    const exitStartValue = item.get("exitStart");
    const exitEnd = item.get("exitEnd");
    const duration = exitEnd - exitStartValue;
    const effectType = item.get("exitEffectName");
    const totalCount = 10;
    const equalTime = duration / totalCount;

    const onStartTween = (blockId, type) => {
      if (!type)
        type = "";
      const maskFound = this.maskExist(blockId, type);
      if (!maskFound) {
        this.removeMask(blockId);
        this.addMask(blockId, type);
      }
      const mask_rect = `#mask_${blockId}_rect`;
      this.tweenSetHandler(exitStartValue, mask_rect, { opacity: 1, transformOrigin: "50% 50%" });
      this.tweenSetHandler(exitStartValue, itemEl, { opacity: 1, transformOrigin: "50% 50%" });

      for (let count = 0; count < totalCount; count++) {
        switch (effectType) {
          case "horizontalLeftSlice": {
            const newCount = (totalCount - 1) - count;
            const startTimeVal = equalTime * count;
            const exitStart = exitStartValue + startTimeVal
            this.tweenToHandler(mask_rect + newCount, equalTime + 0.4, { scaleX: 0, transformOrigin: "100%" }, exitStart - 0.3);
            break;
          }
          case "horizontalRightSlice": {
            const startTimeVal = equalTime * count;
            const exitStart = exitStartValue + startTimeVal
            this.tweenToHandler(mask_rect + count, equalTime + 0.4, { scaleX: 0, transformOrigin: "0%" }, exitStart - 0.3);
            break;
          }

          case "verticalUpSlice": {
            const newCount = (totalCount - 1) - count;
            const startTimeVal = equalTime * count;
            const exitStart = exitStartValue + startTimeVal
            this.tweenToHandler(mask_rect + newCount, equalTime + 0.4, { transformOrigin: "bottom", scaleY: 0 }, exitStart - 0.3);
            break;
          }
          case "verticalDownSlice": {
            const startTimeVal = equalTime * count;
            const exitStart = exitStartValue + startTimeVal
            this.tweenToHandler(mask_rect + count, equalTime + 0.4, { transformOrigin: "top", scaleY: 0 }, exitStart - 0.3);
            break;
          }
          default:
            break;
        }
      }
    }

    if (effectType === "horizontalLeftSlice")
      this.tweenfromHandler(itemEl.id, 0.00000001, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "multiWidth"], opacity: 1 }, exitStartValue);
    else if (effectType === "horizontalRightSlice")
      this.tweenfromHandler(itemEl.id, 0.00000001, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "multiWidth"], opacity: 1 }, exitStartValue);
    else if (effectType === "verticalUpSlice")
      this.tweenfromHandler(itemEl.id, 0.00000001, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "multiHeight"], opacity: 1 }, exitStartValue);
    else if (effectType === "verticalDownSlice")
      this.tweenfromHandler(itemEl.id, 0.00000001, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "multiHeight"], opacity: 1 }, exitStartValue);

    this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });

  }

  exitEffects = (itemEl, item) => {
    const effect = item.get("exitEffectName");
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart

    const widthVal = item.get("width");
    const heightVal = item.get("height")
    const projectHeight = this.props.projectData.get("height");
    const projectWidth = this.props.projectData.get("width");

    const groupXoffset = 0;
    const groupYoffset = 0;

    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
    switch (effect) {
      case "exitToLeft": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { x: (widthVal * (-1)) - groupXoffset }, exitStart);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
        break;
      }
      case "exitToLeftBottom": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { x: (widthVal * (-1)) - groupXoffset, y: projectHeight }, exitStart);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
        break;
      }
      case "exitToTop": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { y: (heightVal * (-1)) - groupYoffset }, exitStart);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
        break;
      }
      case "exitToRight": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { x: projectWidth * this.props.zoomFactor }, exitStart);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
        break;
      }
      case "exitToBottom": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { y: (projectHeight * this.props.zoomFactor) }, exitStart);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
        break;
      }
      case "exitToRightTop": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { x: projectWidth * this.props.zoomFactor, y: (heightVal * (-1)) - groupYoffset }, exitStart);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
        break;
      }
      case "exitToRightBottom": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { x: projectWidth * this.props.zoomFactor, y: projectHeight * this.props.zoomFactor }, exitStart);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
        break;
      }
      case "exitToLeftTop": {
        const onStartTween = (itemId) => {
          this.removeMask(itemId);
          this.tweenToHandler(itemEl, duration, { x: (widthVal * (-1)) - groupXoffset, y: (heightVal * (-1)) - groupYoffset }, exitStart);
        }
        this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id], opacity: 1 }, exitStart);
        break;
      }
      default:
        break;
    }
    this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });
  }

  exitHideEffects = (itemEl, item) => {
    const xval = item.get("x");
    const yval = item.get("y");
    const rotation = item.get("angle");
    const scaleX = 1;
    const scaleY = 1;
    const effect = item.get("exitEffectName");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart

    const groupXoffset = 0;
    const groupYoffset = 0;

    function onStartTween(blockId, type) {

      const maskType = ""

      const maskFound = this.maskExist(blockId, maskType);
      if (!maskFound) {
        this.removeMask(blockId);
        this.addMask(blockId, "wipe", "", rotation, scaleX, scaleY);
      } else {
        // Add mask URL if it's removed from the enter effects.
        itemEl.style.clipPath = `url(#mymask_${itemEl.id}${type})`;
      }

      const mask_rect = `#mask_${blockId}_rect`;
      const idVal = document.getElementById(itemEl.id);
      const bboxVal = idVal.getBoundingClientRect();
      this.tweenSetHandler(exitStart, itemEl.id, { x: xval * this.props.zoomFactor - groupXoffset, y: yval * this.props.zoomFactor - groupYoffset });

      const yvalue = document.getElementById(`mask_${itemEl.id}_rect`).getAttribute("y")
      const xvalue = document.getElementById(`mask_${itemEl.id}_rect`).getAttribute("x")

      switch (effect) {
        case "slideDown": {
          this.tweenToHandler(mask_rect, duration, { attr: { y: (parseFloat(yvalue) + (bboxVal.height * (-scaleY))) } }, exitStart);
          this.tweenToHandler(itemEl, duration, { y: (yval + bboxVal.height) - groupYoffset }, exitStart);
          break;
        }
        case "slideRight": {
          this.tweenToHandler(mask_rect, duration, { attr: { x: parseFloat(xvalue) + (bboxVal.width) * -scaleX } }, exitStart);
          this.tweenToHandler(itemEl, duration, { x: bboxVal.width + xval - groupXoffset }, exitStart);
          break;
        }
        case "slideUp": {
          this.tweenToHandler(mask_rect, duration, { attr: { y: parseFloat(yvalue) + (bboxVal.height * (scaleY)) } }, exitStart);
          this.tweenToHandler(itemEl, duration, { y: (yval - bboxVal.height - groupYoffset) }, exitStart);
          break;
        }
        case "slideLeft": {
          this.tweenToHandler(mask_rect, duration, { attr: { x: parseFloat(xvalue) + (bboxVal.width * scaleX) } }, exitStart);
          this.tweenToHandler(itemEl, duration, { x: (xval - bboxVal.width - groupXoffset) }, exitStart);
          break;
        }
        default:
          break;
      }
    }

    this.tweenfromHandler(itemEl.id, duration, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, effect], opacity: 1 }, exitStart);
    this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });
  }

  wipeExitHandler = (itemEl, item) => {
    const rotation = item.get("angle");
    const scaleX = 1;
    const scaleY = 1;

    const effect = item.get("exitEffectName");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart

    function onStartTween(blockId, type, rotation) {

      const maskFound = this.maskExist(blockId, "");
      if (!maskFound) {
        this.removeMask(blockId);
        this.addMask(blockId, "wipe", "", rotation, scaleX, scaleY, "", !itemEl.classList.contains("scene-item"));
      }

      const mask_rect = `#mask_${blockId}_rect`;
      const idVal = document.getElementById(itemEl.id);
      const bboxVal = idVal.getBoundingClientRect();
      const offset = 2;
      const yval = document.getElementById(`mask_${itemEl.id}_rect`).getAttribute("y")
      const xval = document.getElementById(`mask_${itemEl.id}_rect`).getAttribute("x")

      this.tweenSetHandler(exitStart, mask_rect, { opacity: 1, transformOrigin: "50% 50%" });
      this.tweenSetHandler(exitStart, itemEl, { opacity: 1, transformOrigin: "50% 50%" });


      switch (effect) {
        case "wipeUp": {
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * scaleY) } }, exitStart);
          break;
        }
        case "wipeDown": {
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * -scaleY) } }, exitStart);
          break;
        }
        case "wipeRight": {
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * -scaleX) } }, exitStart);
          break;
        }
        case "wipeLeft": {
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * scaleX) } }, exitStart);
          break;
        }
        case "wipeBottomRight": {
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * scaleY) } }, exitStart);
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * scaleX) } }, exitStart);
          break;
        }
        case "wipeBottomLeft": {
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * scaleY) } }, exitStart);
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * -scaleX) } }, exitStart);
          break;
        }
        case "wipeTopLeft": {
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * -scaleX) } }, exitStart);
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * -scaleY) } }, exitStart);
          break;
        }
        case "wipeTopRight": {
          this.t1.to(mask_rect, duration, { attr: { x: parseFloat(xval) + ((bboxVal.width + offset) * scaleX) } }, exitStart);
          this.t1.to(mask_rect, duration, { attr: { y: parseFloat(yval) + ((bboxVal.height + offset) * -scaleY) } }, exitStart);
          break;
        }
        default:
          break;
      }
      this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });
    }

    this.tweenfromHandler(
      itemEl.id,
      duration,
      {
        onStart: onStartTween.bind(this),
        onStartParams: [itemEl.id, effect, rotation, 1],
        opacity: 1,
      },
      exitStart
    );
  }

  applyRotateZoomOutEffect = (itemEl, item) => {

    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart
    const effect = item.get("exitEffectName");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    // this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
    // this.tweenToHandler(itemEl, duration, { rotation: "-=180", scale: 0, transformOrigin: "50% 50%" }, exitStart);
    // this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });

    function onStartTween(blockId, type) {
      this.removeMask(blockId);
      if (type === "rotateZoomOutCcw")
        this.tweenToHandler(itemEl, duration, { rotation: "-=180", scale: 0, transformOrigin: "50% 50%" }, exitStart);
      else if (type === "rotateZoomOutCw")
        this.tweenToHandler(itemEl, duration, { rotation: "+=180", transformOrigin: "50% 50%" }, exitStart);
      this.tweenToHandler(itemEl, duration, { scale: 0, transformOrigin: "50% 50%" }, exitStart);
    }
    this.tweenfromHandler(itemEl.id, 0.1, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, effect], opacity: 1 }, exitStart);

  }

  rotateZoomoutPopup = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart
    const effect = item.get("exitEffectName");
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });
    function onStartTween(blockId, type) {
      this.removeMask(blockId);
      this.tweenFromToHandler(itemEl, duration - 0.0000001, { scale: 1.2 }, { scale: 0, transformOrigin: "50% 50%", ease: Back.easeIn }, exitStart + 0.0000001);

      if (type === "rotateZoomOutPopupCcw")
        this.tweenToHandler(itemEl, duration - duration / 2, { rotation: "-=360", transformOrigin: "50% 50%" }, exitStart);
      else if (type === "rotateZoomOutPopupCw")
        this.tweenToHandler(itemEl, duration - duration / 2, { rotation: "+=360", transformOrigin: "50% 50%" }, exitStart);
    }
    this.tweenfromHandler(itemEl.id, 0.1, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, effect], opacity: 1 }, exitStart);
  }

  brightSquareExitHandler = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitStart = item.get("exitStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - exitStart
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1 });

    function onStartTween() {
      this.addMask(itemEl.id, "square", "", "", 1, 1, "exit");
      const mask_rect = `#mask_${itemEl.id}_rectexit`;
      const totalCount = 6;
      for (let count = 0; count < totalCount; count++) {
        for (let cnt = 0; cnt < totalCount; cnt++) {
          this.tweenToHandler(mask_rect + count + cnt, duration + 0.5, { transformOrigin: "50% 50%", scale: 0 }, exitStart)
        }
      }

    }

    this.tweenfromHandler(itemEl.id, 0.1, { onStart: onStartTween.bind(this), onStartParams: [itemEl.id, "square"], opacity: 1 }, exitStart - 0.1);

    // this.tweenAddCallbackHandler({ callBack: onStartTween.bind(this), startTime: enterstart, dataArr: [innerblock.id, "square"] })
  }

  applyNoEnterEffect = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    if (enterStart > 0) {
      this.tweenSetHandler(0, itemEl, { opacity: 0 });
    }
    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
  }


  transitionNoEnterEffect = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    if (enterStart > 0) {
      this.tweenSetHandler(0, itemEl, { opacity: 0 });
    }
    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
  }

  transitionNoExitEffect = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const duration =  transitionTime;
    if (enterStart > 0) {
      this.tweenSetHandler(0, itemEl, { opacity: 0 });
    }
    this.tweenSetHandler(enterStart - duration, itemEl, { opacity: 1 });
  }

  applyNoExitEffect = (itemEl, item) => {
    const exitEnd = item.get("exitEnd");
    this.tweenSetHandler(exitEnd, itemEl, { opacity: 0 });
  }

  enterSplitEffects = (itemEl, item) => {
    const effect = item.get("enterEffectName");
    const enterStart = item.get("enterStart");
    const enterEnd = item.get("enterEnd");
    const duration = enterEnd - enterStart;

    this.removeMask(itemEl.id, "reveal");
    this.addMask(itemEl.id, "reveal");
    const maskRect = `#mask_${itemEl.id}_rect`;

    this.tweenSetHandler(enterStart, maskRect, { attr: { x: 0, y: 0 }, opacity: 1, transformOrigin: "50% 50%" });
    this.tweenSetHandler(enterStart, itemEl, { opacity: 1, transformOrigin: "50% 50%" });

    this.tweenSetHandler(enterStart, maskRect, { attr: { x: 0, y: 0 }, opacity: 1, transformOrigin: "50% 50%" });
    if (effect === "verticalSplit") {
      this.tweenFromToHandler(maskRect, duration, { scaleX: 0, transformOrigin: "50% 50%" }, { scaleX: 1, transformOrigin: "50% 50%", attr: { y: 0 } }, enterStart);
    } else if (effect === "horizontalSplit") {
      this.tweenFromToHandler(maskRect, duration, { scaleY: 0, transformOrigin: "50% 50%" }, { scaleY: 1, transformOrigin: "50% 50%", attr: { y: 0 } }, enterStart);
    }
  }

  textReveal = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - enterStart;
    const mySplitText = new SplitText(itemEl, { type: "words", reduceWhiteSpace: false });
    const { words } = mySplitText;
    function allDone() {
      mySplitText.revert();
    }
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 1 }, enterStart);
    this.tweenSetHandler(enterStart, words, { visibility: "hidden" });
    this.tweenStaggerToHandler(words.slice(), (duration / words.length), { visibility: "visible", ease: Linear.easeNone, willChange: "transform, opacity", immediateRender: false }, (duration / words.length), enterStart, allDone);
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  textHighlight = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - enterStart;
    this.removeMask(itemEl.id);
    const highlightNode = document.createElement("div");
    highlightNode.id = "highlight-node";
    highlightNode.style.backgroundColor = this.props.projectData.getIn(["subtitle", "animData", "colorProp"]);
    highlightNode.style.display = "none";
    highlightNode.style.position = "absolute";
    highlightNode.style.borderRadius = "3px";
    const mySplitText = new SplitText(itemEl, { type: "words", wordsClass: "splitedWords++", reduceWhiteSpace: false });
    function placeNode() {
      const width = this.target.offsetWidth;
      const height = this.target.offsetHeight;
      const top = this.target.offsetTop;
      const left = this.target.offsetLeft;
      highlightNode.style.width = `${width + 4}px`;
      highlightNode.style.height = `${height + 4}px`;
      highlightNode.style.top = `${top - 2}px`;
      highlightNode.style.left = `${left - 2}px`;
      highlightNode.style.display = `block`;
      highlightNode.style.zIndex = "-1";
      itemEl.appendChild(highlightNode);
    }
    function allDone() {
      mySplitText.revert();
    }
    const { words } = mySplitText;
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 1 }, enterStart);
    this.tweenStaggerToHandler(words.slice(), (duration / words.length), { ease: Linear.easeNone, willChange: "transform, opacity", immediateRender: false, onStart: placeNode }, (duration / words.length), enterStart, allDone);
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  textFlip = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - enterStart;
    let animDuration = 1;
    if (animDuration >= duration) {
      if (duration <= 0.5) {
        animDuration = 0.2
      } else if (duration > 0.5 && duration <= 1) {
        animDuration = 0.5
      }
    }
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 1 }, enterStart);
    this.tweenSetHandler(0, itemEl, { rotationX: 50, opacity: 0, transformPerspective: 1000 });
    this.tweenToHandler(itemEl, animDuration, { opacity: 1, perspective: 0, rotationX: 0, ease: Back.easeOut.config(0.5) }, enterStart)
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  textAlphaFadeEffect = (itemEl, item) => {
    const effect = this.props.projectData.getIn(["subtitle", "animData", "animation"]);
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - enterStart;
    function tweenStart() {
      this.target.style.opacity = 1;
    }
    function tweenEnd() {
      if (effect === "textAlphaFadeOut") {
        this.target.style.opacity = 0.3;
      }
    }
    const mySplitText = new SplitText(itemEl, { type: "words", reduceWhiteSpace: false });
    const { words } = mySplitText;
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 1 }, enterStart);
    this.tweenSetHandler(enterStart, words, { opacity: 0.3 });
    this.tweenStaggerToHandler(words.slice(), (duration / words.length), {
      ease: Linear.easeNone, willChange: "transform, opacity", immediateRender: false, onStart: tweenStart, onComplete: tweenEnd
    }, (duration / words.length), enterStart, () => {
      mySplitText.revert();
    });
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  textScaleIn = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const fontSize = 20;
    const mySplitText = new SplitText(itemEl, { type: "words", reduceWhiteSpace: false });
    const { words } = mySplitText;
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 1 }, enterStart);
    this.tweenSetHandler(enterStart, words, { opacity: 0, fontSize: 0 });
    this.tweenStaggerToHandler(words, 0.5, { opacity: 1, fontSize, ease: Power1.easeOut, willChange: "transform, opacity", immediateRender: false, stagger: { each: 0, from: "left" } }, 0, enterStart);
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  textRevealWordbyWord = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const height = item.get("height")
    const duration = exitEnd - enterStart;
    const y = item.get("y");
    const mySplitText = new SplitText(itemEl, { type: "words", reduceWhiteSpace: false });
    const { words } = mySplitText;
    itemEl.style.display = "flex";
    function tweenStart() {
      this.target.style.display = "inline-block";
    }
    function tweenEnd() {
      this.target.style.display = "none";
    }
    function allDone() {
      mySplitText.revert();
    }
    const elemY = y * this.props.zoomFactor;
    const elemHeight = height * this.props.zoomFactor;
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 1, display: "flex", justifyContent: "center", alignItems: "center", x: 0, y: elemY, width: "100%", height: `${elemHeight}px`, maxWidth: "100%" }, enterStart);
    this.tweenSetHandler(enterStart, words, { visibility: "hidden", display: "none" });
    this.tweenStaggerToHandler(words.slice(), (duration / words.length), { visibility: "visible", ease: Linear.easeNone, willChange: "transform, opacity", immediateRender: false, onStart: tweenStart, onComplete: tweenEnd }, (duration / words.length), enterStart, allDone);
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  textFloat = (itemEl, item) => {
    const effect = this.props.projectData.getIn(["subtitle", "animData", "animation"]);
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - enterStart;

    const mySplitText = new SplitText(itemEl, { type: "words", reduceWhiteSpace: false });
    const { words } = mySplitText;
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 1 }, enterStart);
    let y = -20;
    if (effect === "textFloatUp") {
      y = 20;
    }
    function allDone() {
      mySplitText.revert();
    }
    this.tweenSetHandler(enterStart, words, { visibility: "hidden", y });
    this.tweenStaggerToHandler(words.slice(), (duration / words.length), { visibility: "visible", y: 0, ease: Expo.easeOut, willChange: "transform, opacity", immediateRender: false }, (duration / words.length), enterStart, allDone);
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  textDropIn = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - enterStart;
    this.tweenSetHandler(enterStart, itemEl, { opacity: item.get("opacity") });
    const childNode = itemEl.childNodes[0]

    const mySplitText = new SplitText(childNode, { type: "words,lines", reduceWhiteSpace: false });

    const { words } = mySplitText;
    const { lines } = mySplitText;
    this.tweenToHandler(childNode, 0.0000001, { opacity: 1 }, enterStart);
    let x = 0; let y = 0;
    let wordCount = 0;
    for (let i = 0; i < lines.length; i++) {
      const splitWord = new SplitText(lines[i], { type: "words", reduceWhiteSpace: false });
      const splittedWords = splitWord.words;
      const totalLength = splittedWords.length;
      const divider = totalLength >= 3 ? 3 : 2;
      const midStartIndex = Math.round((totalLength / divider));
      const midEndIndex = Math.round((splittedWords.length / 2));
      for (let i = 0; i < splittedWords.length; i++) {
        const elem = words[wordCount];
        x = 0;
        y = -10;
        if (splittedWords.length <= 3) {
          x = -10
        } else if (i === 0) {
          x = -50
        } else if (i <= midStartIndex) {
          x = -50;
        } else if (i > midStartIndex && i <= midEndIndex) {
          x = 10;
        } else {
          x = 50;
        }
        this.tweenSetHandler(enterStart, elem, { opacity: 0, x, y, scaleX: 1, scaleY: 1.3 });
        wordCount++;
      }
    }
    function allDone() {
      mySplitText.revert();
    }
    this.tweenStaggerToHandler(words.slice(), (duration / words.length), { opacity: 1, x: 0, y: 0, scaleX: 1, scaleY: 1, ease: Expo.easeOut, willChange: "transform, opacity", immediateRender: false }, (duration / words.length), enterStart, allDone);
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  textColorHighlight = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - enterStart;
    const highlightColor = this.props.projectData.getIn(["subtitle", "animData", "colorProp"]);
    function tweenStart() {
      this.target.style.color = highlightColor;
    }
    function tweenEnd() {
      this.target.style.color = "";
    }
    const mySplitText = new SplitText(itemEl, { type: "words", reduceWhiteSpace: false });
    const { words } = mySplitText;
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 1 }, enterStart);
    this.tweenStaggerToHandler(words.slice(), (duration / words.length), { ease: Linear.easeNone, willChange: "transform, opacity", immediateRender: false, onStart: tweenStart, onComplete: tweenEnd }, (duration / words.length), enterStart);
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  textPopupWordbyWord = (itemEl, item) => {
    const enterStart = item.get("enterStart");
    const exitEnd = item.get("exitEnd")
    const duration = exitEnd - enterStart;
    const y = item.get("y")
    const height = item.get("height");
    const mySplitText = new SplitText(itemEl, { type: "words", reduceWhiteSpace: false });
    const { words } = mySplitText;
    itemEl.children[0].style.display = "flex";
    const elemY = y * this.props.zoomFactor;
    const elemHeight = height * this.props.zoomFactor;
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 1, display: "flex", justifyContent: "center", alignItems: "center", x: 0, y: elemY, width: "100%", height: `${elemHeight}px`, maxWidth: "100%" }, enterStart);
    this.tweenSetHandler(enterStart, words, { display: "none", opacity: 0, scale: 0 });
    function tweenStart() {
      this.target.style.display = "inline-block";
    }
    function tweenEnd() {
      this.target.style.display = "none";
    }
    function allDone() {
      mySplitText.revert();
    }
    this.tweenStaggerToHandler(words.slice(), (duration / words.length), { opacity: 1, scale: 1, ease: Linear.easeOut, willChange: "transform, opacity", immediateRender: false, onStart: tweenStart, onComplete: tweenEnd }, (duration / words.length), enterStart, allDone);
    this.tweenToHandler(itemEl, 0.0000001, { opacity: 0 }, exitEnd);
  }

  /* Put ADD_TWEEN wrappers after this comment */

  tweenToHandler = (playerDOM, startTime, toVal, time) => {
    this.props.addTween({ playerDOM, startTime, toVal, time }, "to");
  }

  tweenfromHandler = (playerDOM, startTime, fromVal, time) => {
    this.props.addTween(
      {
        playerDOM,
        startTime,
        fromVal,
        time,
      },
      "from"
    );
  }

  tweenFromToHandler = (playerDOM, startTime, fromVal, toVal, time) => {
    this.props.addTween(
      {
        playerDOM,
        startTime,
        fromVal,
        toVal,
        time,
      },
      "fromTo"
    );
  }

  tweenStaggerFromToHandler = (
    playerDOM,
    startTime,
    fromVal,
    toVal,
    staggerTime,
    time
  ) => {
    this.props.addTween(
      {
        playerDOM,
        startTime,
        fromVal,
        toVal,
        staggerTime,
        time,
      },
      "staggerFromTo"
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  tweenStaggerToHandler = (playerDOM, startTime, toVal, staggerTime, time) => {
    this.props.addTween(
      {
        playerDOM,
        startTime,
        toVal,
        staggerTime,
        time,
      },
      "staggerTo"
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  tweenStaggerFromHandler = (
    playerDOM,
    startTime,
    fromVal,
    staggerTime,
    time,
    onComplete
  ) => {
    this.props.addTween(
      {
        playerDOM,
        startTime,
        fromVal,
        staggerTime,
        time,
        onComplete,
      },
      "staggerFrom"
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  tweenSetPropHandler = (elem, vals) => {
    this.props.addTween(
      { playerDOM: elem, setType: "styles", setVal: vals },
      "set"
    );
  }

  tweenSetHandler = (time, playerDOM, setVal) => {
    this.props.addTween({ time, playerDOM, setVal }, "set");
  }

  tweenAddCallbackHandler = (valArr) => {
    this.props.addTween(valArr, "addCallback");
    // this.tweenCount ++;
  }

  render() {
    const workspaceBG = this.props.projectData.get("workspaceBG");
    const workspaceItems = this.props.projectData.get("workspaceItems");
    const workspaceChildren = this.props.projectData.get("workspaceChildren");
    const allVideos = this.filterVideos.executor(
      [workspaceBG, workspaceItems, workspaceChildren],
      workspaceBG,
      workspaceItems,
      workspaceChildren
    );

    return (
      <PlayerAudio
        key="player-audio"
        audios={this.props.projectData.get("audios")}
        videos={allVideos}
      >
        {this.props.children}
      </PlayerAudio>
    );
  }
}

PlayerComponent.propTypes = {
  videoBufferStatus: PropTypes.object,
  projectData: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  addTween: PropTypes.func,
  isLoaded: PropTypes.bool,
  t1: PropTypes.object,
  runningState: PropTypes.object,
  prefetchCount: PropTypes.number,
  prefetchCompleteCount: PropTypes.number,
  startPlay: PropTypes.func,
  setPlayAll: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  zoomFactor: PropTypes.number,
  isAnimLoaded: PropTypes.bool,
  isAnimoPlayer: PropTypes.bool,
  isShortsPlayer: PropTypes.bool,
  isPausePlayer: PropTypes.bool,
  pausePlayer: PropTypes.func,
};

const mapStateToProps = (state) => ({
  videoBufferStatus: state.app.get("videoBufferStatus"),
  projectData: state.projectDetails,
  t1: state.app.get("t1"),
  isLoaded: state.app.get("isLoaded"),
  runningState: state.app.get("runningState"),
  prefetchCount: state.app.get("prefetchCount"),
  prefetchCompleteCount: state.app.get("prefetchCompleteCount"),
  prefetchToken: state.app.get("prefetchToken"),
  zoomFactor: state.app.get("zoomFactor"),
  isAnimLoaded: state.app.get("isAnimLoaded"),
  isAnimoPlayer: state.app.get("isAnimoPlayer"),
  isShortsPlayer: state.app.get("isShortsPlayer"),
  isPausePlayer: state.app.get("isPausePlayer"),
});

const mapDispatchToProps = (dispatch) => ({
  addTween: (data, tweenType) => {
    dispatch(addTween(data, tweenType));
  },
  setPlayAll: (data) => dispatch(setPlayAll(data)),
  startPlay: (data) => dispatch(startPlay(data)),
  pausePlayer: (data) => dispatch(playPausePlayer(data)),

});

const Player = connect(mapStateToProps, mapDispatchToProps)(PlayerComponent);

export default Player;
