import { fromJS } from "immutable";
import { ASSET_URL, UPLOADS } from "../constants/config";

/**
 * To load the fonts using timeout and waits for 30s and returns the promise.
 * @param {String} font 
 * @param {String} textContent 
 * @returns a promise.
 */
const fontLoadWithTimeout = (font, textContent) => new Promise((res, rej) => {
  const div = document.createElement("div");
  div.textContent = textContent;
  div.style.position = "absolute";
  div.style.visibility = "hidden";
  div.style.font = font;
  document.body.appendChild(div);
  const timer = setTimeout(() => {
    rej("TIMEOUT");
    div.remove();
  }, 30000);
  document.fonts.load(font, textContent).then(() => {
    clearTimeout(timer);
    res();
    div.remove();
  }).catch((error) => {
    clearTimeout(timer);
    rej(error);
    div.remove();
  });
});

/**
 * @summary load a given font family by creating a dummy node
 * @param {string} fontFamily font family with style (load key in preloadTextFonts)
 * @param {Function} fontLoaded sample textContent
 * @param {string} textContent sample textContent
 */
function loadStyle(fontFamily, fontLoaded, textContent = "") {
  textContent = `${textContent}abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ${fontFamily}`
  const dummyNode = document.createElement("span").appendChild(document.createTextNode(textContent)).parentNode;
  dummyNode.style.position = "fixed";
  dummyNode.style.opacity = 0;
  const fontFamilyToSet = `"${fontFamily.replace(/"/g, "")}"`;
  const actualFont = fontFamilyToSet.replace(", gfs", "");
  dummyNode.style.fontFamily = `${actualFont}, gfs`;
  dummyNode.style.fontSize = "14px";
  dummyNode.setAttribute("data-dummy", "true");
  document.body.insertBefore(dummyNode, document.body.firstChild);
  const fallbackFont = "gfs";
  const hasLoaded = document.fonts.check(`12px ${actualFont}`, textContent) && document.fonts.check(`12px ${fallbackFont}`, textContent);

  if (hasLoaded) {
    fontLoaded({ load: fontFamily });
  } else {
    Promise.allSettled([
      fontLoadWithTimeout(`12px ${actualFont}`, textContent),
      fontLoadWithTimeout(`12px ${fallbackFont}`, textContent)
    ]).then(() => {
      fontLoaded({ load: fontFamily });
    });
  }
}

export function addFont(toAdd, fontLoaded, textContent) {
  const styleToLoad = toAdd.get("load");
  const fontFileName = toAdd.get("font"); // font.replace(new RegExp(" ", 'g'), "-"); font.replace(" ", "-");

  if (!styleToLoad) {
    return;
  }

  let styleLink = "";
  if (toAdd.get("isPartialFont")) {
    styleLink = toAdd.get("url");
  } else if (toAdd.get("byUser") !== true) {
    styleLink = `${ASSET_URL}animaker/admin-fonts/${fontFileName}.css`;
  } else {
    styleLink =
      toAdd.get("url").indexOf("https://") === 0
        ? toAdd.get("url")
        : `${UPLOADS.font_src}${toAdd.get("url")}`;
  }

  if (!styleLink) {
    return;
  }

  let link = document.getElementById(styleLink);

  if (link) {
    loadStyle(styleToLoad, fontLoaded, textContent);
    return;
  }

  link = document.createElement("link");
  link.id = styleLink;
  link.rel = "stylesheet";
  link.onload = () => {
    loadStyle(styleToLoad, fontLoaded, textContent);
  };
  link.onerror = () => {
    fontLoaded({ load: styleToLoad });
  };
  link.href = styleLink;
  document.getElementsByTagName("head")[0].appendChild(link);
}

export async function loadSubtitleFontFamily(fontsToLoad, textContent) {
  const promises = [];
  const newLoadedFonts = [];
  fontsToLoad.forEach(element => {
    promises.push(new Promise((resolve) => {
      const fontLoaded = () => {
        newLoadedFonts.push({ ...element, isLoaded: true })
        resolve();
      }
      addFont(fromJS(element), fontLoaded, textContent)
    }))
  });
  await Promise.allSettled(promises);
  return newLoadedFonts;
}

/**
 * @summary A function that takes following params and returns new fontData which supposed to be loaded.
 * @param {Object} subtitlesData Immutable Object
 * @param {Object} loadedFonts Immutable Object
 * @param {Object} globalTextStyle  Object
 */
export const getSubtitlesFontToLoad = (subtitlesData, loadedFonts, globalTextStyle = undefined, forceLoad = false) => {
  const fontToLoad = []; let isUserFont; let fontFamily; let fontFamilyName; let cssUrl;
  const validateLoadedFont = (textStyles) => {
    if (textStyles === null || textStyles === undefined || textStyles.fontFamily === undefined) return;
    isUserFont = textStyles.others.isUserFont;
    fontFamily = textStyles.fontFamily;
    fontFamilyName = textStyles.fontFamilyName;
    cssUrl = `${ASSET_URL}animaker/admin-fonts/${fontFamilyName}.css`;
    if (isUserFont) {
      cssUrl = textStyles.others.cssUrl;
    }
    const hasLoaded = loadedFonts.some(font => (font.get("url") === cssUrl))
    if (!hasLoaded || forceLoad) {
      fontToLoad.push({
        "font": fontFamilyName,
        "load": fontFamily,
        "byUser": isUserFont,
        "isLoaded": false,
        "url": cssUrl
      })
    }
  }
  validateLoadedFont(globalTextStyle);
  // Object.values(subtitlesData).forEach((dropSubList) => dropSubList.forEach(subtitle => validateLoadedFont(subtitle.textStyles)));
  return fontToLoad
}

/**
 * @summary load a given font family by creating a dummy node
 * @param {String} fontFamily Name of the font family to be loaded
 * * @param {boolean} shouldCreateDummy 
 * @param {String} sampleText text present in subtitles
 */
export const checkFontFamilyLoadedPromise = (
  fontName,
  shouldCreateDummy = true,
  sampleText = ""
) => {
  const tempTextContent = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ, ابپتجچحخدذرزسشصضططعغفقکگلمنوهی, அஆஇஈஉஊஎஏஐஒஓஔஃ`;
  return new Promise((resolve) => {
    if (fontName) {
      const textContent = tempTextContent + sampleText;
      if (shouldCreateDummy) {
        loadStyle(fontName, textContent);
      }
      if (
        document.fonts.check(`12px ${fontName}`, textContent) &&
        document.fonts.check(`12px gfs`, textContent)
      ) {
        resolve({ isLoaded: true });
      } else {
        const fontPromise = document.fonts.load(
          `12px ${fontName}`,
          textContent
        );
        const fontFallBackPromise = document.fonts.load(
          `12px gfs`,
          textContent
        );
        const maxWaitTime = 10000;
        const resolvePromise = () => {
          // eslint-disable-next-line no-use-before-define
          clearTimeout(clearTimer);
          resolve({ isLoaded: true });
        };
        const clearTimer = setTimeout(() => {
          resolvePromise();
        }, maxWaitTime);
        /**
         * @note we try to load font. we resolve it even it fails to load.
         */
        Promise.allSettled([fontPromise, fontFallBackPromise]).then(() => {
          resolvePromise();
        });
      }
    } else {
      resolve({ isLoaded: true });
    }
  });
};