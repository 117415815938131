import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import LabelDropdown from "../../../common-components/LabelDropdown";
import { font } from "../../../constants/font";
import Action from "../../../common-components/Action";
import vmTheme from "../../../constants/theme";
import content from "../../../constants/content";
import useNotify from "../../../helper/hooks/useNotify";
import isValidEmail from "../../../helper/isValidEmail";
import ShareList from "./share-list";
import useApi from "../../../helper/hooks/useApi";
import { API, API_URL, STATIC_PATH } from "../../../constants/config";
import { SmartImage } from "../../../common-components/SmartImage";
import Typography from "../../../common-components/Typography";

const TagsInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow: auto;
  @media screen and (max-width: 767px) {
    touch-action: auto;
  }
`;

const Tag = styled.span`
  background-color: ${(props) => props.theme.boxShadowColor};
  padding: 4px 8px;
  margin: 4px;
  box-shadow: 0 0 5px ${(props) => props.theme.boxShadowColor};
  font: ${font.normalBase};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const FirstLetter = styled.span`
  font: ${font.normalBase};
  background-color: ${(props) => props.theme.inputBgColor};
  border-radius: 50px;
  color: ${(props) => props.theme.polarColor};
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.warningColor};
  font: ${font.normalBase};
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    transform: scale(1.2);
  }
`;

const Input = styled.input`
  padding: 12px 12px 12px 40px;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
  font: ${font.mediumMini};
  ::placeholder {
    font: ${font.mediumMini};
    color: ${(props) => props.theme.inputTextColor};
  }
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.warningColor};
  font: ${font.normalBase};
  margin: 10px 0;
`;

const Span = styled.span`
  position: relative;
  border: 1px solid ${(props) => props.theme.headerImgBorderColor};
  display: block;
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : "auto")};
  &::before {
    content: url(${(props) => props.icon});
    position: absolute;
    width: 25px;
    height: 25px;
    z-index: 9;
    bottom: ${(props) => (props.top ? "0" : "7px")};
    left: ${(props) => (props.left ? props.left : "10px")};
  }
`;

const InputDropdownWrap = styled.div`
  position: absolute;
  bottom: -8px;
  right: 0px;
`;

const TagifyWrapper = styled.div`
  position: relative;
`;

const SharedWrapper = styled.div`
  .shared-btn {
    display: unset;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
    background-color: unset;
    padding: 10px 0 0px;
    display: block;
  }
`;

const SuggestionsWrapper = styled.ul`
  position: absolute;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 1;
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  border: 1px solid rgb(236, 236, 236);
  border-radius: 0px 0px 8px 8px;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  font: ${font.normalBase};
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const SharedDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 10px;
`;

const shareOption = [
  { label: "Can Edit", value: content.EDIT },
  { label: "Can View", value: content.VIEW },
];

const ShareInput = ({
  icon,
  left,
  bottom,
  width,
  onFocus,
  // onBlur,
  onModalClose,
  setOpen,
  onOpen,
}) => {
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [permission, setPermission] = useState(content.EDIT);
  const [addressBook, setAddressBook] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const theme = useSelector((state) => state.app.get("theme"));
  const { warn, success } = useNotify();
  const { post } = useApi();
  const { userId, teamId, projectId } = useSelector(
    (state) => state.userDetails
  );
  const [users, setUsers] = useState([]);
  const [name, setName] = useState('');

  useEffect(() => {
    if (!isLoading) {
      post(`${API_URL}${API.GET_SHARE}`, {
        projectId
      }).then((res) => {
        const { sharedWith, message, addressBook } = res;
        if (addressBook) {
          setAddressBook(addressBook);
        }
        if (message === "unauthorized") {
          //
        } else {
          const values = sharedWith.map((a) => a.name === "undefined" ? 'Un Registered User' : a.name);
          if (values.length) {
            setName(`Shared with ${values[0]} ${values.length > 1 ? `and ${values.length - 1} users` : ''}`)
          }
          setUsers([...sharedWith])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isLoading]);

  useEffect(() => {
    if (tags.length > 0) {
      setError("");
    }
  }, [tags])

  const onChange = useCallback((e) => {
    const { value } = e.target;
    setSelectedIndex(-1);
    if (!value.includes(",")) {
      setValue(value);
    }
    if (!value.trim()) {
      setFilteredSuggestions([]);
      setError("");
      return;
    }

    const filtered = addressBook
      .filter((contact) =>
        (contact.email.includes(value) || contact.name.toLowerCase().includes(value.toLowerCase())) &&
        !tags.includes(contact.email)
      )
      .map((contact) => ({
        email: contact.email,
        name: contact.name,
        profilePicture: contact.image
      }));

    setFilteredSuggestions(filtered);
  }, [addressBook, tags]);

  const handleSuggestionClick = (suggestion) => {
    if (!tags.includes(suggestion)) {
      setTags([...tags, suggestion]);
      setError("");
    }
    setValue("");
    setFilteredSuggestions([]);
    setSelectedIndex(-1);
  };

  const handleSend = useCallback(() => {
    setLoading(true);
    post(`${API_URL}${API.SHARE_API}`, {
      userId,
      teamId,
      projectId,
      shareTo: tags,
      permission,
    }).then((res) => {
      success(`Shared successfully with ${res.sharedWith.length} person`);
    }).catch((error) => {
      warn(error.message || content.FAILED_TO_SHARE);
    }).finally(() => {
      setLoading(false);
      onModalClose();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, permission]);

  const handleDropdownChanges = (selectedValue) => {
    setPermission(selectedValue);
  };

  const handleInputKeyPress = (e) => {
    const { value } = e.target;
    if ((e.key === "Enter" || e.key === "," || e.key === " ") && value.trim() !== "") {
      setError(isValidEmail(value) ? "" : "Invalid email address");
      if (filteredSuggestions.length > 0) {
        try {
          handleSuggestionClick(filteredSuggestions[selectedIndex].email);
        }
        catch {
          handleSuggestionClick(filteredSuggestions[0].email);
        }
      } else if (isValidEmail(value.trim())) {
        if (!tags.includes(value.trim())) {
          setTags([...tags, value.trim()]);
          setValue("");
          setError("");
        } else {
          setValue("");
        }
      } else {
        setError("Invalid email address");
      }
    }
  };
  const handleMouseLeave = (e) => {
    if (e.type === "mouseleave" && value.trim() !== "") {
      setError(isValidEmail(value) ? "" : "Invalid email address");
      if (!filteredSuggestions.length) {
        if (isValidEmail(value.trim())) {
          if (!tags.includes(value.trim())) {
            setTags([...tags, value.trim()]);
            setValue("");
            setError("");
          } else {
            setValue("");
          }
        }
      }
    }
  };
  const scrollToView = (index) => {
    const suggestionElement = document.getElementById(`suggestion-item-${index}`);
    if (suggestionElement) {
      suggestionElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedIndex((prevIndex) => {
        const newIndex = prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0;
        scrollToView(newIndex);
        return newIndex;
      });
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedIndex((prevIndex) => {
        const newIndex = prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1;
        scrollToView(newIndex);
        return newIndex;
      });
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const pasteText = e.clipboardData.getData("text");
    const emails = pasteText.split(/[ ,;]+/).map((email) => email.trim());

    emails.forEach((email) => {
      if (isValidEmail(email) && !tags.includes(email)) {
        setTags((prevTags) => [...prevTags, email]);
      }
    });

    setValue("");
  };
  const handleTagRemove = useCallback((tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  }, [tags]);


  return (
    <div>
      {onOpen ? (
        <ShareList users={users} goBack={() => {
          setOpen(false)
        }} onClose={onModalClose} />
      ) : (
        <SharedWrapper>
          <TagifyWrapper>
            <Span icon={icon} left={left} bottom={bottom} width={width}>
              <TagsInputWrapper>
                {tags.map((tag) => (
                  <Tag key={tag}>
                    <FirstLetter>{tag[0]}</FirstLetter>
                    {tag}
                    <RemoveButton
                      type="button"
                      onClick={() => handleTagRemove(tag)}
                    >
                      &times;
                    </RemoveButton>
                  </Tag>
                ))}
              </TagsInputWrapper>
              <Input
                type="text"
                value={value}
                onChange={onChange}
                onKeyPress={handleInputKeyPress}
                onKeyDown={handleInputKeyDown}
                onFocus={onFocus}
                onPaste={handlePaste}
                onMouseLeave={filteredSuggestions.length === 0 ? handleMouseLeave : null}
                placeholder="Enter one or more email addresses"
                error={!!error}
              />

              {filteredSuggestions.length > 0 && (
                <SuggestionsWrapper>
                  {filteredSuggestions.map((suggestion, index) => (
                    <SuggestionItem
                      id={`suggestion-item-${index}`}
                      key={suggestion.email}
                      onClick={() => handleSuggestionClick(suggestion.email)}
                      style={{
                        backgroundColor: index === selectedIndex ? "#f0f0f0" : "transparent",
                      }}
                    >
                      <SmartImage
                        className="profile-pic"
                        imageSource={(suggestion.profilePicture === "undefined" || !suggestion.profilePicture) ? `${STATIC_PATH}chr-male.svg` : suggestion.profilePicture}
                        placeHolderImage={`${STATIC_PATH}chr-male.svg`}
                        cursor="pointer"
                        width="43px"
                        height="43px"
                        alt={""}
                      />
                      <SharedDetails>
                        <Typography
                          content={suggestion.name === "undefined" ? 'Unregistered User' : suggestion.name}
                          font={font.boldBase}
                          color={vmTheme[theme].panelPrimaryColor}
                          padding="3px 0px"
                          width="155px"
                          cursor="pointer"
                        />
                        <Typography
                          content={suggestion.email}
                          font={font.normalBase_16}
                          color={vmTheme[theme].panelPrimaryColor}
                          padding="3px 0px"
                          width="155px"
                          cursor="pointer"
                        />
                      </SharedDetails>
                    </SuggestionItem>
                  ))}
                </SuggestionsWrapper>
              )}

              <InputDropdownWrap>
                <LabelDropdown
                  className="inputDropdown"
                  width="98px"
                  margin="10px 0px"
                  optionWidth="105px"
                  borderActive="none"
                  border="none"
                  options={shareOption}
                  onChange={handleDropdownChanges}
                  value={permission}
                />
              </InputDropdownWrap>
            </Span>
          </TagifyWrapper>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Action
            text="Send"
            margin="10px 0"
            background={
              tags.length && error === ""
                ? vmTheme[theme].headerColor
                : vmTheme[theme].rgbaBlack
            }
            hoverColor={vmTheme[theme].headerColor}
            isDisabled={!tags.length || error === "Invalid email address"}
            onClick={handleSend}
            isLoading={isLoading}
          />

          <Action
            text={name}
            className="shared-btn"
            font={font.normal14}
            height="auto"
            onClick={() => setOpen(true)}
            fontColor={vmTheme[theme].propertyColor}
          />
        </SharedWrapper>
      )}
    </div>
  );
};

ShareInput.propTypes = {
  icon: PropTypes.string,
  left: PropTypes.string,
  bottom: PropTypes.string,
  width: PropTypes.string,
  onFocus: PropTypes.func,
  // onBlur: PropTypes.func,
  onModalClose: PropTypes.func,
  setOpen: PropTypes.func,
  onOpen: PropTypes.bool,
};

export default ShareInput;
