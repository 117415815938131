/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import Action from "../../common-components/Action";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";
import { Modal } from "../../common-components/modal";
import DropdownLabel from "../../common-components/DropdownLabel";
import {
  updateEnterprise,
  fetchCountries,
} from "../../redux/actions/onboardingUtils";
import content from "../../constants/content";
import { STATIC_PATH } from "../../constants/config";
import { setWorkspaceTextFocus } from "../../redux/actions/appUtils";
import vmTheme from "../../constants/theme";

const Container = styled.div`
  padding: 32px 10px;
  max-height: 655px;
  overflow: hidden;
  width: 500px;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    pointer-events: none;
    opacity: 0.6;
  `}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  max-height: 480px;
  overflow-y: auto;
  padding: 10px 22px 0px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const PhoneNumberContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 38px;
  padding: 0px 12px 0px 0px;
  font-size: 14px;
  background: ${(props) => props.theme.polarColor};
  border: 1px solid ${(props) => props.theme.activeBorderColor};
  border-radius: 8px;
`;

const DialCode = styled.span`
  background: ${(props) => props.theme.polarColor};
  border-radius: 8px;
  padding: 0 12px;
  font-size: 14px;
  height: 100%;
  line-height: 36px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  min-width: 50px;
  position: relative;
  &:after {
    content: "";
    height: 50%;
    width: 1px;
    background: ${(props) => props.theme.activeBorderColor};
    position: absolute;
    right: 3px;
  }
`;

const PhoneNumberInput = styled.input`
  flex: 1;
  margin-left: 0;
  height: 100%;
  padding: 0px 12px;
  font-size: 14px;
  background: ${(props) => props.theme.polarColor};
  border: 0px solid ${(props) => props.theme.activeBorderColor};
  border-radius: 8px;
`;

const DropdownWrapper = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    background-image: url(${STATIC_PATH}DownArrowIcon.svg);
    width: 7px;
    height: 7px;
    background-repeat: no-repeat;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  ${({ dropdownVisible }) =>
    dropdownVisible &&
    `
    &:after{
      transform: translateY(-50%) rotate(180deg);
    }
    input{
      border: 1px solid ${(props) => props.theme.mediumGray};
    }
  `}
`;
const CountryDropdown = styled.ul`
  position: absolute;
  top: 41px;
  left: 0;
  width: 100%;
  max-height: 150px;
  background-color: ${(props) => props.theme.polarColor};
  border-radius: 6px;
  overflow-y: auto;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: ${(props) => props.theme.boxShadowColor} 0px 0px 4px;
`;

const CountryOption = styled.li`
  padding: 12px 10px;
  user-select: auto;
  color: ${(props) => props.theme.tertiaryColor};
  text-align: initial;
  float: initial;
  cursor: pointer;
  visibility: visible;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  font: ${font.normalBase};
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 15px;
    top: 8px;
    width: 4px;
    height: 12px;
    border-style: solid;
    border-color: ${(props) => props.theme.polarColor};
    border-image: initial;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
  }
  &:hover {
    color: ${(props) => props.theme.panelPrimaryColor};

    font-weight: 600;
    &:after {
      border-color: ${(props) => props.theme.secondaryBorderColor};
    }
  }
`;

const Label = styled.label`
  text-align: left;
  font: ${font.mediumBase_31};
  letter-spacing: 0px;
  color: ${(props) => props.theme.panelPrimaryColor};
  margin-bottom: 8px;
`;

const Span = styled.span`
  color: ${(props) => props.theme.textboxErrorColor};
  font: ${font.mediumBase_31};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 22px;
`;

const Input = styled.input`
  height: 38px;
  padding: 0px 12px;
  font-size: 14px;
  background: ${(props) => props.theme.polarColor};
  border: 1px solid ${(props) => props.theme.activeBorderColor};
  border-radius: 8px;
  width: 100%;
`;

const ErrorMsg = styled.span`
  color: ${(props) => props.theme.textboxErrorColor};
  font: ${font.normalSmall_14};
  padding-top: 5px;
`;

const customStyles = `
transition: none;
.dropdown-options-wrapper{
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
}
.dropdown-option-item{
  &:hover{
    background: ${(props) => props.theme.polarColor};
  label{
      color: ${(props) => props.theme.panelPrimaryColor};
      font-weight: 600;
    }
  }
  &:hover::after{
    border-color: ${(props) => props.theme.secondaryBorderColor}
  }
}
`;

const ProfessionalForm = ({
  usage,
  moreAboutYou,
  selectedValue = {},
  onClose,
  formContent
}) => {
  const formRef = useRef(null);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    companyEmail: "",
    jobTitle: "",
    companySize: "",
    country: "",
    phoneNumber: "",
    website: "",
    industry: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [dialCode, setDialCode] = useState("+0");
  // eslint-disable-next-line no-unused-vars
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const theme = useSelector((state) => state.app.get("theme"));

  useEffect(() => {
    fetchCountries()
      .then((response) => {
        setCountries(response.data);
      })
      .catch(() => {
      });
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setFormData(selectedValue);
      const selectedCountry = countries.find(
        (country) => country.value === selectedValue.country
      );
      if (selectedCountry) {
        setDialCode(selectedCountry.dialCode);
        setSearchQuery(selectedCountry.label);
      }
    }
  }, [countries, selectedValue]);

  const isBusinessEmail = (email) => {
    const businessDomains = [
      "gmail.com",
      "yahoo.com",
      "qq.com",
      "hotmail.com",
      "icloud.com",
      "comcast.net",
      "yopmail.net",
      "live.com",
      "freemail.com",
      "me.com",
      "ymail.com",
      "outlook.com",
      "rediffmail.com",
      "yopmail.com",
    ];
    const domain = email.split("@")[1];
    return businessDomains.includes(domain);
  };

  const handleKeyPress = (event) => {
    const inputKey = event.key;

    if (!/\d/.test(inputKey)) {
      event.preventDefault();
    }
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!formData.companyEmail) {
      errors.companyEmail = "Company email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.companyEmail)) {
      errors.companyEmail = "Invalid email address";
      valid = false;
    } else if (isBusinessEmail(formData.companyEmail)) {
      errors.companyEmail = "Please enter a valid business email";
      valid = false;
    }
    const maxWords = 30;
    const maxLength = 30;
    const invalidCharacters = /[^a-zA-Z0-9\s]/;

    if (!formData.jobTitle) {
      errors.jobTitle = "Job title is required";
      valid = false;
    } else if (formData.jobTitle.length > maxLength) {
      errors.jobTitle = `Job title should not exceed ${maxLength} characters`;
      valid = false;
    } else if (formData.jobTitle.trim().split(/\s+/).length > maxWords) {
      errors.jobTitle = `Job title should not exceed ${maxWords} words`;
      valid = false;
    } else if (invalidCharacters.test(formData.jobTitle)) {
      errors.jobTitle = "Job title should not contain special characters";
      valid = false;
    }

    if (!formData.companySize) {
      errors.companySize = "Company size is required";
      valid = false;
    }

    if (!formData.country) {
      errors.country = "Country is required";
      valid = false;
    }
    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
      valid = false;
    } else {
      const phoneNoValidate = formData.phoneNumber.trim().replace(/\s+/g, '');
      if (!/^\+?\d+$/.test(phoneNoValidate)) {
        errors.phoneNumber = "Phone number should contain numbers only and may start with a '+'";
        valid = false;
      }
      else if (phoneNoValidate.length < 8 || phoneNoValidate.length > 15) {
        errors.phoneNumber = "Phone number should be between 8 and 15 digits";
        valid = false;
      }
    }

    if (!formData.website) {
      errors.website = "Website is required";
      valid = false;
    } else if (!/^((https?:\/\/)?(www\.)?\S+\.\S+)$/.test(formData.website)) {
      errors.website = "Website URL format is invalid";
      valid = false;
    }

    if (!formData.industry) {
      errors.industry = "Industry is required";
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    setDisabled(true);
    const isValid = validateForm();

    if (isValid) {
      const reqBody = {
        user_type: usage,
        user_role: moreAboutYou,
        user_details: formData,
        is_onboarding_flow_completed: 1,
        is_final_submission: 1,
      };
      updateEnterprise(reqBody)
        .then(() => {
          setShowPopup(true);
          setFormVisible(false);
          setButtonLoading(false);
          setDisabled(false);
        })
        .catch(() => {
          setButtonLoading(false);
          setDisabled(false);
        });
    } else {
      setButtonLoading(false);
      setDisabled(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value || "",
    });
  };

  const handleDropdownChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "country") {
      const selectedCountry = countries.find(
        (country) => country.value === value
      );
      if (selectedCountry) {
        setDialCode(selectedCountry.dialCode);
        if (
          formData.phoneNumber &&
          !formData.phoneNumber.startsWith(selectedCountry.dialCode)
        ) {
          setFormData({
            ...formData,
            phoneNumber: `${selectedCountry.dialCode
              } ${formData.phoneNumber.replace(/^\+\d+\s*/, "")}`,
          });
        }
      }
    }
  };

  const handleInputClick = () => {
    setDropdownVisible(true);
    setSearchQuery("");
  };

  const handleCountryChange = (country) => {
    setFormData((prev) => ({
      ...prev,
      country: country.label,
    }));
    setDialCode(country.dialCode);
    setSelectedCountry(country.label);
    setSearchQuery(country.label);
    setDropdownVisible(false);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setDropdownVisible(true);
  };

  const filteredCountries = countries.filter((country) =>
    country.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      {formVisible && (
        <Container isDisabled={disabled}>
          <Typography
            content={formContent || content.LET_GET_YOUR_TEAM_WITH_VMAKER}
            font={font.boldSmall_22}
            enableTrim={false}
            color={vmTheme[theme].panelPrimaryColor}
            margin="0px 0px"
            padding="0px 22px"
            display="inline-block"
            width="100%"
            align="center"
          />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="companyEmail">Company Email <Span>*</Span></Label>
              <Input
                type="email"
                id="companyEmail"
                name="companyEmail"
                value={formData.companyEmail}
                onChange={handleChange}
                required
                onFocus={() => dispatch(setWorkspaceTextFocus(true))}
                onBlur={() => dispatch(setWorkspaceTextFocus(false))}
              />
              {formErrors.companyEmail && (
                <ErrorMsg className="error-message">
                  {formErrors.companyEmail}
                </ErrorMsg>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="jobTitle">Job Title <Span>*</Span></Label>
              <Input
                type="text"
                id="jobTitle"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                required
                onFocus={() => dispatch(setWorkspaceTextFocus(true))}
                onBlur={() => dispatch(setWorkspaceTextFocus(false))}
              />
              {formErrors.jobTitle && (
                <ErrorMsg className="error-message">
                  {formErrors.jobTitle}
                </ErrorMsg>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="CompanySize">Company Size</Label>
              <DropdownLabel
                options={[
                  { label: "1-10", value: "1-10" },
                  { label: "11-50", value: "11-50" },
                  { label: "51-200", value: "51-200" },
                  { label: "200+ employees", value: "200+ employees" },
                ]}
                name="CompanySize"
                labelText="Choose Company Size"
                onChange={(value) => handleDropdownChange("companySize", value)}
                value={formData.companySize || "Choose Company Size"}
                borderRadius="8px"
                border={`1px solid ${vmTheme[theme].activeBorderColor}`}
                optionWidth="100%"
                height="36px"
                margin={"0px"}
                showRequiredStar={false}
                customStyles={customStyles}
                color={vmTheme[theme].panelPrimaryColor}
                fontStyle={font.normalMini_14}
              />
              {formErrors.companySize && (
                <ErrorMsg className="error-message">
                  {formErrors.companySize}
                </ErrorMsg>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Industry">Industry <Span>*</Span></Label>
              <DropdownLabel
                options={[
                  {
                    label: "Advertising / Agency",
                    value: "Advertising / Agency",
                  },
                  { label: "E-commerce", value: "E-commerce" },
                  { label: "Entertainment", value: "Entertainment" },
                  { label: "Finance", value: "Finance" },
                  {
                    label: "Medical / Pharmaceutical",
                    value: "Medical / Pharmaceutical",
                  },
                  {
                    label: "Internet / Software",
                    value: "Internet / Software",
                  },
                  {
                    label: "Construction / Real estate",
                    value: "Construction / Real estate",
                  },
                  { label: "Education", value: "Education" },
                  { label: "Manufacturing", value: "Manufacturing" },
                  { label: "Textile", value: "Textile" },
                  { label: "Food processing", value: "Food processing" },
                  { label: "Hospitality", value: "Hospitality" },
                  { label: "Law", value: "Law" },
                  { label: "Logistics", value: "Logistics" },
                  { label: "Others", value: "Others" },
                ]}
                name="Industry"
                labelText="Choose Industry"
                onChange={(value) => handleDropdownChange("industry", value)}
                value={formData.industry || "Choose Industry"}
                borderRadius="8px"
                border={`1px solid ${vmTheme[theme].activeBorderColor}`}
                optionWidth="100%"
                margin={"0px"}
                height="36px"
                showRequiredStar={false}
                customStyles={customStyles}
                color={vmTheme[theme].panelPrimaryColor}
                fontStyle={font.normalMini_14}
              />
              {formErrors.industry && (
                <ErrorMsg className="error-message">
                  {formErrors.industry}
                </ErrorMsg>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="country">Country <Span>*</Span></Label>
              {/* <DropdownLabel
            id="country"
            labelText="Country"
            name="country"
            options={countries.map(country => ({ value: country.value, label: country.label }))}
            selectedValue={formData.country}
            onChange={(value) => handleDropdownChange('country', value)}
            customStyles={customStyles}
          /> */}
              <DropdownWrapper dropdownVisible={dropdownVisible} ref={dropdownRef}>
                <Input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Choose country"
                  onClick={handleInputClick}
                  id="country"
                  name="country"
                  required
                  onFocus={() => dispatch(setWorkspaceTextFocus(true))}
                  onBlur={() => dispatch(setWorkspaceTextFocus(false))}
                />
                {dropdownVisible && (
                  <CountryDropdown>
                    {filteredCountries.map((country) => (
                      <CountryOption
                        key={country.label}
                        onClick={() => handleCountryChange(country)}
                      >
                        {country.label}
                      </CountryOption>
                    ))}
                  </CountryDropdown>
                )}
              </DropdownWrapper>
              {formErrors.country && (
                <ErrorMsg className="error-message">
                  {formErrors.country}
                </ErrorMsg>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="phoneNumber">Phone Number <Span>*</Span></Label>
              <PhoneNumberContainer>
                <DialCode>{dialCode}</DialCode>
                <PhoneNumberInput
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                  onFocus={() => dispatch(setWorkspaceTextFocus(true))}
                  onBlur={() => dispatch(setWorkspaceTextFocus(false))}
                  onKeyPress={handleKeyPress}
                />
              </PhoneNumberContainer>
              {formErrors.phoneNumber && (
                <ErrorMsg className="error-message">
                  {formErrors.phoneNumber}
                </ErrorMsg>
              )}
            </FormGroup>
            <FormGroup>
              <Label htmlFor="website">Website <Span>*</Span></Label>
              <Input
                type="url"
                id="website"
                name="website"
                value={formData.website}
                onChange={handleChange}
                required
                onFocus={() => dispatch(setWorkspaceTextFocus(true))}
                onBlur={() => dispatch(setWorkspaceTextFocus(false))}
              />
              {formErrors.website && (
                <ErrorMsg className="error-message">
                  {formErrors.website}
                </ErrorMsg>
              )}
            </FormGroup>
          </Form>
          <ButtonContainer>
            <Action
              text="Contact Us"
              width={buttonLoading ? "100%" : "100%"}
              height="36px"
              padding="0px 20px"
              background={vmTheme[theme].secondaryBorderColor}
              font={font.normalMini_14}
              customClass="open-vmaker"
              marginTop="30px"
              marginBottom="0"
              onClick={handleSubmit}
              isLoading={buttonLoading}
              borderRadius="8px"
            />
          </ButtonContainer>
        </Container>
      )}
      {showPopup && (
        <Modal
          showModal={showPopup}
          onClose={() => {
            setShowPopup(false);
            onClose();
          }}
          width="384px"
          height="auto"
          borderRadius="8px"
        >
          <div style={{ padding: "24px" }}>
            <Typography
              content="Thank you for your interest!"
              font={font.boldSmall}
              color={vmTheme[theme].tertiaryColor}
              margin="0 0 9px"
              display="inline-block"
              width="100%"
            />
            <Typography
              content="Our sales team will get in touch with you soon."
              font={font.normal_16}
              color={vmTheme[theme].shortsSubText}
              margin="0px 0px 12px"
              display="inline-block"
              width="100%"
              enableTrim={false}
            />
            <Action
              text="Okay"
              width={buttonLoading ? "auto" : "170px"}
              height="40px"
              padding="0px 20px"
              background={vmTheme[theme].secondaryBorderColor}
              hoverColor={vmTheme[theme].secondaryBorderColor}
              font={font.normalMini_14}
              customClass="open-vmaker"
              marginTop="24px"
              marginBottom="0"
              margin="24px auto 0px"
              onClick={() => {
                setShowPopup(false);
                onClose();
              }}
              isLoading={buttonLoading}
              borderRadius="8px"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

ProfessionalForm.propTypes = {
  usage: PropTypes.string,
  moreAboutYou: PropTypes.string,
  selectedValue: PropTypes.shape({
    companyEmail: PropTypes.string,
    jobTitle: PropTypes.string,
    companySize: PropTypes.string,
    country: PropTypes.string,
    phoneNumber: PropTypes.string,
    website: PropTypes.string,
    industry: PropTypes.string,
  }),
  onClose: PropTypes.func,
  formContent: PropTypes.string,
};

export default ProfessionalForm;
