import React from "react";
import PropTypes from "prop-types";
import {
  AmountSection,
  Head,
  Image,
  List,
  UnorderList,
  UpgradCard,
  Wrapper,
} from "./upgrade-components";
import Typography from "../../common-components/Typography";
import { font } from "../../constants/font";
import { STATIC_PATH } from "../../constants/config";
import Action from "../../common-components/Action";
import { PLAN } from "../../constants";
import content from "../../constants/content";

const PlanCard = ({
  iconSrc,
  planName,
  titleSubtext,
  width,
  height,
  planContent,
  action,
  onClick,
  actionBgColor,
  data,
  enterpriseContent,
  monthlyPrice,
  yearlyPrice,
  billingCycle,
  className
}) => {
  const isTeamPlan = data.plan === PLAN.TEAM;

  return (
    <UpgradCard
      width={width}
      height={height}
      enterprise={data.plan === PLAN.ENTERPRISE}
      isPremium={isTeamPlan}
      onClick={onClick}
      className={className}
    >
      <Wrapper>
        <Head>
          <Image src={`${STATIC_PATH}${iconSrc}`} />
          <Typography
            content={planName}
            font={font.bold_16}
            color={"#313345"}
            padding={"3px 0px"}
            cursor={"pointer"}
            enableTrim={false}
          />
        </Head>
        <Typography
          content={titleSubtext}
          font={font.normalMicro12}
          color={"#7B7E8C"}
          padding={"3px 0px"}
          cursor={"pointer"}
          enableTrim={false}
        />
        {data.plan === PLAN.ENTERPRISE ? (
          <Typography
            content={enterpriseContent}
            font={font.normal_14_23}
            color={"#313345"}
            cursor={"pointer"}
            align={"center"}
            enableTrim={false}
            padding="18px 0px"
          />
        ) : (
          <AmountSection>
            <Typography
              content={monthlyPrice || yearlyPrice}
              font={font.largeBold_40}
              padding="3px 0"
              color={"#0D0F0F"}
              cursor={"pointer"}
              enableTrim={false}
            />
            <Typography
              content={billingCycle === 0 ? content.MONTH_USER : content.YEARLY_USER}
              font={font.normal_13_22}
              padding="1px 0"
              color={"#4D5061"}
              cursor={"pointer"}
            />
          </AmountSection>
        )}
      </Wrapper>
      <UnorderList>
        {planContent && planContent.map((data) => {
          return (
            <List key={data}>
              <Typography
                content={data}
                color={"#313345"}
                font={font.normalBase_21}
                enableTrim={false}
                cursor={"pointer"}
              />
            </List>
          );
        })}
      </UnorderList>
      <Action
        text={action}
        background={
          isTeamPlan
            ? "transparent linear-gradient(98deg, #FF781A 0%, #F55322 100%)"
            : actionBgColor
        }
        borderRadius={"8px"}
        font={font.boldBase}
        className={isTeamPlan
          ? "upgradeTeam"
          : "upgradeButton"}
      />
    </UpgradCard>
  );
};

PlanCard.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  iconSrc: PropTypes.string,
  titleSubtext: PropTypes.string,
  planName: PropTypes.string,
  planContent: PropTypes.string,
  onClick: PropTypes.func,
  action: PropTypes.string,
  actionBgColor: PropTypes.string,
  enterpriseContent: PropTypes.string,
  data: PropTypes.object,
  monthlyPrice: PropTypes.number,
  yearlyPrice: PropTypes.number,
  billingCycle: PropTypes.number,
  className: PropTypes.string
};
export default PlanCard;
